import React from 'react';
import {Route} from 'react-router';
import FormContext from './FormContext';
import SignUpStep1 from 'views/SignUp/Step1';
import SignUpStep2 from 'views/SignUp/Step2';



const SignUp: React.FC = () => {
    return (
        <FormContext>
            <Route exact path="/signUp/1" component={SignUpStep1}/>
            <Route exact path="/signUp/2" component={SignUpStep2}/>
        </FormContext>
    )
};

export default SignUp;

