// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetPreSaleProduct} from 'services/preSale';


/**
 * 取得預先下特商品列表
 */
function* fetchPreSale(action: IPickAction<IActionCreators, 'fetchPreSale'>) {
    yield put(Actions.fetchPreSaleBegin());

    try {
        const {startDate, endDate, model} = action.payload;
        const [{body}]: [TGetPreSaleProduct] = yield all([
            call(ApiService.getPreSaleProduct, startDate, endDate, model),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, currentPage, pageLimit, totalItems, totalPages} = body.data;
        yield put(Actions.fetchPreSaleSuccess({preSaleList: rows, preSalePagination: {currentPage, pageLimit, totalItems, totalPages}}));

    } catch (err) {
        yield put(Actions.fetchPreSaleFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 送出商品到預先下特（整批）
 */
function* submitPreSaleBatch(action: IPickAction<IActionCreators, 'submitPreSaleBatch'>) {
    yield put(Actions.submitPreSaleBatchBegin());
    yield put(UiBlockActions.visible({message: '正在加入預先下特'}));

    try {
        const {productGoodsId, batchQty} = action.payload;
        yield all([
            call(ApiService.postPreSaleBatch, productGoodsId, batchQty),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(Actions.submitPreSaleBatchSuccess());
        yield put(UiDialogActions.openSuccess({message: '成功加入預先下特'}));

    } catch (err) {
        yield put(Actions.submitPreSaleBatchFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

/**
 * 送出商品到預先下特（挑碼 or 現貨）
 */
function* submitPreSalePick(action: IPickAction<IActionCreators, 'submitPreSalePick'>) {
    yield put(Actions.submitPreSalePickBegin());
    yield put(UiBlockActions.visible({message: '正在加入預先下特'}));

    try {
        const {productGoodsId, sizeQty, isInStock} = action.payload;
        yield all([
            call(ApiService.postPreSalePick, productGoodsId, sizeQty, isInStock),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(Actions.submitPreSalePickSuccess());
        yield put(UiDialogActions.openSuccess({message: '成功加入預先下特'}));

    } catch (err) {
        yield put(Actions.submitPreSalePickFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}



export default [
    takeLatest(ActionTypes.FETCH_PRE_SALE, fetchPreSale),
    takeLatest(ActionTypes.SUBMIT_PRE_SALE_BATCH, submitPreSaleBatch),
    takeLatest(ActionTypes.SUBMIT_PRE_SALE_PICK, submitPreSalePick),
];
