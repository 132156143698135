import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {Controller, useForm} from 'react-hook-form';
import {Row, Col, media} from 'library/styled-bs-grid';
import {isEmpty} from 'utils/equal';
import site from 'config/site';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import DateTimeInput from 'components/atoms/DateTimeInput';
import {motion, AnimatePresence} from 'framer-motion'
import {getOs} from 'utils/browser';

interface IProps {
    isVisible: boolean;
    onClose?: Function;
    onSubmitForm?: Function;
    bankList: Array<{
        id: number;
        name: string;
        nickName: string;
        account: string;
    }>;
}

/**
 *  存款通知單光箱
 * @constructor
 */
const DepositNoticeModal: React.FC<IProps> = ({
    isVisible = false,
    onClose = () => {},
    onSubmitForm = () => {},
    bankList,
}) => {
    const [toBankId, setToBankId] = useState<number|null>(null);
    const {handleSubmit, watch, control} = useForm({
        mode: 'onChange'
    });

    const {fromName, fromDepositAt, fromLast5Number, amount} = watch();

    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    /**
     * 檢查是否可以送出
     */
    const checkDisabledSubmit = () => {
        if (isEmpty(fromName) ||
            isEmpty(fromDepositAt) ||
            isEmpty(fromLast5Number) ||
            isEmpty(amount) ||
            isEmpty(toBankId)
        ){
            return false;
        }

        return true;
    };


    /**
     * 送出存款通知單
     * @param formData
     */
    const onSubmit = (formData: any) => {
        formData.toBankId = toBankId;
        onSubmitForm(formData);
        onClose();
    };


    return (
        <AnimatePresence>
            {isVisible && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalRoot
                        initial={'initial'}
                        animate={'isOpen'}
                        exit={'exit'}
                        variants={modalVariant}
                    >
                        <div className="d-flex align-items-end justify-content-center align-items-md-center">
                            <Content
                                variants={containerVariant}
                                transition={{type: "spring", duration: 0.5}}
                            >
                                <Header>
                                    <Title>存款通知單</Title>
                                    <CloseButton
                                        type="button"
                                        onClick={onClose}
                                    >
                                        <Icon code="times" color="#282731" size={10}/>
                                    </CloseButton>
                                </Header>

                                <Line/>

                                <FieldContent>
                                    <FillTitle>填寫匯款資料：</FillTitle>
                                    <Controller
                                        control={control}
                                        name="fromName"
                                        rules={{required: true}}
                                        as={<CustomInput
                                            placeholder="匯款人姓名"
                                        />}
                                    />

                                    <FieldRow>
                                        <FieldCol col={24} md={12}>
                                            <Controller
                                                control={control}
                                                name="fromDepositAt"
                                                as={<CustomDateTimeInput
                                                    label="匯款時間"
                                                    isSetTodayVisible
                                                    showTime
                                                />}
                                            />
                                        </FieldCol>
                                        <FieldCol col={24} md={12}>
                                            <Controller
                                                control={control}
                                                name="fromLast5Number"
                                                rules={{required: true}}
                                                as={<CustomInput
                                                    type="number"
                                                    placeholder="匯款後5碼"
                                                />}
                                            />
                                        </FieldCol>
                                    </FieldRow>

                                    <Controller
                                        control={control}
                                        name="amount"
                                        rules={{required: true}}
                                        as={<CustomInput
                                            type="number"
                                            placeholder="匯款金額"
                                            isVisibleCleanButton
                                        />}
                                    />

                                    <ChooseTitle>選擇匯入帳號：</ChooseTitle>
                                    {bankList.map(row => (
                                        <Account
                                            key={row.id}
                                            isSelected={row.id === toBankId}
                                            onClick={() => setToBankId(row.id)}
                                        >
                                            <div className="d-flex flex-column flex-grow-1">
                                                <FullName>{row.name}</FullName>
                                                <BankAccount>{row.account}</BankAccount>
                                            </div>
                                            <BankNiceName>{row.nickName}</BankNiceName>
                                        </Account>
                                    ))}

                                    <RemarkTitle>備註留言：</RemarkTitle>
                                    <Controller
                                        control={control}
                                        name="remark"
                                        as={<CustomInput
                                            placeholder="備註訊息"
                                            remarkMessage="其他帳號請填寫存入銀行名稱及後4碼，無摺存款請提供收據章上的局號。"
                                        />}
                                    />

                                    <ConfirmButton
                                        theme="default"
                                        disabled={!checkDisabledSubmit()}
                                        type="submit"
                                    >
                                        確認付款
                                    </ConfirmButton>
                                </FieldContent>
                            </Content>
                        </div>
                    </ModalRoot>
                </form>
            )}
        </AnimatePresence>
    );
};

export default DepositNoticeModal;

const ConfirmButton = styled(Button)`
    width: 240px;
    min-height: 44px;
    align-self: center;
    margin-bottom: 20px;

    ${media.md`
        width: 300px;
        height: 56px;
        margin-bottom: 0;
    `}
`;

const RemarkTitle = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #6e6e6e;
    display: block;
    padding: 10px 0 5px 0;

    ${media.md`
        font-size: 20px;
        font-weight: 600;
        padding: 10px 0;
    `}
`;

const BankNiceName = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9d9a9a;

    ${media.md`
        font-size: 16px;
    `}
`;

const BankAccount = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;

    ${media.md`
        font-size: 14px;
    `}
`;

const FullName = styled.span`
    font-size: 10px;
    color: #6e6e6e;
    padding-bottom: 2px;

    ${media.md`
        font-size: 17px;
        font-weight: bold;
        padding-bottom: 7px;
        color: #9d9a9a;
    `}
`;

const Account = styled.div<any>`
    width: 100%;
    min-height: 44px;
    border-radius: 5px;
    border: solid 1px #9d9a9a;
    padding: 0 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    ${media.md`
        min-height: 70px;
        padding: 0 18px;
    `}

    ${props => props.isSelected && css`
        border: solid 1px ${site.theme.thirdColor};

        ${FullName} {
            color: ${site.theme.thirdColor};
        }
        ${BankAccount} {
            color: ${site.theme.thirdColor};
        }
        ${BankNiceName} {
            color: ${site.theme.thirdColor};
        }
    `}
`;

const ChooseTitle = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #6e6e6e;
    padding: 10px 0 5px 0;
    display: block;

    ${media.md`
        font-size: 20px;
        font-weight: 600;
        padding: 10px 0;
    `}
`;

const CustomDateTimeInput = styled(DateTimeInput)`
    margin-bottom: 10px;
`;

const FieldCol = styled(Col)`
    ${media.md`
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

const FieldRow = styled(Row)`
    flex: 0 0 auto;

    ${media.md`
        margin-left: -5px;
        margin-right: -5px;
    `}
`;

const CustomInput = styled(Input)`
    margin-bottom: 10px;
`;

const FillTitle = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #6e6e6e;
    display: block;
    padding-bottom: 5px;

    ${media.md`
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 10px;
    `}
`;

const FieldContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    ${media.lg`
        overflow-y: scroll;
        overflow-x: hidden;
    `}

    ${media.xl`
        padding: 0 45px;
    `}

    ${media.xxl`
        overflow: initial;
    `}
`;

const Line = styled.div`
    width: 100%;
    min-height: 1px;
    background-color: ${site.theme.secondColor};
    margin-bottom: 20px;
`;

const CloseButton = styled.button<any>`
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    padding: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 30px;
        height: 30px;

        i{
            font-size: 20px;
        }
    `}
`;

const Title = styled.span`
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #282731;
    display: block;

    ${media.md`
        font-size: 24px;
        font-weight: 500;
    `}
`;

const Header = styled.div`
    margin-bottom: 13px;
    position: relative;

    ${media.md`
        margin-bottom: 24px;
    `}

    ${media.xl`
        margin-bottom: 20px;
    `}
`;

const Content = styled(motion.div)`
    width: 100%;
    height: calc(100% - 20px);
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    padding: 13px 10px 20px 10px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    position: absolute;

    ${media.md`
        width: 726px;
        height: 875px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        border-radius: 0;
        padding: 23px 20px 30px 20px;
        overflow-y: initial;
        top: 0;
        bottom: 0;
        margin: auto;
    `}

    ${media.lg`
        height: 90%;
    `}

    ${media.xl`
        width: 900px;
        padding: 23px 30px 30px 30px;
    `}

    ${media.xxl`
        height: 875px;
    `}
`;

const ModalRoot = styled(motion.div)`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 50;
`;
