// Libraries
import api from 'library/apisauce';

import {TPostMemberDepositRes, TGetRemit} from './types';


export default {
    /**
     * 新增匯款單
     */
    postMemberDeposit: (fromName: string, fromDepositAt: string, fromLast5Number: string, amount: number, toBankId: string, remark?: string): Promise<TPostMemberDepositRes> => {
        return api.post('/member/deposit', {
            fromName,
            fromDepositAt,
            fromLast5Number,
            amount,
            toBankId,
            remark,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 匯款單列表
     */
    getRemit: (currentPage: number = 1, pageLimit: number = 20): Promise<TGetRemit> => {
        return api.get('/member/deposit', {currentPage, pageLimit}, {
            headers: {isAuth: true},
        });
    },
};
