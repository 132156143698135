import * as React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    children: React.ReactNode;
    isVisible?: boolean;
}

/**
 * Tooltips
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Tooltips: React.FC<IProps> = ({
    className,
    style,
    children,
    isVisible = true,
}) => (
    <TooltipsRoot
        className={className}
        style={style}
        isVisible={isVisible}
    >
        {children}

        <TooltipsArrow/>
    </TooltipsRoot>
);

export default Tooltips;

const TooltipsRoot = styled.div<any>`
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 99;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 13px);
    padding: 10px;
    opacity: 0;
    transition: opacity .3s ease;
    pointer-events: none;

    ${props => props.isVisible && css`
        opacity: 1;
    `};

    ${media.lg`
        padding: 10px 30px;
    `};
`;

const TooltipsArrow = styled.div`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
`;
