import React, {useCallback} from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {media, Container} from 'library/styled-bs-grid';
import {useSelector} from 'react-redux';
import BearCarousel, {BearSlideItem, elClassName} from 'bear-react-carousel';

// Component
import ProductCard from 'components/molecules/ProductCard';
import Icon from 'components/atoms/Icon';

// Stores
import {Selector as AuthSelector} from 'store/auth';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    isAuth: boolean;
    data: Array<{
        id: number;
        brandName: string;
        name: string;
        model: string;
        thumbUrl: string;
        downloadThumbUrl: string;
        size: string;
        color: string;
        price: number;
        prePrice: number;
        createDate: string;
        isNew: boolean;
        isSale: boolean;
        isInStock: boolean;
        isVipGoods: boolean;
        isHot: boolean;
        isOutStock: boolean;
        isTrack: boolean;
        isOrderPreSale: boolean;
    }>;
    onSpeedOrder?: Function;
    onClickFavorite?: Function;
}

/**
 * ProductCarousel
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProductCarousel: React.FC<IProps> = ({
    className,
    style,
    isAuth = false,
    data = [],
    onSpeedOrder = () => {},
    onClickFavorite = () => {},
}) => {
    const {payload} = useSelector(state => ({
        payload: AuthSelector.payload(state),
    }));

    const renderNavButton = useCallback((handleGoToPrev: any, handleGoToNext: any) => {
        return <Nav>
            <PreButton button="button" onClick={handleGoToPrev}><ArrowIcon code="chevron-fillet-right" color="#282731" size={15} rotate={180}/></PreButton>
            <NextButton button="button" onClick={handleGoToNext}><ArrowIcon code="chevron-fillet-right" color="#282731" size={15}/></NextButton>
        </Nav>;
    }, []);



    const sliderDataList = data.map(row => {
        return {
            key: row.id,
            children: <BearSlideItem key={row.id} as="card">
                <CustomProductCard
                    id={row.id}
                    key={row.id}
                    isVisibleHeart={false}
                    isVisiblePrice={false}
                    isVisibleSpeed={false}
                    isVisibleDetail={isAuth}
                    brandName={row.brandName}
                    name={row.name}
                    thumbUrl={row.thumbUrl}
                    downloadThumbUrl={row.downloadThumbUrl}
                    model={row.model}
                    size={row.size}
                    color={row.color}
                    price={row.price}
                    prePrice={row.prePrice}
                    createDate={row.createDate}
                    isNew={row.isNew}
                    isSale={row.isSale}
                    isInStock={row.isInStock}
                    isHot={row.isHot}
                    isOutStock={row.isOutStock}
                    isVipGoods={row.isVipGoods}
                    isFavorite={row.isTrack}
                    detailPath={`/product/detail/${payload?.id}/${row.id}`}
                    onSpeedOrder={() => onSpeedOrder(row.id)}
                    onClickFavorite={() => onClickFavorite(row.id, !row.isTrack)}
                />
            </BearSlideItem>,
        }
    });


    return (
        <ProductCarouselRoot style={style} className={className}>
            <ProductBg>
                <BearCarousel
                    slidesPerView={2}
                    slidesPerGroup={2}
                    spaceBetween={8}
                    // isEnableAutoPlay
                    autoPlayTime={5000}
                    isEnableLoop
                    isEnablePagination
                    isEnableNavButton={false}
                    data={sliderDataList}
                    renderNavButton={renderNavButton}
                    aspectRatio={{widthRatio: 5, heightRatio: 6, addStaticHeight: '120px'}}
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 12,
                            isEnablePagination: true,
                            aspectRatio: {widthRatio: 5, heightRatio: 6, addStaticHeight: '120px'},
                        },
                        992: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 10,
                            isEnablePagination: false,
                            aspectRatio: {widthRatio: 6, heightRatio: 7, addStaticHeight: '100px'},
                        },
                        1200: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 12,
                            isEnablePagination: false,
                            isEnableNavButton: true,
                            aspectRatio: {widthRatio: 6, heightRatio: 7, addStaticHeight: '100px'},
                        },
                        1540: {
                            slidesPerView: 5,
                            slidesPerGroup: 5,
                            spaceBetween: 20,
                            isEnablePagination: false,
                            isEnableNavButton: true,
                            aspectRatio: {widthRatio: 6, heightRatio: 7, addStaticHeight: '100px'},
                        },
                    }}
                />
            </ProductBg>
        </ProductCarouselRoot>
    );
};

export default ProductCarousel;

const ArrowIcon = styled(Icon)``;

const NavigationButton = styled.button`
    border: none;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f4f9;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
`;

const NextButton = styled(NavigationButton)<any>`
    left: 100%;
    transform: translate(10px);

    ${media.xxl`
        transform: translate(20px);
    `}
`;

const PreButton = styled(NavigationButton)<any>`
    right: 100%;
    transform: translate(-10px);

    ${media.xxl`
        transform: translate(-20px);
    `}
`;

const Nav = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
`;

const CustomProductCard = styled(ProductCard)`
    padding-bottom: 36px;

    ${media.lg`
        padding: 0;
    `}
`;

const ProductBg = styled.div`
    width: 100%;
`;

const ProductCarouselRoot = styled(Container)`


  .${elClassName.paginationButton}{
      transition: width .6s;
      width: 6px;
      height: 6px;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 0 4px;

       &[data-active]{
          background-color:  #8ec5ff;;
            opacity: 1;
            width: 20px;
        }
  }

    ${media.xl`
        padding-left: 30px;
        padding-right: 30px;
    `}

    ${media.xxl`
        padding-left: 40px;
        padding-right: 40px;
    `}
`;
