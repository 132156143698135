// Libraries & Utils
import Immutable from 'seamless-immutable';
import {TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'preSale';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    message: '',

    preSaleList: [],
    preSalePagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        pageLimit: 0,
    },
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得預先下特商品列表
     */
    fetchPreSale: (state) => {
        return state;
    },
    fetchPreSaleBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取預先下特商品清單'});
    },
    fetchPreSaleSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', preSaleList: payload.preSaleList, preSalePagination: payload.preSalePagination});
    },
    fetchPreSaleFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 送出商品到預先下特（整批）
     */
    submitPreSaleBatch: (state) => {
        return state;
    },
    submitPreSaleBatchBegin: (state) => {
        return state.merge({isFetching: true, message: '正在加入預先下特'});
    },
    submitPreSaleBatchSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    submitPreSaleBatchFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 送出商品到預先下特（挑碼 or 現貨）
     */
    submitPreSalePick: (state) => {
        return state;
    },
    submitPreSalePickBegin: (state) => {
        return state.merge({isFetching: true, message: '正在加入預先下特'});
    },
    submitPreSalePickSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    submitPreSalePickFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
