import React, {useContext} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media, Container, Row, Col,} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import {asset} from 'config/app';
import {formatCurrency} from 'utils/number';
import {isEmpty} from 'utils/equal';
import site from 'config/site';

// Component
import A from 'components/atoms/A';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import DropDownList from 'components/atoms/DropDownList';
import {StoreContext} from '../../../views/HomeLayout/FormContext';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    isAuth?: boolean;
    account?: string;
    avatarUrl?: string;
    walletAmount?: number;
    unpaidAmount?: number;
    messageUnreadCount?: number;
    favoriteItemCount?: number;
    shoppingCartCount?: number;
    onClickSearchIcon?: Function;
    onClickLoginButton?: Function;
    onClickLogOutButton?: Function;
    onClickSignUpButton?: Function;
}

/**
 * Navbar
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Navbar: React.FC<IProps> = ({
    className,
    style,
    isAuth = false,
    account = '',
    avatarUrl,
    walletAmount = 0,
    unpaidAmount = 0,
    messageUnreadCount = 0,
    favoriteItemCount = 0,
    shoppingCartCount = 0,
    onClickSearchIcon = () => {},
    onClickLoginButton = () => {},
    onClickLogOutButton = () => {},
    onClickSignUpButton = () => {},
}) => {
    const history = useHistory();
    const {searchForm} = useContext(StoreContext);
    const {register, handleSubmit, watch, getValues}: any = searchForm;
    const {search: searchText} = watch(['search']);


    const onSubmit = () => {
        const {categoryGroupId, categoryMainId, categorySubId, type, search, season, popularId, sort} = getValues();

        // 品牌
        const {brands: checkedId} = getValues({nest: true});
        const ids = [];
        for(const id in checkedId){
            // 過濾掉不等於 true 的 Key
            if(checkedId[id]){
                ids.push(id);
            }
        }
        const brandsJson = JSON.stringify(ids);

        if (type === '1') {
            // 搜尋商品貨號
            history.push(`/product?categoryGroupId=${categoryGroupId ?? ''}&categoryMainId=${categoryMainId ?? ''}&categorySubId=${categorySubId ?? ''}&brands=${brandsJson}&season=${season ?? ''}&popularId=${popularId ?? ''}&sort=${sort ?? 'onAtDesc'}&model=${search}&typeId=1`);
        } else if (type === '2') {
            // 搜尋商品名稱
            history.push(`/product?categoryGroupId=${categoryGroupId ?? ''}&categoryMainId=${categoryMainId ?? ''}&categorySubId=${categorySubId ?? ''}&brands=${brandsJson}&season=${season ?? ''}&popularId=${popularId ?? ''}&sort=${sort ?? 'onAtDesc'}&name=${search}&typeId=2`)
        } else {
            // 搜尋品牌
            history.push(`/product?categoryGroupId=${categoryGroupId ?? ''}&categoryMainId=${categoryMainId ?? ''}&categorySubId=${categorySubId ?? ''}&brands=${brandsJson}&season=${season ?? ''}&popularId=${popularId ?? ''}&sort=${sort ?? 'onAtDesc'}&brandName=${search}&typeId=3`)
        }
    };

    return (
        <NavbarRoot style={style} className={className} isAuth={isAuth}>
            {isAuth && (
                <MemberInfo className="d-md-none">
                    <Info>{`${account} /  $${formatCurrency(walletAmount - unpaidAmount)}`}</Info>
                </MemberInfo>
            )}

            <Row className="h-100">
                <Col col={isAuth ? true : 'auto'} md className="d-flex align-items-center">
                    <MenuButton
                        className="d-block d-md-none"
                        type="button"
                        onClick={() => history.push('/menu')}
                    >
                        <Icon code="bars" color="#282731" size={20}/>
                    </MenuButton>

                    <Row>
                        {isAuth ? (
                            <Col col={24} lg="auto" className="d-none d-md-flex align-items-center">
                                <DropDownList
                                    account={account}
                                    avatarUrl={avatarUrl}
                                    amount={(walletAmount - unpaidAmount)}
                                    onClickLogOut={onClickLogOutButton}
                                    contentList={[
                                        {iconCode: 'user-circle', name: '我的帳戶', href: '/profile/account'},
                                        {iconCode: 'comment-dots', name: '訊息', href: '/profile/message'},
                                        {iconCode: 'heart', name: '追踨清單', href: '/profile/favorite'},
                                        {iconCode: 'clipboard-list-check', name: '預先下特', href: '/profile/advanceOrder'},
                                        {iconCode: 'clipboard-list', name: '我的訂單', href: '/profile/order'},
                                        {iconCode: 'box', name: '備貨狀態', href: '/profile/stockUp'},
                                        {iconCode: 'truck', name: '出貨明細', href: '/profile/ship'},
                                        {iconCode: 'clipboard-list-sync', name: '匯款通知', href: '/profile/remittance'},
                                        {iconCode: 'clipboard-list-clock', name: '交易記錄', href: '/profile/trade'},
                                    ]}
                                />
                            </Col>
                        ) : (
                            <Col col={24} lg="auto" className="d-none d-md-flex align-items-center">
                                <LoginButton
                                    theme="default"
                                    onClick={onClickLoginButton}
                                >
                                    登入
                                </LoginButton>
                                <SingUpButton
                                    theme="default"
                                    onClick={onClickSignUpButton}
                                >
                                    註冊
                                </SingUpButton>
                            </Col>
                        )}

                        <Col col="auto" className="d-none d-xl-flex">
                            <MessageButton type="button" onClick={() => history.push('/profile/message')}>
                                <Icon code="comment-dots" size={30} color="#282731"/>
                                {messageUnreadCount > 0 && <Number>{messageUnreadCount}</Number>}
                            </MessageButton>
                        </Col>
                        <Col col="auto" className="d-none d-xl-flex">
                            <TrackButton type="button" onClick={() => history.push('/profile/favorite')}>
                                <Icon code="heart" size={30} color="#282731"/>
                                {favoriteItemCount > 0 && <Number>{favoriteItemCount}</Number>}
                            </TrackButton>
                        </Col>
                        <Col col="auto" className="d-none d-xl-flex">
                            <BagButton type="button" onClick={() => history.push('/shop/bag')}>
                                <Icon code="shopping-bag" size={30} color="#282731"/>
                                {shoppingCartCount > 0 && <Number>{shoppingCartCount}</Number>}
                            </BagButton>
                        </Col>
                    </Row>
                </Col>

                <Col col="auto" className="d-flex align-items-center justify-content-between justify-content-md-center">
                    <LogoBox>
                        <A href="/">
                            <Logo src={asset('/images/home/header-logo.png')}/>
                        </A>
                    </LogoBox>
                </Col>

                <Col col className="d-flex justify-content-end">
                    <RightRow className="justify-content-end align-items-center">
                        <Col col="auto" xl className="d-none d-xl-flex justify-content-end">
                            <Welcome className="d-xxl-none">歡迎光臨韓爸有衣<br/>韓國童裝批發網站！</Welcome>
                            <Welcome className="d-none d-xxl-block">歡迎光臨韓爸有衣 韓國童裝批發網站！</Welcome>
                        </Col>

                        <Col col="auto">
                            <Row className="justify-content-end align-items-center">
                                {!isAuth && (
                                    <Col col="auto" className="d-flex d-md-none">
                                        <LoginButton
                                            theme="default"
                                            onClick={onClickLoginButton}
                                        >
                                            登入
                                        </LoginButton>
                                    </Col>
                                )}

                                <Col col="auto" className="d-flex d-xl-none">
                                    <SearchIconButton type="button" onClick={onClickSearchIcon}>
                                        <Icon code="search" color="#282731" size={28}/>
                                    </SearchIconButton>
                                </Col>

                                <Col col="auto" className="d-none d-md-flex d-xl-none">
                                    <MessageButton type="button" onClick={() => history.push('/profile/message')}>
                                        <Icon code="comment-dots" size={30} color="#282731"/>
                                        {messageUnreadCount > 0 && <Number>{messageUnreadCount}</Number>}
                                    </MessageButton>
                                </Col>
                                <Col col="auto" className="d-none d-md-flex d-xl-none">
                                    <TrackButton type="button" onClick={() => history.push('/profile/favorite')}>
                                        <Icon code="heart" size={30} color="#282731"/>
                                        {favoriteItemCount > 0 && <Number>{favoriteItemCount}</Number>}
                                    </TrackButton>
                                </Col>
                                <Col col="auto" className="d-none d-md-flex d-xl-none">
                                    <BagButton type="button" onClick={() => history.push('/shop/bag')}>
                                        <Icon code="shopping-bag" size={30} color="#282731"/>
                                        {shoppingCartCount > 0 && <Number>{shoppingCartCount}</Number>}
                                    </BagButton>
                                </Col>

                                <SearchCol col={12} lg="auto" className="d-none d-xl-flex justify-content-start align-items-center">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <SearchSection>
                                            <SearchSelect
                                                name="type"
                                                ref={register()}
                                            >
                                                <option value={1}>貨號</option>
                                                <option value={2}>商品</option>
                                                <option value={3}>品牌</option>
                                            </SearchSelect>

                                            <SearchInput
                                                placeholder="搜尋..."
                                                name="search"
                                                ref={register()}
                                            />
                                            <SearchButton isFill={!isEmpty(searchText)}>
                                                <Icon code="search" size={20}/>
                                            </SearchButton>
                                        </SearchSection>
                                    </form>
                                </SearchCol>
                            </Row>
                        </Col>
                    </RightRow>
                </Col>
            </Row>
        </NavbarRoot>
    );
};

export default Navbar;

const Number = styled.div`
    width: 14px;
    height: 14px;
    background-color: #ff7d6a;
    border-radius: 50%;
    font-size: 8px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -3px;
    top: -2px;

    ${media.xl`
        width: 20px;
        height: 20px;
        font-size: 12px;
        right: -8px;
        top: 8px;
    `}
`;

const RightIconButton = styled.button`
    padding: 0;
    position: relative;

    i{
        transition: color .2s;
    }
`;

const MessageButton = styled(RightIconButton)`
    &:hover{
        i{
            color: ${site.theme.primaryColor};
        }
    }
`;

const TrackButton = styled(RightIconButton)`
    &:hover{
        i{
            color: #ff7d6a;
        }
    }
`;

const BagButton = styled(RightIconButton)`
    &:hover{
        i{
            color: #a8d580;
        }
    }
`;

const SearchIconButton = styled.button<any>`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Welcome = styled.span`
    font-size: 12px;
    color: #282731;
    white-space: nowrap;
`;

const RightRow = styled(Row)`
    width: 100%;
`;

const LoginButton = styled(Button)`
    width: 60px;
    min-height: auto;
    height: 26px;
    font-size: 12px;
    border-radius: 2px;
    background-color: #9c92ff;
    border: solid 1px #9c92ff;
    padding: 0;

    ${media.md`
        margin: 0 5px;
        height: 30px;
    `}

    ${media.xl`
        width: 90px;
        height: 35px;
        border-radius: 5px;
    `}

    ${media.xxl`
        width: 100px;
        height: 40px;
        border-radius: 5px;
    `}
`;

const SearchCol = styled(Col)`
    padding-left: 0;
    padding-right: 0;

    ${media.xl`
        padding-left: 10px;
        padding-right: 10px;
    `}
`;

const SingUpButton = styled(LoginButton)`
    background-color: transparent;
    border: solid 1px #9d9a9a;
    color: #9d9a9a;

    &:hover {
        border-color: #9d9a9a !important;
    }
`;

const Logo = styled.img`
    width: 160px;
    height: 43px;
    margin: auto;

    ${media.md`
        width: 187px;
        height: 50px;
    `}

    ${media.xl`
        width: 177px;
        height: 47px;
    `}

    ${media.xxl`
        width: 220px;
        height: auto;
    `}
`;

const LogoBox = styled.div`
`;

const SearchButton = styled.button<any>`
    width: 32px;
    height: 32px;
    border-radius: 20px;
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4px;
    margin: auto;
    transition: background-color .3s;

    i{
        color: #6e6e6e;
        transition: color .3s;
    }

    ${props => props.isFill && css`
        background-color: ${props => props.theme.primaryColor};

        i{
            color: #ffffff;
        }
    `}
`;

const SearchInput = styled.input`
    width: 100%;
    height: 100%;
    background-color: #edeff2;
    border: none;
    padding-left: 10px;
    font-size: 14px;
    color: #6e6e6e;

    ::placeholder {
        color: #dddddd;
    }
`;

const SearchSelect = styled.select`
    font-size: 14px;
    color: #6e6e6e;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const SearchSection = styled.div<any>`
    width: 228px;
    height: 40px;
    background-color: #edeff2;
    border-radius: 20px;
    padding: 0 40px 0 20px;
    position: relative;
    display: flex;
    align-items: center;

    ${media.xxl`
        margin-left: 20px;
    `}
`;

const MenuButton = styled.button`
    padding: 0;
    height: 100%;
`;

const Info = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
`;

const MemberInfo = styled.div`
    width: 100%;
    height: 30px;
    background-color: #282731;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NavbarRoot = styled(Container)<any>`
    height: 55px;
    padding-top: 0;

    ${props => props.isAuth && css`
        height: 85px;
        padding-top: 30px;
    `}

    ${media.md`
        height: 65px;
        padding-top: 0;
    `}

    ${media.xxl`
        height: 78px;
    `}
`;
