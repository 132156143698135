/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `src/resources/lang`)
 */

import React from 'react';
import {IntlProvider} from 'react-intl';
import { useSelector } from 'react-redux';
import {MessageFormatElement} from "intl-messageformat-parser";
import TranslationWrapper from './TranslationWrapper';
import IntlGlobalProvider from '../global';
import {ILocaleCode} from '../types';

interface IProps {
    messages: {
        [code in ILocaleCode]?: Record<string, string> | Record<string, MessageFormatElement[]>;
    }
    children: React.ReactNode;
}

const LanguageProvider: React.FC<IProps> = ({
      messages,
      children
}) => {
    const locale = useSelector((state) => state.language.locale);
    const activeMessage = messages[locale];

    return (
        <IntlProvider
            locale={locale}
            key={locale}
            defaultLocale="en-US"
            messages={activeMessage}
            // @ts-ignore
            textComponent={TranslationWrapper}
        >
            <IntlGlobalProvider>
                {React.Children.only(children)}
            </IntlGlobalProvider>
        </IntlProvider>
    );
};

export default LanguageProvider;
