import React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import {Row, Col} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    children?: React.ReactNode;
    isActiveCode?: string;
}

const dataList = [
    {
        code: '/',
        text: '首頁',
        href: '/',
        icon: 'home',
    },
    {
        code: '/profile/message',
        text: '訊息',
        href: '/profile/message',
        icon: 'comment-dots',
    },
    {
        code: '/profile/order',
        text: '我的訂單',
        href: 'profile/order',
        icon: 'clipboard-list',
    },
    {
        code: '/shop/bag',
        text: '購物袋',
        href: '/shop/bag',
        icon: 'shopping-bag',
    },
    {
        code: '/profile/account',
        text: '我的帳戶',
        href: '/profile/account',
        icon: 'user-circle',
    },
];

/**
 * TabBar
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TabBar: React.FC<IProps> = ({
    className,
    style,
    isActiveCode = '',
}) => {
    const history = useHistory();

    return (
        <TabBarRoot style={style} className={className}>
            <TabBarRow noGutters>
                {dataList.map(row => {
                    const isActive = row.code === isActiveCode;
                    return (
                        <Col col key={row.text} className="d-flex justify-content-center">
                            <Circle className="d-flex justify-content-center" isActive={isActive}>
                                <Item onClick={() => history.push(row.href)}>
                                    <Icon code={row.icon} size={25} color="#6e6e6e"/>
                                    <div>{row.text}</div>
                                </Item>
                            </Circle>
                        </Col>
                    );
                })}
            </TabBarRow>
        </TabBarRoot>
    );
};

export default TabBar;

const Item = styled.button<any>`
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    > div{
        padding-top: 2px;
    }
`;

const Circle = styled.div<any>`
    ${props => props.isActive && css`
        position: absolute;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: solid 4px #ffffff;
        background-color: #8ec5ff;
        bottom: 0;
        top: 0;
        margin: auto;

        ${Item} {
            > div{
                color: #ffffff;
            }

            > i{
                color: #ffffff;
            }
        }
    `}
`;

const TabBarRow = styled(Row)`
    height: 54px;
    background-color: #ffffff;
    padding: 0 10px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
`;

const TabBarRoot = styled.div`
    width: 100%;
    height: 70px;
    padding-top: 16px;
    overflow: hidden;
`;
