import React, {useEffect} from 'react';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {get} from 'lodash';
import {Container} from 'library/styled-bs-grid';
import {formatCurrency} from 'utils/number';
import dayjs from 'dayjs';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';
import site from 'config/site';

// Component
import Icon from 'components/atoms/Icon';
import {StatusTag} from 'components/atoms/Tag';

// Reducer
import {Actions as ShipmentAction} from 'store/shipment';
import {Selector as AuthSelector} from 'store/auth';

/**
 *  出貨明細-明細
 * @constructor
 */
const Detail: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {payload, orderShipperList, orderShipperDetail} = useSelector(state => ({
        payload: AuthSelector.payload(state),
        orderShipperList: state.shipment.orderShipperList,
        orderShipperDetail: state.shipment.orderShipperDetail,
    }));

    const productInfo = orderShipperList.find(o => o.id === Number(id));

    useEffect(() => {
        if (get(orderShipperList, 'length', 0) === 0) {
            dispatch(ShipmentAction.fetchOrderShipper({shipperId: id}));
        }

        dispatch(ShipmentAction.fetchOrderShipperDetail({id}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* Mobile Header */}
            <Container>
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/ship')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>出貨明細</MobileTitle>

                    <IconButton>
                        {/*<Icon code="print" size={20} color="#282731"/>*/}
                    </IconButton>
                </MobileHeader>
            </Container>

            <ContainerInfo>
                <OrderInfo>
                    <TopSection>
                        <CustomStatusTag theme="green" text="已出貨"/>
                        <Price>$ {formatCurrency(get(productInfo, 'totalAmount'))}</Price>
                    </TopSection>

                    <OrderContent>
                        <FieldTitle>出貨單號</FieldTitle>
                        <FieldValue>{get(productInfo, 'id')}</FieldValue>
                        <View>查看商品</View>
                    </OrderContent>
                    <OrderContent>
                        <FieldTitle>出貨時間</FieldTitle>
                        <FieldValue>{dayjs(get(productInfo, 'shippedAt')).format('YYYY-MM-DD')}</FieldValue>
                    </OrderContent>

                    {/* 隱藏出貨方式 */}
                    {/*<OrderContent>*/}
                    {/*    <FieldTitle>出貨方式</FieldTitle>*/}
                    {/*    <FieldValue>自行通知</FieldValue>*/}
                    {/*</OrderContent>*/}

                    <OrderContent>
                        <FieldTitle>物流公司</FieldTitle>
                        <FieldValue>{get(productInfo, 'freightCompany')}</FieldValue>
                    </OrderContent>
                    <OrderContent className="align-items-start">
                        <FieldTitle>出貨地址</FieldTitle>
                        <FieldValue>{get(productInfo, 'deliveryAddress')}</FieldValue>
                    </OrderContent>
                </OrderInfo>
            </ContainerInfo>


            <Container className="d-flex flex-column">

                {/* Detail */}
                {orderShipperDetail.map(row => (
                    <ProductCard key={get(row, 'id')}>
                        <Model onClick={() => window.open(`/product/detail/${payload?.id}/${row.productGoods.id}`)}>{get(row, 'productGoods.model')}</Model>
                        <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                        <ProductName>{get(row, 'productGoods.name')}</ProductName>
                        <SubName>{get(row, 'productGoods.subName')}</SubName>

                        <div className="d-flex">
                            <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>

                            <div>
                                <Info>
                                    <DetailFieldTitle>顏色</DetailFieldTitle>
                                    <DetailFieldValue>{get(row, 'productGoods.color')}</DetailFieldValue>
                                </Info>
                                <Info>
                                    <DetailFieldTitle>購買SIZE</DetailFieldTitle>
                                    <DetailFieldValue>{get(row, 'size')}</DetailFieldValue>
                                </Info>
                                <Info>
                                    <DetailFieldTitle>單價</DetailFieldTitle>
                                    <DetailFieldValue>$ {formatCurrency(get(row, 'price'))}</DetailFieldValue>
                                    <DetailFieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</DetailFieldValue>
                                </Info>
                                <Info>
                                    <DetailFieldTitle>數量</DetailFieldTitle>
                                    <DetailFieldValue>{get(row, 'qty')}</DetailFieldValue>
                                </Info>
                                <Info>
                                    <DetailFieldTitle>總計</DetailFieldTitle>
                                    <DetailFieldValue>$ {formatCurrency(get(row, 'totalAmount', 0))}</DetailFieldValue>
                                </Info>
                            </div>
                        </div>
                    </ProductCard>
                ))}

                <HomeButton type="button" onClick={() => history.push('/')}>回首頁</HomeButton>
            </Container>
        </>
    );
};

export default Detail;

const HomeButton = styled.button`
    width: 250px;
    height: 30px;
    border-radius: 2px;
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    margin: 10px 0 20px 0;
    text-align: center;
    align-self: center;
`;

const DetailFieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const DetailFieldTitle = styled.span`
    width: 62px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
`;

const Info = styled.div`
    display: flex;
    align-items: center;
`;

const ProductImg = styled.img`
    width: 100px;
    height: 100px;
    margin-right: 6px;
    background-color: lightgray;
`;

const SubName = styled.span`
    font-size: 12px;
    text-align: center;
    color: #6e6e6e;
    margin-bottom: 8px;
`;

const ProductName = styled.span`
    font-size: 12px;
    text-align: center;
    color: #6e6e6e;
`;

const BrandName = styled.span`
    font-size: 12px;
    text-align: center;
    color: #6e6e6e;
`;

const Model = styled.span`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #6e6e6e;
`;

const ProductCard = styled.div`
    width: 100%;
    min-height: 185px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    padding: 10px 8px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
`;

const View = styled.span`
    font-size: 12px;
    color: #d8d8d8;
    text-align: right;
`;

const OrderContent = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 5px;
`;

const OrderInfo = styled.div`
    padding: 0 10px 6px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
`;

const FieldValue = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    flex: 1;
`;

const FieldTitle = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    padding-right: 8px;
`;

const Price = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #ff7d6a;
`;

const CustomStatusTag = styled(StatusTag)`
    width: 64px;
    height: 20px;
`;

const TopSection = styled.div`
    width: 100%;
    height: 30px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
`;

const ContainerInfo = styled(Container)`
    background-color: #fcfcfc;
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
