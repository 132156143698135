import React from 'react';

interface IProps {
    dangerouslySetInnerHTML: {
        __html: string;
    };
}

const TranslationWrapper: React.FC<IProps> = ({
  dangerouslySetInnerHTML,
  ...props
}) => {
    if(dangerouslySetInnerHTML){
        return <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...props}/>
    }
    return <React.Fragment {...props}/>
};

export default TranslationWrapper;
