import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {media, Container} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';
import {motion} from 'framer-motion'
import {getOs} from 'utils/browser';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onClose: Function;
    sizesTable: Array<{
       id: number;
       url: string;
    }>;
}

/**
 * SizeDescModal
 * @param props
 * @returns {*}
 * @constructor
 */
const SizeDescModal: React.FC<IProps> = ({
    className,
    style,
    onClose = () => {},
    sizesTable,
}) => {
    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    return (
        <Modal
            className={className}
            style={style}
            variants={containerVariant}
            transition={{type: "spring", duration: 0.5}}
        >
            <Container>
                <Header className="d-flex flex-column align-items-center">
                    <Title>尺寸說明</Title>
                    <IconButton type="button" onClick={() => onClose()}>
                        <Icon code="times" size={15} color="#d8d8d8"/>
                    </IconButton>
                    <Line/>
                </Header>
                {sizesTable.map(row => (
                    <SizeImg key={row.id} src={row.url}/>
                ))}

                <Desc>
                    尺寸表僅供參考, 各品牌尺寸可能稍有不同<br/>
                    如有疑問留言問。<br/>
                    刊登圖片皆為韓國提供之實品圖<br/>
                    因顯示器不同皆會出現色差！顏色及尺寸皆以實品圖為主。<br/>
                    代購款, 無法退換貨, 請媽咪們務必確認好尺寸再下標購買
                </Desc>
            </Container>
        </Modal>
    );
};

export default SizeDescModal;


const Desc = styled.p`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`

const SizeImg = styled.img`
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #a8d580;
`;

const IconButton = styled.button<any>`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 0;

    ${media.md`
        i {
            font-size: 20px;
        }
    `}

    ${media.xl`
        width: 30px;
        height: 30px;

        i {
            font-size: 25px;
        }
    `}
`;

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #282731;
    margin-bottom: 13px;

    ${media.md`
        font-size: 16px;
        margin-bottom: 15px;
    `}

    ${media.xl`
        font-size: 24px;
        margin-bottom: 25px;
    `}
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 10px;
    margin-bottom: 10px;
    position: relative;

    ${media.md`
        margin-bottom: 20px;
    `}

    ${media.xxl`
        margin-bottom: 40px;
    `}
`;

const Modal = styled(motion.div)`
    width: 100%;
    min-height: 420px;
    max-height: 90%;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    overflow: scroll;
    padding: 13px 0 10px 0;
    position: absolute;

    ${media.md`
        width: 460px;
        min-height: 590px;
        max-height: 680px;
        border-radius: 10px;
        padding: 30px 20px;
    `}

    ${media.xl`
        width: 900px;
        height: 850px;
        border-radius: 0;
        padding: 23px 20px 40px 20px;
    `}
`;
