// Libraries & Utils
import React from 'react';
import { Provider } from 'react-redux';
import Immutable from "seamless-immutable";
import { ThemeProvider } from 'styled-components/macro';
import { ConnectedRouter } from 'connected-react-router';
import {history, AutoScrollTop} from 'library/react-router';
import { GridThemeProvider } from 'library/styled-bs-grid';
import {LanguageProvider, translationMessages} from 'library/intl';
import configureStore from "library/redux/configureStore";

// Configs & Data
import gridConfig from 'config/grid';
import site from 'config/site';
import {persistKey, persistVersion} from "config/app";
import Route from './Route';
import GlobalBlock from './GlobalBlock';
import GlobalDialog from './GlobalDialog';



// 持久化資料回存到 Redux (若版本不同則重設)
let initialState = {};
if(persistVersion !== window.localStorage.getItem('persistVersion')){
    window.localStorage.setItem('persistVersion', persistVersion);
    window.localStorage.setItem(persistKey, '{}');
}else{
    initialState = JSON.parse(window.localStorage.getItem(persistKey) || '{}');
}

const store = configureStore(Immutable(initialState), history);



const App: React.FC = () => {
    return ( <Provider store={ store }>
            <ConnectedRouter history={history}>
                <LanguageProvider messages={translationMessages}>
                    <GridThemeProvider gridTheme={gridConfig}>
                        <ThemeProvider theme={site.theme}>
                            <Route/>
                            <GlobalBlock/>
                            <GlobalDialog/>
                            <AutoScrollTop/>
                        </ThemeProvider>
                    </GridThemeProvider>
                </LanguageProvider>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;

