import {ILocaleCode} from "../library/intl/types";

type TTheme = any;
interface ISite {
    siteName: string,
    meta: {title: string, description: string},
    googleBot: {noIndex: boolean}
    isPWAEnable: boolean,
    defaultLang: ILocaleCode,
    blackLang: ILocaleCode[]
    theme: TTheme
}

const site: ISite = {
    siteName: '韓爸有衣',
    meta: {
        title: '韓爸有衣 - 🏆韓國童裝女裝玩具雜貨批發網',
        description: '韓爸有衣是一個最棒最齊全的韓國童裝女裝玩具雜貨批發網',
    },
    googleBot: {
        noIndex: true,
    },
    isPWAEnable: true,
    defaultLang: 'zh-TW',
    blackLang: ['zh-TW'],
    theme: {
        pwaStartUpBackground: '#0e0f13',
        primaryColor: '#8ec5ff',
        primaryContrastColor: 'rgba(0, 163, 224, 0.8)',
        primaryShadowColor: 'rgba(0, 163, 224, 0.4)',
        primaryGradientColor: '#004e6b',
        loaderMaskColor: '#0d3c6f',
        secondColor: '#a8d580',
        thirdColor: '#9c92ff',
    },
};

export default site;
