import React, {useRef} from 'react';
import {useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {Col, Container, media, Row} from 'library/styled-bs-grid';
import site from 'config/site';
import {asset} from 'config/app';
import {formatCurrency} from 'utils/number';
import get from 'lodash/get';
import dayjs from 'dayjs';
import {isEmpty} from 'utils/equal';
import {HalfCircleSpinner} from 'react-epic-spinners';

// Component
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';


interface IProps {
    onClose?: Function;
    id: number;
    isFetching: boolean;
}

/**
 *  出貨單光箱
 * @constructor
 */
const PrintModal: React.FC<IProps> = ({
    onClose = () => {},
    id,
    isFetching,
}) => {
    const printRef = useRef<HTMLDivElement>();

    const {orderShipperList, orderShipperDetail, company} = useSelector(state => ({
        orderShipperList: state.shipment.orderShipperList,
        orderShipperDetail: state.shipment.orderShipperDetail,
        company: state.system.company,
    }));

    const shipInfo = orderShipperList.find(o => o.id === id);

    const handlePrint = () => {
        const printContents = get(printRef, 'current.innerHTML');
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    return (
        <PrintModalRoot>
            <PrintContainer className="h-100 d-flex align-items-center justify-content-center">
                <Modal ref={printRef}>
                    <Header>
                        <Title>出貨單</Title>
                        <CloseButton
                            onClick={onClose}
                        >
                            <Icon code="times" color="#282731" size={25}/>
                        </CloseButton>
                    </Header>

                    <ShopInfo>
                        <ShopName>韓爸有衣YAHOO</ShopName>
                        <Address>{get(shipInfo, 'deliveryAddress')}</Address>
                        <div className="d-flex align-items-center">
                            <Phone>聯絡電話：{get(shipInfo, 'deliveryPhone')}</Phone>
                            <Company>物流公司：{get(shipInfo, 'freightCompany')}</Company>
                        </div>
                        <div className="d-flex align-items-center">
                            <Email>電子信箱：{get(shipInfo, 'deliveryEmail')}</Email>
                            <Model>追蹤單號：{get(shipInfo, 'freightNumber')}</Model>
                        </div>

                        <PrintButton
                            className="d-none d-lg-flex"
                            theme="green"
                            onClick={() => handlePrint()}
                        >
                            <CustomIcon code="print" size={20} color="#fff"/>
                            列印
                        </PrintButton>
                    </ShopInfo>

                    <Detail>
                        <DetailTitle>
                            <Num>項</Num>
                            <OrderTime>訂購時間</OrderTime>
                            <Product>商品貨號/品牌</Product>
                            <OrderInfo>訂購資訊</OrderInfo>
                            <Price>單價</Price>
                            <Remark>備註</Remark>
                        </DetailTitle>

                        {isFetching && (
                            <Row>
                                <Col col>
                                    <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                                </Col>
                            </Row>
                        )}

                        {!isFetching && orderShipperDetail.map(row => (
                            <Item key={row.id} className="noBreak">
                                <Num>
                                    <FieldValue>{row.id}</FieldValue>
                                </Num>
                                <OrderTime>
                                    <FieldValue>{dayjs(get(row, 'createdAt')).format('YYYY-MM-DD HH:mm:ss')}</FieldValue>
                                </OrderTime>
                                <Product className="d-flex align-items-center">
                                    <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl', '')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>

                                    <div className="d-flex flex-column justify-content-center">
                                        <ProductModel>{get(row, 'productGoods.model')}</ProductModel>
                                        <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                                        <ProductName>{get(row, 'productGoods.name')}</ProductName>
                                        <FieldValue>{get(row, 'productGoods.subName')}</FieldValue>
                                    </div>
                                </Product>
                                <OrderInfo className="d-flex flex-column">
                                    <FieldValue>{get(row, 'productGoods.color')}</FieldValue>
                                    <Size>{`${get(row, 'size')} (${get(row, 'qty')}件)`}</Size>
                                </OrderInfo>
                                <Price>
                                    <FieldValue>$ {formatCurrency(get(row, 'price'))}</FieldValue>
                                    <FieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                                </Price>
                                <Remark>
                                    <RemarkText>{get(row, 'remark')}</RemarkText>
                                </Remark>
                            </Item>
                        ))}
                    </Detail>

                    <PrintAt>列印時間：{dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}</PrintAt>

                    <Footer>
                        <Logo src={asset('/images/home/header-logo.png')}/>

                        <Contact>
                            <IconDiv>
                                <Icon code="phone-alt" color="#9d9a9a" size={20}/>
                            </IconDiv>
                            <IconValue>{get(company, 'tel')}</IconValue>
                        </Contact>
                        <Contact>
                            <IconDiv>
                                <Icon code="fa-mobile" color="#9d9a9a" size={20}/>
                            </IconDiv>
                            <IconValue>{get(company, 'mobile')}</IconValue>
                        </Contact>
                        <Contact>
                            <IconDiv>
                                <Icon code="envelope" color="#9d9a9a" size={20}/>
                            </IconDiv>
                            <IconValue>{get(company, 'email')}</IconValue>
                        </Contact>
                        <Contact>
                            <IconDiv>
                                <Icon code="line" color="#9d9a9a" size={20}/>
                            </IconDiv>
                            <IconValue>LINE ID : {get(company, 'line')}</IconValue>
                        </Contact>
                    </Footer>
                </Modal>
            </PrintContainer>
        </PrintModalRoot>
    );
};

export default PrintModal;

const IconValue = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #9d9a9a;
`;

const IconDiv = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Contact = styled.div`
    display: flex;
    align-items: center;

    &:nth-last-child(1) {
        margin-right: 0;
    }

    ${media.lg`
        margin-right: 16px;
    `}
`;

const Logo = styled.img`
    width: 119px;
    height: 29px;

    ${media.lg`
        margin-right: 16px;
    `}
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.lg`
        justify-content: center;
    `}
`;

const PrintAt = styled.span`
    font-size: 14px;
    color: #9d9a9a;
    display: block;
    text-align: end;
    margin-bottom: 20px;
`;

const FieldValue = styled.span<any>`
    font-size: 14px;
    color: #6e6e6e;

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const RemarkText = styled(FieldValue)`
    color: ${site.theme.thirdColor};
`;

const Size = styled(FieldValue)`
    padding-top: 6px;
`;

const ProductName = styled(FieldValue)`
    padding-bottom: 6px;
`;

const BrandName = styled(FieldValue)`
    padding-bottom: 6px;
`;

const ProductModel = styled(FieldValue)`
    font-weight: bold;
    padding-bottom: 8px;
`;

const ProductImg = styled.img`
    width: 70px;
    height: 80px;
    margin-right: 10px;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: solid 1px #ebebeb;
`;

const FieldTitle = styled.span`
    font-size: 14px;
    font-weight: 300;
    color: #6e6e6e;
`;

const Remark = styled(FieldTitle)`
    width:10%;
    text-align: center;
`;

const Price = styled(FieldTitle)`
    width: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const OrderInfo = styled(FieldTitle)`
    width: 17%;
`;

const Product = styled(FieldTitle)`
    width: 40%;
`;

const OrderTime = styled(FieldTitle)`
    width: 12%;
`;

const Num = styled(FieldTitle)`
    width: 8%;
    text-align: center;
`;

const DetailTitle = styled.div`
    display: flex;
    padding: 15px 0;
    border-bottom: solid 1px #ebebeb;

    ${media.xxl`
        padding: 20px 0;
    `}
`;

const Detail = styled.div`
    min-height: 359px;
    display: flex;
    flex-direction: column;
    border: solid 1px #ebebeb;
    margin-bottom: 10px;
    overflow-y: scroll;

    ${media.xxl`
        min-height: 462px;
    `}
`;

const CustomIcon = styled(Icon)`
    margin-right: 10px;
`;

const PrintButton = styled(Button)`
    width: 88px;
    height: 40px;
    min-height: initial;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
`;

const InfoText = styled.span`
    font-size: 16px;
    color: #6e6e6e;
    padding-bottom: 4px;
`;

const Model = styled(InfoText)`
    padding: 0;
`;

const Company = styled(InfoText)`
`;

const Email = styled(Company)`
    width: 369px;
`;

const Phone = styled(InfoText)`
    width: 369px;
`;

const Address = styled(InfoText)`
`;

const ShopName = styled.span`
    font-size: 20px;
    color: #6e6e6e;
    padding-bottom: 4px;

    ${media.xxl`
        font-size: 24px;
    `}
`;

const ShopInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
`;

const CloseButton = styled.button<any>`
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    padding: 0;
    top: 0;
    bottom: 0;
    margin: auto;
`;

const Title = styled.span`
    font-size: 20px;
    font-weight: 500;
    color: #282731;
    display: block;
    text-align: center;

    ${media.xxl`
        font-size: 24px;
    `}
`;

const Header = styled.div`
    padding-bottom: 15px;
    position: relative;
    border-bottom: solid 1px ${site.theme.secondColor};
    margin-bottom: 20px;

    ${media.xxl`
        padding-bottom: 25px;
    `}
`;

const Modal = styled.div<any>`
    width: 100%;
    height: 830px;
    background-color: #ffffff;
    padding: 23px 20px 30px 20px;
    display: flex;
    flex-direction: column;

    ${media.lg`
        width: 900px;
        height: 700px;
        padding: 23px 30px;
    `}

    ${media.xxl`
        height: 850px;
        padding: 23px 30px 30px 30px;
    `}
`;

const PrintContainer = styled(Container)`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PrintModalRoot = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 50;
`;
