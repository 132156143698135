import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {formatCurrency} from 'utils/number';
import {isEmpty} from 'utils/equal';
import dayjs from 'dayjs';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {asset} from 'config/app';
import site from 'config/site';

// component
import Icon from 'components/atoms/Icon';
import Pagination from 'components/atoms/Pagination';
import Search from './Search';

// Reducers
import {Actions as OrderAction} from 'store/order';
import {Selector as AuthSelector} from 'store/auth';


// 一頁顯示筆數
const pageLimit = 20;

const placeholder = {
    1: '搜尋商品貨號',
    2: '搜尋商品名稱',
    3: '搜尋品牌名稱',
    4: '搜尋訂單編號',
};

/**
 * 備貨狀態
 * @constructor
 */
const StockUp: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [categoryId, setCategoryId] = useState<number|null>(null);
    const [isVisibleSearch, setVisibleSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const useFormParams = useForm();
    const {handleSubmit, getValues, watch, setValue} = useFormParams;

    const {payload, isFetching, stockCategory, stockProductList, stockProductPagination} = useSelector(state => ({
        payload: AuthSelector.payload(state),
        isFetching: state.order.isFetching,
        stockCategory: state.order.stockCategory,
        stockProductList: state.order.stockProductList,
        stockProductPagination: state.order.stockProductPagination,
    }));

    useEffect(() => {
        // 預設選擇第一個類別
        if (isEmpty(categoryId)) {
            setCategoryId(get(stockCategory, '0.id'));
        }

        // 預設搜尋商品貨號
        setValue('searchType', '1');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockCategory]);


    // 選取中的狀態資訊
    const selectedStatus = stockCategory.find(o => o.id === categoryId);

    const {startDate, endDate} = getValues();
    const {searchType, search} = watch(['searchType', 'search']);

    let goodsModel = '';
    let goodsName = '';
    let brandName = '';
    let orderId = '';
    if (searchType === '1') {
        // 商品貨號
        goodsModel = search;
    } else if (searchType === '2') {
        // 商品名稱
        goodsName = search;
    } else if (searchType === '3') {
        // 品牌名稱
        brandName = search;
    } else if (searchType === '4') {
        // 訂單編號
        orderId = search;
    }


    // 取得備貨狀態類別
    useEffect(() => {
        dispatch(OrderAction.fetchStockCategory({}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // 取得商品備貨狀態資料
    useEffect(() => {
        if (!isEmpty(categoryId)) {
            dispatch(OrderAction.fetchStockProduct({categoryId, startDate, endDate, goodsModel, goodsName, brandName, orderId, currentPage, pageLimit}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId, currentPage]);


    /**
     *  搜尋商品
     */
    const handleSearch = (formData: any) => {
        setVisibleSearch(false);

        // 搜尋商品備貨狀態資料
        dispatch(OrderAction.fetchStockProduct({
            categoryId,
            startDate: formData.startDate,
            endDate: formData.endDate,
            goodsModel,
            goodsName,
            brandName,
            orderId,
            currentPage,
            pageLimit,
        }));
    };


    /**
     * 換頁
     */
    const handleChangePage = (p: number) => {
        setCurrentPage(p);
    };

    /**
     * 商品取消申請
     */
    const handleApplyCancel = (id: number) => {
        dispatch(OrderAction.fetchApplyCancel({id, categoryId, startDate, endDate, goodsModel, goodsName, brandName, orderId, currentPage, pageLimit}));
    };

    // 狀態欄位
    const renderStatus = (status: number, isEnableCancel: boolean, id: number) => {
        if (status === 0) {
            if (isEnableCancel) {
                return (
                    <CancelButton
                        type="button"
                        onClick={() => handleApplyCancel(id)}
                    >
                        取消
                    </CancelButton>
                );
            }
        } else {
            if (status === 1) {
                return <StatusValue>已取消</StatusValue>;
            } else if (status === 2) {
                return <StatusValue>取消審核中</StatusValue>;
            } else if (status === 4) {
                return <StatusValue>無法取消</StatusValue>;
            }
        }
    };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/account')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>備貨狀態</MobileTitle>

                    <IconButton onClick={() => setVisibleSearch(true)}>
                        <Icon code="search" size={20} color="#282731"/>
                    </IconButton>
                </MobileHeader>
            </Container>

            <form onSubmit={handleSubmit(handleSearch)}>

                {/* 搜尋 */}
                <Search
                    isVisible={isVisibleSearch}
                    onClose={() => setVisibleSearch(false)}
                    useFormParams={useFormParams}
                    placeholder={get(placeholder, searchType)}
                    startLabel="訂購日期"
                    endLabel="完成日期"
                />

                {/* 狀態 */}
                <Container>
                    <StatusRow>
                        {stockCategory.map(row => (
                            <StatusCol key={get(row, 'id')} col="auto" md={4} lg>
                                <StatusButton
                                    key={get(row, 'id')}
                                    isActive={categoryId === get(row, 'id')}
                                    type="button"
                                    onClick={() => {
                                        setCategoryId(get(row, 'id'));
                                        setCurrentPage(1);
                                    }}
                                >
                                    <StatusText>{get(row, 'name')}</StatusText>
                                    <StatusInfo>{`共 ${get(row, 'totalQty')} 件`}</StatusInfo>
                                    <StatusInfo>{`合計 $ ${formatCurrency(get(row, 'totalAmount'))} 元`}</StatusInfo>
                                </StatusButton>
                            </StatusCol>
                        ))}
                    </StatusRow>
                    <TotalInfo className="d-md-none">{`共 ${get(selectedStatus, 'totalQty', 0)} 件 / 合計 $ ${formatCurrency(get(selectedStatus, 'totalAmount', 0))} 元`}</TotalInfo>
                </Container>

                <AdvanceOrderContainer>
                    {/* 標題 */}
                    <Header className="d-none d-md-flex">
                        <NumSection>項</NumSection>
                        <ProductInfo>商品貨號/品牌</ProductInfo>
                        <Category>顏色/SIZE</Category>
                        <Price>單價</Price>
                        <Total>合計</Total>
                        <OrderDate>訂購日期</OrderDate>
                        {categoryId !== 1 && (<AssignDate>完成日期</AssignDate>)}
                        <Remark isStockUp={categoryId === 1}>備註</Remark>
                        <Status/>
                    </Header>

                    {/* 讀取狀態 */}
                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}

                    {/* 明細 */}
                    {!isFetching && stockProductList.map(row => (
                        <Content key={row.key}>

                            <DetailContent className="d-flex flex-column flex-md-row">
                                {/* 訂單編號 */}
                                <NumSection className="d-none d-md-flex">{row.orderDetailId}</NumSection>

                                <TopSection className="d-md-none">
                                    <Num>{row.orderDetailId}</Num>
                                    <MobileAmount>$ {formatCurrency(row.totalAmount || 0)}</MobileAmount>
                                </TopSection>

                                {/* 商品貨號/品牌 */}
                                <ProductInfo>
                                    <ProductImg className="d-none d-md-block" src={isEmpty(row.productGoods.thumbUrl) ? asset('/images/goods/default.jpg') : row.productGoods.thumbUrl}/>

                                    <div className="w-100 d-flex flex-column align-items-start justify-content-center">
                                        <div className="w-100 d-flex justify-content-between flex-md-column">
                                            <Model onClick={() => window.open(`/product/detail/${payload?.id}/${row.productGoods.id}`)}>{row.productGoods.model}</Model>
                                        </div>
                                        <BrandName>{row.productGoods.brandName}</BrandName>
                                        <ProductName>{row.productGoods.name}</ProductName>
                                        <SubName>{row.productGoods.subName}</SubName>
                                    </div>
                                </ProductInfo>

                                <MobileProductInfo className="d-flex d-md-none">
                                    <ProductImg src={isEmpty(row.productGoods.thumbUrl) ? asset('/images/goods/default.jpg') : row.productGoods.thumbUrl}/>

                                    <FieldTitleSection>
                                        <FieldLine>
                                            <FieldTitle>顏色</FieldTitle>
                                            <FieldValue>{row.productGoods.color}</FieldValue>
                                        </FieldLine>
                                        <FieldLine className="d-flex align-items-start">
                                            <FieldTitle>SIZE</FieldTitle>
                                            <FieldValue className="d-flex flex-wrap align-items-start">
                                                {row.orderStock && row.orderStock.map(row => (
                                                    <div key={row.id} data-stockId={row.id} data-status={row.status}>{row.size}(<FieldValue isRed>{row.qty}</FieldValue>件), </div>
                                                ))}
                                            </FieldValue>
                                        </FieldLine>
                                        <FieldLine>
                                            <FieldTitle>單價</FieldTitle>
                                            <FieldValue>$ {formatCurrency(row.price || 0)}</FieldValue>
                                            <FieldValue isExtraFare={row.isExtraFare}>{row.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                                        </FieldLine>
                                        <FieldLine>
                                            <FieldTitle>訂購時間</FieldTitle>
                                            <FieldValue>{dayjs(row.createdDate).format('YYYY-MM-DD')}</FieldValue>
                                        </FieldLine>
                                        <FieldLine>
                                            <FieldTitle>訂單編號</FieldTitle>
                                            <FieldValue>{row.orderHeadId}</FieldValue>
                                        </FieldLine>
                                        {categoryId !== 1 && (
                                            <FieldLine>
                                                <FieldTitle>完成日期</FieldTitle>
                                                <FieldValue>{dayjs(row.orderStockDate).format('YYYY-MM-DD')}</FieldValue>
                                            </FieldLine>
                                        )}
                                    </FieldTitleSection>
                                </MobileProductInfo>

                                {/* 顏色/SIZE */}
                                <Category className="d-none d-md-flex flex-column justify-content-center align-items-start">
                                    <FieldValue>{row.productGoods.color}</FieldValue>

                                    <FieldValue className="d-flex flex-wrap">
                                        {row.orderStock && row.orderStock.map((item, index) => (
                                            <div key={item.id} data-stockId={item.id} data-status={item.status}>{item.size}(<FieldValue isRed>{item.qty}</FieldValue>件){index+1 !== row.orderStock.length && ', '}</div>
                                        ))}
                                    </FieldValue>
                                </Category>

                                {/* 單價 */}
                                <Price className="d-none d-md-flex">
                                    <FieldValue>$ {formatCurrency(row.price || 0)}</FieldValue>
                                    <FieldValue isExtraFare={row.isExtraFare}>{row.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                                </Price>

                                {/* 合計 */}
                                <Total className="d-none d-md-flex">
                                    <FieldValue
                                        isBlod
                                        isRed
                                    >
                                        $ {formatCurrency(row.totalAmount || 0)}
                                    </FieldValue>
                                </Total>

                                {/* 訂購日期 */}
                                <OrderDate className="d-none d-md-flex flex-column justify-content-center align-items-start">
                                    <OrderHeadId>({row.orderHeadId})</OrderHeadId>
                                    <FieldValue>{dayjs(row.createdDate).format('YYYY-MM-DD')}</FieldValue>
                                </OrderDate>

                                {/* 完成日期 */}
                                {categoryId !== 1 && (
                                    <AssignDate className="d-none d-md-flex">
                                        <FieldValue>{dayjs(row.orderStockDate).format('YYYY-MM-DD')}</FieldValue>
                                    </AssignDate>
                                )}

                                {/* 備註 */}
                                <Remark className="d-none d-md-flex" isStockUp={categoryId === 1}>
                                    <FieldValue>{row.replyComment}</FieldValue>
                                </Remark>

                                {/* 狀態 */}
                                <Status className="d-flex justify-content-center">
                                    {renderStatus(row.applyCancelStatus, row.isEnableCancel, row.orderDetailId)}
                                </Status>

                            </DetailContent>
                        </Content>
                    ))}
                </AdvanceOrderContainer>
            </form>

            {/* 分頁 */}
            <CustomPagination
                page={currentPage}
                totalPage={get(stockProductPagination, 'totalPages')}
                totalItems={get(stockProductPagination, 'totalItems')}
                onChange={(currentPage: number) => handleChangePage(currentPage)}
            />
        </>
    );
};

export default StockUp;

const CustomPagination = styled(Pagination)`
    margin: 25px auto;

    ${media.lg`
        margin: 15px auto 50px auto;
    `}
`;

const CancelButton = styled.button<any>`
    width: 120px;
    height: 30px;
    border-radius: 2px;
    border: solid 1px #9d9a9a;
    font-size: 12px;
    color: #9d9a9a;
    margin: 0 5px;
    transition: background-color 0.2s, border-color 0.2s;

    ${props => props.disabled && css`
        color: #d8d8d8;
        border: solid 1px #d8d8d8;
    `}

    ${media.md`
        width: 101px;
        height: 40px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;

        ${(props: any) => !props.disabled && css`
            &:hover {
                background-color: rgba(158, 154, 154, 0.05);
                border-color: transparent;
            }
        `}
    `}
`;


const StatusValue = styled.div`
    min-height: 30px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ff7d6a;
    display: flex;
    align-items: center;

    ${media.md`
        font-size: 16px;
    `}
`;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isBlod && css`
        font-weight: bold;
    `}

    ${props => props.isRed && css`
        color: #ff7d6a;
    `}

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const OrderHeadId = styled.span<any>`
    font-size: 10px;
    color: #9e9e9e;

    ${media.md`
        font-size: 14px;
    `}
`;

const FieldTitle = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    min-width: 62px;
`;

const FieldLine = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 2px;
`;

const FieldTitleSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const MobileProductInfo = styled.div`
    margin-bottom: 10px;
`;

const SubName = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    padding-bottom: 14px;

    ${media.md`
        font-size: 16px;
        padding-bottom: 0;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 0;
    `}
`;

const BrandName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 3px;
    `}
`;

const Model = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #6e6e6e;
    padding-bottom: 2px;
    cursor: pointer;

    ${media.md`
        font-size: 16px;
        padding-bottom: 0;
    `}
`;

const ProductImg = styled.img`
    width: 100px;
    height: 100px;
    margin-right: 6px;
    background-color: lightgray;

    ${media.md`
        width: 70px;
        height: 80px;
        margin-right: 10px;
    `}
`;

const MobileAmount = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #ff7d6a;
`;

const Num = styled.span`
    font-size: 14px;
    color: #6e6e6e;
`;

const TopSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 7px;
    margin-bottom: 6px;
    border-bottom: solid 1px #ebebeb;
`;

const Content = styled.div`
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    ${media.md`
        width: 1120px;
        min-height: auto;
        box-shadow: none;
        border: none;
        border-bottom: solid 1px #edeff2;
        padding: 10px 0;
        margin-bottom: 0;
        flex-direction: row;
        align-items: center;

        &:hover{
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
        }
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const TotalInfo = styled.span`
    font-size: 14px;
    display: block;
    text-align: center;
    color: #9d9a9a;
    padding-bottom: 10px;
`;

const StatusInfo = styled.span`
    font-size: 12px;
    text-align: center;
    color: #9d9a9a;
    display: none;

    ${media.md`
        display: block;
    `}
`;

const StatusText = styled.span`
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #9d9a9a;
    display: block;

    ${media.md`
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
    `}
`;

const StatusButton = styled.button<any>`
    width: 80px;
    height: 30px;
    border-radius: 2px;
    background-color: #f8f8f8;

    ${props => props.isActive && css`
        background-color: #a8d580;

        ${StatusText} {
            color: #ffffff;
        }
    `}

    ${media.md`
        width: 100%;
        height: 100px;
        border-radius: 5px;
        border: solid 1px #d8d8d8;
        background-color: transparent;
        transition: border .2s;

        ${(props: any) => props.isActive && css`
            background-color: #a8d580;
            border: solid 1px transparent;

            ${StatusInfo} {
                 color: #ffffff;
            }
        `}

        &:hover{
            border: solid 1px #a8d580;
        }
    `}
`;

const StatusCol = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;

    ${media.lg`
        padding-left: 6px;
        padding-right: 6px;
    `}

    ${media.xl`
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

const StatusRow = styled(Row)`
    flex-wrap: inherit;
    overflow-x: scroll;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 50px;
        overflow-x: auto;
    `}

    ${media.lg`
        margin-left: -6px;
        margin-right: -6px;
    `}

    ${media.lg`
        margin-left: -5px;
        margin-right: -5px;
    `}
`;

const Status = styled(HeaderFieldName)<any>`
    ${media.md`
        min-width: 120px;
    `}

    ${media.xxl`
        min-width: 252px;
    `}
`;

const Remark = styled(HeaderFieldName)<any>`
    ${media.md`
        min-width: 110px;

        ${(props: any) => props.isStockUp && css`
            min-width: 219px;
        `}
    `}

    ${media.xxl`
        min-width: 130px;

        ${(props: any) => props.isStockUp && css`
            min-width: 254px;
        `}
    `}
`;

const AssignDate = styled(HeaderFieldName)`
    ${media.md`
        min-width: 99px;
    `}

    ${media.xxl`
        min-width: 124px;
    `}
`;

const OrderDate = styled(HeaderFieldName)`
    ${media.md`
        min-width: 99px;
        max-width: 99px;
    `}

    ${media.xxl`
        min-width: 124px;
        max-width: 124px;
    `}
`;

const Total = styled(HeaderFieldName)`
    ${media.md`
        min-width: 92px;
    `}
`;

const Price = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 102px;
        padding-bottom: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `}
`;

const Category = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 127px;
        padding-bottom: 0;
    `}
`;

const ProductInfo = styled(HeaderFieldName)`
    ${media.md`
        border-bottom: solid 1px #f2f2f2;
        min-width: 300px;
        padding-bottom: 0;
        border-bottom: none;
    `}

    ${media.xxl`
        min-width: 399px;
    `}
`;

const NumSection = styled(HeaderFieldName)<any>`
    display: flex;
    justify-content: center;

    ${media.md`
        min-width: 70px;
        word-break: break-all;
        position: initial;
        top: auto;
        left: auto;
    `}

    ${media.xxl`
        min-width: 100px;
    `}

    ${props => props.isBlod && css`
        font-weight: bold;
    `}
`;

const DetailContent = styled.div`
    padding: 7px 10px;

    ${media.md`
        padding: 0;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 22px;
    border-bottom: solid 1px #edeff2;
    margin-bottom: 10px;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const AdvanceOrderContainer = styled(Container)`
    ${media.md`
        overflow-x: auto;
        padding-bottom: 15px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
