// Libraries
import api from 'library/apisauce';

// Types
import {TLoginRes, TLogoutRes, TVerifyRes, TSignUpRes} from './types';

export default {
    /**
     * 登入
     * @param account 帳號
     * @param password 密碼
     * @param os 系統別 (ios|android)
     * @param browser 瀏覽器 (chrome, safari, firefox)
     */
    login: (account: string, password: string, os: string, browser: string): Promise<TLoginRes> => {
        return api.post('/member/auth/signIn', {account, password, os, browser});
    },
    /**
     * 登出
     */
    logout: (): Promise<TLogoutRes> => {
        return api.post('/member/auth/signOut');
    },
    /**
     * 驗證 帳號信箱是否重複
     */
    postVerify: (account: string, email: string): Promise<TVerifyRes> => {
        return api.post('/member/auth/verify', {account, email});
    },
    /**
     * 註冊 會員
     */
    signUp: (
        account: string,
        password: string,
        realName: string,
        email: string,
        phone: string,
        lineId: string,
        deliveryCity: string,
        deliveryTownship: string,
        deliveryAddress: string,
        storeTypeId: number,
        storeName: string,
        storeWebsite: string,
        storeCity: string,
        storeTownship: string,
        storeAddress: string,
        isBuyOtherStoreBefore: boolean,
        otherStoreName: string,
        otherStoreWebsite: string,
    ): Promise<TSignUpRes> => {
        return api.post('/member/auth/signUp', {
            account,
            password,
            realName,
            email,
            phone,
            lineId,
            deliveryCity,
            deliveryTownship,
            deliveryAddress,
            storeTypeId,
            storeName,
            storeWebsite,
            storeCity,
            storeTownship,
            storeAddress,
            isBuyOtherStoreBefore,
            otherStoreName,
            otherStoreWebsite,
        });
    },
};
