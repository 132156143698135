import React from 'react';
import CSS from 'csstype';
import styled from 'styled-components/macro';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    children?: React.ReactNode;
}

/**
 * PageScrollView
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const PageScrollView: React.FC<IProps> = ({
    className,
    style,
    children,
}) => {
    return (
        <ScrollViewRoot className={className} style={style}>
            {/* 內容 */}
            {children}
        </ScrollViewRoot>
    );
};

export default PageScrollView;

const ScrollViewRoot = styled.div`
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    flex: 1 1 0;
    height: 100%;
`;
