// Libraries & Utils
import {call, put, take, select, takeLatest} from 'redux-saga/effects';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import {persistWhileList, persistKey} from 'config/app';
import {IPickAction} from 'library/redux';
import {replace} from 'connected-react-router';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {Actions as SystemActions, ActionTypes as SystemActionTypes} from 'store/system';
import {Actions as AuthAction} from 'store/auth';
import {IActionCreators} from './types';


/**
 * 啟動檢查
 */
function* checkIn(action: IPickAction<IActionCreators, 'checkIn'>)  {
    yield put(Actions.checkInBegin());
    yield put(UiBlockActions.visible());

    try{
        // 取得系統資訊
        yield put(SystemActions.fetchSetting());
        yield take(SystemActionTypes.FETCH_SETTING_SUCCESS);

        const {token} = action.payload;
        yield put(AuthAction.setToken({token}));

        // 導回首頁
        yield put(replace('/'));

        yield put(Actions.checkInSuccess());
    } catch(err){
        yield put(Actions.checkInFail());
    } finally {
        yield put(UiBlockActions.hidden());
    }

}


/**
 * Store Sync
 */
function* watchStateSyncCookie() {

    while (true) {
        yield take('*');

        // 更新狀態
        let isNeedUpdate = false;

        // 目前 LocalStorage State Object
        let storage = JSON.parse(window.localStorage.getItem(persistKey) || '{}');

        // 目前 Redux State Object
        const reduxState = yield select();

        // 檢查白名單內的項目
        for(let i=0; i < persistWhileList.length; i++){
            const statePath = persistWhileList[i];

            const selectState = get(reduxState, statePath);
            const selectPreloadState = get(storage, statePath);

            if (JSON.stringify(selectState) !== JSON.stringify(selectPreloadState)) {
                isNeedUpdate = true;
                storage = setWith(storage, statePath, selectState, Object);
            }
        }

        // 將新的更新同步到Cookie
        if (isNeedUpdate) {
            window.localStorage.setItem(persistKey, JSON.stringify(storage));
        }

    }
}



export default [
    call(watchStateSyncCookie),
    takeLatest(ActionTypes.CHECK_IN, checkIn),
];
