import { sec2ms } from "utils/number";

const staticPrefixUrl = '/static';
const routePrefixPath = '';
const api = {baseUrl: '/api', timeout: sec2ms(300)};
const persistKey = 'persist:kr8851-ec-agent';
const persistWhileList = [
    'auth.memberToken',
    'profile.walletAmount',
    'profile.unpaidAmount',
    'profile.isOrderPreSale',
    'profile.isOrderVipGoods',
    'profile.isOrderNormalGoods',
    'profile.messageUnreadCount',
    'profile.trackProductCount',
    'profile.shoppingCartCount',
];
const persistVersion = '1.0.6';
const fetchDataDelayMinMs = 600;

const asset = (path: string) => `${staticPrefixUrl}${path}`;


export {
    staticPrefixUrl,
    api,
    persistKey,
    persistWhileList,
    persistVersion,
    fetchDataDelayMinMs,
    routePrefixPath,
    asset,

};
