import * as React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import get from 'lodash/get';
import {media} from 'library/styled-bs-grid';
import site from 'config/site';
import {themes} from './tagTypes';
import Icon from 'components/atoms/Icon';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    text?: string;
    theme?: themes;
    isStar?: boolean;
}

const themeConfig = {
    default: {
        bgColor: site.theme.primaryColor,
    },
    orange: {
        bgColor: '#ff8e3b',
    },
    purple: {
        bgColor: site.theme.thirdColor,
    },
    danger: {
        bgColor: '#ff7d6a',
    },
    black: {
        bgColor: '#282731',
    },
    yellow: {
        bgColor: '#f9c02e',
    },
};

/**
 * Tag 一般標籤
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Tag: React.FC<IProps> = ({
    className,
    style,
    theme = 'default',
    text,
    isStar,
}) => {
    const activeTheme = get(themeConfig, theme, {});

    return (
        <TagRoot
            className={className}
            style={style}
            baseTheme={{...activeTheme}}
        >
            {text}
            {isStar && <Icon code="star" color="#ffffff" size={12}/>}
        </TagRoot>
    );
};

export default Tag;

const TagRoot = styled.div<any>`
    width: 100%;
    min-width: 30px;
    height: 16px;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.baseTheme.bgColor};
    color: #ffffff;
    margin-bottom: 5px;
    padding: 0 3px;

    ${media.md`
        min-width: 38px;
        height: 22px;
        font-size: 12px;
        padding: 0 7px;

        i{
            font-size: 16px;
        }
    `}

    ${media.xxl`
        min-width: 44px;
        padding: 0 10px;
    `}
`;

