import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {
    media, Container, Row, Col,
} from 'library/styled-bs-grid';
import {isEmpty} from 'utils/equal';
import {asset} from 'config/app';
import Icon from 'components/atoms/Icon';
import {motion} from 'framer-motion'
import {useSelector} from 'react-redux';

// Stores
import {Selector as AuthSelector} from 'store/auth';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onBack?: () => void;
    onClose?: Function;
    id: number;
    brandName: string;
    name: string;
    model: string;
    price: number;
    prePrice: number;
    isSale?: boolean;
    relatedProducts: Array<{
        id: number;
        thumbUrl: string;
    }>;
    imageUrl: string;
    isOrderNormalGoods?: boolean;
}

/**
 * RelatedModal
 * @param props
 * @returns {*}
 * @constructor
 */
const RelatedModal: React.FC<IProps> = ({
    className,
    style,
    onBack,
    onClose = () => {},
    brandName,
    name,
    model,
    price,
    prePrice,
    isSale,
    relatedProducts,
    imageUrl,
    isOrderNormalGoods= false,
}) => {
    const {payload} = useSelector(state => ({
        payload: AuthSelector.payload(state),
    }));

    return(
        <Modal
            className={className}
            style={style}
            variants={{
                initial: {top: '100%'},
                isOpen: {top: 'unset', bottom: '0'},
                exit: {top: '100%'}
            }}
            transition={{type: "spring", duration: 0.5}}
        >
            <Container>
                <Header>
                    <IconButton type="button" onClick={onBack}>
                        {typeof onBack !== 'undefined' && (<Icon code="arrow-right" size={15} color="#d8d8d8" rotate={180}/>)}
                    </IconButton>

                    <IconButton type="button" onClick={() => {
                        onClose();

                        if (typeof onBack !== 'undefined') {
                            onBack();
                        }
                    }}>
                        <Icon code="times" size={15} color="#d8d8d8"/>
                    </IconButton>
                </Header>

                <div>
                    {/* 商品資訊 */}
                    <ProductInfo className="d-flex">
                        <ProductImg src={imageUrl}/>
                        <div className="d-flex flex-column">
                            <BrandName>{brandName}</BrandName>
                            <ProductName>{name}</ProductName>
                            <Model>{`商品貨號：${model}`}</Model>

                            {isOrderNormalGoods && (
                                <div>
                                    {isSale && <PrePrice>NT$ {prePrice}</PrePrice>}
                                    <Price>NT$ {price}</Price>
                                </div>
                            )}
                        </div>
                    </ProductInfo>

                    {/* 相關產品 */}
                    <RelatedSection className="d-flex flex-column">
                        <RelatedTitle>相關產品：</RelatedTitle>

                        <RelatedRow>
                            {relatedProducts && relatedProducts.map(row => (
                                <RelatedImgCol key={row.id} col={8}>
                                    <a key={row.id} href={`/product/detail/${payload?.id}/${row.id}`} target="_blank" rel="noopener noreferrer">
                                        <RelatedImg src={isEmpty(row.thumbUrl) ? asset('/images/goods/default.jpg') : row.thumbUrl}/>
                                    </a>
                                </RelatedImgCol>
                            ))}
                        </RelatedRow>
                    </RelatedSection>
                </div>
            </Container>
        </Modal>
    );
};

export default RelatedModal;

const RelatedImg = styled.div<any>`
    background-image: url("${props => props.src}");
    background-size: 100% 100%;
    border-radius: 5px;
    padding-bottom: 100%;
    cursor: pointer;
`;

const RelatedImgCol = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
`;

const RelatedTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;
    margin-bottom: 10px;

    ${media.md`
        font-size: 14px;
    `}
`;

const RelatedRow = styled(Row)`
    margin-right: -5px;
    margin-left: -5px;
`;

const RelatedSection = styled.div`
    width: 100%;
    height: 217px;
    overflow-y: scroll;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    padding: 10px 10px 0 10px;
`;

const Price = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: #ff7d6a;
`;

const PrePrice = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    padding-right: 4px;
    text-decoration: line-through;

    ${media.md`
        font-size: 14px;
        padding-right: 6px;
    `}
`;

const Model = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #9d9a9a;
    padding-bottom: 6px;

    ${media.md`
        font-size: 14px;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #282731;

    ${media.md`
        font-size: 16px;
    `}
`;

const BrandName = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #282731;

    ${media.md`
        font-size: 16px;
    `}
`;

const ProductImg = styled.img`
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 5px;

    ${media.md`
        width: 90px;
        height: 90px;
        margin-right: 15px;
    `}
`;

const ProductInfo = styled.div`
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 20px;
    `}
`;

const IconButton = styled.button<any>`
    width: 16px;
    height: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 20px;
        height: 20px;

        i {
            font-size: 20px;
        }
    `}
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    ${media.md`
        margin-bottom: 20px;
    `}
`;

const Modal = styled(motion.div)`
    width: 100%;
    min-height: 420px;
    max-height: 90%;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    overflow-y: scroll;
    padding: 15px 10px;
    position: absolute;

    ${media.md`
        width: 460px;
        border-radius: 10px;
        padding: 30px 40px;
    `}
`;
