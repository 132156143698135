// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetOrderHead, TGetOrderHeadDetail, TGetStockCategory, TGetStockProduct} from 'services/order';


/**
 * 取得訂單列表
 */
function* fetchOrderHead(action: IPickAction<IActionCreators, 'fetchOrderHead'>) {
    yield put(Actions.fetchOrderHeadBegin());

    try {
        const {startDate, endDate, orderId, currentPage, pageLimit} = action.payload;
        const [{body}]: [TGetOrderHead] = yield all([
            call(ApiService.getOrderHead, startDate, endDate, orderId, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, ...meta} = body.data;
        yield put(Actions.fetchOrderHeadSuccess({orderHeadList: rows, orderHeadPagination: meta}));

    } catch (err) {
        yield put(Actions.fetchOrderHeadFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 取得訂單明細
 */
function* fetchOrderHeadDetail(action: IPickAction<IActionCreators, 'fetchOrderHeadDetail'>) {
    yield put(Actions.fetchOrderHeadDetailBegin());

    try {
        const {id} = action.payload;
        const [{body}]: [TGetOrderHeadDetail] = yield all([
            call(ApiService.getOrderHeadDetail, id),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchOrderHeadDetailSuccess({orderHeadDetail: rows}));

    } catch (err) {
        yield put(Actions.fetchOrderHeadDetailFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 取得商品備貨狀態類別
 */
function* fetchStockCategory(action: IPickAction<IActionCreators, 'fetchStockCategory'>) {
    yield put(Actions.fetchStockCategoryBegin());

    try {
        const {startDate, endDate, orderId, itemModel} = action.payload;
        const [{body}]: [TGetStockCategory] = yield all([
            call(ApiService.getStockCategory, startDate, endDate, orderId, itemModel),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchStockCategorySuccess({stockCategory: rows}));

    } catch (err) {
        yield put(Actions.fetchStockCategoryFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 取得商品備貨狀態資料 (依狀態類別)
 */
function* fetchStockProduct(action: IPickAction<IActionCreators, 'fetchStockProduct'>) {
    yield put(Actions.fetchStockProductBegin());

    try {
        const {categoryId, startDate, endDate, goodsModel, goodsName, brandName, orderId, currentPage, pageLimit} = action.payload;
        const [{body}]: [TGetStockProduct] = yield all([
            call(ApiService.getStockProduct, categoryId, startDate, endDate, goodsModel, goodsName, brandName, orderId, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, ...meta} = body.data;
        yield put(Actions.fetchStockProductSuccess({stockProductList: rows, stockProductPagination: meta}));

    } catch (err) {
        yield put(Actions.fetchStockProductFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 商品取消申請
 */
function* fetchApplyCancel(action: IPickAction<IActionCreators, 'fetchApplyCancel'>) {
    yield put(Actions.fetchApplyCancelBegin());
    yield put(UiBlockActions.visible({message: '正在取消申請商品'}));

    try {
        const {id, categoryId, startDate, endDate, goodsModel, goodsName, brandName, orderId, currentPage, pageLimit} = action.payload;
        yield all([
            call(ApiService.postCancelApply, id),
            delay(fetchDataDelayMinMs),
        ]);

        // 重新取得列表資料
        yield put(Actions.fetchStockProduct({categoryId, startDate, endDate, goodsModel, goodsName, brandName, orderId, currentPage, pageLimit}));
        yield put(Actions.fetchApplyCancelSuccess());
    } catch (err) {
        yield put(Actions.fetchApplyCancelFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}



export default [
    takeLatest(ActionTypes.FETCH_ORDER_HEAD, fetchOrderHead),
    takeLatest(ActionTypes.FETCH_ORDER_HEAD_DETAIL, fetchOrderHeadDetail),
    takeLatest(ActionTypes.FETCH_STOCK_CATEGORY, fetchStockCategory),
    takeLatest(ActionTypes.FETCH_STOCK_PRODUCT, fetchStockProduct),
    takeLatest(ActionTypes.FETCH_APPLY_CANCEL, fetchApplyCancel),
];
