import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {isEmpty} from 'utils/equal';

// Reducer
import {Actions as StartUpAction} from 'store/startUp';

const CheckIn: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {token} = useParams();

    useEffect(() => {
        const decodeToken: any = handleDecode(token);

        if (typeof decodeToken === 'object' && !isEmpty(decodeToken?.account)) {
            dispatch(StartUpAction.checkIn({token}));
        } else {
            history.push('/notFount');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleDecode = (accessToken: string) => {
        try {
            return jwtDecode(accessToken);
        } catch (e) {
            history.push('/notFount');
        }
    };


    return (<></>);
};

export default CheckIn;
