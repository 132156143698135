import React from 'react';
import {Link} from 'react-router-dom';
import {LocationState} from 'history';
import CSS from 'csstype';

interface IProps {
    children: React.ReactNode;
    style?: CSS.Properties;
    className?: string;
    href: string;
    as?: string;
    alt?: string;
    onClick?: Function;
    forwardAs?: string;
}

/**
 * 路由超連結
 * fake react-route <Linkt to="/about"/>
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const A: React.FC<IProps> = ({
    className,
    style,
    as,
    children,
    onClick,
    href,
    forwardAs,
}) => {
    let params: LocationState = {as, style, className, onClick};

    if (forwardAs === 'a') {
        return <a {...params} href={href}>{children}</a>
    }

    return (
        <Link {...params} to={href}>{children}</Link>
    );
};

export default A;
