import React, {useRef, useState} from 'react';
import * as CSS from 'csstype';
import get from 'lodash/get';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';
import {checkIsMobile} from 'utils/browser';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onChange?: Function;
    option: Array<{
        code: number | string;
        name: string;
        isRed?: boolean;
        isVisibleLine?: boolean;
    }>;
    value?: number | string;
}

/**
 * Input Component
 * form type in text...
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CustomSelect: React.FC<IProps> = ({
    className,
    style,
    option = [],
    onChange = () => {},
    value= '',
}) => {
    const [isVisible, setVisible] = useState(false);
    const selectRef: any = useRef();

    const optionText = get(option.find(o => String(o.code) === String(value)), 'name', '');
    const handleOnChange = (optionValue: any) => {
        onChange(optionValue);
        setVisible(false);
    };

    return (
        <CustomSelectRoot
            className={className}
            style={style}
            isVisible={isVisible}
        >
            <input
                value={value}
                type="hidden"
                onChange={e => handleOnChange(e.target.value)}
            />

            <SelectSection onClick={() => {
                setVisible(!isVisible);
                if (checkIsMobile()) {
                    selectRef.current.focus();
                }
            }}
            >
                <Value>{optionText}</Value>
                <CustomIcon code="chevron-fillet-down" size={10} color="#c4c4c4" rotate={isVisible ? 180 : 0}/>
            </SelectSection>

            {checkIsMobile() ? (
                <MobileSelect
                    ref={selectRef}
                    onChange={(e: any) => handleOnChange(e.target.value)}
                >
                    {option.map(row => (
                        <option
                            value={row.code}
                            key={row.code}
                        >
                            {row.name}
                        </option>
                    ))}
                </MobileSelect>
            ) : (
                <OptionList>
                    {option.map(row => (
                        <Option
                            key={row.code}
                            isRed={row.isRed}
                            isVisibleLine={row.isVisibleLine}
                            onClick={() => handleOnChange(row.code)}
                        >
                            {row.name}
                        </Option>
                    ))}
                </OptionList>
            )}

            {isVisible && <CloseArea onClick={() => setVisible(false)}/>}
        </CustomSelectRoot>
    );
};

CustomSelect.defaultProps = {
};

export default CustomSelect;

const CloseArea = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
`;

const Option = styled.div<any>`
    font-size: 16px;
    color: #9d9a9a;
    padding: 8px 0;
    cursor: pointer;
    position: relative;

    ${props => props.isRed && css`
        color: #ff7d6a;
    `}

    :hover {
        color: ${props => props.theme.primaryColor};
    }

    ${props => props.isVisibleLine && css`
        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #f2f2f2;
        }
    `}
`;

const OptionList = styled.div<any>`
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    position: absolute;
    z-index: 4;
    top: 100%;
    left: 0;
    padding-left: 30px;
    margin-top: 10px;
    display: none;
`;

const MobileSelect = styled.select`
    opacity: 0;
    pointer-events: none;
`;

const CustomIcon = styled(Icon)`

`;

const Value = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #6e6e6e;

    ${media.lg`
        font-size: 16px;
        font-weight: 600;
    `}
`;

const SelectSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px  0 30px;
    cursor: pointer;
`;

const CustomSelectRoot = styled.div<any>`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f2f2f2;
    position: relative;
    -moz-user-select: none;  /* for Firefox */
    -webkit-user-select: none;   /* for Chrome */

    ${media.lg`
        height: 50px;
    `}

    ${props => props.isVisible && css`
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;

        ${OptionList} {
            display: block;
        }
    `}
`;
