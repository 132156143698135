import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';
import cx from 'classnames';
import {media, Container} from 'library/styled-bs-grid';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {getPopularOptions} from './utils';
import {isEmpty} from 'utils/equal';

import TabBar from 'components/organisms/TabBar';
import Navbar from 'components/organisms/Navbar';
import AdvertModal from 'components/organisms/AdvertModal';
import ProductCategory from 'components/molecules/ProductCategory';
import Footer from 'components/organisms/Footer';
import SearchProductModal from 'components/organisms/SearchProductModal';
import LoginModal from 'components/organisms/LoginModal';
import SignUpModal from 'components/organisms/SignUpModal';
import Icon from 'components/atoms/Icon';
import Router from './Router';
import FormContext from './FormContext';

// Stores & Reducer
import {Selector as AuthSelector, Actions as AuthAction} from 'store/auth';
import {Actions as SystemAction} from 'store/system';
import {Actions as ProductAction} from 'store/product';
import {Actions as AdAction} from 'store/ad';


/**
 * HomeLayout
 * @constructor
 */
const HomeLayout: React.FC = () => {
    const [isVisibleSearchModal, setVisibleSearchModal] = useState(false);

    const history = useHistory();
    const {pathname} = useLocation();
    const dispatch = useDispatch();

    const {
        isAuth,
        payload,
        isOrderPreSale,
        isOrderVipGoods,
        walletAmount,
        unpaidAmount,
        avatarUrl,
        messageUnreadCount,
        trackProductCount,
        shoppingCartCount,
        company,
        hotKeywords,
        categoryGroup,
        seasonData,
        brandGroup,
        isVisibleSignInNewsModal,
        signInNews,
        placementAd,
    } = useSelector(state => ({
        isAuth: AuthSelector.isAuth(state),
        payload: AuthSelector.payload(state),

        isOrderPreSale: state.profile.isOrderPreSale,
        isOrderVipGoods: state.profile.isOrderVipGoods,
        walletAmount: state.profile.walletAmount,
        unpaidAmount: state.profile.unpaidAmount,
        avatarUrl: state.profile.avatarUrl,
        messageUnreadCount: state.profile.messageUnreadCount,
        trackProductCount: state.profile.trackProductCount,
        shoppingCartCount: state.profile.shoppingCartCount,

        company: state.system.company,
        hotKeywords: state.system.hotKeywords,

        categoryGroup: state.product.categoryGroup,
        seasonData: state.product.seasonData,
        brandGroup: state.product.brandGroup,

        isVisibleSignInNewsModal: state.ad.isVisibleSignInNewsModal,
        signInNews: state.ad.signInNews,
        placementAd: state.ad.placementAd||[],
    }));


    useEffect(() => {
        // 取得熱門搜尋關鍵字
        if (get(hotKeywords, 'length', 0) === 0) {
            dispatch(SystemAction.fetchHotKeyword());
        }

        // 取得網站設定
        if (!isEmpty(company)) {
            dispatch(SystemAction.fetchSetting());
        }

        // 取得商品類別(含子類)
        if (get(categoryGroup, 'length', 0) === 0) {
            dispatch(ProductAction.fetchCategoryGroup());
        }

        // 取得各區塊置入廣告
        if (get(placementAd, 'length', 0) === 0) {
            dispatch(AdAction.fetchPlacement());
        }

        // 取所有品牌
        if (get(brandGroup, 'length', 0) === 0) {
            dispatch(ProductAction.fetchBrandGroup());
        }

        // 取得產品季節清單
        if (get(seasonData, 'length', 0) === 0) {
            dispatch(ProductAction.fetchSeason());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // 產品類別區塊置入廣告
    const searchPlacementAd = placementAd.find(o => o.code === 'searchPage');
    // 登入光箱置入廣告資訊
    const loginPlacementAd = placementAd.find(o => o.code === 'loginPage');


    return (
        <FormContext>
            <HomeLayoutRoot className="d-flex flex-column" style={{height: 'inherit'}}>

                {/* Navbar */}
                <Navbar
                    className={cx({'d-none': !['/', '/guide', '/about', '/brand', '/product'].includes(pathname)}, 'd-md-block')}
                    isAuth={isAuth}
                    account={payload?.account}
                    avatarUrl={avatarUrl}
                    walletAmount={walletAmount}
                    unpaidAmount={unpaidAmount}
                    messageUnreadCount={messageUnreadCount}
                    favoriteItemCount={trackProductCount}
                    shoppingCartCount={shoppingCartCount}
                    onClickSearchIcon={() => setVisibleSearchModal(true)}
                    onClickLoginButton={() => dispatch(AuthAction.openLoginModal())}
                    onClickLogOutButton={() => dispatch(AuthAction.logout())}
                    onClickSignUpButton={() => dispatch(AuthAction.openSignUpModal())}
                />

                <ProductCategory
                    className={cx({'d-none': !['/', '/guide', '/about', '/brand', '/product'].includes(pathname)}, 'd-md-block')}
                    categoryList={categoryGroup}
                    isOrderVipGoods={isOrderVipGoods}
                    isOrderPreSale={isOrderPreSale}
                    adImageUrl={get(searchPlacementAd, 'imageUrl', '')}
                    adSearchParam={get(searchPlacementAd, 'routePath', '')}
                />

                {/* 熱門搜尋 */}
                <HotKeyword
                    className={cx({'d-none': !['/', '/guide', '/about', '/brand', '/product'].includes(pathname)}, 'd-md-flex')}
                >
                    <Container className="d-flex justify-content-lg-center align-items-center">
                        <KeywordTitle className="d-none d-md-block">熱門搜尋 ：</KeywordTitle>
                        {hotKeywords.map(row => (
                            <Keyword key={row.id} onClick={() => history.push(row.routePath)}>{row.keyword}</Keyword>
                        ))}
                    </Container>
                </HotKeyword>

                {/* Content */}
                <Section>
                    <Router/>
                </Section>

                <Footer
                    className="d-none d-md-block"
                    address={company.address}
                    phone={company.tel}
                    mobile={company.mobile}
                    email={company.email}
                    line={company.line}
                    fb={company.fb}
                    categoryGroup={categoryGroup}
                    isOrderVipGoods={isOrderVipGoods}
                    isOrderPreSale={isOrderPreSale}
                />

                {(['/', '/product'].includes(pathname)) && (
                    <FixedTabBar
                        className="d-md-none"
                        isActiveCode={pathname}
                    />
                )}

                <TopButton onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}>
                    <Icon code="chevron-up-icon" color="#6e6e6e" size={25}/>
                </TopButton>
            </HomeLayoutRoot>

            {/* 登入後廣告區塊 */}
            <AdvertModal
                isVisible={isVisibleSignInNewsModal}
                data={signInNews}
                onClose={() => dispatch(AdAction.closeSignInNewsModal())}
            />

            {/* 搜尋光箱 */}
            <SearchProductModal
                className="d-xl-none"
                isVisible={isVisibleSearchModal}
                onClose={() => setVisibleSearchModal(false)}
                categoryList={categoryGroup}
                popularOptions={getPopularOptions(isOrderVipGoods, isOrderPreSale)}
                seasonData={seasonData}
                brandList={brandGroup}
            />

            {/* 登入光箱 */}
            <LoginModal
                onClose={() => dispatch(AuthAction.closeLoginModal())}
                onClickLogin={(data: {account: string, password: string}) => dispatch(AuthAction.login({account: data.account, password: data.password}))}
                onClickSignUp={() => {
                    dispatch(AuthAction.closeLoginModal());
                    dispatch(AuthAction.openSignUpModal())
                }}
                adImageUrl={get(loginPlacementAd, 'imageUrl', '')}
            />

            {/* 註冊光箱 */}
            <SignUpModal onClose={() => dispatch(AuthAction.closeSignUpModal())}/>
        </FormContext>
    );
};

export default HomeLayout;

const TopButton = styled.button`
    width: 36px;
    height: 36px;
    border: solid 1px #ebebeb;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    position: fixed;
    z-index: 99;
    right: 10px;
    bottom: 62px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 50px;
        height: 50px;
        right: 15px;
        bottom: 15px;

        i{
            font-size: 30px;
        }
    `}
`;

const Section = styled.div`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
`;

const FixedTabBar = styled(TabBar)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 19;
`;

const Keyword = styled.div`
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
    padding-right: 20px;
    white-space:nowrap;
    position: relative;
    cursor: pointer;

    &:after{
        content: '';
        width: 1px;
        height: 12px;
        background-color: #ffffff;
        position: absolute;
        right: 10px;
        bottom: 0;
        top: 0;
        margin: auto;
    }

    &:nth-last-child(1) {
        &:after{
            display: none;
        }
    }

    ${media.md`
        font-size: 16px;
        padding-right: 30px;


        &:after{
            height: 20px;
            right: 15px;
        }
    `}
`;

const KeywordTitle = styled.span`
    min-width: 101px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
`;

const HotKeyword = styled.div`
    width: 100%;
    min-height: 30px;
    background-color: #a8d580;
    display: flex;
    align-items: center;
    overflow: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;

    ${media.md`
        min-height: 36px;
    `}
`;

const HomeLayoutRoot = styled.div``;
