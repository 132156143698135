// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetSignInNews, TGetHomeCarousel, TGetPlacementAd} from 'services/ad';


/**
 * 取得登入後廣告
 */
function* fetchSignInNews(action: IPickAction<IActionCreators, 'fetchSignInNews'>) {
    yield put(Actions.fetchSignInNewsBegin());
    yield put(UiBlockActions.visible({message: '正在讀取廣告資訊'}));

    try {
        const [{body}]: [TGetSignInNews] = yield all([
            call(ApiService.getSignInNews),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchSignInNewsSuccess({signInNews: rows, isVisible: rows.length > 0}));

    } catch (err) {
        yield put(Actions.fetchSignInNewsFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

/**
 * 取得首頁輪播廣告
 */
function* fetchHomeCarousel(action: IPickAction<IActionCreators, 'fetchHomeCarousel'>) {
    yield put(Actions.fetchHomeCarouselBegin());

    try {
        const [{body}]: [TGetHomeCarousel] = yield all([
            call(ApiService.getHomeCarousel),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchHomeCarouselSuccess({homeCarousel: rows}));

    } catch (err) {
        yield put(Actions.fetchHomeCarouselFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得各區塊置入廣告
 */
function* fetchPlacement(action: IPickAction<IActionCreators, 'fetchPlacement'>) {
    yield put(Actions.fetchPlacementBegin());

    try {
        const [{body}]: [TGetPlacementAd] = yield all([
            call(ApiService.getPlacementAd),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchPlacementSuccess({placementAd: rows}));

    } catch (err) {
        yield put(Actions.fetchPlacementFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


export default [
    takeLatest(ActionTypes.FETCH_SIGN_IN_NEWS, fetchSignInNews),
    takeLatest(ActionTypes.FETCH_HOME_CAROUSEL, fetchHomeCarousel),
    takeLatest(ActionTypes.FETCH_PLACEMENT, fetchPlacement),
];
