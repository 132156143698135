import React, {useState, useEffect, useContext} from 'react';
import styled, {css} from 'styled-components';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller} from 'react-hook-form';
import cx from 'classnames';
import site from 'config/site';
import {GridThemeProvider, Container, Row, Col, media} from 'library/styled-bs-grid';
import {jsonDecode, isJSON} from 'utils/equal';
import {get} from 'lodash';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {parseQueryString} from 'utils/uri';
import {getPopularOptions} from '../HomeLayout/utils';
import {isEmpty, deepCompare, objsComposeByKey} from 'bear-jsutils/equal';

// Component
import ProductCard from 'components/molecules/ProductCard';
import CustomSelect from 'components/atoms/CustomSelect';
import {IOptions} from 'components/organisms/SearchProductModal/types';
import MultipleSelect from 'components/atoms/MultipleSelect';
import Button from 'components/atoms/Button';
import SpeedOrderModal from 'components/organisms/SpeedOrderModal';
import Pagination from 'components/atoms/Pagination';
import Icon from 'components/atoms/Icon';

// Reducer
import {Actions as ProductAction} from 'store/product';
import {Selector as AuthSelector} from 'store/auth';
import {Actions as TrackAction} from 'store/track';
import {StoreContext} from '../HomeLayout/FormContext';


const sortOptions = [
    {code: 'onAtDesc', name: '最新到最舊'},
    {code: 'hotDesc', name: '最熱銷'},
    {code: 'priceAsc', name: '價錢低到高'},
    {code: 'updateAtDesc', name: '更新排序'},
];

/**
 * 所有商品
 * @constructor
 */
const Product: React.FC = () => {
    const dispatch = useDispatch();

    const {searchForm} = useContext(StoreContext);
    const {handleSubmit, getValues, setValue, control, watch} = searchForm;

    const [perPageNum, setPerPageNum] = useState<number>(100);
    const [visibleSpeedModal, setVisibleSpeedModal] = useState<boolean>(false);
    const {categoryGroupId, categoryMainId} = watch(['categoryGroupId', 'categoryMainId']);
    const [currentPage, setCurrentPage] = useState<number>(1);


    const payload = useSelector(AuthSelector.payload);
    const isAuth = useSelector(AuthSelector.isAuth);
    const profileIsVip = useSelector(state => state.profile.isOrderVipGoods);
    const profileIsPreSaleOrder = useSelector(state => state.profile.isOrderPreSale);
    const isOrderNormalGoods = useSelector(state => state.profile.isOrderNormalGoods);
    const isFetching = useSelector(state => state.product.isFetchingGoods);
    const categoryGroup = useSelector(state => state.product.categoryGroup, (a, b) => objsComposeByKey('id', a, b));
    const productGoods = useSelector(state => state.product.productGoods, (a, b) => objsComposeByKey('id', a, b));
    const productPagination = useSelector(state => state.product.productPagination, deepCompare);
    const brandGroup = useSelector(state => state.product.brandGroup, deepCompare);
    const seasonData = useSelector(state => state.product.seasonData, (a, b) => objsComposeByKey('id', a, b));
    const isFetchingDetail = useSelector(state => state.product.isFetchingDetail);
    const productDetail = useSelector(state => state.product.productDetail, deepCompare);


    // 產品分類群組
    const groupInfo = categoryGroup.find(row => String(row.id) === String(categoryGroupId));

    // 搜尋條件
    const {search} = useLocation();
    const queryString = parseQueryString(search);
    const groupId = get(queryString, 'categoryGroupId', '');
    const mainId = get(queryString, 'categoryMainId', '');
    const subId = get(queryString, 'categorySubId', '');

    const typeId = get(queryString, 'typeId', '1');
    const name = decodeURI(get(queryString, 'name', ''));
    const model = decodeURI(get(queryString, 'model', ''));
    const brandName = decodeURI(get(queryString, 'brandName', ''));

    let brands = get(queryString, 'brands', '[]');
    if (brands !== '[]') {
        brands = decodeURI(brands);
        if (!isJSON(brands)) {
            brands = '[]';
        }
    }

    const season = get(queryString, 'season', '');
    const searchPopular = get(queryString, 'popularId', '');
    const sort = get(queryString, 'sort', 'onAtDesc');
    const pageLimit = get(queryString, 'perPageNum', '');


    useEffect(() => {
        // 取得產品季節清單
        if (get(seasonData, 'length', 0) === 0) {
            dispatch(ProductAction.fetchSeason());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        // 判斷品牌搜尋不為空時，預設帶入品牌下拉
        const jsonBrands = jsonDecode(brands);
        if(jsonBrands.length > 0){
            jsonBrands.map((brandId: number) => {
                return setValue(`brands[${brandId}]`, true);
            });
        }else{
            // 取消選取品牌勾選
            const keys = getValues();
            for(const row of Object.keys(keys)){
                if (row.search('brands') !== -1 ) {
                    setValue(row, false);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandGroup, brands]);


    useEffect(() => {
        // 取產品列表
        if (isAuth) {
            if (typeId === '1') {
                setValue('type', 1);
                setValue('search', model);
            } else if (typeId === '2') {
                setValue('type', 2);
                setValue('search', name);
            } else if (typeId === '3') {
                setValue('type', 3);
                setValue('search', brandName);
            }


            setValue('categoryGroupId', groupId);
            setValue('categoryMainId', mainId);
            setValue('categorySubId', subId);
            setValue('season', season);
            setValue('popularId', searchPopular ? Number(searchPopular) : '');
            setValue('sort', sort);
            setCurrentPage(1);

            if (!isEmpty(pageLimit)) {
                setPerPageNum(pageLimit);
                handleSearch(pageLimit, getValues().sort, 1, jsonDecode(brands));
            } else {
                handleSearch(perPageNum, getValues().sort, 1, jsonDecode(brands));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, mainId, subId, typeId, name, model, brandName, brands, season, searchPopular, sort, pageLimit]);


    /**
     *  商品群
     */
    const renderGroupOptions = () => {
        const defaultGroupOption: IOptions[] = [{code: '', name: '選擇群組'}];
        const groupOptions = categoryGroup.map(row => (
            {code: row.id, name: row.name}
        ));

        return defaultGroupOption.concat(groupOptions);
    };

    /**
     *  商品主分類
     */
    const renderCategoryMainOptions = () => {
        const defaultCategoryOption: IOptions[] = [{code: '', name: '選擇主分類'}];
        let categoryOptions: IOptions[] = [];

        if (groupInfo && groupInfo.categoryMains) {
            categoryOptions = groupInfo.categoryMains.map(row => (
                {code: row.id, name: row.name}
            ));
        }

        return defaultCategoryOption.concat(categoryOptions);
    };

    /**
     * 商品次分類
     */
    const renderCategorySubOptions = () => {
        const defaultCategorySubOption: IOptions[] = [{code: '', name: '選擇次分類'}];
        let categorySubOptions: IOptions[] = [];

        if (groupInfo && !isEmpty(categoryMainId)) {
            const subInfo = groupInfo.categoryMains ?.find(row => String(row.id) === String(categoryMainId));
            if (subInfo) {
                categorySubOptions = subInfo.categorySubs.map(row => {
                    return {code: row.id, name: row.name};
                });
            }
        }

        return defaultCategorySubOption.concat(categorySubOptions);
    };

    /**
     * 商品季節選項
     */
    const renderSeasonOptions = () => {
        const defaultSeasonOption: IOptions[] = [{code: '', name: '季節'}];
        const seasonOptions = seasonData.map(row => (
            {code: row.id, name: row.name}
        ));

        return defaultSeasonOption.concat(seasonOptions);
    };

    /**
     *  清除所有欄位
     */
    const handleClearAll = () => {
        setValue('categoryGroupId', '');
        setValue('categoryMainId', '');
        setValue('categorySubId', '');
        setValue('season', '');
        setValue('popularId', '');
        setValue('sort', 'onAtDesc');
        setValue('type', 1);
        setValue('search', '');
        setCurrentPage(1);

        // 取消選取品牌勾選
        const keys = getValues();
        for(const row of Object.keys(keys)){
            if (row.search('brands') !== -1 ) {
                setValue(row, false);
            }
        }
    };

    /**
     * 處理搜尋條件
     */
    const handleSearch = (pageNum = perPageNum, sortValue = getValues().sort, page = 1, brandIds = []) => {
        if (page === 1) {
            setCurrentPage(1);
        }

        // 品牌
        const {brands: checkedId} = getValues({nest: true});
        let ids = [];
        if (brandIds.length !== 0) {
            ids = brandIds;
        } else {
            for(const id in checkedId){
                // 過濾掉不等於 true 的 Key
                if(checkedId[id]){
                    ids.push(Number(id));
                }
            }
        }

        dispatch(ProductAction.fetchProductGoods({
            currentPage: page,
            pageLimit: pageNum,
            categoryGroupId: getValues().categoryGroupId,
            categoryMainId: getValues().categoryMainId,
            categorySubId: getValues().categorySubId,

            model: getValues().type === '1' ? getValues().search : '',
            name: getValues().type === '2' ? getValues().search : '',
            brandName: getValues().type === '3' ? getValues().search : '',

            brands: ids,
            season: getValues().season,
            isHot: getValues().popularId === 0,
            isInStock: getValues().popularId === 1,
            isNew: getValues().popularId === 2,
            isSale: getValues().popularId === 3,
            isVip: getValues().popularId === 4,
            isOrderPreSale: getValues().popularId === 5,
            isTodaySale: getValues().popularId === 6,
            isTodayNew: getValues().popularId === 7,

            sort: sortValue,
        }));


        // 移動至最上方
        window.scrollTo({top: 0, left: 0});
    };

    /**
     * 送出搜尋條件
     */
    const onSubmit = () => {
        handleSearch();
    };

    /**
     * 設定一頁顯示筆數
     * @param pageNum
     */
    const handleSetPerPageNum = (pageNum: number) => {
        setPerPageNum(pageNum);

        handleSearch(pageNum);
    };

    /**
     * 設定排序
     * @param sortValue
     */
    const handleChangeSort = (sortValue: string) => {
        handleSearch(perPageNum, sortValue, currentPage);
    };

    /**
     * 設定網頁版排序
     * @param sortValue
     */
    const handleChangeMobileSort = (sortValue: string) => {
        setValue('sort', sortValue);
        handleSearch(perPageNum, sortValue, currentPage);
    };

    /**
     * 換頁
     * @param page
     */
    const handleSetPage = (page: number) => {
        setCurrentPage(page);

        handleSearch(perPageNum, getValues().sort, page);
    };


    /**
     * 點擊快速下單
     */
    const handleSpeedOrder = (id: number) => {
        setVisibleSpeedModal(true);

        // 取得產品明細
        dispatch(ProductAction.fetchProductDetail({id}));
    };

    return (
        <ProductRoot>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* 手機版產品排序 */}
                <OrderSection className="d-lg-none">
                    <OrderText onClick={() => handleChangeMobileSort('onAtDesc')}>
                        最新到最舊
                        <Icon code={getValues().sort === 'onAtDesc' ? 'sort-down' : 'sort'} size={15} color="#9d9a9a"/>
                    </OrderText>
                    <OrderText onClick={() => handleChangeMobileSort('updateAtDesc')}>
                        更新排序
                        <Icon code={getValues().sort === 'updateAtDesc' ? 'sort-down' : 'sort'} size={15} color="#9d9a9a"/>
                    </OrderText>
                    <OrderText onClick={() => handleChangeMobileSort('priceAsc')}>
                        價錢低到高
                        <Icon code={getValues().sort === 'priceAsc' ? 'sort-up' : 'sort'} size={15} color="#9d9a9a"/>
                    </OrderText>
                </OrderSection>

                <Header className="d-none d-lg-flex">
                    <HeaderContainer>
                        <TitleSection>
                            <Title className="flex-grow-1">Home / {get(groupInfo, 'name', '')}</Title>


                            <div className="d-flex align-items-center">
                                <Title>排序</Title>

                                {/* 排序 */}
                                <Controller
                                    control={control}
                                    name="sort"
                                    as={<OrderSelectExtend
                                        option={sortOptions}
                                    />}
                                    onChange={event => {
                                        handleChangeSort(event[0]);
                                        return event[0];
                                    }}
                                />
                            </div>

                            <PerPageSection>
                                <Title>Show per page</Title>
                                <PerPageButton type="button" isSelected={perPageNum === 20} onClick={() => handleSetPerPageNum(20)}>20</PerPageButton>
                                <PerPageButton type="button" isSelected={perPageNum === 40} onClick={() => handleSetPerPageNum(40)}>40</PerPageButton>
                                <PerPageButton type="button" isSelected={perPageNum === 80} onClick={() => handleSetPerPageNum(80)}>80</PerPageButton>
                                <PerPageButton type="button" isSelected={perPageNum === 100} onClick={() => handleSetPerPageNum(100)}>100</PerPageButton>
                            </PerPageSection>
                        </TitleSection>


                        {/* 篩選條件 */}
                        <FilterSection>
                            <FilterTitle>篩選商品</FilterTitle>

                            <SelectRow className="w-100 d-flex flex-wrap">
                                <SelectCol col={4} xxl="auto">
                                    {/* 商品群組 */}
                                    <Controller
                                        control={control}
                                        name="categoryGroupId"
                                        as={<CustomSelectExtend
                                            option={renderGroupOptions()}
                                        />}
                                        onChange={(event) => {
                                            setValue('categoryMainId', '');
                                            setValue('categorySubId', '');
                                            return event[0];
                                        }}
                                    />
                                </SelectCol>

                                <SelectCol
                                    col={4}
                                    xxl="auto"
                                    className={cx({'d-none': !categoryGroupId})}
                                >
                                    {/* 商品主分類 */}
                                    <Controller
                                        control={control}
                                        name="categoryMainId"
                                        as={<CustomSelectExtend
                                            option={renderCategoryMainOptions()}
                                        />}
                                        onChange={(event) => {
                                            setValue('categorySubId', '');
                                            return event[0];
                                        }}
                                    />
                                </SelectCol>

                                <SelectCol
                                    col={4}
                                    xxl="auto"
                                    className={cx({'d-none': !categoryMainId})}
                                >
                                    {/* 商品次分類 */}
                                    <Controller
                                        control={control}
                                        name="categorySubId"
                                        as={<CustomSelectExtend
                                            option={renderCategorySubOptions()}
                                        />}
                                    />
                                </SelectCol>


                                <SelectCol col={4} xxl="auto">
                                    {/* 品牌 */}
                                    <CustomMultipleSelect
                                        name="brands"
                                        brandList={brandGroup}
                                        useFormParams={searchForm}
                                    />
                                </SelectCol>

                                <SelectCol col={4} xxl="auto">
                                    {/* 季節 */}
                                    <Controller
                                        control={control}
                                        name="season"
                                        as={<CustomSelectExtend
                                            option={renderSeasonOptions()}
                                        />}
                                    />
                                </SelectCol>

                                <SelectCol col={4} xxl="auto">
                                    {/* 選擇特性 */}
                                    <Controller
                                        control={control}
                                        name="popularId"
                                        as={<CustomSelectExtend
                                            option={getPopularOptions(profileIsVip, profileIsPreSaleOrder)}
                                        />}
                                    />
                                </SelectCol>

                                <SelectCol col>
                                    <Submit className="d-flex align-items-center justify-content-end">
                                        <ClearAll type="reset" onClick={() => handleClearAll()}>全部清除</ClearAll>

                                        <SubmitButton isBlock type="submit">
                                            確定
                                        </SubmitButton>
                                    </Submit>
                                </SelectCol>
                            </SelectRow>
                        </FilterSection>
                    </HeaderContainer>
                </Header>

                {/* 產品（前10筆） */}
                <GridThemeProvider gridTheme={{gridColumns: 60}}>
                    <ProductContainer>
                        {isFetching && (
                            <Row>
                                <Col col>
                                    <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                                </Col>
                            </Row>
                        )}

                        <ProductRow>
                            {!isFetching && productGoods.map(row => (
                                <ProductCol key={row.id} col={30} md={20} lg={15} xxl={12}>
                                    <ProductCard
                                        id={row.id}
                                        brandName={row.brandName}
                                        name={row.name}
                                        thumbUrl={row.thumbUrl}
                                        downloadThumbUrl={row.downloadThumbUrl}
                                        model={row.model}
                                        size={row.size}
                                        color={row.color}
                                        price={row.price}
                                        prePrice={row.prePrice}
                                        createDate={row.createDate}
                                        isOrderNormalGoods={isOrderNormalGoods}
                                        isNew={row.isNew}
                                        isSale={row.isSale}
                                        isInStock={row.isInStock}
                                        isHot={row.isHot}
                                        isOutStock={row.isOutStock}
                                        isVipGoods={row.isVipGoods}
                                        isFavorite={row.isTrack}
                                        detailPath={`/product/detail/${payload?.id}/${row.id}`}
                                        onSpeedOrder={() => handleSpeedOrder(row.id)}
                                        onClickFavorite={() => dispatch(TrackAction.putTrackProduct({productGoodsId: row.id, isTrack: !row.isTrack}))}
                                    />
                                </ProductCol>
                            ))}
                        </ProductRow>
                    </ProductContainer>
                </GridThemeProvider>


                {/* 快速下單光箱 */}
                <SpeedOrderModal
                    isVisible={visibleSpeedModal}
                    isFetching={isFetchingDetail}
                    onClose={() => setVisibleSpeedModal(false)}
                    id={get(productDetail, 'id')}
                    brandName={get(productDetail, 'brandName')}
                    name={get(productDetail, 'name')}
                    model={get(productDetail, 'model')}
                    price={get(productDetail, 'price', 0)}
                    prePrice={get(productDetail, 'prePrice', 0)}
                    isSale={get(productDetail, 'isSale', false)}
                    isOrderPreSale={get(productDetail, 'isOrderPreSale', false)}
                    isOrderNormalGoods={isOrderNormalGoods}
                    isTrack={get(productDetail, 'isTrack', false)}
                    inStock={get(productDetail, 'inStock', [])}
                    isOutStock={get(productDetail, 'isOutStock')}
                    isCanChooseSize={productDetail.isCanChooseSize}
                    color={get(productDetail, 'color')}
                    size={get(productDetail, 'sizes', [])}
                    batchPrice={get(productDetail, 'batchPrice', 0)}
                    batchUnitQty={get(productDetail, 'batchUnitQty', 0)}
                    images={get(productDetail, 'images', [])}
                    onTrack={() => dispatch(TrackAction.putTrackProduct({productGoodsId: get(productDetail, 'id'), isTrack: !get(productDetail, 'isTrack', false)}))}
                />
            </form>

            {!isFetching && productGoods.length === 0 && (<NotFound>您的搜尋條件目前沒有符合的資料。</NotFound>)}

            {/* 分頁 */}
            {!isFetching && (
                <CustomPagination
                    page={currentPage}
                    totalPage={get(productPagination, 'totalPages')}
                    totalItems={get(productPagination, 'totalItems')}
                    onChange={(currentPage: number) => handleSetPage(currentPage)}
                />
            )}
        </ProductRoot>
    );
};

export default Product;

const CustomPagination = styled(Pagination)`
    margin: 20px auto 25px auto;

    ${media.lg`
        margin: 47px auto 100px auto;
    `}
`;

const NotFound = styled.div`
    width: 100%;
    font-size: 14px;
    color: #ff7d6a;
    text-align: center;

    ${media.md`
        font-size: 16px;
    `}
`;

const ProductCol = styled(Col)`
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 20px;

    ${media.md`
        padding-left: 6px;
        padding-right: 6px;
        margin-bottom: 30px;
    `}

    ${media.md`
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 50px;
    `}

    ${media.md`
        margin-bottom: 20px;
    `}
`;

const ProductRow = styled(Row)`
    margin-left: -4px;
    margin-right: -4px;

    ${media.md`
        margin-left: -6px;
        margin-right: -6px;
    `}

    ${media.md`
        margin-left: -5px;
        margin-right: -5px;
    `}
`;

const ProductContainer = styled(Container)`
    padding-left: 8px;
    padding-right: 8px;

    ${media.md`
        padding-left: 10px;
        padding-right: 10px;
    `}

    ${media.lg`
        padding-left: 0;
        padding-right: 0;
    `}

    ${media.xxl`
        padding-left: 40px;
        padding-right: 40px;
    `}
`;

const SubmitButton = styled(Button)`
    width: 70px;
    height: 36px;
    min-height: auto;

    ${media.xxl`
        width: 80px;
    `}
`;

const ClearAll = styled.button`
    font-size: 14px;
    color: #9d9a9a;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    margin-right: 8px;

    ${media.xxl`
        font-size: 16px;
        margin-right: 15px;
    `}
`;

const Submit = styled.div`
`;

const CustomMultipleSelect = styled(MultipleSelect)`
    height: 36px;
    margin-bottom: 10px;

    >div{
        padding-left: 10px;
        padding-right: 10px;

        >input{
            width: 100px;
            font-size: 14px;
            color: #8f8f8f;
        }
    }

    ${media.xxl`
        width: 160px;
        margin-bottom: 0;

        >div{
            padding-left: 12px;
            padding-right: 12px;

            >input{
                width: 115px;
                font-size: 16px;
            }
        }
    `}
`;

const CustomSelectExtend = styled(CustomSelect)`
    height: 36px;
    margin-bottom: 10px;

    >div {
        padding-left: 10px;
        padding-right: 10px;

        span {
            font-size: 14px;
            color: #8f8f8f;
        }
    }

    ${media.xxl`
        width: 140px;
        margin-bottom: 0;

        >div {
            padding-left: 12px;
            padding-right: 12px;

            span {
                font-size: 16px;
            }
        }
    `}
`;

const OrderSelectExtend = styled(CustomSelect)`
    width: 135px;
    height: 36px;
    background-color: transparent;
    border: solid 1px ${site.theme.primaryColor};
    margin: 0 10px;

    i{
        color: ${site.theme.primaryColor};
    }

    >div {
        padding-left: 10px;
        padding-right: 10px;

        span {
            font-size: 14px;
            color: ${site.theme.primaryColor};
        }
    }

    ${media.xxl`
        width: 140px;

        >div {
            padding-left: 12px;
            padding-right: 12px;

            span {
                font-size: 16px;
            }
        }
    `}
`;

const SelectCol = styled(Col)`
    padding-left: 7px;
    padding-right: 7px;
`;

const SelectRow = styled(Row)`
    margin-left: -7px;
    margin-right: -7px;
`;

const FilterTitle = styled.div`
    min-width: 79px;
    height: 36px;
    font-size: 16px;
    color: #9d9a9a;
    display: flex;
    align-items: center;

    ${media.xxl`
        min-width: 84px;
    `}
`;

const FilterSection = styled.div`
    padding: 20px 0 10px 0;
    display: flex;

    ${media.xxl`
        padding: 12px 0;
    `}
`;

const PerPageButton = styled.button<any>`
    min-width: 26px;
    height: 20px;
    border: solid 1px ${site.theme.primaryColor};
    font-size: 16px;
    font-weight: bold;
    color: ${site.theme.primaryColor};
    margin-left: 10px;
    padding: 1px 5px;

    ${props => props.isSelected && css`
        background-color: ${site.theme.primaryColor};
        color: #fff;
    `}
`;

const PerPageSection = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.span`
    font-size: 16px;
    color: #a7a4a4;
`;

const TitleSection = styled.div`
    height: 60px;
    display: flex;
    align-items: center;

    ${media.xxl`
        height: 70px;
    `}
`;

const HeaderContainer = styled(Container)`
    padding-left: 0;
    padding-right: 0;
`;

const Header = styled.div`
    width: 100%;
    background-color: #fbfbfb;
    margin-bottom: 30px;

    ${media.xxl`
        margin-bottom: 33px;
    `}
`;

const OrderText = styled.div`
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: #6e6e6e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &:after{
        content: '';
        width: 1px;
        height: 10px;
        background-color: #ebebeb;
        position: absolute;
        right: 0;
    }

    &:nth-last-child(1){
        &:after{
            display: none;
        }
    }

    ${media.md`
        font-size: 16px;

        &:after{
            height: 19px;
        }
    `}
`;

const OrderSection = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 15px;
    `}
`;

const ProductRoot = styled.div`
    padding-bottom: 40px;

    ${media.md`
        padding-bottom: 0;
    `}
`;
