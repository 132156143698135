import React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {
    media, Container
} from 'library/styled-bs-grid';
import site from 'config/site';
import {motion} from 'framer-motion'
import {getOs} from 'utils/browser';

import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onBack?: () => void;
    onClose?: Function;
    id: number;
    brandName: string;
    name: string;
    model: string;
    price: number;
    prePrice: number;
    isSale?: boolean;
    size: Array<{
        size: string;
        isInStock: boolean;
    }>;
    imageUrl: string;
    batchPrice: number;
    batchUnitQty: number;
    batchQty?: number;
    setBatchQty?: Function;
}

/**
 * BatchModal
 * @param props
 * @returns {*}
 * @constructor
 */
const BatchModal: React.FC<IProps> = ({
    className,
    style,
    onBack,
    onClose = () => {},
    brandName,
    name,
    model,
    price,
    prePrice,
    isSale,
    size,
    batchPrice,
    batchUnitQty,
    imageUrl,
    batchQty = 0,
    setBatchQty = () => {},
}) => {
    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    return (
        <Modal
            className={className}
            style={style}
            variants={containerVariant}
            transition={{type: "spring", duration: 0.5}}
        >
            <Container>
                <Header>
                    <IconButton type="button" onClick={onBack}>
                        {typeof onBack !== 'undefined' && (<Icon code="arrow-right" size={15} color="#d8d8d8" rotate={180}/>)}
                    </IconButton>

                    <IconButton type="button" onClick={() => {
                        onClose();

                        if (typeof onBack !== 'undefined') {
                            onBack();
                        }
                    }}>
                        <Icon code="times" size={15} color="#d8d8d8"/>
                    </IconButton>
                </Header>

                <div className="d-flex flex-column align-items-center">
                    {/* 商品資訊 */}
                    <ProductInfo className="d-flex w-100">
                        <ProductImg src={imageUrl}/>
                        <div className="d-flex flex-column">
                            <BrandName>{brandName}</BrandName>
                            <ProductName>{name}</ProductName>
                            <Model>{`商品貨號：${model}`}</Model>

                            <div>
                                {isSale && <PrePrice>NT$ {prePrice}</PrePrice>}
                                <Price>NT$ {price}</Price>
                            </div>
                        </div>
                    </ProductInfo>

                    {/* 整手下單數量 */}
                    <OrderSection>
                        <OrderTitle>整手下單：</OrderTitle>

                        <div className="d-flex align-items-center">
                            <HandOrderButton
                                type="button"
                                disabled={batchQty === 0}
                                onClick={() => setBatchQty(batchQty - 1)}
                            >
                                <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                            </HandOrderButton>

                            <HandOrderNum>{batchQty}</HandOrderNum>

                            <HandOrderButton
                                type="button"
                                onClick={() => setBatchQty(batchQty + 1)}
                            >
                                <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                            </HandOrderButton>
                        </div>
                    </OrderSection>

                    <SizeList>
                        {size.map(row => (
                            <div key={row.size} className="d-flex flex-column align-items-center">
                               <Size>{row.size}</Size>
                               <Num>{batchQty}</Num>
                            </div>
                        ))}
                    </SizeList>

                    <TotalOrder>{`${batchUnitQty * batchQty}(件) / NT$ ${batchPrice * batchQty} 元`}</TotalOrder>
                    <SubmitButton onClick={onBack}>確認</SubmitButton>
                </div>
            </Container>
        </Modal>
    );
};

export default BatchModal;

const SubmitButton = styled(Button)`
    width: 240px;
    height: 44px;
    min-height: auto;
    margin-bottom: 10px;
    font-size: 14px;

    ${media.md`
        margin-bottom: 0;
        font-size: 16px;
    `}
`;

const TotalOrder = styled.span`
    font-size: 12px;
    color: #9d9a9a;
    margin-bottom: 10px;

    ${media.md`
        font-size: 14px;
    `}
`;

const Num = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: ${site.theme.primaryColor};

    ${media.md`
        font-size: 16px;
    `}
`;

const Size = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #9d9a9a;
    margin-bottom: 6px;
    padding: 0 15px;

    ${media.md`
        font-size: 16px;
    `}
`;

const SizeList = styled.div`
    border-radius: 2px;
    background-color: #f2f4f9;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 62px;
    padding: 10px;
`;

const HandOrderNum = styled.div`
    width: 60px;
    height: 26px;
    font-size: 14px;
    font-weight: bold;
    color: ${site.theme.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    ${media.md`
        font-size: 16px;
    `}
`;

const HandOrderButton = styled.button<any>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 1px #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    ${props => props.disabled && css`
        i{
            opacity: 0.4;
        }
    `}
`;

const OrderTitle = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;

    ${media.md`
        font-size: 14px;
    `}
`;

const OrderSection = styled.div`
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.md`
        margin-bottom: 25px;
    `}
`;

const Price = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: #ff7d6a;
`;

const PrePrice = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    padding-right: 4px;
    text-decoration: line-through;

    ${media.md`
        font-size: 14px;
        padding-right: 6px;
    `}
`;

const Model = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #9d9a9a;
    padding-bottom: 6px;

    ${media.md`
        font-size: 14px;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #282731;

    ${media.md`
        font-size: 16px;
    `}
`;

const BrandName = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #282731;

    ${media.md`
        font-size: 16px;
    `}
`;

const ProductImg = styled.img`
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 5px;

    ${media.md`
        width: 90px;
        height: 90px;
        margin-right: 15px;
    `}
`;

const ProductInfo = styled.div`
    margin-bottom: 20px;

    ${media.md`
        margin-bottom: 25px;
    `}
`;

const IconButton = styled.button<any>`
    width: 16px;
    height: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 20px;
        height: 20px;

        i {
            font-size: 20px;
        }
    `}
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    ${media.md`
        margin-bottom: 20px;
    `}
`;

const Modal = styled(motion.div)`
    width: 100%;
    min-height: 434px;
    max-height: 90%;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    overflow-y: scroll;
    padding: 15px 10px 0 10px;
    position: absolute;

    ${media.md`
        width: 460px;
        border-radius: 10px;
        padding: 30px 40px;
    `}
`;
