// Libraries
import api from 'library/apisauce';

import {TGetOrderShipper, TGetOrderShipperDetail} from './types';

export default {
    /**
     * 查詢 出貨單列表
     */
    getOrderShipper: (startDate?: string, endDate?: string, shipperId?: number, currentPage: number = 1, pageLimit: number = 20): Promise<TGetOrderShipper> => {
        return api.get('/order/shipper', {startDate, endDate, shipperId, currentPage, pageLimit}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 出貨單明細
     */
    getOrderShipperDetail: (id: number): Promise<TGetOrderShipperDetail> => {
        return api.get(`/order/shipper/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
};
