// Libraries & Utils
import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {get} from 'lodash';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {Actions as ProfileActions} from 'store/profile';
import {Actions as TrackActions} from 'store/track';
import {Actions as ProductActions} from 'store/product';
import {Actions as CartActions} from 'store/cart';
import {IActionCreators} from './types';

// Services
import ApiService, {TDeleteTrackProduct, TGetTrackProduct, TPutTrackProduct} from 'services/track';
import {Selector as ProductSelector} from 'store/product';
import {Selector as CartSelector} from 'store/cart';


/**
 * 取得追蹤商品清單
 */
function* fetchTrackProduct(action: IPickAction<IActionCreators, 'fetchTrackProduct'>) {
    yield put(Actions.fetchTrackProductBegin());

    try {
        const {currentPage, pageLimit} = action.payload;
        const [{body}]: [TGetTrackProduct] = yield all([
            call(ApiService.getTrackProduct, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);
        const {rows, ...meta} = body.data;

        yield put(Actions.fetchTrackProductSuccess({trackProduct: rows, trackPagination: meta}));
    } catch (err) {
        yield put(Actions.fetchTrackProductFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 清空追蹤商品
 */
function* clearTrackProduct(action: IPickAction<IActionCreators, 'clearTrackProduct'>) {
    yield put(Actions.clearTrackProductBegin());
    yield put(UiBlockActions.visible({message: '正在清空追蹤商品'}));

    try {
        const [{body}]: [TDeleteTrackProduct] = yield all([
            call(ApiService.clearTrackProduct),
            delay(fetchDataDelayMinMs),
        ]);
        const {totalItems} = body.data;

        // 更新追蹤清單數量
        yield put(ProfileActions.setTrackItem({trackProductCount: totalItems}));
        // 重新取得追蹤新單
        yield put(TrackActions.fetchTrackProduct({}));

        yield put(Actions.clearTrackProductSuccess());
    } catch (err) {
        yield put(Actions.clearTrackProductFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 刪除多筆追蹤商品
 */
function* deleteTrackProduct(action: IPickAction<IActionCreators, 'deleteTrackProduct'>) {
    yield put(Actions.deleteTrackProductBegin());
    yield put(UiBlockActions.visible({message: '正在刪除追蹤商品'}));

    try {
        const {productGoodsId, currentPage, pageLimit} = action.payload;
        const [{body}]: [TDeleteTrackProduct] = yield all([
            call(ApiService.deleteTrackProduct, productGoodsId),
            delay(fetchDataDelayMinMs),
        ]);
        const {totalItems} = body.data;

        // 更新追蹤清單數量
        yield put(ProfileActions.setTrackItem({trackProductCount: totalItems}));
        // 重新取得追蹤新單
        yield put(TrackActions.fetchTrackProduct({currentPage, pageLimit}));

        yield put(Actions.deleteTrackProductSuccess());
    } catch (err) {
        yield put(Actions.deleteTrackProductFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 新增 商品到追蹤清單
 */
function* putTrackProduct(action: IPickAction<IActionCreators, 'putTrackProduct'>) {
    yield put(Actions.putTrackProductBegin());

    try {
        const {productGoodsId, isTrack, callBack} = action.payload;

        // 複製產品明細資料 更改追蹤狀態
        const detailData = yield select(ProductSelector.productDetail);
        let cloneData = detailData;
        if (String(productGoodsId) === String(cloneData.id)) {
            cloneData = {
                ...cloneData,
                isTrack,
            };
            yield put(ProductActions.fetchProductDetailSuccess({productDetail: cloneData}));
        }


        // 複製產品列表 更改追蹤狀態
        const productData = yield select(ProductSelector.productGoods);
        let cloneProductData = [...productData];
        const currentIndex = cloneProductData.findIndex(o => String(o.id) === String(productGoodsId));
        if (currentIndex >= 0) {
            cloneProductData[currentIndex] = {
                ...cloneProductData[currentIndex],
                isTrack,
            };
            yield put(ProductActions.setProductGoods({productGoods: cloneProductData}));
        }

        // 複製購物車商品明細 更改追蹤狀態
        const orderDetail = yield select(CartSelector.orderDetail);
        let cloneOrderDetail = orderDetail;
        if (String(productGoodsId) === String(get(cloneOrderDetail, 'productGoods.id'))) {
            cloneOrderDetail = {
                ...cloneOrderDetail,
                productGoods: {
                    ...cloneOrderDetail.productGoods,
                    isTrack,
                }
            };
            yield put(CartActions.fetchCartOrderDetailSuccess({orderDetail: cloneOrderDetail}));
        }



        const [{body}]: [TPutTrackProduct] = yield all([
            call(ApiService.postTrackProduct, productGoodsId, isTrack),
            delay(fetchDataDelayMinMs),
        ]);
        const {totalItems} = body.data;

        // 更新追蹤清單數量
        yield put(ProfileActions.setTrackItem({trackProductCount: totalItems}));

        if (callBack) {
            callBack()
        }

        yield put(Actions.putTrackProductSuccess());
    } catch (err) {
        yield put(Actions.putTrackProductFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

export default [
    takeLatest(ActionTypes.FETCH_TRACK_PRODUCT, fetchTrackProduct),
    takeLatest(ActionTypes.CLEAR_TRACK_PRODUCT, clearTrackProduct),
    takeLatest(ActionTypes.DELETE_TRACK_PRODUCT, deleteTrackProduct),
    takeLatest(ActionTypes.PUT_TRACK_PRODUCT, putTrackProduct),
];
