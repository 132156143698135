// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {Actions as UiBlockActions} from 'store/uiBlock';

import {IActionCreators} from './types';

// Services
import ApiService, {
    TGetProductMall,
    TGetBrandGroup,
    TGetHotBrand,
    TGetCategoryGroup,
    TGetProductSeason,
    TGetProductGoods,
    TGetProductGoodsDetail,
    TGetRelatedProducts,
    TGetProductDesc,
    TGetOtherRelated, TDownloadImagesRes,
} from 'services/product';


/**
 * 查詢 商城列表
 */
function* fetchProductMall(action: IPickAction<IActionCreators, 'fetchProductMall'>) {
    yield put(Actions.fetchProductMallBegin());

    try {
        const [{body}]: [TGetProductMall] = yield all([
            call(ApiService.getProductMall),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchProductMallSuccess({mallList: rows}));

    } catch (err) {
        yield put(Actions.fetchProductMallFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 查詢 品牌列表(群組化)
 */
function* fetchBrandGroup(action: IPickAction<IActionCreators, 'fetchBrandGroup'>) {
    yield put(Actions.fetchBrandGroupBegin());

    try {
        const [{body}]: [TGetBrandGroup] = yield all([
            call(ApiService.getBrandGroup),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchBrandGroupSuccess({brandGroup: rows}));

    } catch (err) {
        yield put(Actions.fetchBrandGroupFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得熱門品牌
 */
function* fetchHotBrand(action: IPickAction<IActionCreators, 'fetchHotBrand'>) {
    yield put(Actions.fetchHotBrandBegin());

    try {
        const [{body}]: [TGetHotBrand] = yield all([
            call(ApiService.getHotBrand),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchHotBrandSuccess({hotBrand: rows}));

    } catch (err) {
        yield put(Actions.fetchHotBrandFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得商品類別(含子類)
 */
function* fetchCategoryGroup(action: IPickAction<IActionCreators, 'fetchCategoryGroup'>) {
    yield put(Actions.fetchCategoryGroupBegin());

    try {
        const [{body}]: [TGetCategoryGroup] = yield all([
            call(ApiService.getCategoryGroup),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchCategoryGroupSuccess({categoryGroup: rows}));

    } catch (err) {
        yield put(Actions.fetchCategoryGroupFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得商品季節列表
 */
function* fetchSeason(action: IPickAction<IActionCreators, 'fetchSeason'>) {
    yield put(Actions.fetchSeasonBegin());

    try {
        const [{body}]: [TGetProductSeason] = yield all([
            call(ApiService.getProductSeason),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchSeasonSuccess({seasonData: rows}));

    } catch (err) {
        yield put(Actions.fetchSeasonFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得產品列表 (Guest)
 */
function* fetchProductGoodsGuest(action: IPickAction<IActionCreators, 'fetchProductGoodsGuest'>) {
    yield put(Actions.fetchProductGoodsGuestBegin());

    try {
        const {currentPage, pageLimit, brands} = action.payload;
        const [{body}]: [TGetProductGoods] = yield all([
            call(ApiService.getProductGoodsGuest, currentPage, pageLimit, brands),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, ...meta} = body.data;
        yield put(Actions.fetchProductGoodsGuestSuccess({productGoods: rows, productPagination: meta}));

    } catch (err) {
        yield put(Actions.fetchProductGoodsGuestFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得產品列表
 */
function* fetchProductGoods(action: IPickAction<IActionCreators, 'fetchProductGoods'>) {
    yield put(Actions.fetchProductGoodsBegin());

    try {
        const {
            currentPage,
            pageLimit,
            categoryGroupId,
            categoryMainId,
            categorySubId,
            name,
            model,
            brandName,
            brands,
            season,
            isHot,
            isInStock,
            isNew,
            isSale,
            isVip,
            isOrderPreSale,
            isTodaySale,
            isTodayNew,
            sort,
        } = action.payload;

        const [{body}]: [TGetProductGoods] = yield all([
            call(ApiService.getProductGoods,
                currentPage,
                pageLimit,
                categoryGroupId,
                categoryMainId,
                categorySubId,
                name,
                model,
                brandName,
                brands,
                season,
                isHot,
                isInStock,
                isNew,
                isSale,
                isVip,
                isOrderPreSale,
                isTodaySale,
                isTodayNew,
                sort,
            ),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, ...meta} = body.data;
        yield put(Actions.fetchProductGoodsSuccess({productGoods: rows, productPagination: meta}));

    } catch (err) {
        yield put(Actions.fetchProductGoodsFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得產品明細
 */
function* fetchProductDetail(action: IPickAction<IActionCreators, 'fetchProductDetail'>) {
    yield put(Actions.fetchProductDetailBegin());

    try {
        const {id} = action.payload;
        const [{body}]: [TGetProductGoodsDetail] = yield all([
            call(ApiService.getProductGoodsDetail, id),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(Actions.fetchProductDetailSuccess({productDetail: {...body.data}}));

    } catch (err) {
        yield put(Actions.fetchProductDetailFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得關聯產品
 */
function* fetchRelatedProducts(action: IPickAction<IActionCreators, 'fetchRelatedProducts'>) {
    yield put(Actions.fetchRelatedProductsBegin());

    try {
        const {id} = action.payload;
        const [{body}]: [TGetRelatedProducts] = yield all([
            call(ApiService.getRelatedProducts, id),
            delay(fetchDataDelayMinMs),
        ]);
        const {rows} = body.data;

        yield put(Actions.fetchRelatedProductsSuccess({relatedProducts: rows}));

    } catch (err) {
        yield put(Actions.fetchRelatedProductsFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得商品說明
 */
function* fetchProductDesc(action: IPickAction<IActionCreators, 'fetchProductDesc'>) {
    yield put(Actions.fetchProductDescBegin());

    const {productGoodsId} = action.payload;
    try {
        const [{body}]: [TGetProductDesc] = yield all([
            call(ApiService.getProductDesc, productGoodsId),
            delay(fetchDataDelayMinMs),
        ]);
        const {desc, notes, sizeTables} = body.data;

        yield put(Actions.fetchProductDescSuccess({productDesc: {desc, notes, sizeTables}}));

    } catch (err) {
        yield put(Actions.fetchProductDescFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得其他綜合相關商品
 */
function* fetchOtherRelated(action: IPickAction<IActionCreators, 'fetchOtherRelated'>) {
    yield put(Actions.fetchOtherRelatedBegin());

    try {
        const [{body}]: [TGetOtherRelated] = yield all([
            call(ApiService.getOtherRelated),
            delay(fetchDataDelayMinMs),
        ]);
        const {newProductGoods, inStockProductGoods, saleProductGoods, hotProductGoods} = body.data;

        yield put(Actions.fetchOtherRelatedSuccess({otherRelated: {newProductGoods, inStockProductGoods, saleProductGoods, hotProductGoods}}));

    } catch (err) {
        yield put(Actions.fetchOtherRelatedFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}



/**
 * 下載商品圖片
 */
function* downloadImages(action: IPickAction<IActionCreators, 'downloadImages'>) {
    yield put(Actions.downloadImagesBegin());
    yield put(UiBlockActions.visible({message: '正在打包圖片'}));

    const {id} = action.payload;

    const saveBlob = (blob: Blob, fileName: string) =>  {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    };

    try {
        const [data]: [TDownloadImagesRes] = yield all([
            call(ApiService.downloadImages, id),
            delay(fetchDataDelayMinMs),
        ]);


        const fileName = data.headers['content-disposition'].match(/\sfilename="([^"]+)"(\s|$)/);
        saveBlob(data.body, fileName ? fileName[1] : 'no-name.zip');


        yield put(Actions.downloadImagesSuccess());


    } catch (err) {
        yield put(Actions.downloadImagesFail({message: err.message}));
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));

    } finally {
        yield put(UiBlockActions.hidden());

    }
}

export default [
    takeLatest(ActionTypes.FETCH_PRODUCT_MALL, fetchProductMall),
    takeLatest(ActionTypes.FETCH_BRAND_GROUP, fetchBrandGroup),
    takeLatest(ActionTypes.FETCH_HOT_BRAND, fetchHotBrand),
    takeLatest(ActionTypes.FETCH_CATEGORY_GROUP, fetchCategoryGroup),
    takeLatest(ActionTypes.FETCH_SEASON, fetchSeason),
    takeLatest(ActionTypes.FETCH_PRODUCT_GOODS_GUEST, fetchProductGoodsGuest),
    takeLatest(ActionTypes.FETCH_PRODUCT_GOODS, fetchProductGoods),
    takeLatest(ActionTypes.FETCH_PRODUCT_DETAIL, fetchProductDetail),
    takeLatest(ActionTypes.FETCH_RELATED_PRODUCTS, fetchRelatedProducts),
    takeLatest(ActionTypes.FETCH_PRODUCT_DESC, fetchProductDesc),
    takeLatest(ActionTypes.FETCH_OTHER_RELATED, fetchOtherRelated),
    takeLatest(ActionTypes.DOWNLOAD_IMAGES, downloadImages),
];
