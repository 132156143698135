import React from 'react';
import * as CSS from 'csstype';
import {media} from 'library/styled-bs-grid';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {formatCurrency} from 'utils/number';
import Icon from 'components/atoms/Icon';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    account: string;
    avatarUrl: string|undefined;
    amount: number;
    contentList: Array<{
        iconCode: string;
        name: string;
        href: string;
    }>;
    onClickLogOut?: Function;
}

/**
 * DropDownList
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const DropDownList: React.FC<IProps> = ({
    className,
    style,
    account,
    avatarUrl,
    amount,
    contentList,
    onClickLogOut = () => {},
}) => {
    const history = useHistory();

    return (
        <Menu>
            <MemberInfo
                className={className}
                style={style}
            >
                <AvatarImg avatarUrl={avatarUrl}/>

                <AccountInfo>
                    <Account>{account}</Account>
                    <Amount>$ {formatCurrency(amount)}</Amount>
                </AccountInfo>
                <ChevronIcon code="chevron-fillet-down" size={10} color="#9d9a9a"/>
            </MemberInfo>

            <MenuBg>
                <MenuList>
                    {contentList.map(row => (
                        <DropDownItem
                            key={row.iconCode}
                            onClick={() => history.push(row.href)}
                        >
                            <Icon code={row.iconCode} size={20} color="#9d9a9a"/>
                            <ItemName>{row.name}</ItemName>
                        </DropDownItem>
                    ))}

                    <LoginOutButton
                        type="button"
                        onClick={onClickLogOut}
                    >
                        登出
                    </LoginOutButton>
                </MenuList>
            </MenuBg>
        </Menu>
    );
};

export default DropDownList;

const LoginOutButton = styled.button<any>`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #d8d8d8;
    margin: 5px 0 16px 0;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;

    :hover{
        background-color: #c3c3c3;
    }
`;

const ItemName = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #9d9a9a;
    padding-left: 24px;
`;

const DropDownItem = styled.div`
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
        ${ItemName} {
            color: ${props => props.theme.primaryColor};
        }
        i {
            color: ${props => props.theme.primaryColor};
        }
    }
`;

const MenuList = styled.div`
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
    padding: 0 16px;
`;

const MenuBg = styled.div`
    width: 100%;
    padding-top: 10px;
    position: absolute;
    z-index: 6;
    top: 100%;
    left: 0;
    display: none;
    cursor: pointer;
`;

const ChevronIcon = styled(Icon)`
`;

const Amount = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #ff7d6a;
`;

const Account = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #282731;
`;

const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-right: 10px;
`;

const AvatarImg = styled.div<any>`
    background-image: url("${props => props.avatarUrl}");
    background-size: cover;
    width: 30px;
    height: 30px;
    background-color: #edeff2;
    border-radius: 50%;
    margin-right: 10px;

    ${media.xl`
        margin-right: 16px;
    `}
`;

const MemberInfo = styled.div`
    width: 100%;
    height: 55px;
    padding: 0 18px 0 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
    }

    ${media.xl`
        min-width: 190px;
    `}

    ${media.xxl`
        min-width: 220px;
    `}
`;

const Menu = styled.div`
    position: relative;
    display: inline-flex;

    :hover {
        ${MenuBg} {
            display: block;
        }

        ${MemberInfo} {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
        }

        ${ChevronIcon} {
            transform: rotate(180deg);
        }
    }
`;
