import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {useHistory, useParams} from 'react-router-dom';
import {formatCurrency} from 'utils/number';
import {get} from 'lodash';
import {Container} from 'library/styled-bs-grid';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';
import site from 'config/site';

// component
import Icon from 'components/atoms/Icon';

// Reducers
import {Actions as OrderAction} from 'store/order';
import {Selector as AuthSelector} from 'store/auth';


/**
 *  我的訂單-明細
 * @constructor
 */
const Detail: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {orderHeadList, orderHeadDetail, payload} = useSelector(state => ({
        orderHeadList: state.order.orderHeadList,
        orderHeadDetail: state.order.orderHeadDetail,
        payload: AuthSelector.payload(state),
    }));

    // 該筆訂單資訊
    const productInfo = orderHeadList.find(o => o.id === Number(id));

    useEffect(() => {
        // 取得訂單列表
        if (get(orderHeadList, 'length', 0) === 0) {
            dispatch(OrderAction.fetchOrderHead({orderId: id}));
        }

        // 取得該筆訂單的明細api
        dispatch(OrderAction.fetchOrderHeadDetail({id}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* Mobile Header */}
            <Container>
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/order')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>我的訂單</MobileTitle>

                    <IconButton/>
                </MobileHeader>
            </Container>

            <OrderInfoHeader>
                <Container>
                    <OrderNumber>
                        <FieldTitle>訂單編號</FieldTitle>
                        <FieldValue>{get(productInfo, 'id')}</FieldValue>
                        <Price>$ {formatCurrency(get(productInfo, 'amount'))}</Price>
                    </OrderNumber>
                </Container>
            </OrderInfoHeader>
            <Container className="d-flex flex-column">
                <OrderInfo>
                    <OrderContent>
                        <FieldTitle>訂購時間</FieldTitle>
                        <FieldValue>{get(productInfo, 'createdAt')}</FieldValue>
                        <View>查看訂單</View>
                    </OrderContent>
                    <OrderContent>
                        <FieldTitle>付款方式</FieldTitle>
                        <FieldValue>{get(productInfo, 'payWay')}</FieldValue>
                    </OrderContent>
                    <OrderContent>
                        <FieldTitle>詳情備註</FieldTitle>
                        <FieldValue>{get(productInfo, 'remark')}</FieldValue>
                    </OrderContent>
                </OrderInfo>

                {/* Detail */}
                {orderHeadDetail.map(row => (
                    <ProductCard key={row.id}>
                        <Model onClick={() => window.open(`/product/detail/${payload?.id}/${row.productGoods.id}`)}>{get(row, 'productGoods.model')}</Model>
                        <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                        <ProductName>{get(row, 'productGoods.name')}</ProductName>
                        <SubName>{get(row, 'productGoods.subName')}</SubName>

                        <div className="d-flex">
                            <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>

                            <div>
                                <Info>
                                    <DetailFieldTitle>顏色</DetailFieldTitle>
                                    <DetailFieldValue>{get(row, 'productGoods.color')}</DetailFieldValue>
                                </Info>
                                <Info>
                                    <DetailFieldTitle>購買SIZE</DetailFieldTitle>
                                    <DetailFieldValue>{get(row, 'sizeQty')}</DetailFieldValue>
                                </Info>
                                <Info>
                                    <DetailFieldTitle>單價</DetailFieldTitle>
                                    <DetailFieldValue>$ {formatCurrency(get(row, 'price'))}</DetailFieldValue>
                                    <DetailFieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</DetailFieldValue>
                                </Info>
                                <Info>
                                    <DetailFieldTitle>數量</DetailFieldTitle>
                                    <Qty isDelete={row.totalQty !== row.updateTotalQty}>{row.totalQty}</Qty>
                                    {row.totalQty !== row.updateTotalQty && (<UpdateQty>{row.updateTotalQty}</UpdateQty>)}
                                </Info>
                                <Info>
                                    <DetailFieldTitle>總計</DetailFieldTitle>
                                    <DetailFieldValue>$ {formatCurrency(get(row, 'totalAmount'))}</DetailFieldValue>
                                </Info>
                            </div>
                        </div>
                    </ProductCard>
                ))}

                <HomeButton type="button" onClick={() => history.push('/')}>回首頁</HomeButton>
            </Container>
        </>
    );
};

export default Detail;

const HomeButton = styled.button`
    width: 250px;
    height: 30px;
    border-radius: 2px;
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    margin: 10px 0 20px 0;
    text-align: center;
    align-self: center;
`;

const DetailFieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const UpdateQty = styled(DetailFieldValue)`
    color: #ff7d6a;
    padding-left: 3px;
`;

const Qty = styled(DetailFieldValue)<any>`
    ${props => props.isDelete && css`
        text-decoration: line-through;
    `}
`;

const DetailFieldTitle = styled.span`
    width: 62px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
`;
const Info = styled.div`
    display: flex;
    align-items: center;
`;

const ProductImg = styled.img`
    width: 100px;
    height: 100px;
    margin-right: 8px;
    background-color: lightgray;
`;

const SubName = styled.span`
    font-size: 12px;
    text-align: center;
    color: #6e6e6e;
    margin-bottom: 8px;
`;

const ProductName = styled.span`
    font-size: 12px;
    text-align: center;
    color: #6e6e6e;
`;

const BrandName = styled.span`
    font-size: 12px;
    text-align: center;
    color: #6e6e6e;
`;

const Model = styled.span`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #6e6e6e;
`;

const ProductCard = styled.div`
    width: 100%;
    min-height: 185px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
`;

const View = styled.span`
    font-size: 12px;
    color: #d8d8d8;
    text-align: right;
`;

const OrderContent = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 5px;
`;

const OrderInfo = styled.div`
    padding: 0 10px 13px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Price = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #ff7d6a;
`;

const FieldValue = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    flex: 1;
`;

const FieldTitle = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    padding-right: 8px;
`;

const OrderNumber = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
`;

const OrderInfoHeader = styled.div`
    width: 100%;
    height: 30px;
    border-radius: 2px;
    background-color: #fcfcfc;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
