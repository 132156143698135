import React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import site from 'config/site';
import {Col, media, Row} from 'library/styled-bs-grid';
import get from 'lodash/get';
import {formatCurrency} from 'utils/number';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {useSelector} from 'react-redux';

// Component
import StatusTag from 'components/atoms/Tag/StatusTag';
import Icon from 'components/atoms/Icon';

// Stores
import {Selector as AuthSelector} from 'store/auth';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    isFetching?: boolean,
    id: number;
    shipNumber: number;
    shipTime: string;
    shipWay?: string;
    shipAmount: number;
    logisticsCompany: string;
    freightNumber: string;
    address: string;
    isSelected: boolean;
    onClick: Function;
    onClickPrint?: Function;
    details: Array<{
        id: number,
        size: string,
        qty: number,
        price: number,
        totalAmount: number,
        productGoods: {
            id: number,
            model: string,
            name: string,
            subName: string,
            brandName: string,
            thumbUrl: string,
            isExtraFare: boolean,
        }
    }>;
}

/**
 * Ship accordion
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ShipAccordion: React.FC<IProps> = ({
    style,
    className,
    isFetching,
    id,
    shipNumber,
    shipTime,
    shipWay,
    shipAmount,
    logisticsCompany,
    freightNumber,
    address,
    isSelected,
    onClick = () => {},
    onClickPrint = () => {},
    details,
}) => {
    const {payload} = useSelector(state => ({
        payload: AuthSelector.payload(state),
    }));

    /**
     * 按下列印按鈕
     * @param event
     */
    const handleClickPrint = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isSelected) {
            event.stopPropagation(); // 终止事件冒泡
        }
        onClickPrint();
    };

    return (
        <>
            <AccordionRoot
                className={className}
                style={style}
                isSelected={isSelected}
                onClick={onClick}
            >
                <Num className="d-none d-md-block"/>
                <MobileCardHeader className="d-md-none">
                    <CustomStatusTag className="d-md-none" theme="green" text="已出貨"/>
                    <MobilePrice className="d-md-none">{`$ ${formatCurrency(shipAmount)}`}</MobilePrice>
                </MobileCardHeader>

                <Number>
                    <MobileTitle>出貨單號</MobileTitle>
                    {shipNumber}

                    <ViewDetail className="d-md-none">查看商品</ViewDetail>
                </Number>
                <DateTime>
                    <MobileTitle>出貨時間</MobileTitle>
                    {shipTime}
                </DateTime>
                <ShipPrice className="d-none d-md-block">
                    $ {formatCurrency(shipAmount)}
                </ShipPrice>

                <Company className="d-flex">
                    <MobileTitle>物流公司</MobileTitle>
                    <div className="d-md-none">{`${logisticsCompany} ${freightNumber}`}</div>
                    <div className="d-none d-md-block">{logisticsCompany}<br/>{freightNumber}</div>
                </Company>
                <Address className="d-flex">
                    <MobileTitle>出貨地址</MobileTitle>
                    <div>{address}</div>
                </Address>

                <View className="h-100 d-none d-md-flex align-items-center justify-content-around">
                    <ViewText>查看商品</ViewText>


                        <PrintButton type="button" onClick={handleClickPrint}>
                            <Icon code="print" size={30} color={site.theme.primaryColor}/>

                            <Print>
                                列印
                                <Arrow/>
                            </Print>
                        </PrintButton>
                </View>

            </AccordionRoot>

            {isSelected && (
                <Detail className="d-none d-md-block">
                    <Header>
                        <OrderNumber/>
                        <Product>商品貨號/品牌</Product>
                        <Category>顏色</Category>
                        <Size>出貨SIZE</Size>
                        <Price>單價</Price>
                        <Quantity>數量</Quantity>
                        <Total>總計</Total>
                    </Header>


                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}


                    {!isFetching && details.map((row, index) => (
                        <Body key={row.id}>
                            <OrderNumber>{index + 1}</OrderNumber>
                            <Product>
                                <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>
                                <div className="d-flex flex-column justify-content-center align-items-start">
                                    <ProductNumber onClick={() => window.open(`/product/detail/${payload?.id}/${row.productGoods.id}`)}>{get(row, 'productGoods.model')}</ProductNumber>
                                    <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                                    <ProductName>{get(row, 'productGoods.name')}</ProductName>
                                    <SubName>{get(row, 'productGoods.subName')}</SubName>
                                </div>
                            </Product>
                            <Category>{get(row, 'productGoods.color')}</Category>
                            <Size>{get(row, 'size')}</Size>
                            <Price>
                                <FieldValue>{`$ ${formatCurrency(get(row, 'price'))}`}</FieldValue>
                                <FieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                            </Price>
                            <Quantity>
                                <Qty>{get(row, 'qty')}</Qty>
                            </Quantity>
                            <Total isBold>{`$ ${formatCurrency(get(row, 'totalAmount'))}`}</Total>
                        </Body>
                    ))}
                </Detail>
            )}
        </>
    );
};

export default ShipAccordion;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const HeaderTitle = styled.div<any>`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    text-align: left;

    ${props => props.isCenter && css`
        text-align: center;
    `};
`;

const Word = styled.div`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}
`;

const Total = styled(HeaderTitle)`
    width: 140px;

    ${media.xxl`
        width: 232px;
    `}

    ${props => props.isBold && css`
        font-weight: bold;
    `}
`;

const Qty = styled.span`
    width: 32px;
    display: block;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    color: #6e6e6e;
`;

const Quantity = styled(HeaderTitle)`
    width: 140px;

    ${media.xxl`
        width: 142px;
    `}
`;

const Price = styled(HeaderTitle)`
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.xxl`
        width: 142px;
    `}
`;

const Size = styled(HeaderTitle)`
    width: 140px;

    ${media.xxl`
        width: 221px;
    `}
`;

const Category = styled(HeaderTitle)`
    width: 150px;

    ${media.xxl`
        width: 172px;
    `}
`;

const SubName = styled(Word)`

`;

const ProductName = styled(Word)`

`;

const BrandName = styled(Word)`
`;

const ProductNumber = styled(Word)`
    font-weight: bold;
    padding-bottom: 7px;
    cursor: pointer;
`;

const ProductImg = styled.img`
    width: 70px;
    height: 80px;
    margin-right: 10px;
    background-color: lightgray;
`;

const Product = styled(HeaderTitle)`
    width: 369px;
    display: flex;
    align-items: center;

    ${media.xxl`
        width: 422px;
    `}
`;

const OrderNumber = styled(Word)`
    width: 50px;
    text-align: center;
    font-weight: bold;

    ${media.xxl`
        width: 109px;
    `}
`;

const Header = styled.div`
    width: 100%;
    height: 44px;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    border-bottom: solid 1px #edeff2;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const Body = styled(Header)`
    height: 100px;
    align-items: center;
    margin-top: 0;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const Detail = styled.div<any>`
    width: 100%;
`;

const Arrow = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${props => props.theme.primaryColor} transparent transparent transparent;
`;

const Print = styled.div`
    width: 42px;
    height: 15px;
    background-color: #8ec5ff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -7px);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PrintButton = styled.button<any>`
    width: 30px;
    height: 30px;
    background-color: #fbfbfb;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ViewText = styled(Word)`
    font-weight: 600;
    color: #acacac;
    transition: color .2s;

    &:hover{
       color: ${site.theme.primaryColor};
    }
`;

const View = styled(Word)`
    width: 162px;
    text-align: center;
    visibility: hidden;
`;

const Address = styled(Word)`
    padding: 0 10px 4px 10px;

    ${media.md`
        width: 376px;
        padding: 0;
    `}

    ${media.xxl`
        width: 370px;
    `}
`;

const CustomStatusTag = styled(StatusTag)`
    width: 64px;
    height: 20px;
    border-radius: 4px;

    ${media.md`
        width: 90px;
        height: 32px;
    `}
`;

const Company = styled(Word)`
    padding: 0 10px 4px 10px;

    ${media.md`
        width: 150px;
        padding: 0;
    `}

    ${media.xxl`
        width: 322px;
    `}
`;

const ShipPrice = styled(Word)`
    ${media.md`
        width: 140px;
        padding: 0;
        font-weight: bold;
    `}

    ${media.xxl`
        width: 195px;
    `}
`;

const DateTime = styled(Word)`
    padding: 0 10px 4px 10px;
    display: flex;
    align-items: center;

    ${media.md`
        width: 140px;
        padding: 0;
    `}

    ${media.xxl`
        width: 165px;
    `}
`;

const MobilePrice = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #ff7d6a;
    flex: 1;
`;

const ViewDetail = styled.span`
    font-size: 12px;
    color: #d8d8d8;
    text-align: right;
    flex: 1;
`;

const MobileTitle = styled.span`
    min-width: 56px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    padding-right: 8px;

    ${media.md`
        display: none;
    `}
`;

const Number = styled(Word)`
    padding: 0 10px 4px 10px;
    display: flex;
    align-items: center;

    ${media.md`
        width: 130px;
        min-height: auto;
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
    `}

    ${media.xxl`
        width: 165px;
    `}
`;

const MobileCardHeader = styled.div`
    width: 100%;
    min-height: 30px;
    border-radius: 2px;
    background-color: #f8f8f8;
    padding: 0 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;

const Num = styled(Word)`
    width: 20px;

    ${media.xxl`
        width: 60px;
    `}
`;

const AccordionRoot = styled.div<any>`
    width: 100%;
    min-height: 102px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    cursor: pointer;

    ${media.md`
        width: 1120px;
        height: 56px;
        min-height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: none;
        margin-top: 10px;
        margin-bottom: 0;

        ${(props: any) => props.isSelected && css`
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

            ${View} {
                visibility: visible;
            }
        `};
    `}

    ${media.lg`
        &:hover {
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
            ${View} {
                visibility: visible;
            }
        }
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;
