/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux';
import {rootReducers} from 'store/rootReducers';
import history from 'library/react-router/history';
import {connectRouter} from 'connected-react-router';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
    const appReducer = combineReducers({
        // 路由 & 語系
        router: connectRouter(history),

        // 自定義
        ...rootReducers,
        ...injectedReducers,
    });

    // 重設APP Redux Store
    const rootReducer = (state: any, action: any) => {
        if (action.type === 'startup/RESET_APP') {
            // 白名單設定(不做清除)
            const {
                system, auth, startup, ui,
            } = state;
            state = {
                system, auth, startup, ui,
            };
        }
        return appReducer(state, action);
    };

    return rootReducer;
}
