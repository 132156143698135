import React, {useState} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';
import {isEmpty} from 'utils/equal';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    title: string;
    content: React.ReactNode;
    iconPath?: string;
}

/**
 * CollapsibleItem
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CollapsibleItem: React.FC<IProps> = ({
    className,
    style,
    title,
    content,
    iconPath,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <CollapsibleItemRoot>
            <ItemRoot className={className} style={style} onClick={() => setIsVisible(!isVisible)}>
                {!isEmpty(iconPath) && (
                    <IconImg src={iconPath}/>
                )}
                <Title>{title}</Title>
                <IconPlus code={isVisible ? 'minus' : 'plus'} size={18}/>
            </ItemRoot>

            <Content isVisible={isVisible}>
                <ContentInfo>
                    {content}
                </ContentInfo>
            </Content>
        </CollapsibleItemRoot>
    );
};

export default CollapsibleItem;

const IconImg = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 8px;
`;

const ItemRoot = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0;
    border-bottom: 1px solid rgba(156, 146, 255, 0.5);
    cursor: pointer;
    z-index: 1;
`;

const Title = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #6e6e6e;
    flex: 1 1 auto;

    ${media.lg`
        font-size: 18px;
    `};
`;

const IconPlus = styled(Icon)`
    color: rgba(156, 146, 255);
    opacity: 0.6;

    ${media.lg`
        font-size: 22px;
    `};
`;

const ContentInfo = styled.p`
    padding: 15px 0;


    ${media.lg`
        padding: 15px 0 35px 0;
    `};
`;

const Content = styled.div<any>`
    font-size: 13px;
    color: #6e6e6e;
    display: block;
    z-index: -1;
    max-height: 0;
    transition: transform .3s ease, opacity .1s ease, max-height .3s ease;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    ${props => props.isVisible && css`
        opacity: 1;
        max-height: 100vh;
    `};

    ${media.lg`
        font-size: 16px;
    `};
`;

const CollapsibleItemRoot = styled.div`
    &:last-child {
        ${ContentInfo}{
            padding: 15px 0 0 0;
        }
    }
`;
