// Libraries & Utils
import Immutable  from 'seamless-immutable';
import {TReducers} from "library/redux";
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from "./types";



/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'message';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    isFetchingContent: false,
    message: '',

    messageCategory: [],
    messageContent: [],
    isReadonly: false,

    isSubmitting: false,
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {
    messageContent: (state) => state[storeKey].messageContent,
    messageCategory: (state) => state[storeKey].messageCategory,
};


/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得訊息類別大綱
     */
    fetchCategory: (state, action) => {
        return state;
    },
    fetchCategoryBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取訊息類別'})
    },
    fetchCategorySuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', messageCategory: payload.messageCategory});
    },
    fetchCategoryFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得訊息內容
     */
    fetchContent: (state, action) => {
        return state;
    },
    fetchContentBegin: (state) => {
        return state.merge({isFetchingContent: true, message: '正在讀取訊息內容', messageContent: []})
    },
    fetchContentSuccess: (state, {payload}) => {
        return state.merge({isFetchingContent: false, message: '', messageContent: payload.messageContent, isReadonly: payload.isReadonly});
    },
    fetchContentFail: (state, {payload}) => {
        return state.merge({isFetchingContent: false, message: ''});
    },
    /**
     * 送出訊息
     */
    submitMessage: (state) => {
        return state;
    },
    submitMessageBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在送出訊息'})
    },
    submitMessageSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: '', messageContent: payload.messageContent});
    },
    submitMessageFail: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
