import React from 'react';
import {Switch, Route} from 'react-router-dom';
import NotFound from 'views/NotFound';
import Account from 'views/Profile/Account';
import AdvanceOrder from 'views/Profile/AdvanceOrder';
import Order from 'views/Profile/Order';
import OrderDetail from 'views/Profile/Order/Detail';
import StockUp from 'views/Profile/StockUp';
import Ship from 'views/Profile/Ship';
import ShipDetail from 'views/Profile/Ship/Detail';
import Remittance from 'views/Profile/Remittance';
import Trade from 'views/Profile/Trade';

const Router: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/profile/account" component={Account}/>
            <Route exact path="/profile/advanceOrder" component={AdvanceOrder}/>
            <Route exact path="/profile/order" component={Order}/>
            <Route exact path="/profile/order/detail/:id" component={OrderDetail}/>
            <Route exact path="/profile/stockUp" component={StockUp}/>
            <Route exact path="/profile/ship" component={Ship}/>
            <Route exact path="/profile/ship/detail/:id" component={ShipDetail}/>
            <Route exact path="/profile/remittance" component={Remittance}/>
            <Route exact path="/profile/trade" component={Trade}/>

            <Route path="*" component={NotFound}/>
        </Switch>
    );
};

export default Router;
