// Libraries & Utils
import Immutable from 'seamless-immutable';
import {TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'ad';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    message: '',

    signInNews: [],
    isVisibleSignInNewsModal: false,
    homeCarousel: [],
    placementAd: [],
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得登入後廣告
     */
    fetchSignInNews: (state) => {
        return state;
    },
    fetchSignInNewsBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取廣告資訊'});
    },
    fetchSignInNewsSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', isVisibleSignInNewsModal: payload.isVisible, signInNews: payload.signInNews});
    },
    fetchSignInNewsFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 關閉登入後廣告光箱
     */
    closeSignInNewsModal: (state) => {
        return state.merge({isVisibleSignInNewsModal: false});
    },
    /**
     * 取得首頁輪播橫幅
     */
    fetchHomeCarousel: (state) => {
        return state;
    },
    fetchHomeCarouselBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取首頁輪播廣告'});
    },
    fetchHomeCarouselSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', homeCarousel: payload.homeCarousel});
    },
    fetchHomeCarouselFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得各區塊置入廣告
     */
    fetchPlacement: (state) => {
        return state;
    },
    fetchPlacementBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取各區塊置入廣告資訊'});
    },
    fetchPlacementSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', placementAd: payload.placementAd});
    },
    fetchPlacementFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
