import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {asset} from 'config/app';
import {motion, AnimatePresence} from 'framer-motion'

// Component
import Icon from 'components/atoms/Icon';
import ScrollView from 'components/atoms/ScrollView';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onClose?: Function;
    isVisible?: boolean;
    address: string;
    phone: string;
    mobile: string;
    email: string;
    line: string;
}

/**
 * ContactModal
 * @param props
 * @returns {*}
 * @constructor
 */
const ContactModal: React.FC<IProps> = ({
    className,
    style,
    onClose = () => {},
    isVisible = false,
    address,
    phone,
    mobile,
    email,
    line,
}) => {
    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <Overlay
                    className={className}
                    style={style}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <ScrollView className="d-flex align-items-end justify-content-center">
                        <Modal
                            className="d-flex flex-column align-items-center"
                            variants={containerVariant}
                            transition={{type: "spring", duration: 0.5}}
                        >
                            <CloseButton type="button" onClick={onClose}>
                                <Icon code="times" size={15} color="#cecece"/>
                            </CloseButton>

                            <Logo src={asset('/images/home/header-logo.png')}/>

                            <div className="d-flex flex-column">
                                <ContactInfo className="d-flex align-items-center">
                                    <IconDiv>
                                        <Icon code="map-marker-alt" color="#9d9a9a" size={20}/>
                                    </IconDiv>
                                    <ContactDesc>{address}</ContactDesc>
                                </ContactInfo>

                                <ContactInfo className="d-flex align-items-center">
                                    <IconDiv>
                                        <Icon code="phone-alt" color="#9d9a9a" size={20}/>
                                    </IconDiv>
                                    <ContactDesc>{phone}</ContactDesc>
                                </ContactInfo>

                                <ContactInfo className="d-flex align-items-center">
                                    <IconDiv>
                                        <Icon code="fa-mobile" color="#9d9a9a" size={20}/>
                                    </IconDiv>
                                    <ContactDesc>{mobile}</ContactDesc>
                                </ContactInfo>

                                <ContactInfo className="d-flex align-items-center">
                                    <IconDiv>
                                        <Icon code="envelope" color="#9d9a9a" size={20}/>
                                    </IconDiv>
                                    <ContactDesc>{email}</ContactDesc>
                                </ContactInfo>

                                <ContactInfo className="d-flex align-items-center">
                                    <IconDiv>
                                        <Icon code="line" color="#9d9a9a" size={20}/>
                                    </IconDiv>
                                    <ContactDesc>LINE ID : {line}</ContactDesc>
                                </ContactInfo>
                            </div>
                        </Modal>
                    </ScrollView>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default ContactModal;

const ContactDesc = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #9d9a9a;
`;

const IconDiv = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContactInfo = styled.div`
    margin-bottom: 15px;
`;

const Logo = styled.img`
    width: 118px;
    height: 29px;
    margin-bottom: 35px;
`;

const CloseButton = styled.button<any>`
    width: 16px;
    height: 16px;
    padding: 0;
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Modal = styled(motion.div)`
    width: 100%;
    height: 340px;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    background-color: #ffffff;
    position: relative;
`;

const Overlay = styled(motion.div)`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
`;
