import React, {useContext, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {isEmpty} from 'utils/equal';
import get from 'lodash/get';
import {addressList} from 'config/address';

// Component
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Button from 'components/atoms/Button';

// Reducer
import {Actions as UiModalAction} from 'store/uiDialog';
import {Actions as AuthAction} from 'store/auth';

// Stores
import {StoreContext} from '../FormContext';


/**
 * 註冊：步驟ㄧ
 * @constructor
 */
const Step1: React.FC = () => {
    const dispatch = useDispatch();
    const {formTempState, dispatchFormData} = useContext(StoreContext);

    const {handleSubmit, watch, setValue, control, errors, reset, formState, triggerValidation} = useForm({
        mode: 'onChange',
    });

    const {deliveryCity} = watch(['deliveryCity']);
    const history = useHistory();

    useEffect(() => {
        if (!isEmpty(get(formTempState, 'step1Data.account'))) {
            reset(formTempState.step1Data);

            setTimeout(()=>{
                // 觸發驗證
                triggerValidation();
            }, 0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /**
     * 取得縣市資料
     */
    const countyOptions = () => {
        return addressList.map(row => (
            {value: row.counties, text: row.counties}
        ));
    };

    /**
     * 取得鄉鎮資料
     */
    const townshipOptions = () => {
        const town = addressList.find(o => o.counties === deliveryCity);
        if (town) {
            return town.districts.map(row => (
                {value: row, text: row}
            ));
        } else {
            return [{value: '', text: '請選擇'}];
        }
    };

    /**
     * 送出
     */
    const onSubmit = (formData: any) => {
        if (formData.confirmPassword !== formData.password) {
            dispatch(UiModalAction.openError({message: '確認密碼失敗'}));
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            dispatch(UiModalAction.openError({message: '電子信箱欄位格式錯誤'}));
        } else {
            dispatchFormData({step1Data: {
                account: formData.account,
                confirmPassword: formData.confirmPassword,
                deliveryAddress: formData.deliveryAddress,
                deliveryCity: formData.deliveryCity,
                deliveryTownship: formData.deliveryTownship,
                email: formData.email,
                lineId: formData.lineId,
                password: formData.password,
                phone: formData.phone,
                postalCode: formData.postalCode,
                realName: formData.realName,
            }});

            dispatch(AuthAction.submitVerify({account: formData.account, email: formData.email}));
        }
    };

    return (
        <SignUpRoot>
            {/* Mobile Header */}
            <SignUpContainer className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>
                    <MobileTitle>建立您的帳號</MobileTitle>
                    <IconButton disabled/>
                </MobileHeader>
            </SignUpContainer>

            {/* PC Header */}
            <HeaderBg className="d-none d-md-flex">
                <HeaderContainer>
                    <HeaderRow className="d-flex align-items-end">
                        <HeaderCol col>
                            <HeaderBgDesc>Home / 註冊帳號</HeaderBgDesc>
                        </HeaderCol>
                        <HeaderCol col className="d-flex flex-column align-items-center">
                            <ChineseTitle>建立您的帳號</ChineseTitle>
                            <HeaderBgDesc>Create your account</HeaderBgDesc>
                        </HeaderCol>
                        <HeaderCol col/>
                    </HeaderRow>
                </HeaderContainer>
            </HeaderBg>

            <form onSubmit={handleSubmit(onSubmit)}>
                <SignUpContainer>
                    <Controller
                        control={control}
                        name="account"
                        rules={{required: true}}
                        as={<CustomInput
                            placeholder="會員帳號"
                            remarkMessage="英文加數字組合6位數以上"
                        />}
                    />
                    <SignUpRow>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="password"
                                rules={{required: true}}
                                as={<CustomInput
                                    type="password"
                                    placeholder="密碼"
                                />}
                            />
                        </SignUpCol>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="confirmPassword"
                                rules={{required: true}}
                                as={<CustomInput
                                    type="password"
                                    placeholder="確認密碼"
                                />}
                            />
                        </SignUpCol>
                    </SignUpRow>

                    <SectionTitle>個人資料：</SectionTitle>
                    <SignUpRow>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="realName"
                                rules={{required: true}}
                                as={<CustomInput
                                    placeholder="姓名"
                                />}
                            />
                        </SignUpCol>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="lineId"
                                rules={{required: true}}
                                as={<CustomInput
                                    placeholder="Line ID"
                                />}
                            />
                        </SignUpCol>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="phone"
                                rules={{required: '請填寫手機號碼'}}
                                as={<CustomInput
                                    type="number"
                                    placeholder="電話/手機"
                                    errorMessage={get(errors, 'phone.message')}
                                />}
                            />
                        </SignUpCol>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="email"
                                rules={{required: true}}
                                as={<CustomInput
                                    placeholder="電子信箱"
                                    remarkMessage="ex: name@example.com"
                                />}
                            />
                        </SignUpCol>
                    </SignUpRow>

                    <SectionTitle>配送地址：</SectionTitle>
                    <SignUpRow>
                        <SignUpCol col={24} md={8}>
                            <Controller
                                control={control}
                                name="deliveryCity"
                                rules={{required: true}}
                                as={<CustomSelect
                                    title="縣/市"
                                    option={countyOptions()}
                                />}
                                onChange={event => {
                                    setValue('deliveryTownship', '');
                                    setValue('postalCode', '-');
                                    return event[0].target.value;
                                }}
                            />
                        </SignUpCol>
                        <SignUpCol col={24} md={8}>
                            <Controller
                                control={control}
                                name="deliveryTownship"
                                rules={{required: true}}
                                as={<CustomSelect
                                    title="鄉鎮市區"
                                    option={townshipOptions()}
                                />}
                                onChange={event => {
                                    setValue('postalCode', event[0].target.value.substr(0 ,3));
                                    return event[0].target.value;
                                }}
                            />
                        </SignUpCol>
                        <SignUpCol col={24} md={8}>
                            <Controller
                                control={control}
                                name="postalCode"
                                as={<CustomInput
                                    placeholder="郵遞區號"
                                    readonly
                                    defaultValue="-"
                                />}
                            />
                        </SignUpCol>
                        <SignUpCol col={24}>
                            <Controller
                                control={control}
                                name="deliveryAddress"
                                rules={{required: true}}
                                as={<CustomInput
                                    placeholder="地址"
                                />}
                            />
                        </SignUpCol>
                    </SignUpRow>

                    <SubmitButton
                        type="submit"
                        disabled={!formState.isValid}
                    >
                        前往驗証店家資料
                    </SubmitButton>
                </SignUpContainer>
            </form>
        </SignUpRoot>
    );
};

export default Step1;

const SubmitButton = styled(Button)`
    width: 240px;
    height: 44px;
    font-size: 14px;
    min-height: auto;
    align-self: center;
    margin: 10px auto 20px auto;

    ${media.md`
        margin: 40px auto 100px auto;
    `}

    ${media.xl`
        font-size: 16px;
        width: 300px;
        height: 56px;
    `}
`;

const SectionTitle = styled.div`
    font-size: 14px;
    color: #6e6e6e;
    margin: 10px 0;

    ${media.xl`
        font-size: 20px;
        font-weight: 600;
    `}
`;

const CustomSelect = styled(Select)`
    margin-bottom: 10px;
`;

const CustomInput = styled(Input)`
    margin-bottom: 10px;
`;

const ChineseTitle = styled.span`
    font-size: 30px;
    font-weight: 600;
    color: #3a394b;
    margin-bottom: 12px;

    ${media.xl`
        font-size: 40px;
        font-weight: bold;
    `}
`;

const HeaderBgDesc = styled.span`
    font-size: 14px;
    text-align: center;
    color: #c1bfbf;

    ${media.xl`
        font-size: 16px;
    `}
`;

const HeaderCol = styled(Col)`
    padding: 0;
`;

const HeaderRow = styled(Row)`
    padding: 20px 0 22px 0;
    margin: 0;
`;

const HeaderContainer = styled(Container)`
    padding: 0;
`;

const HeaderBg = styled.div`
    width: 100%;
    height: 112px;
    background-color: #fbfbfb;
    margin-bottom: 30px;

    ${media.xl`
        height: 133px;
        margin-bottom: 50px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SignUpCol = styled(Col)`
    ${media.md`
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

const SignUpRow = styled(Row)`
    ${media.md`
        margin-left: -5px;
        margin-right: -5px;
    `}
`;

const SignUpContainer = styled(Container)`
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;

    ${media.md`
        padding-left: 0;
        padding-right: 0;
        max-width: 600px;
    `}

    ${media.xl`
        max-width: 750px;
    `}
`;

const SignUpRoot = styled.div``;
