// Libraries
import api from 'library/apisauce';

// Types
import {TPostCartBatch, TPostCartPick, TGetMemberCart, TDeleteMemberCart, TGetCartOrderDetail, TPutCartBatch, TPutCartPick, TPostCartOrder} from './types';


export default {
    /**
     * 新增 商品到購物車(整批)
     */
    postCartBatch: (productGoodsId: number, batchQty: number): Promise<TPostCartBatch> => {
        return api.post('/member/cart/batch', {productGoodsId, batchQty}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 商品到購物車(挑碼or現貨)
     */
    postCartPick: (productGoodsId: number, sizeQty: string, isInStock: boolean): Promise<TPostCartPick> => {
        return api.post('/member/cart/pick', {productGoodsId, sizeQty, isInStock}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 購物車所有商品
     */
    getMemberCart: (): Promise<TGetMemberCart> => {
        return api.get('/member/cart', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 刪除 購物車中的所有商品
     */
    cleanMemberCart: (): Promise<TDeleteMemberCart> => {
        return api.delete('/member/cart/clean', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 刪除 購物車中的商品(多筆)
     */
    deleteMemberCart: (id: Array<number>): Promise<TDeleteMemberCart> => {
        return api.delete('/member/cart', {id}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 購物車商品的下單明細
     */
    getCartOrderDetail: (id: number): Promise<TGetCartOrderDetail> => {
        return api.get(`/member/cart/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更改 商品到購物車(整批) 尺寸數量
     */
    putCartBatch: (id: number, batchQty: number): Promise<TPutCartBatch> => {
        return api.put(`/member/cart/batch/${id}`, {batchQty}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 更改 商品到購物車(挑碼) 尺寸數量
     */
    putCartPick: (id: number, sizeQty: string, isInStock: boolean): Promise<TPutCartPick> => {
        return api.put(`/member/cart/pick/${id}`, {sizeQty, isInStock}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 購物車商品到訂單
     */
    postCartOrder: (cartId: Array<number>, comment: string, payWayId: number): Promise<TPostCartOrder> => {
        return api.post('/order/head/cartSubmit', {cartId, comment, payWayId}, {
            headers: {isAuth: true},
        });
    },
};



