// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetMemberLevel} from 'services/level';


/**
 * 查詢 會員等級特性
 */
function* fetchMemberLevel(action: IPickAction<IActionCreators, 'fetchMemberLevel'>) {
    yield put(Actions.fetchMemberLevelBegin());

    try {
        const [{body}]: [TGetMemberLevel] = yield all([
            call(ApiService.getMemberLevel),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchMemberLevelSuccess({levelInfo: rows}));

    } catch (err) {
        yield put(Actions.fetchMemberLevelFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


export default [
    takeLatest(ActionTypes.FETCH_MEMBER_LEVEL, fetchMemberLevel),
];
