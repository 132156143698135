import React from "react";
import {useForm} from 'react-hook-form';

interface IContext {
    searchForm: any,
}

const defaultState = {
    searchForm: undefined
};

export const StoreContext = React.createContext<IContext>({
    searchForm: defaultState,
});


const FormContext: React.FC = ({
    children
}) => {
    const searchForm = useForm();

    return (
        <StoreContext.Provider value={{searchForm}}>
            {children}
        </StoreContext.Provider>
    )
};

export default FormContext;

