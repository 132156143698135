import * as React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import get from 'lodash/get';
import {media} from 'library/styled-bs-grid';
import site from 'config/site';
import {themes} from './statusTagTypes';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    text: string;
    theme?: themes;
}

const themeConfig = {
    default: {
        bgColor: 'rgba(142, 197, 255, 0.1)',
        color: site.theme.primaryColor,
    },
    danger: {
        bgColor: 'rgba(255, 125, 106, 0.1)',
        color: '#ff7d6a',
    },
    green: {
        bgColor: 'rgba(168, 213, 128, 0.1)',
        color: '#a8d580',
    },
    orange: {
        bgColor: 'rgba(255, 142, 59, 0.1)',
        color: '#ff8e3b',
    },
    darkBlue: {
        bgColor: 'rgba(132, 156, 206, 0.1)',
        color: '#849cce',
    },
    yellow: {
        bgColor: 'rgba(249, 192, 46, 0.1)',
        color: '#f9c02e',
    },
    purple: {
        bgColor: 'rgba(156, 146, 255, 0.1)',
        color: '#9c92ff',
    },
    gray: {
        bgColor: 'rgba(157, 154, 154, 0.1)',
        color: '#9d9a9a',
    },
    blueGreen: {
        bgColor: 'rgba(64, 194, 200, 0.1)',
        color: '#40c2c8',
    },
};

/**
 * StatusTag 狀態標籤
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const StatusTag: React.FC<IProps> = ({
    className,
    style,
    theme = 'default',
    text,
}) => {
    const activeTheme = get(themeConfig, theme, {});

    return (
        <TagRoot
            className={className}
            style={style}
            baseTheme={{...activeTheme}}
        >
            {text}
        </TagRoot>
    );
};

export default StatusTag;

const TagRoot = styled.div<any>`
    height: 20px;
    border-radius: 2px;
    color: ${props => props.baseTheme.color};
    border: solid 1px ${props => props.baseTheme.color};
    background-color: ${props => props.baseTheme.bgColor};
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lg`
        height: 32px;
        font-size: 16px;
    `}
`;
