import React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import Icon from 'components/atoms/Icon';
import Tooltips from 'components/atoms/Tooltips';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    title: string;
    content: string;
    isVip: boolean;
}

/**
 * MemberTooltips
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const MemberTooltips: React.FC<IProps> = ({
    className,
    style,
    title,
    content,
    isVip = false,
}) => (
    <ReadMore
        className={className}
        style={style}
    >
        <CustomIcon code="info-circle" size={20} color="#6e6e6e"/>
        閱讀詳情

        <CustomTooltips isVisible>
            <Title isVip={isVip}>{title}</Title>
            <Content>{content}</Content>
        </CustomTooltips>
    </ReadMore>
);

export default MemberTooltips;

const CustomTooltips = styled(Tooltips)`
    min-width: 300px;
`;

const CustomIcon = styled(Icon)`
    margin-right: 6px;
`;

const ReadMore = styled.div`
    width: 100%;
    font-size: 16px;
    color: #6e6e6e;
    text-decoration: underline;
    text-align: center;
    position: relative;
    -moz-user-select: none;  /* for Firefox */
    -webkit-user-select: none;   /* for Chrome */
`;

const Title = styled.p<any>`
    font-size: 12px;
    font-weight: 600;
    color: #ff8e3b;
    padding-bottom: 10px;

    ${props => props.isVip && css`
        color: ${props.theme.thirdColor};
    `}
`;

const Content = styled.p`
    font-size: 12px;
    color: #8d8d8d;
`;
