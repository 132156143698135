// Libraries & Utils
import Immutable from 'seamless-immutable';
import {TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'order';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    isFetchingDetail: false,
    isSubmitting: false,
    message: '',

    orderHeadList: [],
    orderHeadPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        pageLimit: 0,
    },
    orderHeadDetail: [],

    stockCategory: [],

    stockProductList: [],
    stockProductPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        pageLimit: 0,
    },
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得訂單列表
     */
    fetchOrderHead: (state) => {
        return state;
    },
    fetchOrderHeadBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取訂單資訊'});
    },
    fetchOrderHeadSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', orderHeadList: payload.orderHeadList, orderHeadPagination: payload.orderHeadPagination});
    },
    fetchOrderHeadFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得訂單明細
     */
    fetchOrderHeadDetail: (state) => {
        return state;
    },
    fetchOrderHeadDetailBegin: (state) => {
        return state.merge({isFetchingDetail: true, message: '正在讀取訂單明細', orderHeadDetail: []});
    },
    fetchOrderHeadDetailSuccess: (state, {payload}) => {
        return state.merge({isFetchingDetail: false, message: '', orderHeadDetail: payload.orderHeadDetail});
    },
    fetchOrderHeadDetailFail: (state, {payload}) => {
        return state.merge({isFetchingDetail: false, message: ''});
    },
    /**
     * 取得商品備貨狀態類別
     */
    fetchStockCategory: (state) => {
        return state;
    },
    fetchStockCategoryBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取商品備貨狀態類別'});
    },
    fetchStockCategorySuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', stockCategory: payload.stockCategory});
    },
    fetchStockCategoryFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得商品備貨狀態資料 (依狀態類別)
     */
    fetchStockProduct: (state) => {
        return state;
    },
    fetchStockProductBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取商品備貨狀態資訊'});
    },
    fetchStockProductSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', stockProductList: payload.stockProductList, stockProductPagination: payload.stockProductPagination});
    },
    fetchStockProductFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 商品取消申請
     */
    fetchApplyCancel: (state) => {
        return state;
    },
    fetchApplyCancelBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在取消申請商品'});
    },
    fetchApplyCancelSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    fetchApplyCancelFail: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
