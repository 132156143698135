import React, {useState} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import {media} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';
import get from 'lodash/get';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    onChangeIndex?: Function;
    activeCode: number;
    item?: Array<{
        code: number;
        name: string;
        iconCode?: string;
        href?: string;
        num?: number;
    }>;
}

/**
 * ProfileTabs
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProfileTabs: React.FC<IProps> = ({
    style,
    className,
    activeCode,
    item = [],
    onChangeIndex = () => {},
}) => {
    const history = useHistory();
    const [bottomLineWidth, setBottomLineWidth] = useState(0);
    const [itemPosition, setItemPosition] = useState(0);
    const [isFakeBottomLine, setIsFakeBottomLine] = useState(true);

    const handleChangeEvent = (e: any, itemId: string | number, href: string = '') => {
        // item的中間寬度
        const itemMiddleWidth = e.target.offsetWidth / 2;
        // 螢幕寬度
        const screenWidth = document.body.clientWidth;

        if (onChangeIndex) {
            // 切換Tabs類別
            onChangeIndex(itemId);
        }

        // 移動距離算式
        e.target.closest('section').scrollLeft = (e.target.offsetLeft + itemMiddleWidth) - screenWidth / 2;

        // 底線相關

        // 取目標item 寬度
        setBottomLineWidth(e.target.offsetWidth);
        // 取目標item position
        setItemPosition(e.target.offsetLeft);
        setIsFakeBottomLine(false);

        history.push(href);
    };

    return (
        <TabScrollRoot style={style} className={className}>
            <ItemGroup
                bottomLineWidth={bottomLineWidth}
                itemPosition={itemPosition}
            >
                {
                    item.map(row => (
                        <ItemList key={row.code}>
                            <Item
                                onClick={(e: any) => handleChangeEvent(e, row.code, row.href)}
                                active={String(activeCode) === String(row.code)}
                                isFakeBottomLine={isFakeBottomLine && (activeCode.toString() === row.code.toString())}
                            >
                                <IconSection>
                                    {get(row, 'num', 0) > 0 && (
                                        <Dot>
                                            <Num>{row.num}</Num>
                                        </Dot>
                                    )}
                                    <IconItem code={row.iconCode} color="#9d9a9a" size={20}/>
                                </IconSection>
                                {row.name}
                            </Item>
                        </ItemList>
                    ))
                }
            </ItemGroup>
        </TabScrollRoot>
    );
};

export default ProfileTabs;

const IconItem = styled(Icon)<any>`
    transition: opacity .2s;

    ${media.md`
        font-size: 22px;
    `}

    ${media.xl`
        font-size: 25px;
    `}
`;

const Num = styled.div`
    font-size: 10px;
    color: #ffffff;

    ${media.md`
        font-size: 12px;
    `}
`;

const Dot = styled.div`
    width: 13px;
    height: 13px;
    background-color: #ff7d6a;
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 15px;
        height: 15px;
        left: -8px;
        top: -8px;
    `}

    ${media.xl`
        width: 20px;
        height: 20px;
        left: -13px;
        top: -13px;
    `}
`;

const IconSection = styled.div`
    pointer-events: none;
    position: relative;
    margin-right: 5px;
    display: flex;

    ${media.md`
        font-size: 22px;
        margin-right: 10px;
    `}

    ${media.xl`
        font-size: 25px;
        margin-right: 13px;
    `}
`;

const Item = styled.div<any>`
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    color: #9d9a9a;
    height: 100%;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    position: relative;

    &:after {
        height: 4px;
        background-color: ${(props: any) => props.theme.primaryColor};
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity .2s;
    }

    ${media.md`
        font-size: 14px;
        padding-bottom: 11px;

        &:hover{
            ${IconItem} {
                color: ${(props: any) => props.theme.primaryColor};
            }

            &:after {
                opacity: 1;
            }
        }
    `}

    ${media.xl`
        font-size: 16px;
        padding-left: 1px;
        padding-top: 16px;
        padding-bottom: 16px;
    `}

    ${props => props.isFakeBottomLine && css`
        &:after {
            opacity: 1;
        }
    `}

    ${props => props.active && css`
        ${IconItem} {
            color: ${props.theme.primaryColor};
        }
    `}
`;

const ItemList = styled.li`
    padding: 0 15px 0 0;
    list-style: none;

    ${media.md`
        padding: 0 30px 0 0;
    `};

    ${media.xl`
        padding: 0 35px 0 0;
    `};

    ${media.xxl`
        padding: 0 55px 0 0;
    `};
`;

const ItemGroup = styled.ul<any>`
    margin: 0;
    padding: 0;
    display: inline-flex;
    position: relative;

    &:after {
        height: 4px;
        background-color: ${props => props.theme.primaryColor};
        content: '';
        display: block;
        width: ${props => (props.bottomLineWidth ? `${props.bottomLineWidth}px` : 0)};
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateX(${props => (props.itemPosition ? `${props.itemPosition}px` : 0)});
        transition: transform .1s ease;
    }
`;

const TabScrollRoot = styled.section<any>`
    color: #fff;
    position: relative;
    overflow: auto;
    scroll-direction: horizontal;

    /* width */
    ::-webkit-scrollbar {
        display: none;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;
