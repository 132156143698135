// Libraries
import api from 'library/apisauce';

// Types
import {TGetCategory, TGetContent, TPostMessage} from './types';


export default {
    /**
     * 查詢 訊息類別大綱
     */
    getCategory: (): Promise<TGetCategory> => {
        return api.get('/message/category/outline', {},{
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 訊息明細
     */
    getContent: (categoryId: number|undefined, currentPage: number = 1, pageLimit: number = 20): Promise<TGetContent> => {
        return api.get(`/message/category/${categoryId}`, {currentPage, pageLimit},{
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 訊息
     */
    postMessage: (categoryId: number, content?: string, image?: File): Promise<TPostMessage> => {
        const formData = new FormData();
        // @ts-ignore
        formData.append('categoryId', categoryId);
        if (content) {
            formData.append('content', content);
        }
        if (image) {
            formData.append('image', image);
        }

        return api.post('/message/detail', formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                isAuth: true
            },
            timeout: 60000,
        });
    },
};
