import * as React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {GridThemeProvider, media, Container, Row, Col} from 'library/styled-bs-grid';
import {asset} from 'config/app';
import Icon from 'components/atoms/Icon';
import {isEmpty} from 'utils/equal';

interface IProps {
style?: CSS.Properties;
    className?: string;
    address?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    line?: string;
    fb?: string;
    categoryGroup: Array<{
        id: number,
        name: string,
        nickName: string,
        isNew: boolean,
        categoryMains: Array<{
            id: number,
            name: string,
            categorySubs: Array<{
                id: number,
                name: string,
            }>
        }>
    }>;
    isOrderVipGoods?: boolean;
    isOrderPreSale?: boolean;
}

/**
 * Footer
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Footer: React.FC<IProps> = ({
    className,
    style,
    address,
    phone,
    mobile,
    email,
    line,
    fb,
    categoryGroup,
    isOrderVipGoods = false,
    isOrderPreSale = false,
}) => {
    const history = useHistory();

    return(
        <FooterRoot style={style} className={className}>
            <GridThemeProvider gridTheme={{gridColumns: 60}}>
                <FooterContainer>
                    <Row>
                        <LogoCol col={60} xl={20} className="d-flex flex-column align-items-center justify-content-center">
                            <Logo src={asset('/images/home/header-logo.png')}/>
                            <Direction>為老闆們提供累積多年營運技巧獨特優勢,與韓國東南大門直接合作,所有商品皆自韓國進口,提供最新的商品訊息,不必親自飛往韓國挑貨,讓您省下時間與成本。後端採購作業均在韓國首爾直接作業，商品每日空運到台，提供最快速便捷的批貨服務。</Direction>
                            <Line/>
                            <FbSection className="d-flex">
                                <ButtonCircle href={fb} target="_blank">
                                    <Icon code="facebook-f" color="#ffffff" size={20}/>
                                </ButtonCircle>
                            </FbSection>
                        </LogoCol>

                        <ContactCol col={20} xl={20} className="d-flex flex-column align-items-center">
                            <Title>CONTACT US</Title>

                            <div>
                                {/* 地址 */}
                                {!isEmpty(address) && (
                                    <ContactInfo className="d-flex align-items-center">
                                        <ContactIcon>
                                            <Icon code="map-marker-alt" size={20} color="#9d9a9a"/>
                                        </ContactIcon>
                                        <ContactText>{address}</ContactText>
                                    </ContactInfo>
                                )}

                                {/* 電話 */}
                                {!isEmpty(phone) && (
                                    <ContactInfo className="d-flex align-items-center">
                                        <ContactIcon>
                                            <Icon code="phone-alt" size={20} color="#9d9a9a"/>
                                        </ContactIcon>
                                        <ContactText>{phone}</ContactText>
                                    </ContactInfo>
                                )}

                                {/* 手機 */}
                                {!isEmpty(mobile) && (
                                    <ContactInfo className="d-flex align-items-center">
                                        <ContactIcon>
                                            <Icon code="fa-mobile" size={20} color="#9d9a9a"/>
                                        </ContactIcon>
                                        <ContactText>{mobile}</ContactText>
                                    </ContactInfo>
                                )}

                                {/* 信箱 */}
                                {!isEmpty(email) && (
                                    <ContactInfo className="d-flex align-items-center">
                                        <ContactIcon>
                                            <Icon code="envelope" size={20} color="#9d9a9a"/>
                                        </ContactIcon>
                                        <ContactText>{email}</ContactText>
                                    </ContactInfo>
                                )}

                                {/* Line */}
                                {!isEmpty(line) && (
                                    <ContactInfo className="d-flex align-items-center">
                                        <ContactIcon>
                                            <Icon code="line" size={20} color="#9d9a9a"/>
                                        </ContactIcon>
                                        <ContactText>{`LINE ID : ${line}`}</ContactText>
                                    </ContactInfo>
                                )}
                            </div>

                        </ContactCol>

                        <MenuCol col={20} xl={10} className="d-flex flex-column">
                            <Title>MENU</Title>

                            <div className="d-flex justify-content-center">
                                <div>
                                    {
                                        categoryGroup.map(row => (
                                            <Item key={row.id} onClick={() => history.push(`/product?categoryGroupId=${row.id}`)}>
                                                <Chinese>{row.name}</Chinese>
                                                <English>{row.nickName}</English>
                                            </Item>
                                        ))
                                    }
                                    <Item onClick={() => history.push('/product?popularId=1')}>
                                        <Chinese>現貨</Chinese>
                                        <English>IN-STOCK</English>
                                    </Item>
                                    <Item onClick={() => history.push('/product?popularId=2')}>
                                        <Chinese>新品</Chinese>
                                        <English>NEW</English>
                                    </Item>
                                    <Item onClick={() => history.push('/product?popularId=3')}>
                                        <Chinese>特價</Chinese>
                                        <English>SALE</English>
                                    </Item>
                                    {isOrderPreSale && (
                                        <Item onClick={() => history.push('/product?popularId=5')}>
                                            <Chinese>預購</Chinese>
                                            <English>PRE-ORDER</English>
                                        </Item>
                                    )}

                                    {/* 隱藏VIP */}
                                    {/*{isOrderVipGoods && (*/}
                                    {/*    <Item onClick={() => history.push('/product?popularId=4')}>*/}
                                    {/*        <Chinese>會員</Chinese>*/}
                                    {/*        <English>VIP</English>*/}
                                    {/*    </Item>*/}
                                    {/*)}*/}
                                </div>
                            </div>
                        </MenuCol>

                        <InformationCol col={20} xl={10} className="d-flex flex-column align-items-center">
                            <Title>INFORMATION</Title>

                            <div>
                                <Information onClick={() => history.push('/guide')}>購物說明</Information>
                                <Information onClick={() => history.push('/about')}>關於韓爸</Information>
                                <Information onClick={() => history.push('/brand')}>所有品牌</Information>
                            </div>
                        </InformationCol>
                    </Row>
                </FooterContainer>

                <CopyRight>Copyright © 韓國童裝女裝玩具雜貨☆韓爸有衣☆批發網</CopyRight>
            </GridThemeProvider>
        </FooterRoot>
    );
};

export default Footer;

const CopyRight = styled.div`
    width: 100%;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 66px;
`;

const English = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: #6e6e6e;
`;

const Chinese = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: #6e6e6e;
    padding-right: 16px;
`;

const Information = styled(English)`
    display: block;
    padding-bottom: 12px;
    cursor: pointer;

    &:hover {
        color: #fff;
    }
`;

const Item = styled.div<any>`
    padding-bottom: 3px;
    cursor: pointer;

    &:hover {
        span {
            color: #fff;
        }
    }
`;

const ContactText = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: #6e6e6e;
`;

const ContactIcon = styled.div`
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;

const ContactInfo = styled.div`
    display: flex;
    padding-bottom: 10px;
`;

const Title = styled.span`
    font-size: 18px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    position: relative;
    padding-bottom: 36px;

    &:before {
        position: absolute;
        bottom: 18px;
        left: 0;
        right: 0;
        margin: auto;
        content: '';
        width: 70px;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const ButtonCircle = styled.a`
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    margin-left: 10px;
    transition: background-color .3s;

    &:hover {
        background-color: ${props => props.theme.primaryColor};
    }
`;

const FbSection = styled.div`
    padding-bottom: 50px;

    ${media.xl`
        padding-bottom: 0;
    `}
`;

const Line = styled.div`
    width: 70px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 30px 0;

    ${media.xl`
        margin: 35px 0;
    `}
`;

const Direction = styled.span`
    max-width: 500px;
    font-size: 14px;
    color: #ffffff;
    padding-top: 36px;
    text-align: center;

    ${media.xl`
        max-width: 430px;
        padding-left: 15px;
        padding-right: 15px;
    `}

    ${media.xxl`
        padding-left: 0;
        padding-right: 0;
    `}
`;

const Logo = styled.img`
    width: 200px;
    height: auto;
`;

const InformationCol = styled(Col)`
    ${media.xl`
        order: 4;
        padding-top: 7px;
 `}
`;

const MenuCol = styled(Col)`
    ${media.xl`
        order: 3;
        padding-top: 7px;
   `}
`;

const ContactCol = styled(Col)`
    ${media.xl`
        order: 1;
        padding-top: 7px;
    `}
`;

const LogoCol = styled(Col)`
    position: relative;

    ${media.xl`
        order: 2;

        &:before, &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
        }

        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    `}
`;

const FooterContainer = styled(Container)`
    padding-top: 40px;
    padding-bottom: 40px;
`;

const FooterRoot = styled.footer`
    background-color: #282731;
    width: 100%;
`;
