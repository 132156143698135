import React, {useEffect, useState, useContext} from 'react';
import styled, {css} from 'styled-components';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {HalfCircleSpinner} from 'react-epic-spinners';
import site from 'config/site';
import get from 'lodash/get';
import {Container, media, Row, Col} from 'library/styled-bs-grid';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';

// Component
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import SpeedOrderModal from 'components/organisms/SpeedOrderModal';

// Reducer
import {Actions as CartAction} from 'store/cart';
import {Actions as TrackAction} from 'store/track';

// Stores
import {StoreContext} from '../FormContext';
import {Selector as AuthSelector} from 'store/auth';


/**
 * 購物袋
 * @constructor
 */
const Bag: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {dispatchFormData} = useContext(StoreContext);
    const {watch, handleSubmit, control, getValues, setValue}: any = useForm();
    const [visibleSpeedModal, setVisibleSpeedModal] = useState<boolean>(false);

    const {isFetching, memberCart, memberCartTotal, isFetchingDetail, orderDetail, isOrderNormalGoods, payload} = useSelector(state => ({
        isFetching: state.cart.isFetching,
        memberCart: state.cart.memberCart,
        memberCartTotal: state.cart.memberCartTotal,
        isFetchingDetail: state.cart.isFetchingDetail,
        orderDetail: state.cart.orderDetail,
        isOrderNormalGoods: state.profile.isOrderNormalGoods,
        payload: AuthSelector.payload(state),
    }));


    useEffect(() => {
        dispatch(CartAction.fetchMemberCart());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const {product} = watch(['product']);
    const checkedIds: Array<number> = [];
    for(const id in product){
        const memberCartInfo = memberCart.find(o => String(o.id) === String(id));
        // 過濾掉不等於 true 的 Key && 未斷貨(或現貨) && 未下架
        if(
            product[id] &&
            (!memberCartInfo?.productGoods?.isOutStock || memberCartInfo?.pickType.code === 'inStock') &&
            !memberCartInfo?.productGoods?.isOff
        ){
            checkedIds.push(Number(id));
        }
    }

    /**
     * Check All
     * @param isChecked
     */
    const handleCheckAll = (isChecked: boolean) => {
        const keys = getValues();
        for(const row of Object.keys(keys)){
            setValue(row, isChecked);
        }
    };


    /**
     * 刪除選取項目
     */
    const onSubmit = () => {
        // 取得 Table中的 CheckId
        const {product: checkedId} = getValues({nest: true});
        const ids = [];
        for(const id in checkedId){
            // 過濾掉不等於 true 的 Key
            if(checkedId[id]){
                ids.push(Number(id));
            }
        }

        if(ids.length === 0) {
            alert('請選擇項目');
        }else{
            dispatch(CartAction.deleteMemberCart({id: ids}));
        }
    };


    /**
     * 計算總金額
     */
    const countTotalPrice = () => {
        let totalPrice = 0;

        if (checkedIds?.length > 0) {
            totalPrice = checkedIds.reduce((preRow, id) => {
                const selectProduct = memberCart.find(o => o.id === Number(id));
                return preRow + get(selectProduct, 'totalAmount', 0);
            }, 0);
        }

        return totalPrice;
    };


    /**
     * 結帳
     */
    const checkout = () => {
        dispatchFormData({cartData: {
            cartId: checkedIds,
            totalItems: get(checkedIds, 'length', 0),
            totalAmount: countTotalPrice(),
        }});

        history.push('/shop/checkout');
    };

    const statusText = (isOutStock: boolean, isOff: boolean, isSale: boolean, pickTypeCode: string) => {
        if (isOff) {
            return '已下架';
        } else if (isOutStock && pickTypeCode !== 'inStock') {
            return '斷貨';
        } else if (pickTypeCode === 'inStock' && isSale) {
            return '現貨(特價)';
        } else if (pickTypeCode === 'inStock' && !isSale) {
            return '現貨';
        } else if (isSale) {
            return '特價';
        } else {
            return '正常供貨';
        }
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Mobile Header */}
                <Container className="d-md-none">
                    <MobileHeader>
                        <IconButton onClick={() => history.push('/')}>
                            <Icon code="long-arrow-left" size={20} color="#282731"/>
                        </IconButton>

                        <MobileTitle>{`我的購物袋(${get(memberCartTotal, 'totalItems')})`}</MobileTitle>

                        <CheckoutButton
                            disabled={get(checkedIds, 'length', 0) === 0}
                            onClick={() => checkout()}
                        >
                            結帳
                        </CheckoutButton>
                    </MobileHeader>
                </Container>

                {/* PC Header */}
                <CheckoutBg className="d-none d-md-flex">
                    <BagContainer>
                        <Total className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <CheckoutText>結帳</CheckoutText>
                                <CheckoutNum>商品數量 x {get(checkedIds, 'length', 0)}</CheckoutNum>
                            </div>

                            <div className="d-flex align-items-center">
                                <TotalAmountText>顯示訂單總金額 ： </TotalAmountText>
                                <TotalAmount>NT$ {countTotalPrice()}</TotalAmount>
                                <CheckoutButton
                                    disabled={get(checkedIds, 'length', 0) === 0}
                                    onClick={() => checkout()}
                                >
                                    結帳
                                </CheckoutButton>
                            </div>
                        </Total>
                    </BagContainer>
                </CheckoutBg>

                <HeaderBg className="d-none flex-column d-md-flex">
                    <BagContainer>
                        <BagRow className="d-flex align-items-end">
                            <BagCol col={8}>
                                <HeaderBgDesc>Home / 我的帳戶</HeaderBgDesc>
                            </BagCol>
                            <BagCol col={8} className="d-flex flex-column align-items-center">
                                <ChineseTitle>我的購物袋</ChineseTitle>
                                <HeaderBgDesc>{`My Shopping Bag（${get(memberCartTotal, 'totalItems')}）`}</HeaderBgDesc>
                            </BagCol>
                            <BagCol col={8} className="d-flex justify-content-end">
                                <ClearAll onClick={() => dispatch(CartAction.cleanMemberCart())}>清除購物袋</ClearAll>
                            </BagCol>
                        </BagRow>
                    </BagContainer>
                </HeaderBg>

                <BagContainer>
                    <BagContent>
                        {/* 標題 */}
                        <Header className="d-none d-md-flex align-items-start">
                            <CheckboxSection>
                                <Controller
                                    name="checkAll1"
                                    control={control}
                                    valueName="checked"
                                    as={<Checkbox
                                        value="checked"
                                        isLarge
                                    />}
                                    onChange={event => {
                                        handleCheckAll(!!event[0]);
                                        return event[0];
                                    }}
                                />
                            </CheckboxSection>
                            <ProductInfo>商品貨號/品牌</ProductInfo>
                            <Category>顏色</Category>
                            <Size>SIZE</Size>
                            <InStock>狀態</InStock>
                            <Price>單價</Price>
                            <TotalQty>總數量</TotalQty>
                            <Subtotal>小計</Subtotal>
                        </Header>


                        {/* 明細 */}
                        {memberCart.map(row => (
                            <Content key={row.id} isOutStock={(row.productGoods.isOutStock && row.pickType.code !== 'inStock') || row.productGoods.isOff}>
                                {/* Checkbox */}
                                <CheckboxSection>
                                    <Controller
                                        control={control}
                                        name={`product[${row.id}]`}
                                        valueName="checked"
                                        as={<Checkbox
                                            value={String(row.id)}
                                            isLarge
                                        />}
                                    />
                                </CheckboxSection>

                                {/* 商品 */}
                                <ProductInfo>
                                    <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>

                                    <div className="d-flex flex-column justify-content-center w-100">
                                        <Model onClick={() => window.open(`/product/detail/${payload?.id}/${get(row, 'productGoods.id')}`)}>{get(row, 'productGoods.model')}</Model>
                                        <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                                        <ProductName>{get(row, 'productGoods.name')}</ProductName>
                                        <div className="d-flex justify-content-between">
                                            <SubName>{get(row, 'productGoods.subName')}</SubName>
                                            <Edit
                                                className="d-md-none"
                                                onClick={() => {
                                                    if ((!row.productGoods.isOutStock || row.pickType.code === 'inStock') && !row.productGoods.isOff) {
                                                        dispatch(CartAction.fetchCartOrderDetail({id: row.id}));
                                                        setVisibleSpeedModal(true);
                                                    }}
                                                }>
                                                    {row.productGoods.isOff ? '下架' : (row.productGoods.isOutStock && row.pickType.code !== 'inStock') ? '斷貨' : '修改'}
                                            </Edit>
                                        </div>
                                    </div>
                                </ProductInfo>

                                {/* 顏色 */}
                                <Category className="d-flex flex-md-column align-items-start">
                                    <FieldTitle className="d-md-none">顏色</FieldTitle>
                                    <FieldValue>{get(row, 'productGoods.color')}</FieldValue>
                                </Category>

                                {/* SIZE */}
                                <Size>
                                    <FieldTitle className="d-md-none">SIZE</FieldTitle>
                                    <FieldValue>{get(row, 'sizeQty')}</FieldValue>
                                </Size>

                                {/* 狀態 */}
                                <InStock>
                                    <FieldTitle className="d-md-none">狀態</FieldTitle>
                                    <StatusButton
                                        isUnSupply={(row.productGoods.isOutStock && row.pickType.code !== 'inStock') || row.productGoods.isOff}
                                        isSale={row.productGoods.isSale}
                                    >
                                        {statusText(row.productGoods.isOutStock, row.productGoods.isOff, row.productGoods.isSale, row.pickType.code)}
                                    </StatusButton>
                                </InStock>

                                {/* 單價 */}
                                <Price>
                                    <FieldTitle className="d-md-none">單價</FieldTitle>
                                    <FieldValue>$ {get(row, 'productGoods.price')}</FieldValue>
                                    <FieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                                </Price>

                                {/* 總數量 */}
                                <TotalQty>
                                    <FieldTitle className="d-md-none">總數量</FieldTitle>
                                    <FieldValue>{get(row, 'totalQty')}</FieldValue>
                                </TotalQty>

                                {/* 小計 */}
                                <Subtotal>
                                    <FieldTitle className="d-md-none">小計</FieldTitle>
                                    <SubTotalAmount>$ {get(row, 'totalAmount')}</SubTotalAmount>
                                </Subtotal>

                                {/* 刪除 */}
                                <DeleteSection>
                                    <Edit
                                        className="d-none d-md-block"
                                        onClick={() => {
                                            if ((!row.productGoods.isOutStock || row.pickType.code === 'inStock') && !row.productGoods.isOff) {
                                                dispatch(CartAction.fetchCartOrderDetail({id: row.id}));
                                                setVisibleSpeedModal(true);
                                            }}
                                        }
                                    >
                                        {row.productGoods.isOff ? '下架' : (row.productGoods.isOutStock && row.pickType.code !== 'inStock') ? '斷貨' : '修改'}
                                    </Edit>

                                    <DeleteButton
                                        type="button"
                                        onClick={() => dispatch(CartAction.deleteMemberCart({id: [row.id]}))}
                                    >
                                        <DeleteIcon code="trash-alt" size={20} color="#d8d8d8"/>
                                    </DeleteButton>
                                </DeleteSection>
                            </Content>
                        ))}


                        {/* 讀取狀態 */}
                        {isFetching && (
                            <LoadingRow className="w-100">
                                <Col col>
                                    <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                                </Col>
                            </LoadingRow>
                        )}



                        {/* 批次刪除區塊 */}
                        <Bottom>
                            <Controller
                                name="checkAll2"
                                control={control}
                                valueName="checked"
                                as={<Checkbox
                                    value="checked"
                                    text="全選 / 取消全選"
                                    isLarge
                                    className="flex-grow-1 flex-md-grow-0"
                                />}
                                onChange={event => {
                                    handleCheckAll(!!event[0]);
                                    return event[0];
                                }}
                            />

                            <DeleteSelect className="d-none d-md-block">刪除選取項目</DeleteSelect>

                            <div className="d-flex d-md-none align-items-center">
                                <BagTotalDesc>購物袋總金額</BagTotalDesc>
                                <BagTotalAmount>NT$ {countTotalPrice()}</BagTotalAmount>
                            </div>
                        </Bottom>

                        {/* 手機版回首頁按鈕 */}
                        <HomeButton
                            type="button"
                            className="d-md-none"
                            onClick={() => history.push('/')}
                        >
                            回首頁
                        </HomeButton>
                    </BagContent>
                </BagContainer>

                {/* 電腦版下方結帳區塊 */}
                <BagContainer className="d-none d-md-flex justify-content-end">
                    <PcCheckoutSection>
                        <div className="d-flex align-items-center justify-content-between">
                            <CheckoutTotalTitle>總計：</CheckoutTotalTitle>
                            <CheckoutTotalAmount>NT$ {countTotalPrice()}</CheckoutTotalAmount>
                        </div>

                        <PcCheckoutButton
                            disabled={get(checkedIds, 'length', 0) === 0}
                            onClick={() => checkout()}
                        >
                            結帳
                        </PcCheckoutButton>
                    </PcCheckoutSection>
                </BagContainer>
            </form>


            {/* 快速下單光箱 */}
            <SpeedOrderModal
                isVisible={visibleSpeedModal && !isFetchingDetail}
                type="edit"
                onClose={() => setVisibleSpeedModal(false)}
                cartId={get(orderDetail, 'id')}
                id={get(orderDetail, 'productGoods.id')}
                brandName={get(orderDetail, 'productGoods.brandName')}
                name={get(orderDetail, 'productGoods.name')}
                model={get(orderDetail, 'productGoods.model')}
                price={get(orderDetail, 'productGoods.price')}
                prePrice={get(orderDetail, 'productGoods.prePrice')}
                isOrderNormalGoods={isOrderNormalGoods}
                isCanChooseSize={orderDetail.productGoods.isCanChooseSize}
                isSale={get(orderDetail, 'productGoods.isSale')}
                isOrderPreSale={false}
                isTrack={get(orderDetail, 'productGoods.isTrack')}
                inStock={get(orderDetail, 'productGoods.inStock')}
                isOutStock={get(orderDetail, 'productGoods.isOutStock')}
                color={get(orderDetail, 'productGoods.color')}
                size={get(orderDetail, 'productGoods.sizes')}
                batchPrice={get(orderDetail, 'productGoods.batchPrice')}
                batchUnitQty={get(orderDetail, 'productGoods.batchUnitQty')}
                images={[{id: 0, index: 0, url: isEmpty(get(orderDetail, 'productGoods.thumbUrl', '')) ? asset('/images/goods/default.jpg') : get(orderDetail, 'productGoods.thumbUrl')}]}
                onTrack={() => dispatch(TrackAction.putTrackProduct({
                    productGoodsId: get(orderDetail, 'productGoods.id'),
                    isTrack: !get(orderDetail, 'productGoods.isTrack'),
                }))}

                batchQty={get(orderDetail, 'batchQty')}
                pickType={get(orderDetail, 'pickType')}
                sizeQty={get(orderDetail, 'sizeQty')}
                totalQty={get(orderDetail, 'totalQty')}
            />
        </>
    );
};

export default Bag;

const LoadingRow = styled(Row)`
    order: 2;
`;

const HomeButton = styled.button`
    width: 250px;
    height: 30px;
    border-radius: 2px;
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    margin: 5px 0 20px 0;
    order: 3;
`;

const PcCheckoutButton = styled(Button)`
    width: 100%;
    height: 56px;
    margin-top: 30px;
`;

const CheckoutTotalAmount = styled.div`
    font-size: 22px;
    font-weight: bold;
    color: #848484;
`;

const CheckoutTotalTitle = styled.div`
    font-size: 18px;
    color: #6e6e6e;
`;

const PcCheckoutSection = styled.div`
    order: 4;
    flex-direction: column;
    margin-bottom: 100px;
    width: 373px;
    align-self: flex-end;
`;

const BagTotalAmount = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #ff7d6a;
`;

const BagTotalDesc = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    margin-right: 6px;
`;

const DeleteSelect = styled.button`
    font-size: 12px;
    font-weight: 300;
    color: #9d9a9a;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 60px;

    ${media.md`
        font-size: 16px;
        font-weight: 300;
    `}
`;

const Bottom = styled.div`
    padding: 10px 5px 22px 5px;
    display: flex;
    align-items: center;
    order: 1;

    ${media.md`
        order: 3;
        padding: 22px 0 28px 0;
    `}
`;

const DeleteIcon = styled(Icon)`
    transition: color .2s;

    ${media.md`
        font-size: 25px;
    `}
`;
const DeleteButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;

    ${media.md`
        width: 30px;
        height: 30px;
        display: none;

        &:hover{
            ${DeleteIcon} {
                color: #FF7D6A;
            }
        }
    `}
`;

const DeleteSection = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;

    ${media.md`
        width: 57px;
        position: relative;
        top: auto;
        right: auto;
        justify-content: flex-end;
        align-items: center;
        margin-right: 15px;
    `}
`;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isRed && css`
        color: #ff7d6a;
    `}

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const FieldTitle = styled.span`
    width: 60px;
    min-width: 60px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
`;

const StatusButton = styled.div<any>`
    width: 64px;
    height: 20px;
    border-radius: 2px;
    border: solid 1px #a8d580;
    background-color: rgba(168, 213, 128, 0.1);
    font-size: 12px;
    color: #a8d580;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.isUnSupply && css`
        border: solid 1px #ff7d6a;
        background-color: rgba(255, 125, 106, 0.1);
        color: #ff7d6a;
    `};

    ${props => (!props.isUnSupply && props.isSale) && css`
        border: solid 1px #8ec5ff;
        background-color: rgba(142, 197, 255, 0.1);
        color: #8ec5ff;
    `}

    ${media.md`
        width: 90px;
        height: 32px;
        font-size: 16px;
        border-radius: 4px;
    `}
`;

const Edit = styled.div`
    min-width: 24px;
    font-size: 12px;
    font-weight: 300;
    color: ${site.theme.primaryColor};
    cursor: pointer;

    ${media.md`
        font-size: 16px;
        color: #9d9a9a;
    `}
`;

const SubName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const BrandName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const Model = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #6e6e6e;
    cursor: pointer;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const ProductImg = styled.img`
    width: 44px;
    height: 44px;
    margin-right: 6px;
    background-color: lightgray;

    ${media.md`
        width: 70px;
        height: 80px;
        margin-right: 10px;
    `}
`;

const Content = styled.div<any>`
    width: 100%;
    min-height: 160px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    order: 2;
    position: relative;

    ${media.md`
        min-height: auto;
        box-shadow: none;
        border: none;
        border-bottom: solid 1px #edeff2;
        padding: 10px 0;
        margin-bottom: 0;
        order: 1;
        flex-direction: row;
        align-items: center;
    `}

    ${props => props.isOutStock && css`
        opacity: 0.5;

        ${DeleteIcon} {
            color: #ff7d6a;
        }

        ${Edit} {
            color: #ff7d6a;
            cursor: auto;
        }
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const SubTotalAmount = styled.div`
    font-size: 12px;
    color: #ff7d6a;

    ${media.md`
        font-size: 16px;
        font-weight: bold;
        color: #6e6e6e;
    `}
`;

const Subtotal = styled(HeaderFieldName)`
    ${media.md`
        min-width: 90px;
    `}

    ${media.xxl`
        min-width: 108px;
    `}
`;

const TotalQty = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 80px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 138px;
    `}
`;

const Price = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 100px;
        padding-bottom: 0;
        flex-direction: column;
        justify-content: center;
    `}

    ${media.xxl`
        min-width: 122px;
    `}
`;

const InStock = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 140px;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
    `}

    ${media.xxl`
        min-width: 167px;
    `}
`;

const Size = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 200px;
        max-width: 200px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 265px;
        max-width: 265px;
    `}
`;

const Category = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 100px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 164px;
    `}
`;

const ProductInfo = styled(HeaderFieldName)`
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 5px;
    margin-bottom: 6px;

    ${media.md`
        min-width: 300px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    `}

    ${media.xxl`
        min-width: 368px;
    `}
`;

const CheckboxSection = styled(HeaderFieldName)`
    position: absolute;
    top: -5px;
    left: -5px;

    ${media.md`
        min-width: 56px;
        position: initial;
        top: auto;
        left: auto;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: solid 1px #edeff2;
`;

const BagContent = styled.div`
    display: flex;
    flex-direction: column;

    ${media.md`
        width: 1140px;
    `}

    ${media.xxl`
        width: 1460px;
    `}
`;

const ChineseTitle = styled.span`
    font-size: 40px;
    font-weight: bold;
    color: #282731;
    margin-bottom: 10px;
`;

const ClearAll = styled.div`
    font-size: 16px;
    color: #9d9a9a;
    cursor: pointer;
`;

const HeaderBgDesc = styled.span`
    font-size: 16px;
    color: #9d9a9a;
`;

const BagCol = styled(Col)`
    padding: 0;
`;

const BagRow = styled(Row)`
    padding: 22px 0 20px 0;
    margin: 0;
`;

const TotalAmount = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #6e6e6e;
    margin-right: 20px;
`;

const TotalAmountText = styled.div`
    font-size: 14px;
    color: #6e6e6e;
    margin-right: 16px;
`;

const CheckoutNum = styled.div`
    font-size: 14px;
    color: #6e6e6e;
`;

const CheckoutText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #6e6e6e;
    margin-right: 30px;
`;

const Total = styled.div`
    height: 55px;
    border-bottom: solid 1px #ebebeb;
`;

const BagContainer = styled(Container)`
    ${media.md`
        overflow-x: auto;
        padding: 0;
    `}
`;

const HeaderBg = styled.div`
    width: 100%;
    background-color: #fbfbfb;
    margin-bottom: 20px;
`;

const CheckoutBg = styled.div`
    width: 100%;
    background-color: #fbfbfb;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
`;

const CheckoutButton = styled(Button)<any>`
    width: 60px;
    height: 30px;
    min-height: auto;
    font-size: 12px;
    position: absolute;
    right: 0;

    ${media.md`
        position: initial;
        width: 101px;
        height: 34px;
        font-size: 16px;
        margin-right: 20px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5px;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;
