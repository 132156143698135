// Libraries & Utils
import Immutable from 'seamless-immutable';
import { TReducers} from "library/redux";
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from "./types";


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'profile';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    message: '',

    isOrderPreSale: false,
    walletAmount: 0,
    unpaidAmount: 0,
    promotionCode: undefined,
    isOrderVipGoods: false,
    level: {
        code: '',
        id: 0,
        isViewVipGoods: false,
        name: '',
    },
    isOrderNormalGoods: false,
    realName: '',
    email: '',
    avatarUrl: '',
    messageUnreadCount: 0,
    trackProductCount: 0,
    shoppingCartCount: 0,
    lineId: '',
    phone: '',
    deliveryAddress: '',
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得個人資料
     */
    fetchInfo: (state, action) => {
        return state;
    },
    fetchInfoBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取個人資料'})
    },
    fetchInfoSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', ...payload});
    },
    fetchInfoFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 設定個人資料
     */
    setProfileInfo: (state, {payload}) => {
        return state.merge({
            isOrderPreSale: payload.isOrderPreSale,
            isOrderVipGoods: payload.isOrderVipGoods,
            isOrderNormalGoods: payload.isOrderNormalGoods,
            walletAmount: payload.walletAmount,
            unpaidAmount: payload.unpaidAmount,
            realName: payload.realName,
            email: payload.email,
            avatarUrl: payload.avatarUrl,
            messageUnreadCount: payload.messageUnreadCount,
            trackProductCount: payload.trackProductCount,
            shoppingCartCount: payload.shoppingCartCount
        });
    },
    /**
     * 設定未讀訊息筆數
     */
    setMessageItem: (state, {payload}) => {
        return state.merge({messageUnreadCount: payload.messageUnreadCount});
    },
    /**
     * 設定追蹤清單數量
     */
    setTrackItem: (state, {payload}) => {
        return state.merge({trackProductCount: payload.trackProductCount});
    },
    /**
     * 設定購物袋數量
     */
    setShoppingCartItem: (state, {payload}) => {
        return state.merge({shoppingCartCount: payload.shoppingCartCount});
    },
    /**
     * 上傳大頭貼
     */
    uploadAvatar: (state, action) => {
        return state;
    },
    uploadAvatarBegin: (state) => {
        return state.merge({isFetching: true, message: '正在上傳大頭貼'})
    },
    uploadAvatarSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', avatarUrl: payload.avatarUrl});
    },
    uploadAvatarFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得會員餘額
     */
    fetchWalletAmount: (state, action) => {
        return state;
    },
    fetchWalletAmountBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取會員餘額'})
    },
    fetchWalletAmountSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', walletAmount: payload.walletAmount, unpaidAmount: payload.unpaidAmount});
    },
    fetchWalletAmountFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 設定會員餘額
     */
    setWalletAmount: (state, {payload}) => {
        return state.merge({walletAmount: payload.walletAmount, unpaidAmount: payload.unpaidAmount});
    },
    /**
     * 清空會員資訊
     */
    clearProfileInfo: (state) => {
        return state.merge({
            isOrderPreSale: false,
            walletAmount: 0,
            unpaidAmount: 0,
            promotionCode: undefined,
            isOrderVipGoods: false,
            isOrderNormalGoods: false,
            realName: '',
            email: '',
            avatarUrl: '',
            messageUnreadCount: 0,
            trackProductCount: 0,
            shoppingCartCount: 0,
            lineId: '',
            phone: '',
            deliveryAddress: '',
        });
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
