import React from 'react';
import {Route} from 'react-router';
import FormContext from './FormContext';
import ShopBag from './Bag';
import ShopCheckout from './Checkout';



const Atm: React.FC = () => {
    return (
        <FormContext>
            <Route exact path="/shop/bag" component={ShopBag}/>
            <Route exact path="/shop/checkout" component={ShopCheckout}/>
        </FormContext>
    )
};

export default Atm;

