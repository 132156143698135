import {createReducer, createActions} from 'reduxsauce';
import Immutable, { ImmutableObject } from 'seamless-immutable';
import site from 'config/site';
import {IActionCreators, IActionTypes, IState, IPSetLocale} from "./types";
import {IHandleAction} from 'library/redux'

export const storeKey = 'language';

/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    locale: site.defaultLang,
});


/** -----------------------------------------
 Types and Action Creators
 /** ---------------------------------------*/
const {Types, Creators} = createActions<IActionTypes, IActionCreators>({
        // 設定 Token
        setLocale: ['payload'],
    },
    {prefix: `${storeKey}/`}
);

export const ActionTypes = Types;
export const Actions = Creators;


/** ---------------------------------------------------------------
 Reducers
 /** -------------------------------------------------------------*/
export const Reducer = createReducer<ImmutableObject<IState>, any>(InitialState, {
    // 設定 Token
    [Types.SET_LOCALE]: (state, {payload}: IHandleAction<IPSetLocale>) => {
        return state.merge({
            locale: payload.locale,
        });
    },
});
