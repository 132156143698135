// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {IActionCreators} from "./types";
import {Actions as UiBlockActions} from "store/uiBlock";
import {Actions as UiDialogActions} from 'store/uiDialog';

// Services
import ApiService, {TGetRemit} from 'services/deposit';


/**
 * 新增匯款單
 */
function* fetchPending(action: IPickAction<IActionCreators, 'fetchPending'>) {
    yield put(Actions.fetchPendingBegin());
    yield put(UiBlockActions.visible({message: '正在新增存款通知單'}));

    try {
        const {fromName, fromDepositAt, fromLast5Number, amount, toBankId, remark} = action.payload;
        yield all([
            call(ApiService.postMemberDeposit, fromName, fromDepositAt, fromLast5Number, amount, toBankId, remark),
            delay(fetchDataDelayMinMs),
        ]);

        // 重新取得匯款紀錄
        yield put(Actions.fetchRemit({}));

        yield put(UiDialogActions.openSuccess({message: '存款通知單送出成功！'}));
        yield put(Actions.fetchPendingSuccess());
    } catch (err) {
        yield put(Actions.fetchPendingFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

/**
 * 查詢匯款單列表
 */
function* fetchRemit(action: IPickAction<IActionCreators, 'fetchRemit'>) {
    yield put(Actions.fetchRemitBegin());

    try {
        const {currentPage, pageLimit} = action.payload;
        const [{body}]: [TGetRemit] = yield all([
            call(ApiService.getRemit, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);
        const {rows, ...meta} = body.data;

        yield put(Actions.fetchRemitSuccess({remitList: rows, remitPagination: meta}));
    } catch (err) {
        yield put(Actions.fetchRemitFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}



export default [
    takeLatest(ActionTypes.FETCH_PENDING, fetchPending),
    takeLatest(ActionTypes.FETCH_REMIT, fetchRemit),
];
