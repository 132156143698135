import React, {useCallback, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {Controller, OnSubmit, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import get from 'lodash/get';
import {Col, Container, media, Row} from 'library/styled-bs-grid';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {useLocation} from 'react-router-dom';
import {parseQueryString} from 'utils/uri';
import {formatCurrency} from 'utils/number';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';
import site from 'config/site';

// Component
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import Pagination from 'components/atoms/Pagination';

// Reducers
import {Actions as TrackAction} from 'store/track';
import {Selector as AuthSelector} from 'store/auth';

type IFormData = {
    productGoodsId: Array<number>,
    fieldOne: string,
}

// 一頁顯示筆數
const pageLimit = 20;

/**
 * 追蹤商品
 * @constructor
 */
const Favorite: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {search, pathname} = useLocation();
    const {setValue, getValues, handleSubmit, control, watch}: any = useForm();
    const [currentPage, setCurrentPage] = useState<number>(1);

    const {productGoodsId} = watch(['productGoodsId']);
    const checkedIds = [];
    for(const id in productGoodsId){
        // 過濾掉不等於 true 的 Key
        if(productGoodsId[id]){
            checkedIds.push(Number(id));
        }
    }

    const {isFetching, trackProduct, trackPagination, payload} = useSelector(state => ({
        isFetching: state.track.isFetching,
        trackProduct: state.track.trackProduct,
        trackPagination: state.track.trackPagination,
        payload: AuthSelector.payload(state),
    }));


    // 頁數
    const queryString = parseQueryString(search);
    const searchPage = get(queryString, 'page', 1);

    useEffect(() => {
        // 取得追蹤清單
        setCurrentPage(Number(searchPage));
        dispatch(TrackAction.fetchTrackProduct({currentPage: Number(searchPage), pageLimit: pageLimit}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPage]);

    /**
     * Check All
     * @param isChecked
     */
    const handleCheckAll = (isChecked: boolean) => {
        const keys = getValues();
        for(const row of Object.keys(keys)){
            setValue(row, isChecked);
        }
    };

    /**
     * 全部清除
     */
    const handleDeleteAll = () => {
        dispatch(TrackAction.clearTrackProduct());
    };

    /**
     * 刪除選取項目
     */
    const onSubmit: OnSubmit<IFormData> = useCallback(formData => {
        // 取得 Table中的 CheckId
        const {productGoodsId: checkedId} = getValues({nest: true});
        const ids = [];
        for(const id in checkedId){
            // 過濾掉不等於 true 的 Key
            if(checkedId[id]){
                ids.push(Number(id));
            }
        }

        if(ids.length === 0) {
            alert('請選擇項目');
        }else{
            dispatch(TrackAction.deleteTrackProduct({
                productGoodsId: ids,
                currentPage: searchPage,
                pageLimit: pageLimit,
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 換頁
     */
    const handleChangePage = (p: number) => {
        history.push(`${pathname}?page=${p}`);
    };

    const statusText = (isOutStock: boolean, isOff: boolean, isSale: boolean) => {
        if (isOff) {
            return '已下架';
        } else if (isOutStock) {
            return '斷貨';
        } else if (isSale) {
            return '特價';
        } else {
            return '正常供貨';
        }
    };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/account')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>追踨清單</MobileTitle>

                    <IconButton/>
                </MobileHeader>
            </Container>

            {/* Header */}
            <HeaderPc className="d-none d-md-flex flex-column align-items-center justify-content-center">
                <Title>追踨清單</Title>

                <Container>
                    <Row>
                        <Col col={8}>
                            <Breadcrumbs>Home / 追踨清單</Breadcrumbs>
                        </Col>
                        <Col col={8} className="d-flex justify-content-center">
                            <EnglishTitle>Track</EnglishTitle>
                        </Col>
                        <Col col={8} className="d-flex justify-content-end">
                            <Desc>＊＊資料保留期間：半年內</Desc>
                        </Col>
                    </Row>
                </Container>
            </HeaderPc>

            <FavoriteContainer>
                <FavoriteForm onSubmit={handleSubmit(onSubmit)}>
                    {/* 標題 */}
                    <Header className="d-none d-md-flex">
                        <CheckboxSection>
                            <Controller
                                name="checkAll1"
                                control={control}
                                valueName="checked"
                                as={<Checkbox
                                    value="checked"
                                    isLarge
                                />}
                                onChange={event => {
                                    handleCheckAll(!!event[0]);
                                    return event[0];
                                }}
                            />
                        </CheckboxSection>
                        <ProductInfo>商品貨號/品牌</ProductInfo>
                        <Category>顏色</Category>
                        <Size>SIZE</Size>
                        <Price>單價</Price>
                        <Status>狀態</Status>
                    </Header>

                    {/* 讀取狀態 */}
                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}

                    {/* 明細 */}
                    {!isFetching && trackProduct.map((row, index) => (
                        <Content key={row.id}>
                            {/* Checkbox */}
                            <CheckboxSection>
                                <Controller
                                    control={control}
                                    name={`productGoodsId[${row.id}]`}
                                    valueName="checked"
                                    as={<Checkbox
                                        value={String(row.id)}
                                        isLarge
                                    />}
                                />
                            </CheckboxSection>

                            {/* 商品 */}
                            <ProductInfo>
                                <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>

                                <div className="d-flex flex-column justify-content-center">
                                    <Model onClick={() => window.open(`/product/detail/${payload?.id}/${row.productGoods.id}`)}>{get(row, 'productGoods.model')}</Model>
                                    <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                                    <ProductName>{get(row, 'productGoods.name')}</ProductName>
                                    <SubName>{get(row, 'productGoods.subName')}</SubName>
                                </div>
                            </ProductInfo>

                            {/* 類別 & 顏色 */}
                            <Category className="d-flex flex-md-column align-items-start">
                                <FieldTitle className="d-md-none">顏色</FieldTitle>
                                <FieldValue>{get(row, 'productGoods.color')}</FieldValue>
                            </Category>

                            {/* SIZE */}
                            <Size>
                                <FieldTitle className="d-md-none">SIZE</FieldTitle>
                                <FieldValue>{get(row, 'productGoods.size')}</FieldValue>
                            </Size>

                            {/* 單價 */}
                            <Price>
                                <FieldTitle className="d-md-none">單價</FieldTitle>

                                <div className="d-flex align-items-center flex-md-column">
                                    <FieldValue>$ {formatCurrency(get(row, 'productGoods.price'))}</FieldValue>
                                    <FieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                                </div>
                            </Price>

                            {/* 狀態 */}
                            <Status>
                                <FieldTitle className="d-md-none">狀態</FieldTitle>
                                <StatusButton
                                    isUnSupply={row.productGoods.isOutStock || row.productGoods.isOff}
                                    isSale={get(row, 'productGoods.isSale')}
                                >
                                    {statusText(row.productGoods.isOutStock, row.productGoods.isOff, row.productGoods.isSale)}
                                </StatusButton>
                            </Status>
                        </Content>
                    ))}

                    <Bottom>
                        <Controller
                            name="checkAll2"
                            control={control}
                            valueName="checked"
                            as={<Checkbox
                                value="checked"
                                text="全選 / 取消全選"
                                isLarge
                                className="flex-grow-1 flex-md-grow-0"
                            />}
                            onChange={event => {
                                handleCheckAll(!!event[0]);
                                return event[0];
                            }}
                        />

                        <DeleteSelect className="d-none d-md-block">刪除選取項目</DeleteSelect>
                        <DeleteSelect className="d-md-none" type="button" onClick={() => handleDeleteAll()}>全部清除</DeleteSelect>
                    </Bottom>

                    {/* 手機版置底刪除按鈕 */}
                    <FixedDeleteButton
                        className="d-md-none"
                        isVisible={get(checkedIds, 'length', 0) > 0}
                    >
                        刪除選取項目
                    </FixedDeleteButton>
                </FavoriteForm>
            </FavoriteContainer>

            {/* 分頁 */}
            <CustomPagination
                page={currentPage}
                totalPage={get(trackPagination, 'totalPages')}
                totalItems={get(trackPagination, 'totalItems')}
                onChange={(currentPage: number) => handleChangePage(currentPage)}
            />
        </>
    );
};

export default Favorite;

const Desc = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.xl`
        font-size: 16px;
    `}
`;

const EnglishTitle = styled.span`
    font-size: 14px;
    color: #9d9a9a;
    text-align: center;

    ${media.xl`
        font-size: 16px;
    `}
`;

const Breadcrumbs = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.xl`
        font-size: 16px;
    `}
`;

const Title = styled.span`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #282731;
    padding-bottom: 12px;
`;

const HeaderPc = styled.div`
    width: 100%;
    height: 112px;
    background-color: #fbfbfb;
    margin-bottom: 20px;

    ${media.xl`
        height: 132px;
    `}
`;

const CustomPagination = styled(Pagination)`
    margin: 10px auto 25px auto;

    ${media.lg`
        margin: 30px auto 50px auto;
    `}
`;

const FixedDeleteButton = styled.button<any>`
    width: 100%;
    height: 40px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #8ec5ff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s;

    ${props => props.isVisible && css`
        opacity: 1;
        pointer-events: auto;
    `}
`;

const DeleteSelect = styled.button`
    font-size: 12px;
    font-weight: 300;
    color: #9d9a9a;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 60px;

    ${media.md`
        font-size: 16px;
        font-weight: 300;
    `}
`;

const Bottom = styled.div`
    padding: 10px 5px 22px 5px;
    display: flex;
    align-items: center;
    order: 1;

    ${media.md`
        order: 2;
        padding: 25px 0 0 0;
    `}
`;

const StatusButton = styled.div<any>`
    width: 64px;
    height: 20px;
    border-radius: 2px;
    border: solid 1px #a8d580;
    background-color: rgba(168, 213, 128, 0.1);
    font-size: 12px;
    color: #a8d580;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.isUnSupply && css`
        border: solid 1px #ff7d6a;
        background-color: rgba(255, 125, 106, 0.1);
        color: #ff7d6a;
    `};

    ${props => (!props.isUnSupply && props.isSale) && css`
        border: solid 1px #8ec5ff;
        background-color: rgba(142, 197, 255, 0.1);
        color: #8ec5ff;
    `}

    ${media.md`
        width: 90px;
        height: 32px;
        font-size: 16px;
        border-radius: 4px;
    `}
`;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const FieldTitle = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    padding-right: 6px;
`;

const SubName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const BrandName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const Model = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #6e6e6e;
    cursor: pointer;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const ProductImg = styled.img`
    width: 44px;
    height: 44px;
    margin-right: 6px;
    background-color: lightgray;

    ${media.md`
        width: 70px;
        height: 80px;
        margin-right: 10px;
    `}
`;

const Content = styled.div`
    width: 100%;
    min-height: 160px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    order: 2;
    position: relative;

    ${media.md`
        min-height: auto;
        box-shadow: none;
        border: none;
        border-bottom: solid 1px #edeff2;
        padding: 10px 0;
        margin-bottom: 0;
        order: 1;
        flex-direction: row;
        align-items: center;
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const Status = styled(HeaderFieldName)`
    ${media.md`
        min-width: 214px;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
    `}

    ${media.xxl`
        min-width: 258px;
    `}
`;

const Price = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 98px;
        padding-bottom: 0;
        justify-content: center;
    `}
`;

const Size = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 150px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 389px;
    `}
`;

const Category = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 150px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 205px;
    `}
`;

const ProductInfo = styled(HeaderFieldName)`
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 5px;
    margin-bottom: 6px;

    ${media.md`
        width: 444px;
        padding-right: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    `}

    ${media.xxl`
        min-width: 430px;
    `}
`;

const CheckboxSection = styled(HeaderFieldName)`
    position: absolute;
    top: -5px;
    left: -5px;

    ${media.md`
        min-width: 60px;
        position: initial;
        top: auto;
        left: auto;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: solid 1px #edeff2;
`;

const FavoriteForm = styled.form`
    display: flex;
    flex-direction: column;

    ${media.md`
        width: 1118px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const FavoriteContainer = styled(Container)`
    ${media.md`
        overflow-x: auto;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
