import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {useForm} from 'react-hook-form';
import {asset} from 'config/app';
import site from 'config/site';
import {get, groupBy} from 'lodash';
import {HEXToRGBA} from 'utils/format';
import {isEmpty} from 'utils/equal';
import {Container, Row, Col} from 'library/styled-bs-grid';
import {HalfCircleSpinner} from 'react-epic-spinners';

// Component
import Icon from 'components/atoms/Icon';
import ImagePreViewModal from 'components/organisms/ImagePreViewModal';

// Reducer
import {Actions as MessageAction} from 'store/message';


const Detail: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [imagePreviewUrl, setImagePreviewUrl] = useState<string|ArrayBuffer|null>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isVisibleImageModal, setVisibleImageModal] = useState<boolean>(false);

    const {register, handleSubmit, watch, setValue} = useForm();
    const {content, image} = watch();
    const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const {categoryId} = useParams();

    const {isFetchingContent, messageCategory, messageContent, isReadonly} = useSelector(state => ({
        isFetchingContent: state.message.isFetchingContent,
        messageCategory: state.message.messageCategory,
        messageContent: state.message.messageContent,
        isReadonly: state.message.isReadonly,
    }));


    const categoryInfo = messageCategory.find(o => String(o.id) === String(categoryId));
    const tagBg = HEXToRGBA(get(categoryInfo, 'themeColor', '#ffffff'), 0.1);



    // 信息內容以日期分群
    const contentByDate = groupBy(messageContent, function (date) {
        return dayjs(date.createdAt).format('YYYY/MM/DD');
    });


    // 取得訊息明細
    useEffect(() => {
        if (get(messageCategory, 'length', 0) === 0) {
            // 取訊息類別
            dispatch(MessageAction.fetchCategory());
        }

        dispatch(MessageAction.fetchContent({categoryId}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 當訊息明細資料異動時，滑動至底部
    useEffect(() => {
        contentRef.current.scrollTo({top: contentRef.current.scrollHeight});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageContent]);


    /**
     * 上傳圖片
     * */
    const handleImageChange = (e: React.ChangeEvent<Element>) => {
        const reader = new FileReader();
        const file = get(e, 'target.files.0');

        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    /**
     *  送出
     * */
    const onSubmit = (formData: any) => {
        dispatch(MessageAction.submitMessage({categoryId, content: formData.content, image: formData.image[0], imagePreviewUrl: imagePreviewUrl}));
        setValue('content', '');
        setValue('image', '');
        setImagePreviewUrl('');
    };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/message')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>{get(messageContent, 'length', 0) > 0 ? '訊息' : '新訊息'}</MobileTitle>

                    <IconButton/>
                </MobileHeader>
            </Container>

            <form onSubmit={handleSubmit(onSubmit)}>
                <MessageContainer>
                    <CategoryTag tagBg={tagBg}>
                        <CategoryText color={get(categoryInfo, 'themeColor')}>{get(categoryInfo, 'name')}</CategoryText>
                    </CategoryTag>

                    <Content ref={contentRef}>
                        {Object.keys(contentByDate).map(date => (
                            <div key={date}>
                                {/* 訊息日期 */}
                                <div className="d-flex align-items-center justify-content-center">
                                    <Date>{date}</Date>
                                </div>

                                {/* 訊息內容 */}
                                {contentByDate[date].map((row) => {
                                    // 判斷上下午
                                    const timeDesc = dayjs(get(row, 'createdAt')).hour() > 11 ? '下午' : '上午';

                                    if (!get(row, 'isMemberPost', false)) {
                                        return (
                                            <div key={get(row, 'id')}>
                                                <System>
                                                    <LogoBg/>
                                                    <MessageBg>
                                                        <MessageText>{get(row, 'content')}</MessageText>
                                                    </MessageBg>
                                                    <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                </System>

                                                {!isEmpty(row.imageUrl) && (
                                                    <System>
                                                        <LogoBg/>
                                                        <MessageImage
                                                            src={row.imageUrl}
                                                            onClick={() => {
                                                                setVisibleImageModal(true);
                                                                setImageUrl(row.imageUrl);
                                                            }}
                                                        />
                                                        <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                    </System>
                                                )}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={get(row, 'id')}>
                                                {!isEmpty(row.content) && (
                                                    <User>
                                                        <div className="d-flex align-items-center">
                                                            {get(row, 'isSubmitting', false) && (<Icon code="redo" size={15} color="#bbb8b8" isRotateAnimation/>)}
                                                            <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                        </div>
                                                        <UserMessageBg>
                                                            <MessageText>{row.content}</MessageText>
                                                        </UserMessageBg>
                                                    </User>
                                                )}

                                                {!isEmpty(row.imageUrl) && (
                                                    <User>
                                                        <div className="d-flex align-items-center">
                                                            {get(row, 'isSubmitting', false) && (<Icon code="redo" size={15} color="#bbb8b8" isRotateAnimation/>)}
                                                            <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                        </div>
                                                        <MessageImage
                                                            src={row.imageUrl}
                                                            onClick={() => {
                                                                setVisibleImageModal(true);
                                                                setImageUrl(row.imageUrl);
                                                            }}
                                                        />
                                                    </User>
                                                )}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        ))}

                        {/* 訊息內容讀取樣式 */}
                        {isFetchingContent && (
                            <Row>
                                <Col col>
                                    <HalfCircleSpinner style={{margin: 'auto'}} size={20} color="#bdbdbd"/>
                                </Col>
                            </Row>
                        )}


                        {(!isFetchingContent && isEmpty(contentByDate)) && (
                            <NoMessage>（目前尚無訊息...）</NoMessage>
                        )}

                    </Content>

                    {!isReadonly && (
                        <FillSection>

                            {/* 上傳圖片按鈕 */}
                            <InputIconButton type="button">
                                <Icon code="image" size={20} color={site.theme.primaryColor}/>
                                <UploadInput
                                    ref={(e: HTMLInputElement) => register(e)}
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e)}
                                />
                            </InputIconButton>

                            <MessageInput
                                ref={(e: HTMLInputElement) => register(e)}
                                name="content"
                                placeholder="發送訊息..."
                            />

                            {/* 上傳圖片預覽 */}
                            {get(image, 'length', 0) > 0 && (
                                <PreView>
                                    <PreViewImg src={imagePreviewUrl}/>
                                    <CloseButton
                                        type="button"
                                        onClick={() => {
                                            setImagePreviewUrl('');
                                            setValue('image', null);
                                        }}
                                    >
                                        <Icon code="times" size={15} color="#9d9a9a"/>
                                    </CloseButton>
                                </PreView>
                            )}

                            <InputIconButton>
                                <Icon code="paper-plane" size={20} color={isEmpty(content) ? '#bbb8b8' : site.theme.primaryColor}/>
                            </InputIconButton>
                        </FillSection>
                    )}
                </MessageContainer>
            </form>

            {/* 圖片放大 */}
            <ImagePreViewModal
                imageUrl={imageUrl}
                isVisible={isVisibleImageModal}
                onClose={() => setVisibleImageModal(false)}
            />
        </>
    );
};

export default Detail;

const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
`;

const PreViewImg = styled.img<any>`
    width: auto;
    height: 100px;
`;

const PreView = styled.div`
    width: 100%;
    height: 120px;
    background-color: #fff;
    border: solid 1px #ebebeb;
    border-radius: 6px 6px 0 0;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 10px 0;
    display: flex;
    justify-content: center;
`;

const NoMessage = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #bbb8b8;
`;

const MessageInput = styled.input`
    width: 100%;
    height: 100%;
    margin: 0 10px;
    font-size: 12px;
    color: #2d2c38;
    border: none;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #bbb8b8;
    }
`;

const UploadInput = styled.input`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
`;

const InputIconButton = styled.button`
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
`;

const FillSection = styled.div`
    width: 100%;
    min-height: 36px;
    border-radius: 6px;
    border: solid 1px #ebebeb;
    margin: 10px 0;
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: relative;
`;

const UserMessageBg = styled.div`
    max-width: 180px;
    border-radius: 12px 12px 0 12px;
    background-color: rgba(142, 197, 255, 0.1);
    padding: 6px 10px;
    margin-left: 5px;
`;

const User = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 10px;
`;

const TimeText = styled.span`
    font-size: 10px;
    color: #bbb8b8;
    padding-left: 5px;
`;

const MessageImage = styled.img`
    width: auto;
    height: 120px;
    border-radius: 12px 12px 0 12px;
    margin-left: 5px;
`;

const MessageText = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    display: block;
    word-break: break-all;
`;

const MessageBg = styled.div`
    max-width: 180px;
    border-radius: 12px 12px 12px 0;
    background-color: #fafafa;
    padding: 6px 10px;
    margin-right: 5px;
`;

const LogoBg = styled.div`
    width: 30px;
    height: 30px;
    background-color: #fafafa;
    border-radius: 50%;
    background-image: url("${asset('/images/home/message-logo.png')}");
    background-size: cover;
    margin-right: 6px;
`;

const System = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
`;

const Date = styled.span`
    font-size: 12px;
    color: #bbb8b8;
    display: block;
    text-align: center;
    margin: 10px 0 20px 0;
    position: relative;

    &:before{
        content: '';
        width: 30px;
        height: 1px;
        background-color: #ebebeb;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(-10px);
    }

    &:after{
        content: '';
        width: 30px;
        height: 1px;
        background-color: #ebebeb;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(10px);
    }
`;

const Content = styled.div`
    flex: 1 1 auto;
    overflow-y: scroll;
`;

const CategoryText = styled.span<any>`
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.color};
`;

const CategoryTag = styled.div<any>`
    width: 100%;
    min-height: 30px;
    border-radius: 5px;
    background-color: ${props => `rgba(${props.tagBg[0]}, ${props.tagBg[1]}, ${props.tagBg[2]}, ${props.tagBg[3]})`};
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MessageContainer = styled(Container)`
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
