// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetOrderShipper, TGetOrderShipperDetail} from 'services/orderShipper';


/**
 * 取得出貨單列表
 */
function* fetchOrderShipper(action: IPickAction<IActionCreators, 'fetchOrderShipper'>) {
    yield put(Actions.fetchOrderShipperBegin());

    try {
        const {startDate, endDate, shipperId, currentPage, pageLimit} = action.payload;
        const [{body}]: [TGetOrderShipper] = yield all([
            call(ApiService.getOrderShipper, startDate, endDate, shipperId, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, ...meta} = body.data;
        yield put(Actions.fetchOrderShipperSuccess({orderShipperList: rows, orderShipperPagination: meta}));

    } catch (err) {
        yield put(Actions.fetchOrderShipperFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 取得出貨單列表
 */
function* fetchOrderShipperDetail(action: IPickAction<IActionCreators, 'fetchOrderShipperDetail'>) {
    yield put(Actions.fetchOrderShipperDetailBegin());

    try {
        const {id} = action.payload;
        const [{body}]: [TGetOrderShipperDetail] = yield all([
            call(ApiService.getOrderShipperDetail, id),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchOrderShipperDetailSuccess({orderShipperDetail: rows}));

    } catch (err) {
        yield put(Actions.fetchOrderShipperDetailFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


export default [
    takeLatest(ActionTypes.FETCH_ORDER_SHIPPER, fetchOrderShipper),
    takeLatest(ActionTypes.FETCH_ORDER_SHIPPER_DETAIL, fetchOrderShipperDetail),
];
