import * as React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import site from 'config/site';
import Icon from 'components/atoms/Icon';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    page?: number;
    totalPage: number;
    totalItems: number;
    limit?: number;
    onChange: Function;
}

/**
 * Pagination
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Pagination: React.FC<IProps> = ({
    className,
    style,
    page = 1,
    totalPage,
    totalItems,
    limit = 5,
    onChange = () => {},
}) => {
    // 當前頁面需要顯示的起始頁，以及結尾頁
    const pageStart = Math.ceil(page / limit) * limit - (limit - 1);
    const pageEnd = (pageStart + (limit - 1)) > totalPage ? totalPage : (pageStart + (limit - 1));

    const disabledLast = (page === 1);
    const disabledNext = (page >= totalPage);

    // const disabledLastRound = Math.ceil(page / limit) === 1;
    // const disabledNextRound = Math.ceil(page / limit) === (Math.ceil(totalPage / limit));

    const pageItem = [];
    for (let i = pageStart; i <= pageEnd; i++) {
        pageItem.push(
            <PageNum
                key={i}
                isSelected={page === i}
                onClick={() => onChange(i)}
            >
                {i}
            </PageNum>,
        );
    }

    // 上一頁
    const handleSetLastPage = () => {
        onChange(page - 1);
    };

    // 下一頁
    const handleSetNextPage = () => {
        onChange(page + 1);
    };

    // 前五頁
    // const handleSetLastRound = () => {
    //     const lastRoundPage = Math.ceil(page / limit) * limit - limit;
    //     onChange(lastRoundPage);
    // };

    // 下五頁
    // const handleSetNextRound = () => {
    //     const nextRoundPage = Math.ceil(page / limit) * limit + 1;
    //     onChange(nextRoundPage);
    // };

    // 產生頁數下拉選項
    const pageOptions = () => {
        const pageOptions = [];
        for (let totalPageI = 1; totalPageI <= totalPage; totalPageI ++) {
            pageOptions.push(<option key={totalPageI} value={totalPageI}>{totalPageI}</option>);
        }

        return pageOptions;
    };

    return (
        <PaginationRoot className={className} style={style}>
            <div>
                <PageSection>
                    {/* 上5頁按鈕 */}
                    {/*{totalPage > limit && (*/}
                    {/*    <PageButton*/}
                    {/*        disabled={disabledLastRound}*/}
                    {/*        onClick={() => handleSetLastRound()}*/}
                    {/*    >*/}
                    {/*        <Icon code="forward" size={15} rotate={180} color="#8ec5ff"/>*/}
                    {/*    </PageButton>*/}
                    {/*)}*/}

                    <PageButton
                        disabled={disabledLast}
                        onClick={() => handleSetLastPage()}
                    >
                        <Icon code="play" size={15} rotate={180} color="#8ec5ff"/>
                    </PageButton>

                    {pageItem}

                    <PageButton>
                        <Icon code="ellipsis-h" size={15} color="#8ec5ff"/>
                        <PageSelect
                            name="page"
                            value={page}
                            onChange={(e) => onChange(Number(e.target.value))}
                        >
                            {pageOptions()}
                        </PageSelect>
                    </PageButton>


                    <PageButton
                        disabled={disabledNext}
                        onClick={() => handleSetNextPage()}
                    >
                        <Icon code="play" size={15} color="#8ec5ff"/>
                    </PageButton>

                    {/* 下5頁按鈕 */}
                    {/*{totalPage > limit && (*/}
                    {/*    <PageButton*/}
                    {/*        disabled={disabledNextRound}*/}
                    {/*        onClick={() => handleSetNextRound()}*/}
                    {/*    >*/}
                    {/*        <Icon code="forward" size={15} color="#8ec5ff"/>*/}
                    {/*    </PageButton>*/}
                    {/*)}*/}
                </PageSection>
                <PageInfo>{`共  ${totalPage}  頁  /  共  ${totalItems}  筆`}</PageInfo>
            </div>
        </PaginationRoot>
    );
};

export default Pagination;

const PageInfo = styled.div`
    font-size: 12px;
    text-align: center;
    color: #848484;

    ${media.lg`
        font-size: 14px;
    `}
`;

const PageNum = styled.button<any>`
    width: 30px;
    height: 30px;
    font-size: 14px;
    color: ${site.theme.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.isSelected && css`
        background-color: ${site.theme.primaryColor};
        color: #ffffff;
        width: 36px;
        height: 36px;
        border-radius: 4px;
    `};

    ${media.lg`
        width: 60px;
        height: 50px;
        font-size: 17px;

        ${(props: any) => props.isSelected && css`
            width: 60px;
            height: 60px;
        `};
    `}
`;

const PageSelect = styled.select`
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: absolute;
    opacity: 0;
`;

const PageButton = styled.button`
    width: 34px;
    height: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:nth-child(1) {
        border-radius: 4px 0 0 4px;
    }

    &:nth-last-child(1) {
        border-radius: 0 4px 4px 0;
    }

    ${props => props.disabled && css`
        i {
            color: rgba(142, 197, 255, 0.3);
        }
    `};

    ${media.lg`
        width: 50px;
        height: 48px;

        i {
            font-size: 20px;
        }

        &:nth-child(1) {
            border-radius: 8px 0 0 8px;
        }

        &:nth-last-child(1) {
            border-radius: 0 8px 8px 0;
        }
    `}
`;

const PageSection = styled.div`
    height: 30px;
    border-radius: 4px;
    box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.25);
    background-color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;

    ${media.lg`
        height: 48px;
        border-radius: 8px;
        margin-bottom: 20px;
    `}
`;

const PaginationRoot = styled.div`
    display: flex;
    justify-content: center;
`;
