import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import get from 'lodash/get';

// Reducer
import {Actions as ProductAction} from 'store/product';


/**
 * 所有品牌
 * @constructor
 */
const Brand: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedLetter, setLetter] = useState<string|''>('');

    const {isFetching, brandGroup} = useSelector(state => ({
        isFetching: state.product.isFetching,
        brandGroup: state.product.brandGroup,
    }));

    useEffect(() => {
        if (get(brandGroup, 'length', 0) === 0) {
            dispatch(ProductAction.fetchBrandGroup());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 錨點, 滾動到點擊的字母
     * @param letter
     */
    const scrollToLetter = (letter: string) => {
        setLetter(letter);

        let anchorElement = document.getElementById(letter);

        if (anchorElement) {
            window.scroll({
                top: anchorElement.offsetTop - 30,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <BrandRoot>
            <div className="d-flex flex-md-column">
                <BrandSection>
                    {brandGroup.map(row => (
                        <FirstLetterBrand
                            key={get(row, 'firstLetter')}
                            className="d-flex flex-column flex-xl-row"
                        >
                            <FirstLetter id={get(row, 'firstLetter')}>{get(row, 'firstLetter')}</FirstLetter>

                            <Row className="w-100">
                                {row.detail.map(item => (
                                    <Col md={8} xl={6} key={get(item, 'id')}>
                                        <BrandName onClick={() => history.push(`/product?brands=["${get(item, 'id')}"]`)}>{`${get(item, 'name')} (${get(item, 'totalProductGoods', 0)})`}</BrandName>
                                    </Col>
                                ))}
                            </Row>
                        </FirstLetterBrand>
                    ))}
                </BrandSection>

                <LetterSection>
                    {brandGroup.map(row => (
                        <LetterCircle
                            key={get(row, 'firstLetter')}
                            isSelected={selectedLetter === get(row, 'firstLetter')}
                            onClick={() => scrollToLetter(get(row, 'firstLetter'))}
                        >
                            <Letter>{row.firstLetter}</Letter>
                        </LetterCircle>
                    ))}
                </LetterSection>


                {isFetching && (
                    <Row>
                        <Col col>
                            <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                        </Col>
                    </Row>
                )}
            </div>
        </BrandRoot>
    );
};

export default Brand;

const Letter = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #fff;

    ${media.md`
        font-size: 18px;
    `}
`;

const LetterCircle = styled.div<any>`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba(255, 125, 106, 0.5);
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: translateX(5px);

    ${props => props.isSelected && css`
        background-color: #ff7d6a;
    `}

    ${media.md`
        width: 26px;
        height: 26px;
        margin-bottom: 15px;
        margin-right: 15px;
        transform: translateX(0);
    `}

    ${media.xl`
        width: 30px;
        height: 30px;
        margin-bottom: 0;
        margin-right: 10px;
        transform: translateX(0);
    `}
`;

const LetterSection = styled.div`
    display: flex;
    flex-direction: column;

    ${media.md`
        order: 1;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 15px;
    `}

    ${media.xl`
        margin-bottom: 58px;
    `}
`;

const BrandName = styled.span`
    font-size: 14px;
    color: #6e6e6e;
    margin-bottom: 8px;
    display: block;
    cursor: pointer;

    ${media.md`
        font-size: 12px;
        margin-bottom:12px;
    `}
`;

const FirstLetter = styled.span<any>`
    font-size: 18px;
    font-weight: 500;
    color: #ff7d6a;
    padding-bottom: 12px;

    ${media.md`
        font-size: 40px;
        padding-bottom: 10px;
    `}

    ${media.xl`
        font-size: 50px;
        width: 75px;
        margin-right: 0;
        line-height: 0.8;
    `}
`;

const FirstLetterBrand = styled.div`
    margin-bottom: 22px;

    ${media.md`
        margin-bottom: 18px;
    `}

    ${media.xl`
        margin-bottom: 56px;
    `}
`;

const BrandSection = styled.div`
    flex: 1 1 auto;

    ${media.md`
        order: 2;
    `}
`;

const BrandRoot = styled(Container)`
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;

    ${media.md`
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 30px;
    `}
`;
