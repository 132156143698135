import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {media} from 'library/styled-bs-grid';
import {motion, AnimatePresence} from 'framer-motion'

// Component
import Icon from 'components/atoms/Icon';

interface IProps {
    isVisible: boolean;
    className?: string;
    style?: CSS.Properties;
    imageUrl: string;
    onClose: Function;
}

/**
 * ImagePreViewModal
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ImagePreViewModal: React.FC<IProps> = ({
    isVisible,
    className,
    style,
    imageUrl,
    onClose = () => {},
}) => {
    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    const containerVariant: any = {
        initial: {transform: 'scale(0)'},
        isOpen: {transform: 'scale(1)'},
        exit: {transform: 'scale(0)'},
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <Overlay
                    className={className}
                    style={style}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <ImagePreViewModalRoot
                        variants={containerVariant}
                        transition={{type: "spring", duration: 0.5}}
                    >
                        <MessageImage src={imageUrl}/>

                        <CloseButton type="button" onClick={onClose}>
                            <Icon code="times" size={20} color="#9d9a9a"/>
                        </CloseButton>
                    </ImagePreViewModalRoot>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default ImagePreViewModal;

const MessageImage = styled.img`
    width: 100%;
    height: auto;

    ${media.xl`
        width: 700px;
    `}
`;

const CloseButton = styled.button<any>`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;

    ${media.md`
        width: 30px;
        height: 30px;

        i {
            font-size: 25px;
        }
    `}
`;

const ImagePreViewModalRoot = styled(motion.div)`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        padding: 0 100px;
    `}
`;

const Overlay = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
`;
