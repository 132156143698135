// Libraries & Utils
import Immutable from 'seamless-immutable';
import {TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'product';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    isFetchingDetail: false,
    message: '',

    mallList: [],
    brandGroup: [],
    hotBrand: [],
    categoryGroup: [],
    seasonData: [],

    isFetchingGoods: false,
    productGoods: [],
    productPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        pageLimit: 0,
    },

    productDetail: {
        id: 0,
        model: '',
        isVipGoods: false,
        isOutStock: false,
        isCanChooseSize: false,
        images: [],
        categoryGroup: {
            id: 0,
            name: '',
            isNew: false,
        },
        categoryMain: {
            id: 0,
            name: '',
        },
        categorySub: {
            id: 0,
            name: '',
        },
        price: 0,
        prePrice: 0,
        brandName: '',
        brandId: 0,
        name: '',
        sizes: [],
        material: '',
        color: '',
        isSale: false,
        isNew: false,
        isHot: false,
        isInStock: false,
        isExtraFare: false,
        createDate: '',
        isTrack: false,
        inStock: [],
        desc: '',
        batchPrice: 0,
        batchUnitQty: 0,
    },

    relatedProducts: [],
    productDesc: {
        desc: '',
        notes: '',
        sizeTables: [],
    },

    otherRelated: {
        newProductGoods: [],
        inStockProductGoods: [],
        saleProductGoods: [],
        hotProductGoods: [],
    }
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {
    productDetail: (state) => state[storeKey].productDetail,
    productGoods: (state) => state[storeKey].productGoods,
};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得商城列表
     */
    fetchProductMall: (state) => {
        return state;
    },
    fetchProductMallBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得商城列表'});
    },
    fetchProductMallSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', mallList: payload.mallList});
    },
    fetchProductMallFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得品牌列表(群組化)
     */
    fetchBrandGroup: (state) => {
        return state;
    },
    fetchBrandGroupBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得品牌列表'});
    },
    fetchBrandGroupSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', brandGroup: payload.brandGroup});
    },
    fetchBrandGroupFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得熱門品牌
     */
    fetchHotBrand: (state) => {
        return state;
    },
    fetchHotBrandBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得熱門品牌'});
    },
    fetchHotBrandSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', hotBrand: payload.hotBrand});
    },
    fetchHotBrandFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得商品類別(含子類)
     */
    fetchCategoryGroup: (state) => {
        return state;
    },
    fetchCategoryGroupBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得品牌列表'});
    },
    fetchCategoryGroupSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', categoryGroup: payload.categoryGroup});
    },
    fetchCategoryGroupFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得商品季節列表
     */
    fetchSeason: (state) => {
        return state;
    },
    fetchSeasonBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得商品季節清單'});
    },
    fetchSeasonSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', seasonData: payload.seasonData});
    },
    fetchSeasonFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得產品列表 (Guest)
     */
    fetchProductGoodsGuest: (state) => {
        return state;
    },
    fetchProductGoodsGuestBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得商品列表'});
    },
    fetchProductGoodsGuestSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', productGoods: payload.productGoods, productPagination: payload.productPagination});
    },
    fetchProductGoodsGuestFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得產品列表
     */
    fetchProductGoods: (state) => {
        return state;
    },
    fetchProductGoodsBegin: (state) => {
        return state.merge({isFetchingGoods: true, message: '正在取得商品列表'});
    },
    fetchProductGoodsSuccess: (state, {payload}) => {
        return state.merge({isFetchingGoods: false, message: '', productGoods: payload.productGoods, productPagination: payload.productPagination});
    },
    fetchProductGoodsFail: (state) => {
        return state.merge({isFetchingGoods: false, message: ''});
    },

    /**
     * 更新產品列表
     */
    setProductGoods: (state, {payload}) => {
        return state.merge({productGoods: payload.productGoods});
    },
    /**
     * 取得產品明細
     */
    fetchProductDetail: (state) => {
        return state;
    },
    fetchProductDetailBegin: (state) => {
        return state.merge({isFetchingDetail: true, message: '正在取得商品明細'});
    },
    fetchProductDetailSuccess: (state, {payload}) => {
        return state.merge({isFetchingDetail: false, message: '', productDetail: payload.productDetail});
    },
    fetchProductDetailFail: (state) => {
        return state.merge({isFetchingDetail: false, message: ''});
    },
    /**
     * 取得關聯產品
     */
    fetchRelatedProducts: (state) => {
        return state;
    },
    fetchRelatedProductsBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得關聯產品'});
    },
    fetchRelatedProductsSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', relatedProducts: payload.relatedProducts});
    },
    fetchRelatedProductsFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得商品說明
     */
    fetchProductDesc: (state) => {
        return state;
    },
    fetchProductDescBegin: (state) => {
        return state.merge({isFetching: true, message: '正在取得產品說明'});
    },
    fetchProductDescSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', productDesc: payload.productDesc});
    },
    fetchProductDescFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得其他綜合相關商品
     */
    fetchOtherRelated: (state) => {
        return state;
    },
    fetchOtherRelatedBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取其他綜合相關商品'});
    },
    fetchOtherRelatedSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', otherRelated: payload.otherRelated});
    },
    fetchOtherRelatedFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 下載商品圖片
     */
    downloadImages: (state, action) => {
        return state;
    },
    downloadImagesBegin: (state) => {
        return {...state, isFetching: true, message: ''};
    },
    downloadImagesSuccess: (state, {payload}) => {
        return {...state, isFetching: false};
    },
    downloadImagesFail: (state, {payload}) => {
        return {...state, isFetching: false, message: payload.message};
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
