import React, {useMemo, useCallback} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import uniqueId from 'lodash/uniqueId';
import Icon from 'components/atoms/Icon';
import {isEmpty} from 'utils/equal';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    onChange?: (value?: string | number | undefined) => void
    name?: string;
    text?: string;
    checked?: boolean;
    id?: string;
    value: string | number;
    isLarge?: boolean;
}

/**
 * Checkbox
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Checkbox: React.FC<IProps> = ({
    style,
    className,
    onChange,
    text,
    checked= false,
    id,
    value,
    isLarge = false,
}) => {
    const forId = useMemo(() => id ?? uniqueId('checkbox_'), [id]);

    const handleOnChange = useCallback((isChecked: boolean) => {
        if(onChange){
            onChange(isChecked ? value : undefined)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <CheckboxRoot style={style} className={className}>
            <CheckboxInput
                type="checkbox"
                id={forId}
                checked={checked}
                value={value}
                onChange={event => handleOnChange(event.target.checked)}
            />

            <Label htmlFor={forId}>
                <Square isLarge={isLarge}>
                    <Icon code="check" color="#fff" size={10}/>
                </Square>

                {!isEmpty(text) && <LabelText isLarge={isLarge}>{text}</LabelText>}
            </Label>
        </CheckboxRoot>
    );
};

export default Checkbox;

const LabelText = styled.span<any>`
    padding-left: 12px;
    font-size: 12px;
    color: #6e6e6e;

    ${props => props.isLarge && css`
        ${media.md`
            font-size: 16px;
            padding-left: 28px;
        `}
    `}

    ${media.lg`
        font-size: 16px;
        padding-left: 28px;
    `};
`;

const Square = styled.div<any>`
    width: 17px;
    height: 17px;
    background-color: #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        display: none;
        pointer-events: none;
    }

    ${props => props.isLarge && css`
        ${media.md`
            width: 26px;
            height: 26px;

            i {
                font-size: 16px;
            }
        `}
    `}

    ${media.lg`
        width: 26px;
        height: 26px;

        i {
            font-size: 16px;
        }
    `};
`;
const Label = styled.label`
    font-size: 12px;
    cursor: pointer;
    margin: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
`;

const CheckboxInput = styled.input`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

const CheckboxRoot = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    input[type="checkbox"]:checked + ${Label} {

        ${Square} {
            background-color: ${props => props.theme.primaryColor};
            i {
                display: block;
            }
        }
    }
`;
