import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Container, media, Row} from 'library/styled-bs-grid';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {parseQueryString} from 'utils/uri';
import {formatCurrency} from 'utils/number';
import dayjs from 'dayjs';
import {isEmpty} from 'utils/equal';
import {asset} from 'config/app';
import site from 'config/site';

import Icon from 'components/atoms/Icon';
import StatusTag from 'components/atoms/Tag/StatusTag';
import Search from '../Search';

// Reducers
import {Actions as PreSaleAction} from 'store/preSale';
import {Selector as AuthSelector} from 'store/auth';


const statusInfo = {
    transferOrder: {name: '已轉單', theme: 'default'},
    cancel: {name: '已取消', theme: 'gray'},
    unspecified: {name: '未特下', theme: 'purple'},
    pending: {name: '待處理', theme: 'orange'},
    soldOut: {name: '已售完', theme: 'danger'},
};

/**
 * 預先下特
 * @constructor
 */
const AdvanceOrder: React.FC = () => {
    const history = useHistory();
    const [isVisibleSearch, setVisibleSearch] = useState(false);
    const useFormParams = useForm();
    const {handleSubmit} = useFormParams;
    const dispatch = useDispatch();

    const {isFetching, preSaleList, payload} = useSelector(state => ({
        isFetching: state.preSale.isFetching,
        preSaleList: state.preSale.preSaleList || [],
        payload: AuthSelector.payload(state),
    }));

    const {search} = useLocation();
    const queryString = parseQueryString(search);
    const startDate = get(queryString, 'startDate', null);
    const endDate = get(queryString, 'endDate', null);
    const searchInput = get(queryString, 'search', null);

    useEffect(() => {
        // 取得預先下特商品列表
        dispatch(PreSaleAction.fetchPreSale({startDate, endDate, model: searchInput}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, searchInput]);

    /**
     *  搜尋商品
     */
    const handleSearch = (formData: any) => {
        setVisibleSearch(false);
        history.push(`/profile/advanceOrder?startDate=${formData?.startDate ?? ''}&endDate=${formData?.endDate ?? ''}&search=${formData?.search ?? ''}`);
    };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/account')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>預先下特</MobileTitle>

                    <IconButton onClick={() => setVisibleSearch(true)}>
                        <Icon code="search" size={20} color="#282731"/>
                    </IconButton>
                </MobileHeader>
            </Container>

            <form onSubmit={handleSubmit(handleSearch)}>

                {/* 搜尋 */}
                <Search
                    isVisible={isVisibleSearch}
                    onClose={() => setVisibleSearch(false)}
                    useFormParams={useFormParams}
                    placeholder="透過商品貨號搜尋"
                />

                <AdvanceOrderContainer>
                    {/* 標題 */}
                    <Header className="d-none d-md-flex">
                        <NumSection>項</NumSection>
                        <ProductInfo>商品貨號/品牌</ProductInfo>
                        <Category>顏色</Category>
                        <Size>SIZE</Size>
                        <Price>單價</Price>
                        <Time>預購時間</Time>
                        <Status>狀態</Status>
                    </Header>

                    {/* 讀取狀態 */}
                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}

                    {/* 明細 */}
                    {!isFetching && preSaleList.map((row, index) => (
                        <Content key={row.id}>
                            <TopSection className="d-md-none">
                                <StatusButton themeType={get(statusInfo, row.status.code).theme} text={get(row, 'status.name')}/>
                                <MobileAmount>$ {formatCurrency(get(row, 'productGoods.price'))}</MobileAmount>
                            </TopSection>

                            <DetailContent className="d-flex flex-column flex-md-row">
                                {/* 項目 */}
                                <NumSection className="d-none d-md-flex" isBlod>{index + 1}</NumSection>

                                {/* 商品 */}
                                <ProductInfo>
                                    <ProductImg className="d-none d-md-block" src={isEmpty(get(row, 'productGoods.thumbUrl')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>

                                    <div className="w-100 d-flex flex-column align-items-center justify-content-center align-items-md-start">
                                        <Model onClick={() => window.open(`/product/detail/${payload?.id}/${row.productGoods.id}`)}>{get(row, 'productGoods.model')}</Model>
                                        <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                                        <ProductName>{get(row, 'productGoods.name')}</ProductName>
                                        <SubName>{get(row, 'productGoods.subName')}</SubName>
                                    </div>
                                </ProductInfo>

                                <div className="d-flex d-md-none">
                                    <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>
                                    <FieldTitleSection>
                                        <FieldLine>
                                            <FieldTitle>顏色</FieldTitle>
                                            <FieldValue>{get(row, 'productGoods.color')}</FieldValue>
                                        </FieldLine>
                                        <FieldLine>
                                            <FieldTitle>SIZE</FieldTitle>
                                            <FieldValue>{get(row, 'sizeQty')}</FieldValue>
                                        </FieldLine>
                                        <FieldLine>
                                            <FieldTitle>單價</FieldTitle>
                                            <FieldValue>$ {formatCurrency(get(row, 'productGoods.price'))}</FieldValue>
                                            <FieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                                        </FieldLine>
                                        <FieldLine>
                                            <FieldTitle>預購時間</FieldTitle>
                                            <FieldValue>2019-10-14 22:36:49</FieldValue>
                                        </FieldLine>
                                    </FieldTitleSection>
                                </div>

                                {/* 類別 & 顏色 */}
                                <Category className="d-none d-md-flex flex-column justify-content-center align-items-start">
                                    <FieldValue>{get(row, 'productGoods.color')}</FieldValue>
                                </Category>

                                {/* SIZE */}
                                <Size className="d-none d-md-flex">
                                    <FieldValue>{get(row, 'sizeQty')}</FieldValue>
                                </Size>

                                {/* 單價 */}
                                <Price className="d-none d-md-flex flex-column justify-content-center">
                                    <FieldValue isBlod>$ {formatCurrency(get(row, 'productGoods.price'))}</FieldValue>
                                    <FieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                                </Price>

                                {/* 預購時間 */}
                                <Time className="d-none d-md-flex">
                                    <FieldValue>{dayjs(get(row, 'createdAt')).format('YYYY-MM-DD HH:mm:ss')}</FieldValue>
                                </Time>

                                {/* 狀態 */}
                                <Status className="d-none d-md-flex">
                                    <StatusButton themeType={get(statusInfo, row.status.code).theme} text={get(row, 'status.name')}/>
                                </Status>

                            </DetailContent>
                        </Content>
                    ))}
                </AdvanceOrderContainer>
            </form>
        </>
    );
};

export default AdvanceOrder;

const StatusButton = styled(StatusTag)<any>`
    width: 64px;
    height: 20px;

    ${media.md`
        width: 90px;
        height: 32px;
        border-radius: 4px;
    `}
`;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isBlod && css`
        font-weight: bold;
    `}

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const FieldTitle = styled.span`
    width: 62px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
`;

const FieldLine = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 2px;
`;

const FieldTitleSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const SubName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const BrandName = styled.span`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const Model = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #6e6e6e;
    cursor: pointer;

    ${media.md`
        font-size: 16px;
        padding-bottom: 6px;
    `}
`;

const ProductImg = styled.img`
    width: 100px;
    height: 100px;
    margin-right: 6px;
    background-color: lightgray;

    ${media.md`
        width: 70px;
        height: 80px;
        margin-right: 10px;
    `}
`;

const MobileAmount = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #ff7d6a;
`;

const TopSection = styled.div`
    width: 100%;
    height: 30px;
    background-color: #fcfcfc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
`;

const Content = styled.div`
    width: 100%;
    min-height: 210px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    position: relative;

    ${media.md`
        width: 1120px;
        min-height: auto;
        box-shadow: none;
        border: none;
        border-bottom: solid 1px #edeff2;
        padding: 10px 0;
        margin-bottom: 0;
        flex-direction: row;
        align-items: center;

        &:nth-last-child(1) {
            margin-bottom: 50px;
        }
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const Status = styled(HeaderFieldName)`
    ${media.md`
        min-width: 126px;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
    `}

    ${media.xxl`
        min-width: 180px;
    `}
`;

const Time = styled(HeaderFieldName)`
    min-width: 160px;

    ${media.xxl`
        min-width: 176px;
    `}
`;

const Price = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        justify-content: center;
        min-width: 90px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 146px;
    `}
`;

const Size = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        width: 200px;
        padding-bottom: 0;
        word-break: break-all;
    `}

    ${media.xxl`
        width: 273px;
    `}
`;

const Category = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 100px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 174px;
    `}
`;

const ProductInfo = styled(HeaderFieldName)`
    margin-bottom: 8px;

    ${media.md`
        border-bottom: solid 1px #f2f2f2;
        width: 368px;
        word-break: break-all;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        padding-right: 10px;
    `}
`;

const NumSection = styled(HeaderFieldName)<any>`
    display: flex;
    justify-content: center;

    ${media.md`
        min-width: 50px;
        position: initial;
        top: auto;
        left: auto;
    `}

    ${media.xxl`
        min-width: 96px;
    `}

    ${props => props.isBlod && css`
        font-weight: bold;
    `}
`;

const DetailContent = styled.div`
    padding: 10px;

    ${media.md`
        padding: 0;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: solid 1px #edeff2;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const AdvanceOrderContainer = styled(Container)`
    ${media.md`
        overflow-x: auto;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
