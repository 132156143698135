// Libraries
import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import {History} from 'history';
import createApiMiddleware from 'library/apisauce/createApiMiddleware';

// Setting
import createReducer from './createReducer';
import {IStore} from './types';
import sagaManager from './sagaManager';


/**
 * Create the store with dynamic reducers
 */
export default function configureStore(initialState = {}, history: History) {
    const isDev = process.env.NODE_ENV !== 'production';
    const sagaMiddleware = createSagaMiddleware();

    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const enhancers = [applyMiddleware(...middlewares)];

    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    const composeEnhancers = (isDev && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

    const store: IStore = createStore(
        createReducer(),
        initialState,
        composeEnhancers(...enhancers)
    );

    // init api middleware
    createApiMiddleware(store.getState, store.dispatch);

    // Create an object for any later reducers
    store.asyncReducers = {};
    store.asyncSagas = {};

    // install Saga
    sagaManager.runSagas(sagaMiddleware);


    if (isDev && module.hot) {
        module.hot.accept('./createReducer', () => {
            store.replaceReducer(createReducer(store.asyncReducers));
        });
        module.hot.accept('../../store/rootSaga', () => {
            sagaManager.cancelSagas(store);
            const nextSagas = require('./sagaManager');
            nextSagas.runSagas(sagaMiddleware);
        });
    }


    return store;
}
