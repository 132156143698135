// Libraries & Utils
import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {hot} from "react-hot-loader";

// Components
import NotFound from 'views/NotFound';
import HomeLayout from 'views/HomeLayout';
import CheckIn from 'views/CheckIn';


const Router: React.FC = () => {
    return (
        <Switch>
            <Route path="/sign/checkIn/:token" component={CheckIn}/>
            <Route path="/" component={HomeLayout}/>

            <Route path="*" component={NotFound}/>
        </Switch>
    );
};

export default hot(module)(Router);
