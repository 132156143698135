// Libraries & Utils
import {all, call, delay, put, takeLatest, select} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';
import dayjs from 'dayjs';

// Reducers & Types
import {Actions, ActionTypes, Selector} from './reducer';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetCategory, TGetContent} from 'services/message';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {Actions as ProfileActions} from 'store/profile';


/**
 * 取訊息類別列表
 */
function* fetchCategory(action: IPickAction<IActionCreators, 'fetchCategory'>) {
    yield put(Actions.fetchCategoryBegin());

    try {
        const [{body}]: [TGetCategory] = yield all([
            call(ApiService.getCategory),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows} = body.data;
        yield put(Actions.fetchCategorySuccess({messageCategory: rows}));

    } catch (err) {
        yield put(Actions.fetchCategoryFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得訊息內容
 */
function* fetchContent(action: IPickAction<IActionCreators, 'fetchContent'>) {
    yield put(Actions.fetchContentBegin());

    try {
        const {categoryId} = action.payload;
        const [{body}]: [TGetContent] = yield all([
            call(ApiService.getContent, categoryId),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, messageUnreadCount, isReadonly} = body.data;
        yield put(Actions.fetchContentSuccess({messageContent: rows, isReadonly}));

        // 設定未讀訊息筆數
        yield put(ProfileActions.setMessageItem({messageUnreadCount: messageUnreadCount ?? 0}));
    } catch (err) {
        yield put(Actions.fetchContentFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 送出訊息
 */
function* submitMessage(action: IPickAction<IActionCreators, 'submitMessage'>) {
    yield put(Actions.submitMessageBegin());

    try {
        const {categoryId, content, image, imagePreviewUrl} = action.payload;

        // 複製列表 並新增一筆新訊息
        let paginateData = Selector.messageContent(yield select());
        let cloneData = [...paginateData];

        cloneData[cloneData.length] = {
            id: cloneData.length,
            content: content,
            imageUrl: String(imagePreviewUrl),
            isMemberPost: true,
            isRead: true,
            createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            isSubmitting: true,
        };

        yield put(Actions.submitMessageSuccess({messageContent: cloneData}));



        // 複製類別 並修改該類別最新一筆內容
        let messageCategory = Selector.messageCategory(yield select());
        let cloneCategoryData = [...messageCategory];
        const currentIndex = cloneCategoryData.findIndex(row => row.id === categoryId);
        cloneCategoryData[currentIndex] = {
            ...messageCategory[currentIndex],
            lastContent: content,
        };
        yield put(Actions.fetchCategorySuccess({messageCategory: cloneCategoryData}));


        yield all([
            call(ApiService.postMessage, categoryId, content, image),
            delay(fetchDataDelayMinMs),
        ]);


        // api成功送出後，將該筆資料送出狀態取消
        cloneData[cloneData.length - 1] = {
            ...cloneData[cloneData.length - 1],
            isSubmitting: false,
        };

        yield put(Actions.submitMessageSuccess({messageContent: cloneData}));
    } catch (err) {
        yield put(Actions.submitMessageFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

export default [
    takeLatest(ActionTypes.FETCH_CATEGORY, fetchCategory),
    takeLatest(ActionTypes.FETCH_CONTENT, fetchContent),
    takeLatest(ActionTypes.SUBMIT_MESSAGE, submitMessage),
];
