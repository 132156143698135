import * as React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import get from 'lodash/get';
import {media} from 'library/styled-bs-grid';
import site from 'config/site';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    children?: React.ReactNode;
    themeType?: 'default' | 'danger' | 'purple' | 'pureBorder' | 'green' | 'greenBorder' | 'whiteBorderBlur' | 'whiteBorder' | 'gray' | 'grayBorder' | 'grayDisabled' | 'darkGrayBorderFill' | 'defaultBorderFill';
    size?: 'default' | 'small' | 'middle' | 'large';
    shape?: 'default' | 'circle' | 'raised';
    isBlock?: boolean;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    disabledShadow?: boolean;
    isBlurBg?: boolean;
    onClick?: Function;
}

const themeConfig = {
    default: {
        bgColor: site.theme.primaryColor,
        borderColor: site.theme.primaryColor,
        fontColor: '#fff',
        minHeight: undefined,
        hoverBorderColor: 'transparent',
        hoverBoxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    },
    danger: {
        bgColor: '#ff7d6a',
        borderColor: '#ff7d6a',
        fontColor: '#fff',
        minHeight: undefined,
        hoverBorderColor: 'transparent',
        hoverBoxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    },
    purple: {
        bgColor: site.theme.thirdColor,
        borderColor: site.theme.thirdColor,
        fontColor: '#fff',
        minHeight: undefined,
        hoverBorderColor: 'transparent',
        hoverBoxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    },
    pureBorder: {
        bgColor: 'transparent',
        borderColor: '#9d9a9a',
        fontColor: '#9d9a9a',
        minHeight: undefined,
        hoverBorderColor: site.theme.thirdColor,
        hoverFontColor: site.theme.thirdColor,
        hoverBoxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    },
    green: {
        bgColor: site.theme.secondColor,
        borderColor: site.theme.secondColor,
        fontColor: '#fff',
        minHeight: undefined,
        hoverBorderColor: site.theme.secondColor,
        hoverBoxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    },
    greenBorder: {
        bgColor: 'transparent',
        borderColor: '#d8d8d8',
        fontColor: '#9d9a9a',
        minHeight: undefined,
        hoverBorderColor: site.theme.secondColor,
        hoverBoxShadow: 'none',
    },
    whiteBorderBlur: {
        bgColor: 'transparent',
        borderColor: '#ffffff',
        fontColor: '#fff',
        minHeight: undefined,
        hoverBgColor: 'rgba(255, 255, 255, 0.6)',
        hoverBorderColor: '#ffffff',
        hoverBoxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
        hoverFontColor: '#282731',
    },
    whiteBorder: {
        bgColor: 'transparent',
        borderColor: '#fff',
        fontColor: '#fff',
        minHeight: undefined,
    },
    gray: {
        bgColor: '#d8d8d8',
        borderColor: '#d8d8d8',
        fontColor: '#fff',
        minHeight: undefined,
        hoverBorderColor: 'transparent',
        hoverBoxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    },
    grayBorder: {
        bgColor: 'transparent',
        borderColor: '#e8e8e8',
        fontColor: '#9d9a9a',
        minHeight: undefined,
    },
    grayDisabled: {
        bgColor: 'transparent',
        borderColor: '#d8d8d8',
        fontColor: '#d8d8d8',
        minHeight: undefined,
    },
    darkGrayBorderFill: {
        bgColor: 'transparent',
        borderColor: '#9d9a9a',
        fontColor: '#9d9a9a',
        minHeight: undefined,
        hoverBgColor: '#fbfafa',
        hoverBorderColor: '#fbfafa',
        hoverFontColor: '#9d9a9a',
    },
    defaultBorderFill: {
        bgColor: 'transparent',
        borderColor: site.theme.primaryColor,
        fontColor: site.theme.primaryColor,
        minHeight: undefined,
        hoverBgColor: '#f4fafe',
        hoverBorderColor: '#f4fafe',
        hoverFontColor: site.theme.primaryColor,
    },
};

const sizeConfig = {
    default: {
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 56,
    },
    small: {
        fontSize: 16,
        fontWeight: 'normal',
        minHeight: 40,
    },
    middle: {
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 46,
    },
    large: {
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 100,
    },
};

const shapeConfig = {
    default: {
        shape: '5px',
    },
    raised: {
        shape: 0,
    },
    circle: {
        shape: '18px',
    },
};

/**
 * Button 一般按鈕
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Button: React.FC<IProps> = ({
    className,
    style,
    children,
    themeType = 'default',
    size = 'default',
    shape = 'default',
    type = 'button',
    isBlock = false,
    disabled = false,
    disabledShadow = false,
    isBlurBg = false,
    onClick = () => {},
}) => {
    const activeTheme = get(themeConfig, themeType, {});
    const activeSizeConfig = get(sizeConfig, size, {});
    const activeShapeConfig = get(shapeConfig, shape, {});

    return (
        <ButtonRoot
            className={className}
            type={type}
            style={style}
            baseTheme={{...activeTheme, ...activeSizeConfig, ...activeShapeConfig}}
            isBlock={isBlock}
            isBlurBg={isBlurBg}
            disabled={disabled}
            disabledShadow={disabledShadow}
            onClick={onClick}
        >
            {children}
        </ButtonRoot>
    );
};

export default Button;

const ButtonRoot = styled.button<any>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    transition: background-color .3s ease-out, color .3s ease-out, border-color .3s ease-out, box-shadow .3s ease-out;
    padding: ${props => props.baseTheme.padding || '2px 15px'};

    width: ${props => (props.isBlock ? '100%' : 'auto')};
    max-width: 100%;

    background-color: ${props => props.baseTheme.bgColor};
    color: ${props => props.baseTheme.fontColor};
    border: solid 1px ${props => props.baseTheme.borderColor};
    border-radius: ${props => props.baseTheme.shape};
    font-size: ${props => props.baseTheme.fontSize}px;
    font-weight: ${props => props.baseTheme.fontWeight};

    ${props => props.baseTheme.minHeight && css`
        min-height: ${props.baseTheme.minHeight}px;
    `}


    &:disabled,
    &[disabled]{
      border: none;
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff;
    };

    &:not([disabled]):hover {
        background-color: ${props => props.baseTheme.hoverBgColor};
        border-color: ${props => props.baseTheme.hoverBorderColor};
        color: ${props => props.baseTheme.hoverFontColor};
        box-shadow: ${props => props.baseTheme.hoverBoxShadow || '0 0 20px 0 rgba(0, 0, 0, 0.2)'};

        ${props => props.isBlurBg && css`
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
        `};

        ${props => props.disabledShadow && css`
            box-shadow: none;
        `};
    }

    ${media.lg`
        padding: 0 15px;

        font-size: ${(props: any) => props.baseTheme.fontSize}px;
        ${(props: any) => props.baseTheme.minHeight && css`
            min-height: ${props.baseTheme.minHeight}px;
        `}


        // fix ie11
        &:after{
            content:'';
            min-height:inherit;
            font-size:0;
        }
    `}
`;

