import React, {useContext, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import styled, {css} from 'styled-components';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {addressList} from 'config/address';
import {checkIsMobile} from 'utils/browser';
import {isEmpty} from 'utils/equal';
import get from 'lodash/get';

// Component
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Button from 'components/atoms/Button';
import RadioButton from 'components/atoms/RadioButton';
import Checkbox from 'components/atoms/Checkbox';

// Stores
import {Actions as AuthAction} from 'store/auth';
import {StoreContext} from '../FormContext';


/**
 * 註冊：步驟二
 * @constructor
 */
const Step2: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {handleSubmit, watch, setValue, control} = useForm();
    const {isSame, storeCity, storeTypeId, isBuyOtherStoreBefore} = watch(['isSame', 'storeCity', 'storeTypeId', 'isBuyOtherStoreBefore']);

    const {formTempState} = useContext(StoreContext);

    useEffect(() => {
        if (isEmpty(get(formTempState, 'step1Data.account'))) {
            history.push('/signUp/1');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 地址與配送地址相同
    useEffect(() => {
        if (isSame === '1') {
            setValue('storeCity', get(formTempState, 'step1Data.deliveryCity'));
            setValue('storeTownship', get(formTempState, 'step1Data.deliveryTownship'));
            setValue('postalCode', get(formTempState, 'step1Data.postalCode'));
            setValue('storeAddress', get(formTempState, 'step1Data.deliveryAddress'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSame]);


    /**
     * 取得縣市資料
     */
    const countyOptions = () => {
        return addressList.map(row => (
            {value: row.counties, text: row.counties}
        ));
    };

    /**
     * 取得鄉鎮資料
     */
    const townshipOptions = () => {
        const town = addressList.find(o => o.counties === storeCity);
        if (town) {
            return town.districts.map(row => (
                {value: row, text: row}
            ));
        } else {
            return [{value: '', text: '請選擇'}];
        }
    };

    /**
     * 送出
     */
    const onSubmit = (formData: any) => {
        dispatch(AuthAction.signUp({
            ...formTempState.step1Data,
            storeTypeId: Number(formData.storeTypeId),
            storeName: formData.storeName,
            storeWebsite: formData.storeWebsite,
            storeCity: Number(formData.storeTypeId) === 2 ? formData.storeCity : '',
            storeTownship: Number(formData.storeTypeId) === 2 ? formData.storeTownship : '',
            storeAddress: Number(formData.storeTypeId) === 2 ? formData.storeAddress : '',
            isBuyOtherStoreBefore: formData.isBuyOtherStoreBefore === '1',
            otherStoreName: formData.isBuyOtherStoreBefore === '1' ? formData.otherStoreName : '',
            otherStoreWebsite: formData.isBuyOtherStoreBefore === '1' ? formData.otherStoreWebsite : '',
        }));
    };

    return (
        <SignUpRoot>
            {/* Mobile Header */}
            <SignUpContainer className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/signUp/1')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>
                    <MobileTitle>驗証店家資料</MobileTitle>
                    <IconButton disabled/>
                </MobileHeader>
            </SignUpContainer>

            {/* PC Header */}
            <HeaderBg className="d-none d-md-flex">
                <HeaderContainer>
                    <HeaderRow className="d-flex align-items-end">
                        <HeaderCol col>
                            <HeaderBgDesc>Home / 註冊帳號 / 驗證店家</HeaderBgDesc>
                        </HeaderCol>
                        <HeaderCol col className="d-flex flex-column align-items-center">
                            <ChineseTitle>驗證店家資料</ChineseTitle>
                            <HeaderBgDesc>Verify store information</HeaderBgDesc>
                        </HeaderCol>
                        <HeaderCol col/>
                    </HeaderRow>
                </HeaderContainer>
            </HeaderBg>

            <form onSubmit={handleSubmit(onSubmit)}>
                <SignUpContainer>
                    <SignUpRow>
                        <Controller
                            control={control}
                            name="storeTypeId"
                            rules={{required: true}}
                            as={<RadioCol col="auto">
                                <PhysicalRadioButton
                                    name="storeTypeId"
                                    text="實體店家資料"
                                    value={2}
                                />
                            </RadioCol>}
                        />
                        <Controller
                            control={control}
                            name="storeTypeId"
                            rules={{required: true}}
                            as={<RadioCol col className="d-lg-none">
                                <RadioButton
                                    name="storeTypeId"
                                    text="網路店家資料"
                                    value={3}
                                />
                            </RadioCol>}
                        />
                    </SignUpRow>

                    {/* 實體店家資料 */}
                    <PhysicalSection isVisible={storeTypeId === '2'}>
                        <SignUpRow>
                            <SignUpCol col={24} md={12}>
                                <Controller
                                    control={control}
                                    name="storeName"
                                    rules={{required: storeTypeId === '2'}}
                                    as={<CustomInput
                                        placeholder="銷售店名"
                                    />}
                                />
                            </SignUpCol>
                            <SignUpCol col={24} md={12}>
                                <Controller
                                    control={control}
                                    name="storeWebsite"
                                    rules={{required: storeTypeId === '2'}}
                                    as={<CustomInput
                                        placeholder="銷售網址"
                                        remarkMessage="ex: name@example.com"
                                    />}
                                />
                            </SignUpCol>
                        </SignUpRow>

                        <Controller
                            name="isSame"
                            control={control}
                            valueName="checked"
                            as={<CustomCheckbox
                                text="地址與配送地址相同"
                                value="1"
                            />}
                            defaultValue={undefined}
                        />
                        <SignUpRow>
                            <SignUpCol col={24} md={8}>
                                <Controller
                                    control={control}
                                    name="storeCity"
                                    as={<CustomSelect
                                        title="縣/市"
                                        option={countyOptions()}
                                    />}
                                    onChange={event => {
                                        setValue('storeTownship', '');
                                        setValue('postalCode', '-');
                                        return event[0].target.value;
                                    }}
                                />
                            </SignUpCol>
                            <SignUpCol col={24} md={8}>
                                <Controller
                                    control={control}
                                    name="storeTownship"
                                    as={<CustomSelect
                                        title="鄉鎮市區"
                                        option={townshipOptions()}
                                    />}
                                    onChange={event => {
                                        setValue('postalCode', event[0].target.value.substr(0 ,3));
                                        return event[0].target.value;
                                    }}
                                />
                            </SignUpCol>
                            <SignUpCol col={24} md={8}>
                                <Controller
                                    control={control}
                                    name="postalCode"
                                    as={<CustomInput
                                        placeholder="郵遞區號"
                                        readonly
                                        defaultValue="-"
                                    />}
                                />
                            </SignUpCol>
                            <SignUpCol col={24}>
                                <Controller
                                    control={control}
                                    name="storeAddress"
                                    rules={{required: storeTypeId === '2'}}
                                    as={<CustomInput
                                        placeholder="地址"
                                    />}
                                />
                            </SignUpCol>
                        </SignUpRow>
                    </PhysicalSection>


                    {/* 電腦版網路店家資料 */}
                    <PcRadioRow className="d-none d-lg-flex">
                        <Controller
                            control={control}
                            name="storeTypeId"
                            rules={{required: true}}
                            as={<RadioCol col>
                                <RadioButton
                                    name="storeTypeId"
                                    text="網路店家資料"
                                    value={2}
                                />
                            </RadioCol>}
                        />
                    </PcRadioRow>
                    <OnlineRow
                        className="d-none d-lg-flex"
                        isVisible={storeTypeId === '3'}
                    >
                        {(storeTypeId === '3' && !checkIsMobile()) && (
                            <>
                                <SignUpCol col={24} md={12}>
                                    <Controller
                                        control={control}
                                        name="storeName"
                                        rules={{required: storeTypeId === '3'}}
                                        as={<CustomInput
                                            placeholder="銷售店名"
                                        />}
                                    />
                                </SignUpCol>
                                <SignUpCol col={24} md={12}>
                                    <Controller
                                        control={control}
                                        name="storeWebsite"
                                        rules={{required: storeTypeId === '2'}}
                                        as={<CustomInput
                                            placeholder="銷售網址"
                                            remarkMessage="ex: name@example.com"
                                        />}
                                    />
                                </SignUpCol>
                            </>
                        )}
                    </OnlineRow>


                    {/* 您是否曾在其他店家批貨呢 */}
                    <SignUpRow>
                        <TitleCol col={24} xl="auto">
                            <SectionTitle>您是否曾在其他店家批貨呢？</SectionTitle>
                        </TitleCol>
                        <Controller
                            control={control}
                            name="isBuyOtherStoreBefore"
                            rules={{required: true}}
                            as={<RadioCol col="auto" className="d-flex align-items-lg-center align-items-xl-end">
                                <StoreRadioButton
                                    name="isBuyOtherStoreBefore"
                                    text="是"
                                    value={1}
                                />
                                <RadioDesc>(增快審核時間)</RadioDesc>
                            </RadioCol>}
                        />
                        <Controller
                            control={control}
                            name="isBuyOtherStoreBefore"
                            rules={{required: true}}
                            as={<RadioCol col className="d-lg-flex align-items-xl-end">
                                <RadioButton
                                    name="isBuyOtherStoreBefore"
                                    text="否"
                                    value={0}
                                />
                            </RadioCol>}
                        />
                    </SignUpRow>
                    <OtherStoreRow isVisible={isBuyOtherStoreBefore === '1'}>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="otherStoreName"
                                rules={{required: isBuyOtherStoreBefore === '1'}}
                                as={<CustomInput
                                    placeholder="店家名稱"
                                />}
                            />
                        </SignUpCol>
                        <SignUpCol col={24} md={12}>
                            <Controller
                                control={control}
                                name="otherStoreWebsite"
                                rules={{required: isBuyOtherStoreBefore === '1'}}
                                as={<CustomInput
                                    placeholder="店家網址"
                                    remarkMessage="ex: neme@example.com"
                                />}
                            />
                        </SignUpCol>
                    </OtherStoreRow>


                    <SubmitButton
                        type="submit"
                    >
                        完成註冊
                    </SubmitButton>
                </SignUpContainer>
            </form>
        </SignUpRoot>
    );
};

export default Step2;

const SubmitButton = styled(Button)`
    width: 240px;
    height: 44px;
    font-size: 14px;
    min-height: auto;
    align-self: center;
    margin: 10px auto 20px auto;

    ${media.md`
        margin: 40px auto 100px auto;
    `}

    ${media.xl`
        font-size: 16px;
        width: 300px;
        height: 56px;
    `}
`;

const SectionTitle = styled.div`
    font-size: 14px;
    color: #6e6e6e;
    margin: 10px 0;

    ${media.lg`
        margin: 50px 0 10px 0;
        font-size: 20px;
        font-weight: 600;
    `}

    ${media.xl`
        margin-right: 40px;
    `}
`;

const TitleCol = styled(Col)`
    ${media.md`
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

const CustomSelect = styled(Select)`
    margin-bottom: 10px;
`;

const CustomCheckbox = styled(Checkbox)`
    margin-top: 10px;
    margin-bottom: 6px;
`;

const CustomInput = styled(Input)`
    margin-bottom: 10px;
`;

const ChineseTitle = styled.span`
    font-size: 30px;
    font-weight: 600;
    color: #3a394b;
    margin-bottom: 12px;

    ${media.xl`
        font-size: 40px;
        font-weight: bold;
    `}
`;

const HeaderBgDesc = styled.span`
    font-size: 14px;
    text-align: center;
    color: #c1bfbf;

    ${media.xl`
        font-size: 16px;
    `}
`;

const HeaderCol = styled(Col)`
    padding: 0;
`;

const HeaderRow = styled(Row)`
    padding: 20px 0 22px 0;
    margin: 0;
`;

const HeaderContainer = styled(Container)`
    padding: 0;
`;

const HeaderBg = styled.div`
    width: 100%;
    height: 112px;
    background-color: #fbfbfb;
    margin-bottom: 35px;

    ${media.xl`
        height: 133px;
        margin-bottom: 50px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SignUpCol = styled(Col)`
    ${media.md`
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

const RadioDesc = styled.div`
    font-size: 14px;
    color: #6e6e6e;
    margin-left: 2px;

    ${media.md`
        margin-right: 10px;
    `}

    ${media.xl`
        margin: 0 20px 2px 6px;
    `}
`;

const StoreRadioButton = styled(RadioButton)`

`;

const PhysicalRadioButton = styled(RadioButton)`
    margin-right: 30px;
`;

const RadioCol = styled(Col)`
    margin-bottom: 10px;

    ${media.md`
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

const SignUpRow = styled(Row)`
    ${media.md`
        margin-left: -5px;
        margin-right: -5px;
    `}
`;

const OtherStoreRow = styled(SignUpRow)<any>`
    max-height: 0;
    overflow: hidden;
    transition: max-height ease .3s;

    ${props => props.isVisible && css`
        max-height: 100vh;
    `}
`;

const OnlineRow = styled(SignUpRow)<any>`
    max-height: 0;
    overflow: hidden;
    transition: max-height ease .3s;

    ${props => props.isVisible && css`
        max-height: 100vh;
    `}
`;

const PhysicalSection = styled.div<any>`
    ${media.lg`
        max-height: 0;
        overflow: hidden;
        transition: max-height ease .3s;

        ${(props: any) => props.isVisible && css`
            max-height: 100vh;
        `}
    `}
`;

const PcRadioRow = styled(SignUpRow)`
    ${media.lg`
        margin-top: 10px;
    `}
`;

const SignUpContainer = styled(Container)`
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;

    ${media.md`
        padding-left: 0;
        padding-right: 0;
        max-width: 600px;
    `}

    ${media.xl`
        max-width: 750px;
    `}
`;

const SignUpRoot = styled.div``;
