import React from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import get from 'lodash/get';
import {Container, Row, Col, media,} from 'library/styled-bs-grid';
import ProfileTabs from 'components/atoms/ProfileTabs';
import Router from './Router';

const Profile: React.FC = () => {
    const menuList = [
        {code: 0, name: '我的帳戶', iconCode: 'user-circle', href: '/profile/account', isMobileVisible: false},
        {code: 3, name: '預先下特', iconCode: 'clipboard-list-check', href: '/profile/advanceOrder', isMobileVisible: true, order: 1},
        {code: 4, name: '我的訂單', iconCode: 'clipboard-list', href: '/profile/order', isMobileVisible: true, order: 3},
        {code: 5, name: '備貨狀態', iconCode: 'box', href: '/profile/stockUp', isMobileVisible: true, order: 4},
        {code: 6, name: '出貨明細', iconCode: 'truck', href: '/profile/ship', isMobileVisible: true, order: 5},
        {code: 7, name: '匯款通知', iconCode: 'clipboard-list-sync', href: '/profile/remittance', isMobileVisible: true, order: 6},
        {code: 8, name: '餘額紀錄', iconCode: 'clipboard-list-clock', href: '/profile/trade', isMobileVisible: true, order: 7},
    ];

    const {pathname} = useLocation();
    const activeMenu = menuList.find(row => row.href === pathname);

    return (
        <ProfileRoot>
            {/* Header */}
            <Header className="d-none d-md-flex flex-column align-items-center justify-content-center">
                <Title>我的帳戶</Title>

                <Container>
                    <Row>
                        <Col col={8}>
                            <Breadcrumbs>Home / 我的帳戶</Breadcrumbs>
                        </Col>
                        <Col col={8} className="d-flex justify-content-center">
                            <EnglishTitle>My Account</EnglishTitle>
                        </Col>
                        <Col col={8} className="d-flex justify-content-end">
                            <Desc>＊＊資料保留期間：半年內</Desc>
                        </Col>
                    </Row>
                </Container>
            </Header>

            {/* ProfileTabs */}
            <TabsSection className="d-none d-md-block">
                <Container>
                    <ProfileTabs
                        className="d-xxl-flex justify-content-xxl-center"
                        item={menuList}
                        activeCode={get(activeMenu, 'code', 0)}
                    />
                </Container>
            </TabsSection>

            <Router/>
        </ProfileRoot>
    );
};

export default Profile;

const TabsSection = styled.div`
    border-bottom: solid 1px #f2f4f9;
    margin-bottom: 30px;
`;

const Desc = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.xl`
        font-size: 16px;
    `}
`;

const EnglishTitle = styled.span`
    font-size: 14px;
    color: #9d9a9a;
    text-align: center;

    ${media.xl`
        font-size: 16px;
    `}
`;

const Breadcrumbs = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.xl`
        font-size: 16px;
    `}
`;

const Title = styled.span`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #282731;
    padding-bottom: 12px;
`;

const Header = styled.div`
    width: 100%;
    height: 112px;
    background-color: #fbfbfb;
    margin-bottom: 20px;

    ${media.xl`
        height: 132px;
    `}
`;

const ProfileRoot = styled.div``;
