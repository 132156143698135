// Libraries & Utils
import Immutable from 'seamless-immutable';
import {TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'track';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    isSubmitting: false,
    message: '',

    trackProduct: [],
    trackPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        pageLimit: 0,
    },
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得追蹤商品清單
     */
    fetchTrackProduct: (state) => {
        return state;
    },
    fetchTrackProductBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取追蹤商品清單'});
    },
    fetchTrackProductSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', trackProduct: payload.trackProduct, trackPagination: payload.trackPagination});
    },
    fetchTrackProductFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 清空追蹤商品
     */
    clearTrackProduct: (state) => {
        return state;
    },
    clearTrackProductBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在清空追蹤商品'});
    },
    clearTrackProductSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    clearTrackProductFail: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    /**
     * 刪除多筆追蹤商品
     */
    deleteTrackProduct: (state) => {
        return state;
    },
    deleteTrackProductBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在刪除追蹤商品'});
    },
    deleteTrackProductSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    deleteTrackProductFail: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    /**
     * 新增 商品到追蹤清單
     */
    putTrackProduct: (state) => {
        return state;
    },
    putTrackProductBegin: (state) => {
        return state.merge({isFetching: true, message: '正在新增商品至追蹤清單'});
    },
    putTrackProductSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    putTrackProductFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
