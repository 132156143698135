import React, {useState, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import dayjs from 'dayjs';
import {isEmpty} from 'utils/equal';
import Icon from 'components/atoms/Icon';
import {paddingLeft} from 'utils/format';
import get from 'lodash/get';

interface IProps {
    isSetTodayVisible?: boolean;
    showTime?: boolean;
    value?: string;
    name?: string;
    forwardRef?: any;
    onChange: Function;
    onClose?: Function;
}

const config = {
    weekDay: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    monthA: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

/**
 * 產生 時、分、秒陣列
 */
const getTimeData = () => {
    const hourList = [];
    const minuteList = [];
    const secondList = [];

    let number = '';
    for (let i = 0; i < 24; i += 1) {
        number = paddingLeft(i, 2).toString();
        hourList.push(number);
    }

    for (let i = 0; i < 60; i += 1) {
        number = paddingLeft(i, 2).toString();
        minuteList.push(number);
    }

    for (let i = 0; i < 60; i += 1) {
        number = paddingLeft(i, 2).toString();
        secondList.push(number);
    }

    return {hourList, minuteList, secondList};
};

const getLocaleMonth = () => config.month.map((m: any, index) => (
    <option value={index} key={`month-${String(index)}`}>{m}</option>
));

/**
 * 取得 value的 Dayjs 物件
 * @param sourceDate
 * @returns {dayjs.Dayjs}
 */
const getConvertDayjs = (sourceDate: any) => dayjs(sourceDate);

/**
 * DatePicker
 * 日期選擇器
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const DateTimePicker: React.FC<IProps> = ({
    value = undefined,
    onChange,
    onClose = () => {},
    name = undefined,
    forwardRef = () => {},
    isSetTodayVisible = false,
    showTime = false,
}) => {
    // 取得今天日期
    const today = dayjs();

    // 日期格式
    let format = 'YYYY-MM-DD';
    if (showTime) {
        format = 'YYYY-MM-DD HH:mm:ss';
    }

    const [inputValue, setInputValue]: any = useState('');
    const [panelYearMonth, setPanelYearMonth]: any = useState(value ? dayjs(value) : today);

    const localeMonth = getLocaleMonth();

    const hourBoxRef: any = useRef();
    const minuteBoxRef: any = useRef();
    const secondBoxRef: any = useRef();
    const inputRef: any = useRef(value);

    const time = '';
    const {hourList, minuteList, secondList} = getTimeData();

    const [hour, setHour] = useState(value ? get(time, '0', today.format('HH')) : '00');
    const [minute, setMinute] = useState(value ? get(time, '1', today.format('mm')) : '00');
    const [second, setSecond] = useState(value ? get(time, '2', today.format('ss')) : '00');

    useEffect(() => {
        if (isEmpty(value)) {
            // 當傳給input的value為空時(clean value)，將input的value設為空
            inputRef.current.value = '';

            if (showTime) {
                setHour('00');
                setMinute('00');
                setSecond('00');

                // 移動，時、分、秒到頂部
                hourBoxRef.current.scrollTo({top: 0});
                minuteBoxRef.current.scrollTo({top: 0});
                secondBoxRef.current.scrollTo({top: 0});
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if (value) {
            setHour(hour);
            setMinute(minute);
            setSecond(second);
        }

        const hourIndex = hourList.indexOf(hour);
        const minuteIndex = minuteList.indexOf(minute);
        const secondIndex = secondList.indexOf(second);

        if (showTime) {
            // 移動到現在的，時、分、秒
            hourBoxRef.current.scrollTo({behavior: 'smooth', top: hourIndex * 28});
            minuteBoxRef.current.scrollTo({behavior: 'smooth', top: minuteIndex * 28});
            secondBoxRef.current.scrollTo({behavior: 'smooth', top: secondIndex * 28});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hour, minute, second]);

    /**
     * 處理選擇日期
     * @param year
     * @param month
     */
    const handleChangePanel = (year: any = null, month: any = null) => {
        let newPanelDate = panelYearMonth;
        if (year) {
            newPanelDate = newPanelDate.set('year', year);
        }
        if (!isEmpty(month)) {
            newPanelDate = newPanelDate.set('month', month);
        }

        setPanelYearMonth(newPanelDate);
    };

    const handleConformYear = () => {
        const currentYear = panelYearMonth.get('year');

        const localeText = '请输入西元年';
        // @ts-ignore
        const newYear = parseInt(prompt(localeText, panelYearMonth.get('year')));
        if (newYear !== currentYear) {
            handleChangePanel(newYear, null);
        }
    };

    /**
     * 處理選擇日期
     * @param year
     * @param month
     * @param day
     */
    const handleSelectedDate = (year: number | null = null, month: number | null = null, day: number | null = null) => {
        let newDate = panelYearMonth;

        if (value) {
            newDate = getConvertDayjs(value);
        }

        if (year) {
            newDate = newDate.set('year', year);
        }

        if (!isEmpty(month)) {
            newDate = newDate.set('month', month);
        }

        if (day) {
            newDate = newDate.set('date', day);
        }

        const currentDate = getConvertDayjs(value);

        if (typeof value === 'undefined' || !newDate.isSame(currentDate, 'date')) {
            const formatDate = newDate.format(format);

            if (showTime) {
                setHour(newDate.format('HH'));
                setMinute(newDate.format('mm'));
                setSecond(newDate.format('ss'));
            }
            onChange(formatDate);
            setInputValue(formatDate);
        }
    };

    /**
     * 設定為今天日期
     */
    const handleSelectedToday = () => {
        const formatDate = today.format(format);

        if (showTime) {
            setHour(today.format('HH'));
            setMinute(today.format('mm'));
            setSecond(today.format('ss'));
        }
        setPanelYearMonth(today);
        onChange(formatDate);
        setInputValue(formatDate);
    };

    /**
     * 產生年月
     * @returns {*}
     */
    const renderYearMonth = () => {
        const panelPreYear = panelYearMonth.subtract(1, 'year');
        const panelNextYear = panelYearMonth.add(1, 'year');

        const panelPreMonth = panelYearMonth.subtract(1, 'month');
        const panelNextMonth = panelYearMonth.add(1, 'month');

        // 產生年月標題
        return (
            <YearMonthRow>

                <ChangeControl>
                    <MonthButton
                        type="button"
                        onClick={() => handleChangePanel(
                            panelPreYear.get('year'),
                            panelPreYear.get('month'),
                        )}
                    >
                        <Icon code="chevron-double-left" color="rgba(0, 0, 0, 0.25)" size={12}/>
                    </MonthButton>
                    <MonthButton
                        type="button"
                        onClick={() => handleChangePanel(
                            panelPreMonth.get('year'),
                            panelPreMonth.get('month'),
                        )}
                    >
                        <Icon code="chevron-left" color="rgba(0, 0, 0, 0.25)" size={12}/>
                    </MonthButton>

                    <YearMonth>
                        <MonthGroup>
                            <Month>
                                {config.month[panelYearMonth.get('month')]}
                            </Month>

                            <MonthSelect
                                onChange={e => handleChangePanel(null, panelYearMonth.set('month', e.target.value).get('month'))}
                                value={panelYearMonth.get('month')}
                            >
                                {localeMonth}
                            </MonthSelect>
                        </MonthGroup>
                        <Year onClick={handleConformYear}>
                            {panelYearMonth.get('year')}
                        </Year>
                    </YearMonth>

                    <MonthButton
                        type="button"
                        onClick={() => handleChangePanel(
                            panelNextMonth.get('year'),
                            panelNextMonth.get('month'),
                        )}
                    >
                        <Icon code="chevron-right" color="rgba(0, 0, 0, 0.25)" size={12}/>
                    </MonthButton>
                    <MonthButton
                        type="button"
                        onClick={() => handleChangePanel(
                            panelNextYear.get('year'),
                            panelNextYear.get('month'),
                        )}
                    >
                        <Icon code="chevron-double-right" color="rgba(0, 0, 0, 0.25)" size={12}/>
                    </MonthButton>
                </ChangeControl>

            </YearMonthRow>
        );
    };

    /**
     * 產生週標題
     * @returns {*}
     */
    const renderWeek = () => (
        <WeekRow>
            {/* eslint-disable-next-line react/no-array-index-key */}
            {config.weekDay.map((week, index) => <Week key={`localeWeekDay-${index}-${week}`}>{week}</Week>)}
        </WeekRow>
    );

    /**
     * 產生上個月的剩餘日期表
     * @returns {Array}
     */
    const renderPreMonthDay = () => {
        const currentDate = getConvertDayjs(value);

        // 取得指定年月的第一天是星期幾 (0, 1-6)
        const currentMonFirstWeek = panelYearMonth.set('date', 1).day();

        // 取 Panel年月 剩餘月份的可放空間 (星期六 ex: 6-1=5格, 星期日則為7天)
        const preMonthFirstContainer = currentMonFirstWeek === 0 ? 6 : currentMonFirstWeek - 1;

        // 取 Panel年月 上個月的最後一天是幾號
        const preMonth = panelYearMonth.subtract(1, 'month');
        const preMonthLastDay = parseInt(preMonth.endOf('month').get('date'));

        // 取 Panel年月 結束日從幾號開始
        const preMonthFirstDay = preMonthLastDay - preMonthFirstContainer;

        // 產生 Panel年月 上個月的剩餘日期表
        const preMonFirstDayList = new Array(preMonthLastDay);
        for (let d = 0; d < preMonthFirstContainer; d++) {
            const day = preMonthFirstDay + d + 1;
            preMonFirstDayList[d] = (
                <PreDay
                    key={`preMonthDay-${d}`}
                    isSelected={currentDate.isSame(preMonth.set('date', day), 'date')}
                    onClick={() => handleSelectedDate(preMonth.year(), preMonth.month(), day)}
                >
                    <span>
                        {day}
                    </span>
                </PreDay>
            );
        }

        return preMonFirstDayList;
    };

    /**
     * 產生下個月的剩餘日期表
     * @returns {Array}
     */
    const renderNextMonthDay = () => {
        const currentDate = getConvertDayjs(value);

        // 取得指定年月的第一天是星期幾 (0, 1-6)
        const currentMonFirstWeek = panelYearMonth.set('date', 1).day();

        // 取 Panel年月 上個月份的已放空間 (星期六 ex: 6-1=5格, 星期日則為7天)
        const preMonthFirstContainer = currentMonFirstWeek === 0 ? 6 : currentMonFirstWeek - 1;

        // 取 Panel年月 這個月的最後一天是幾號
        const panelMonthLastDay = panelYearMonth.endOf('month').get('date');

        const nextMonth = panelYearMonth.add(1, 'month');

        // 取得指定年月下個月剩餘月份可放空間
        const nextMonthEndContainer = (7 * 6) % (preMonthFirstContainer + panelMonthLastDay);

        // 產生上個月的剩餘日期表
        const nextMonEndDayList = new Array(nextMonthEndContainer);
        for (let d = 0; d < nextMonthEndContainer; d++) {
            const day = d + 1;
            nextMonEndDayList[d] = (
                <PreDay
                    key={`nextMonthDay-${d}`}
                    isSelected={currentDate.isSame(nextMonth.set('date', day))}
                    onClick={() => handleSelectedDate(nextMonth.year(), nextMonth.month(), day)}
                >
                    <span>
                        {day}
                    </span>
                </PreDay>
            );
        }

        return nextMonEndDayList;
    };

    /**
     * 產生當月日期表
     * @returns {*}
     */
    const renderCurrentMonthDay = () => {
        const currentDate = getConvertDayjs(value);

        // 取 Panel年月 的最後一天
        const currentMonthLastDay = panelYearMonth.endOf('month').get('date');

        // 產生 Panel年月 當月日期表
        const currentDayList = new Array(currentMonthLastDay);
        for (let d = 0; d < currentMonthLastDay; d++) {
            const dayNumber = d + 1;
            const eachDate = panelYearMonth.set('date', dayNumber);
            currentDayList[d] = (
                <Day
                    key={`currentDay-${d}`}
                    isToday={today.isSame(eachDate, 'date')}
                    isSelected={currentDate.isSame(eachDate, 'date')}
                    onClick={() => handleSelectedDate(panelYearMonth.year(), panelYearMonth.month(), dayNumber)}
                >
                    <span>
                        {dayNumber}
                    </span>
                </Day>
            );
        }

        return (
            <DayRow>
                {renderWeek()}
                {renderPreMonthDay()}
                {currentDayList}
                {renderNextMonthDay()}
            </DayRow>
        );
    };

    /**
     * 現在時間及ok按鈕
     */
    const renderTodayButton = () => (
        <LabelCheckCardCreate isSetTodayVisible={isSetTodayVisible} showTime={showTime}>
            {isSetTodayVisible && (
                <TodayButton size="small" onClick={handleSelectedToday} type="button">
                    <span>Now</span>
                </TodayButton>
            )}

            {showTime && (
                <SetupButton type="button" onClick={onClose}>
                    Ok
                </SetupButton>
            )}
        </LabelCheckCardCreate>
    );

    /**
     * 處理選擇時間
     */
    const handleSelectedTime = (selectedHour: number | null = null, selectedMinute: number | null = null, selectedSecond: number | null = null) => {
        let newDate = today;

        if (isEmpty(value)) {
            newDate = newDate.set('hour', selectedHour || 0).set('minute', selectedMinute || 0).set('second', selectedSecond || 0);
        } else {
            newDate = getConvertDayjs(value);
            if (selectedHour) {
                newDate = newDate.set('hour', selectedHour);
            }
            if (selectedMinute) {
                newDate = newDate.set('minute', selectedMinute);
            }
            if (selectedSecond) {
                newDate = newDate.set('second', selectedSecond);
            }
        }

        const formatDate = newDate.format('YYYY-MM-DD HH:mm:ss');
        onChange(formatDate);
        setInputValue(formatDate);
    };

    /**
     * 產生 時 的下拉選單
     */
    const renderFakeHourOption = () => hourList.map(i => (
        <FakeOption
            key={`hour-${i}`}
            onClick={() => {
                handleSelectedTime(Number(i));
                setHour(i);
            }}
            isActive={hour === i}
        >
            {i}
        </FakeOption>
    ));

    /**
     * 產生 分 的下拉選單
     */
    const renderFakeMinuteOption = () => minuteList.map(i => (
        <FakeOption
            key={`minute-${i}`}
            onClick={() => {
                handleSelectedTime(null, Number(i));
                setMinute(i);
            }}
            isActive={minute === i}
        >
            {i}
        </FakeOption>
    ));

    /**
     * 產生 秒 的下拉選單
     */
    const renderFakeSecondOption = () => secondList.map(i => (
        <FakeOption
            key={`second-${i}`}
            onClick={() => {
                handleSelectedTime(null, null, Number(i));
                setSecond(i);
            }}
            isActive={second === i}
        >
            {i}
        </FakeOption>
    ));

    /**
     * 時分秒區塊
     */
    const renderTime = () => (
        <PickContainer>
            {/* 假時 */}
            <FakeSelectContainer>
                <SelectBox ref={hourBoxRef} isHiddenBorder>
                    {renderFakeHourOption()}
                </SelectBox>
            </FakeSelectContainer>

            {/* 假分 */}
            <FakeSelectContainer>
                <SelectBox ref={minuteBoxRef}>
                    {renderFakeMinuteOption()}
                </SelectBox>
            </FakeSelectContainer>

            {/* 假秒 */}
            <FakeSelectContainer>
                <SelectBox ref={secondBoxRef}>
                    {renderFakeSecondOption()}
                </SelectBox>
            </FakeSelectContainer>
        </PickContainer>
    );

    return (
        <DatePickerRoot showTime={showTime}>
            <div className="d-flex">
                <Date>
                    {renderYearMonth()}
                    {renderCurrentMonthDay()}
                </Date>

                {showTime && (
                    <Time>
                        <TimeSpan>{`${hour}:${minute}:${second}`}</TimeSpan>
                        {renderTime()}
                    </Time>
                )}
            </div>

            {(isSetTodayVisible || showTime) && renderTodayButton()}

            <input
                ref={e => {
                    forwardRef(e);
                    inputRef.current = e;
                }}
                name={name}
                type="hidden"
                value={inputValue}
            />
        </DatePickerRoot>
    );

};

export default DateTimePicker;

const SetupButton = styled.button<any>`
    width: 35px;
    height: 25px;
    background-color: ${props => props.theme.primaryColor};
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
`;

const TodayButton = styled.button<any>`
    color: ${props => props.theme.primaryColor};
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 14px;
    line-height: 38px;
`;

const LabelCheckCardCreate = styled.div<any>`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px #f0f0f0;
    padding: 4px 12px;

    ${props => !props.isSetTodayVisible && css`
        justify-content: flex-end;
    `};

    ${props => !props.showTime && css`
        justify-content: center;
    `};
`;

const FakeOption = styled.span<any>`
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    width: 56px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;

    background-color: ${props => props.isActive && '#e6f7ff'};

    &:hover {
        background-color: #f5f5f5;
    }
`;

const SelectBox = styled.div<any>`
    height: 226px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    border-left: 1px solid #f0f0f0;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    ${props => props.isHiddenBorder && css`
        border: none;
    `};

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`;

const FakeSelectContainer = styled.div`
    overflow: hidden;
`;

const PickContainer = styled.div`
    display: flex;
    line-height: 1;
`;

const TimeSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 13px;
    border-bottom: solid 1px #f0f0f0;
`;

const Time = styled.div`
    width: 169px;
    display: flex;
    flex-direction: column;
`;

const Week = styled.div`
    flex: 0 0 36px;
    color: rgba(0, 0, 0, 0.65);
    width: 36px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 13px;
`;

const WeekRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Day = styled(Week)<any>`
    position: relative;
    span{
        z-index: 1;
    }

    :before{
        content: '';
        border-radius: 99px;
        position: absolute;
        width: 80%;
        height: 80%;
        z-index: 0;
    }

    :hover{
        color: #ababab;
        cursor: pointer;
    }

    ${props => props.isToday && css`
        color: ${props.theme.primaryColor};
    `};


    ${props => props.isSelected && css`
        color: #fff;

        :before{
            background-color: ${props.theme.primaryColor};
        }

        :hover{
            color: #fff;
        }
    `};
`;

const PreDay = styled(Day)<any>`
    color: rgba(0, 0, 0, 0.25);
    span{
        z-index: 1;
    }

    ${props => props.isSelected && css`
        color: #fff;

        :before{
            background-color: ${props.theme.primaryColor};
        }

        :hover{
            color: #fff;
        }
    `};
`;

const DayRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px 12px;
`;

const MonthButton = styled.button`
    padding: 0;
    border: none;
    background-color: transparent;
    width: 20px;

    :hover{
        .iconfont{
            color: rgba(0, 0, 0, 0.65);
        }
    }

    .iconfont {
        transition: color .3s;
    }
`;

const ChangeControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px;
`;

const MonthSelect = styled.select`
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const Month = styled.span`
    color: inherit;
    font-size: 14px;
    flex: 0 0 auto;
`;

const MonthGroup = styled.div`
    position: relative;
    margin-right: 5px;
`;

const Year = styled.span`
    color: inherit;
    font-size: 14px;
    flex: 0 0 auto;
    cursor: pointer;
`;

const YearMonth = styled.div`
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
`;

const YearMonthRow = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: solid 1px #f0f0f0;
`;

const Date = styled.div`
    width: calc(36px * 7 + 25px);
    border-right: solid 1px #f0f0f0;
`;

const DatePickerRoot = styled.div<any>`
    background-color: #fff;
    width: calc(36px * 7 + 25px);
    margin: 0 auto;
    box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

    ${props => props.showTime && css`
        width: calc(36px * 7 + 25px + 169px);
    `};
`;
