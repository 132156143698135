import React from 'react';
import * as CSS from 'csstype';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {media} from 'library/styled-bs-grid';
import BearCarousel, {BearSlideItem, elClassName} from 'bear-react-carousel';
import {checkIsMobile} from 'utils/browser';
import grid from '../../../config/grid';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    data: Array<{
        id: number,
        title: string,
        desc: string,
        imageUrl: string,
        imageMobileUrl: string,
        routePath: string,
        hoverColor: string,
        color: string,
    }>;
}

/**
 * HomeBannerCarousel
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const HomeBannerCarousel: React.FC<IProps> = ({
    className,
    style,
    data = [],
}) => {
    const history = useHistory();

    const isMobile = checkIsMobile();

    const sliderDataList = data.map(row => {

        return {
            key: row.id,
            children: <BearSlideItem key={row.id} imageUrl={isMobile ? row.imageMobileUrl : row.imageUrl} imageSize="cover">
                <CarouselItem  color={row.color} hoverColor={row.hoverColor}>
                    <Title>{row.title}</Title>
                    <Desc className="d-none d-md-block">{row.desc}</Desc>
                    <ShopButton
                        type="button"
                        onClick={() => history.push(row.routePath)}
                    >
                        SHOP NOW
                    </ShopButton>
                </CarouselItem>
            </BearSlideItem>,
        }
    });


    return (
        <HomeBannerRoot style={style} className={className}>
            <BearCarousel
                isEnablePagination
                isEnableLoop
                autoPlayTime={5000}
                data={sliderDataList}
                staticHeight="150px"
                breakpoints={{
                  [grid.gridBreakpoints.md]: {
                      staticHeight: '360px',
                  },
                  [grid.gridBreakpoints.lg]: {
                      staticHeight: '465px',
                  },
                  [grid.gridBreakpoints.xl]: {
                      staticHeight: '480px',
                  },
                }}
            />

        </HomeBannerRoot>
    );
};

export default HomeBannerCarousel;

const ShopButton = styled.button`
    width: 100px;
    height: 30px;
    border: solid 1px #ffffff;
    background-color: rgba(216, 216, 216, 0);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 30px;
    transition: background-color .3s, color .3s;

    &:hover {
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        background-color: rgba(255, 255, 255, 0.6);
    }

    ${media.md`
        width: 132px;
        height: 40px;
        margin-bottom: 0;
    `}

    ${media.xl`
        font-size: 16px;
        width: 174px;
        height: 46px;
    `}
`;

const Desc = styled.span`
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 22px;
    width: 60%;
    white-space: pre;

    ${media.md`
        font-size: 16px;
    `}
`;

const Title = styled.span`
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 6px;

    ${media.md`
        font-size: 30px;
        padding-bottom: 4px;
    `}

    ${media.xl`
        font-size: 60px;
        padding-bottom: 0;
    `}
`;

const CarouselItem = styled.div<any>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    ${Title} {
        color: ${props => props.color};
    }

    ${Desc} {
        color: ${props => props.color};
    }

    ${ShopButton} {
        color: ${props => props.color};

        &:hover {
            color: ${props => props.hoverColor};
        }
    }

    ${media.md`
        align-items: flex-start;
        padding-left: 50px;
    `}


    ${media.xl`
        padding-left: 95px;
    `}
`;

const HomeBannerRoot = styled.div`
  --primary-color: #8ec5ff;
  background-color: #282c34;

  .${elClassName.paginationButton}{
      transition: width .4s;
       &[data-active]{
            opacity: 1;
            width: 20px;
        }
  }
`;
