import React, {useEffect, useState} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {Controller, useForm} from 'react-hook-form';
import {useHistory, useLocation} from 'react-router-dom';
import cx from 'classnames';
import {media} from 'library/styled-bs-grid';
import {isEmpty, isJSON, jsonDecode} from 'utils/equal';
import {checkIsMobile} from 'utils/browser';
import {parseQueryString} from 'utils/uri';
import {get} from 'lodash';
import {motion, AnimatePresence} from 'framer-motion'
import {getOs} from 'utils/browser';

// Component
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import CustomSelect from 'components/atoms/CustomSelect';
import MultipleSelect from 'components/atoms/MultipleSelect';
import ScrollView from 'components/atoms/ScrollView';
import {IOptions} from './types';

interface IProps {
className?: string;
    style?: CSS.Properties;
    onClose?: Function;
    isVisible?: boolean;
    categoryList: Array<{
        id: number;
        name: string;
        isNew: boolean;
        categoryMains?: Array<{
            id: number;
            name: string;
            categorySubs: Array<{
                id: number;
                name: string;
            }>;
        }>;
    }>;
    brandList: Array<{
        firstLetter: string;
        detail: Array<{
            id: number;
            name: string;
            totalProductGoods: number,
        }>;
    }>;
    seasonData: Array<{
        id: number;
        name: string;
    }>;
    popularOptions: Array<{
        code: number | string;
        name: string;
    }>;
}

/**
 * SearchProductModal
 * @param props
 * @returns {*}
 * @constructor
 */
const SearchProductModal: React.FC<IProps> = ({
    className,
    style,
    onClose = () => {},
    isVisible = false,
    categoryList = [],
    brandList = [],
    seasonData = [],
    popularOptions = [],
}) => {
    const history = useHistory();
    const useFormParams = useForm({mode: 'onChange'});
    const {handleSubmit, getValues, setValue, control, watch} = useFormParams;

    const {categoryGroupId, categoryMainId} = watch(['categoryGroupId', 'categoryMainId']);

    const [perPageNum, setPerPageNum] = useState<number>(100);

    const groupInfo = categoryList.find(row => String(row.id) === String(categoryGroupId));

    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    // 搜尋條件
    const {search} = useLocation();
    const queryString = parseQueryString(search);

    const groupId = get(queryString, 'categoryGroupId', '');
    const mainId = get(queryString, 'categoryMainId', '');
    const subId = get(queryString, 'categorySubId', '');
    const searchPopular = get(queryString, 'popularId', '');
    const sort = get(queryString, 'sort', 'onAtDesc');
    const searchName = get(queryString, 'name', '');
    const searchModel = get(queryString, 'model', '');
    const brandName = get(queryString, 'brandName', '');

    let brands = get(queryString, 'brands', '[]');
    if (brands !== '[]') {
        brands = decodeURI(brands);
        if (!isJSON(brands)) {
            brands = '[]';
        }
    }

    useEffect(() => {
        // 判斷品牌搜尋不為空時，預設帶入品牌下拉
        const jsonBrands = jsonDecode(brands);
        jsonBrands.map((brandId: number) => {
            return setValue(`brands[${brandId}]`, true);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brands, brandList, isVisible]);


    useEffect(() => {
        setTimeout(() => {
            setValue('categoryGroupId', groupId ? Number(groupId) : '');
            setValue('categoryMainId', mainId ? Number(mainId) : '');
            setValue('categorySubId', subId ? Number(subId) : '');
            setValue('popularId', searchPopular ? Number(searchPopular) : '');

            if (!isEmpty(searchName)) {
                setValue('searchText', searchName);
                setValue('typeId', 2);
            }
            if (!isEmpty(searchModel)) {
                setValue('searchText', searchModel);
                setValue('typeId', 1);
            }
            if (!isEmpty(brandName)) {
                setValue('searchText', brandName);
                setValue('typeId', 3);
            }
        }, 200);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, mainId, subId, searchPopular, isVisible]);

    /**
     *  商品群
     */
    const renderGroupOptions = () => {
        const defaultGroupOption: IOptions[] = [{code: '', name: '選擇群組'}];
        const groupOptions = categoryList.map(row => (
            {code: row.id, name: row.name}
        ));

        return defaultGroupOption.concat(groupOptions);
    };

    /**
     *  商品主分類
     */
    const renderCategoryMainOptions = () => {
        const defaultCategoryOption: IOptions[] = [{code: '', name: '選擇主分類'}];
        let categoryOptions: IOptions[] = [];

        if (groupInfo && groupInfo.categoryMains) {
            categoryOptions = groupInfo.categoryMains.map(row => (
                {code: row.id, name: row.name}
            ));
        }

        return defaultCategoryOption.concat(categoryOptions);
    };

    /**
     *  商品次分類
     */
    const renderCategorySubOptions = () => {
        const defaultCategorySubOption: IOptions[] = [{code: '', name: '選擇次分類'}];
        let categorySubOptions: IOptions[] = [];

        if (groupInfo && !isEmpty(categoryMainId)) {
            const subInfo = groupInfo.categoryMains ?.find(row => String(row.id) === String(categoryMainId));
            if (subInfo) {
                categorySubOptions = subInfo.categorySubs.map(row => {
                    return {code: row.id, name: row.name};
                });
            }
        }

        return defaultCategorySubOption.concat(categorySubOptions);
    };

    /**
     * 商品季節選項
     */
    const renderSeasonOptions = () => {
        const defaultSeasonOption: IOptions[] = [{code: '', name: '季節'}];
        const seasonOptions = seasonData.map(row => (
            {code: row.id, name: row.name}
        ));

        return defaultSeasonOption.concat(seasonOptions);
    };

    /**
     *  清除所有欄位
     */
    const handleClearAll = () => {
        setValue('searchText', '');
        setValue('categoryGroupId', '');
        setValue('categoryMainId', '');
        setValue('categorySubId', '');
        setValue('season', '');
        setValue('popularId', '');

        // 取消選取品牌勾選
        const keys = getValues();
        for(const row of Object.keys(keys)){
            if (row.search('brands') !== -1 ) {
                setValue(row, false);
            }
        }
    };

    /**
     *  送出查詢條件
     */
    const onSubmit = (formData: any) => {
        // 品牌
        const {brands: checkedId} = getValues({nest: true});
        const ids = [];
        for(const id in checkedId){
            // 過濾掉不等於 true 的 Key
            if(checkedId[id]){
                ids.push(Number(id));
            }
        }
        const brandsJson = JSON.stringify(ids);

        let model = '';
        let name = '';
        let brandName = '';
        if (String(formData.typeId) === '2') {
            // 產品名稱
            name = formData?.searchText ?? '';
        } else if (String(formData.typeId) === '3') {
            // 品牌
            brandName = formData?.searchText ?? '';
        } else {
            // 產品貨號
            model = formData?.searchText ?? '';
        }

        history.push(`/product?categoryGroupId=${formData?.categoryGroupId ?? ''}&categoryMainId=${formData?.categoryMainId ?? ''}&categorySubId=${formData?.categorySubId ?? ''}&brands=${brandsJson}&season=${formData?.season ?? ''}&popularId=${formData.popularId}&sort=${sort}&name=${name}&model=${model}&brandName=${brandName}&perPageNum=${perPageNum}&typeId=${formData.typeId ?? '1'}`);
        onClose();
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <Overlay
                    className={className}
                    style={style}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <ScrollView className="d-flex align-items-end justify-content-center align-items-md-center">
                        <Modal
                            variants={containerVariant}
                            transition={{type: "spring", duration: 0.5}}
                        >
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <CloseButton type="button" onClick={onClose}>
                                    <Icon code="times" size={15} color="#cecece"/>
                                </CloseButton>

                                <Title>搜尋商品</Title>

                                {/* 搜尋貨號 or 商品 */}
                                <Search>
                                    <div className="position-relative">
                                        <Controller
                                            control={control}
                                            name="typeId"
                                            as={<SearchSelect>
                                                <option value={1}>貨號</option>
                                                <option value={2}>商品</option>
                                                <option value={3}>品牌</option>
                                            </SearchSelect>}
                                        />

                                        {checkIsMobile() && <ChevronIcon code="chevron-fillet-down" size={10} color="#9d9a9a"/>}
                                    </div>

                                    <Controller
                                        control={control}
                                        name="searchText"
                                        as={<SearchInput placeholder="搜尋..."/>}
                                    />

                                    <SearchButton type="button">
                                        <Icon code="search" size={20} color="#d8d8d8"/>
                                    </SearchButton>
                                </Search>

                                {/* 商品群組 */}
                                <Controller
                                    control={control}
                                    name="categoryGroupId"
                                    as={<CustomSelectExtend
                                        option={renderGroupOptions()}
                                    />}
                                    onChange={(event) => {
                                        setValue('categoryMainId', '');
                                        setValue('categorySubId', '');
                                        return event[0];
                                    }}
                                />

                                {/* 商品主分類 */}
                                <Controller
                                    control={control}
                                    name="categoryMainId"
                                    className={cx({'d-none': !categoryGroupId})}
                                    as={<CustomSelectExtend
                                        option={renderCategoryMainOptions()}
                                    />}
                                    onChange={(event) => {
                                        setValue('categorySubId', '');
                                        return event[0];
                                    }}
                                />

                                {/* 商品次分類 */}
                                <Controller
                                    control={control}
                                    name="categorySubId"
                                    className={cx({'d-none': !categoryMainId})}
                                    as={<CustomSelectExtend
                                        option={renderCategorySubOptions()}
                                    />}
                                />

                                {/* 品牌 */}
                                <CustomMultipleSelect
                                    name="brands"
                                    brandList={brandList}
                                    useFormParams={useFormParams}
                                />

                                {/* 季節 */}
                                <Controller
                                    control={control}
                                    name="season"
                                    as={<CustomSelectExtend
                                        option={renderSeasonOptions()}
                                    />}
                                />

                                {/* 選擇特性 */}
                                <Controller
                                    control={control}
                                    name="popularId"
                                    as={<CustomSelectExtend
                                        option={popularOptions}
                                    />}
                                />

                                <div className="d-flex flex-column align-items-end flex-grow-1">
                                    <PageSection>
                                        <PageDesc>Show per page</PageDesc>

                                        <PageButton type="button" onClick={() => setPerPageNum(20)} isSelected={perPageNum === 20}>20</PageButton>
                                        <PageButton type="button" onClick={() => setPerPageNum(40)} isSelected={perPageNum === 40}>40</PageButton>
                                        <PageButton type="button" onClick={() => setPerPageNum(80)} isSelected={perPageNum === 80}>80</PageButton>
                                        <PageButton type="button" onClick={() => setPerPageNum(100)} isSelected={perPageNum === 100}>100</PageButton>
                                    </PageSection>

                                    <Clear type="reset" onClick={() => handleClearAll()}>全部清除</Clear>
                                </div>

                                <SubmitButton isBlock type="submit">
                                    確定
                                </SubmitButton>
                            </Form>
                        </Modal>
                    </ScrollView>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default SearchProductModal;

const SubmitButton = styled(Button)`
    width: 240px;
    height: 44px;
    min-height: auto;
    align-self: center;
`;

const Clear = styled.button`
    font-size: 12px;
    color: #9d9a9a;
    cursor: pointer;
    text-decoration: underline;
`;

const PageButton = styled.button<any>`
    height: 20px;
    border: solid 1px ${props => props.theme.primaryColor};
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 0 3px;
    transition: background-color .1s, color .1s;

    ${media.md`
        padding: 2px 3px 0 3px;
    `}

    ${props => props.isSelected && css`
        background-color: ${props.theme.primaryColor};
        color: #ffffff;
    `};
`;

const PageDesc = styled.span`
    font-size: 14px;
    color: #a7a4a4;
`;

const PageSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
`;

const CustomMultipleSelect = styled(MultipleSelect)`
    margin-bottom: 12px;

    ${media.md`
        height: 50px;
        margin-bottom: 16px;
    `}
`;

const CustomSelectExtend = styled(CustomSelect)`
    margin-bottom: 12px;

    &:nth-last-child(2) {
        margin-bottom: 18px;
    }

    ${media.md`
        height: 50px;
        margin-bottom: 16px;

        &:nth-last-child(2) {
            margin-bottom: 16px;
        }
    `}
`;

const SearchButton = styled.button`
    width: 20px;
    height: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
`;

const SearchInput = styled.input<any>`
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #6e6e6e;
    background-color: transparent;
    flex: 1 1 auto;

    &::placeholder {
        color: #d8d8d8;
    }

    ${media.md`
        font-size: 16px;
        padding-left: 30px;
    `}
`;

const ChevronIcon = styled(Icon)`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
`;

const SearchSelect = styled.select`
    font-size: 14px;
    font-weight: 500;
    color: #6e6e6e;
    border: none;
    height: 100%;
    background-color: transparent;
    padding-left: 26px;
    padding-right: 15px;
    position: relative;

    ${media.md`
        font-size: 16px;
        padding-left: 36px;
    `}
`;

const Search = styled.div`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f4f4f4;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    ${media.md`
        height: 50px;
        margin-bottom: 16px;
    `}
`;

const Title = styled.span`
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #2d2c38;
    padding-bottom: 15px;
    display: block;

    ${media.md`
        font-size: 16px;
        padding-bottom: 20px;
    `}
`;

const CloseButton = styled.button<any>`
    width: 16px;
    height: 16px;
    padding: 0;
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 20px;
        height: 20px;
        top: 30px;
        right: 62px;
    `}
`;

const Form = styled.form`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Modal = styled(motion.div)`
    width: 100%;
    height: calc(100% - 28px);
    border-radius: 10px 10px 0 0;
    padding: 15px 20px 10px 20px;
    background-color: #ffffff;
    position: relative;

    ${media.md`
        width: 460px;
        height: 680px;
        border-radius: 10px;
        padding: 30px 50px;
    `}
`;

const Overlay = styled(motion.div)`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;

    ${media.md`
        align-items: center;
    `}
`;
