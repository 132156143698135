import React, {useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {asset} from 'config/app';
import {GridThemeProvider, Container, Row, Col} from 'library/styled-bs-grid';
import {getPopularOptions} from '../HomeLayout/utils';

// Component
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import A from 'components/atoms/A';
import SearchProductModal from 'components/organisms/SearchProductModal';
import ContactModal from 'components/organisms/ContactModal';

// Reducer
import {Actions as AuthAction} from 'store/auth';
import {Selector as AuthSelector} from 'store/auth';


const menuList = [
    {code: 'new', name: '新品上架', href: '/product?popularId=2'},
    {code: 'sale', name: '特價商品', href: '/product?popularId=3'},
    {code: 'search', name: '商品搜詢', href: ''},
    {code: 'heart', name: '追蹤清單', href: '/profile/favorite'},
    {code: 'medal', name: '所有品牌', href: '/brand'},
    {code: 'comment-minus', name: '購物說明', href: '/guide'},
    {code: 'home', name: '關於韓爸', href: '/about'},
    {code: 'phone-circle', name: '連絡資訊', href: ''},
];

const Menu: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isVisibleSearchModal, setVisibleSearchModal] = useState(false);
    const [isVisibleContactModal, setVisibleContactModal] = useState(false);

    const {isAuth, isOrderVipGoods, isOrderPreSale, categoryGroup, seasonData, brandGroup, company} = useSelector(state => ({
        isAuth: AuthSelector.isAuth(state),
        isOrderVipGoods: state.profile.isOrderVipGoods,
        isOrderPreSale: state.profile.isOrderPreSale,

        categoryGroup: state.product.categoryGroup,
        seasonData: state.product.seasonData,
        brandGroup: state.product.brandGroup,

        company: state.system.company,
    }));

    return (
        <>
            {/* Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton
                        type="button"
                        onClick={() => history.goBack()}
                    >
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <A href="/">
                        <Logo src={asset('/images/home/header-logo.png')}/>
                    </A>

                    <IconButton type="button"/>
                </MobileHeader>
            </Container>

            {/* 置入廣告 */}
            {/*<PlacementBg src={asset('/example/placement/brand-1.jpg')}>*/}
            {/*    <PlacementInfo className="d-flex flex-column justify-content-center align-items-center">*/}
            {/*        <Title>2019 冬季新品上市</Title>*/}
            {/*        <ShopButton themeType="whiteBorderBlur" size="middle" shape="raised" isBlurBg>*/}
            {/*            SHOP NOW*/}
            {/*        </ShopButton>*/}
            {/*    </PlacementInfo>*/}
            {/*</PlacementBg>*/}

            <MenuSection>
                <GridThemeProvider
                    gridTheme={{
                        gridGutterWidth: 7.5,
                    }}
                >
                    <Container>
                        <Row>
                            {menuList.map(row => (
                                <Col col={8} key={row.code}>
                                    <MenuItem onClick={() => {
                                        if (row.code === 'search') {
                                            setVisibleSearchModal(true);
                                        } else if (row.code === 'phone-circle') {
                                            setVisibleContactModal(true);
                                        } else {
                                            history.push(row.href);
                                        }
                                    }}>
                                        <Icon code={row.code} size={30} color="#282731"/>
                                        <MenuText>{row.name}</MenuText>
                                    </MenuItem>
                                </Col>
                            ))}
                        </Row>

                        <SubmitControl>
                            <LoginButton
                                isBlock
                                themeType={isAuth ? 'gray' : 'purple'}
                                onClick={() => {
                                    if (isAuth) {
                                        dispatch(AuthAction.logout());
                                    } else {
                                        dispatch(AuthAction.openLoginModal());
                                    }
                                }}
                            >
                                {isAuth ? '登出' : '登入'}
                            </LoginButton>
                        </SubmitControl>
                    </Container>
                </GridThemeProvider>
            </MenuSection>


            {/* 搜尋光箱 */}
            <SearchProductModal
                className="d-lg-none"
                isVisible={isVisibleSearchModal}
                onClose={() => setVisibleSearchModal(false)}
                categoryList={categoryGroup}
                popularOptions={getPopularOptions(isOrderVipGoods, isOrderPreSale)}
                seasonData={seasonData}
                brandList={brandGroup}
            />

            {/* 連絡資訊光箱 */}
            <ContactModal
                isVisible={isVisibleContactModal}
                onClose={() => setVisibleContactModal(false)}
                address={company.address}
                phone={company.tel}
                mobile={company.mobile}
                email={company.email}
                line={company.line}
            />
        </>
    );
};

export default Menu;

const LoginButton = styled(Button)`
    min-height: auto;
    height: 44px;
`;

const SubmitControl = styled.div`
    padding: 15px 0;
`;

const MenuText = styled.span`
    font-size: 12px;
    color: #282731;
    padding-top: 5px;
`;

const MenuItem = styled.div`
    width: 100%;
    height: 70px;
    border-radius: 2px;
    border: solid 1px #f2f2f2;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    cursor: pointer;
`;

const MenuSection = styled.div`
    margin-top: 30px;
    padding: 0 33px;
`;

// const ShopButton = styled(Button)`
//     width: 100px;
//     height: 30px;
//     min-height: auto;
//     padding: 0;
//     font-size: 12px;
//     font-weight: bold;
// `;
//
// const Title = styled.span`
//     font-size: 16px;
//     color: #ffffff;
//     padding-bottom: 7px;
// `;
//
// const PlacementInfo = styled.div`
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
// `;
//
// const PlacementBg = styled.div<any>`
//     width: 100%;
//     padding-bottom: 35%;
//     background: url('${props => props.src}') no-repeat center center;
//     background-size: cover;
//     position: relative;
//     margin-bottom: 30px;
// `;

const Logo = styled.img`
    width: 118px;
    height: 29px;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
