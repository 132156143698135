import React, {useCallback} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    type?: 'text' | 'number' | 'password' | 'tel';
    value?: string;
    inputType?: 'normal' | 'material';
    name?: string;
    placeholder?: string;
    defaultValue?: string;
    readonly?: boolean;
    errorMessage?: string;
    remarkMessage?: string;
    isVisibleCleanButton?: boolean;
    afterIconCode?: string;
    afterIconOnClick?: Function;
    minLength?: number;
    maxLength?: number;
    onChange?: Function;
    onFocus?: Function;
    onBlur?: Function;
}

/**
 * Input Component
 * form type in text...
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Input: React.FC<IProps> = ({
    className,
    style,
    name,
    type = 'text',
    value='',
    inputType = 'material',
    placeholder = '',
    defaultValue,
    readonly = false,
    errorMessage,
    remarkMessage,
    isVisibleCleanButton = false,
    afterIconCode = '',
    afterIconOnClick,
    minLength,
    maxLength,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
}) => {

    /**
     * 處理值改變
     */
    const handleChange = useCallback((value: string) => {
        onChange(value);
    }, [onChange]);

    return (
        <InputContainer className={className} style={style}>
            <InputRoot>
                {/* Input */}
                <TextBox
                    name={name}
                    value={value}
                    type={type}
                    onChange={(event: any) => handleChange(event.target.value)}
                    inputType={inputType}
                    isError={errorMessage}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    readonly={readonly}
                    minLength={minLength}
                    maxLength={maxLength}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />

                {/* 外框 */}
                <Border>
                    {
                        inputType !== 'normal' && (
                            <MovePlaceholder>{placeholder}</MovePlaceholder>
                        )
                    }
                </Border>

                {/* 後面的Icon */}
                {afterIconCode && (
                    <CustomIcon
                        code={afterIconCode}
                        onClick={afterIconOnClick}
                        color="#9d9a9a"
                        size={20}
                    />
                )}

                {/* 清除按鈕 */}
                {isVisibleCleanButton && (
                    <CloseIcon
                        code="times-circle"
                        onClick={() => handleChange('')}
                        color="#9d9a9a"
                        size={20}
                    />
                )}
            </InputRoot>

            {/* 錯誤訊息提示 */}
            {errorMessage && (
                <Remark isError={errorMessage}>
                    <ErrorIcon code="exclamation-circle" color="#ff4b4b" size={12}/>
                    {errorMessage}
                </Remark>
            )}

            {/* 填寫備註 */}
            {remarkMessage && (
                <Remark>{remarkMessage}</Remark>
            )}

        </InputContainer>
    );
};

Input.defaultProps = {
};

export default Input;

const ErrorIcon = styled(Icon)`
    margin-right: 5px;
`;

const Remark = styled.div<any>`
    font-size: 10px;
    padding-top: 5px;
    color: ${props => (props.isError ? '#ff7d6a' : '#9d9a9a')};
    display: flex;
    align-items: center;

    ${media.lg`
        font-size: 14px;
    `}
`;

const MovePlaceholder = styled.div`
    font-size: 14px;
    padding: 0 0 0 9px;
    color: #9d9a9a;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s ease;

    ${media.lg`
        font-size: 17px;
        padding: 0 0 0 20px;
    `}
`;

const Border = styled.fieldset`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    border: solid 1px #9d9a9a;
    pointer-events: none;
    border-radius: 5px;
`;

const CustomIcon = styled(Icon)`
    padding: 20px;
    color: #c3c3c3;
    order: 2;
    cursor: ${props => (props.onClick ? 'pointer' : 'auto')};

    &:hover {
        color: ${props => props.theme.thirdColor};
    }

    ${media.lg`
        padding: 20px;
    `}
`;

const CloseIcon = styled(CustomIcon)`
    visibility: hidden;
`;

const TextBox = styled.input<any>`
    font-size: 14px;
    font-weight: bold;
    height: 44px;
    max-height: 44px;
    padding-left: 10px;
    color: #6e6e6e;
    width: 100%;
    border: none;
    background-color: transparent;
    order: 1;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9d9a9a;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9d9a9a;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #9d9a9a;
    }

    ${media.lg`
        padding-left: 20px;
        font-size: 17px;
        height: 56px;
        max-height: 56px;
    `}

    ${props => (props.inputType !== 'normal') && css`
        padding-left: 10px;

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: transparent;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: transparent;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
          color: transparent;
        }

        &:focus, &:not(:placeholder-shown) {
            padding-top: 15px;

            &~${Border} {
                ${MovePlaceholder} {
                    font-size: 12px;
                    color: #9d9a9a;
                    top: 12px;
                }
            }
        }

        &:focus~${Border} {
            border-color: ${props.theme.thirdColor};

            ${MovePlaceholder} {
                color: ${props.theme.thirdColor};
            }
        }

        //input有值的情況需搭配placeholder
        &:not(:placeholder-shown) {
            &~${CloseIcon} {
                visibility: visible;
            }
        }

        ${media.lg`
            padding-left: 20px;

            &:focus, &:not(:placeholder-shown) {
                padding-top: 26px;

                &~${Border} {
                    ${MovePlaceholder} {
                        font-size: 14px;
                        top: 17px;
                    }
                }
            }
        `}
    `}

    ${props => props.readonly && css`
        &~${Border} {
            border-color: #d8d8d8;
            pointer-events: auto;
        }
    `}

    ${props => props.isError && css`
        &~${Border} {
            border-color: #ff7d6a !important;

            ${MovePlaceholder} {
                color: #ff7d6a !important;
            }
        }
    `}
`;

const InputRoot = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InputContainer = styled.div`
    width: 100%;
`;
