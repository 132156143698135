import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import {get} from 'lodash';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {useDispatch, useSelector} from 'react-redux';
import {HalfCircleSpinner} from 'react-epic-spinners';
import site from 'config/site';
import {formatCurrency} from 'utils/number';

// Reducers
import {Selector as AuthSelector} from 'store/auth';
import {Actions as ProfileAction} from 'store/profile';
import {Actions as DepositAction} from 'store/deposit';
import {Actions as SystemAction} from 'store/system';

// Component
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import DepositNoticeModal from 'components/organisms/DepositNoticeModal';
import {IFormData} from './types';

/**
 * 我的帳戶
 * @constructor
 */
const Account: React.FC = () => {
    const history = useHistory();
    const [isVisibleProfile, setVisibleProfile] = useState(false);
    const [isVisibleModal, setVisibleModal] = useState(false);

    const dispatch = useDispatch();

    const {payload, isFetching, email, avatarUrl, realName, walletAmount, unpaidAmount, promotionCode, level, lineId, phone, deliveryAddress, depositBank, messageUnreadCount, trackProductCount} = useSelector(state => ({
        payload: AuthSelector.payload(state),
        isFetching: state.profile.isFetching,
        email: state.profile.email,
        avatarUrl: state.profile.avatarUrl,
        realName: state.profile.realName,
        walletAmount: state.profile.walletAmount,
        unpaidAmount: state.profile.unpaidAmount,
        isOrderVipGoods: state.profile.isOrderVipGoods,
        level: state.profile.level,
        promotionCode: state.profile.promotionCode,
        lineId: state.profile.lineId,
        phone: state.profile.phone,
        deliveryAddress: state.profile.deliveryAddress,
        messageUnreadCount: state.profile.messageUnreadCount,
        trackProductCount: state.profile.trackProductCount,
        depositBank: state.system.depositBank,
    }));



    const menuList = [
        {code: 0, name: '我的帳戶', iconCode: 'user-circle', href: '/profile/account', isMobileVisible: false},
        {code: 1, name: '訊息', iconCode: 'comment-dots', href: '/profile/message', isMobileVisible: false, num: messageUnreadCount},
        {code: 2, name: '追蹤清單', iconCode: 'heart', href: '/profile/favorite', isMobileVisible: true, order: 2, num: trackProductCount},
        {code: 3, name: '預先下特', iconCode: 'clipboard-list-check', href: '/profile/advanceOrder', isMobileVisible: true, order: 1},
        {code: 4, name: '我的訂單', iconCode: 'clipboard-list', href: '/profile/order', isMobileVisible: true, order: 3},
        {code: 5, name: '備貨狀態', iconCode: 'box', href: '/profile/stockUp', isMobileVisible: true, order: 4},
        {code: 6, name: '出貨明細', iconCode: 'truck', href: '/profile/ship', isMobileVisible: true, order: 5},
        {code: 7, name: '匯款狀態通知', iconCode: 'clipboard-list-sync', href: '/profile/remittance', isMobileVisible: true, order: 6},
        {code: 8, name: '餘額紀錄', iconCode: 'clipboard-list-clock', href: '/profile/trade', isMobileVisible: true, order: 7},
    ];

    useEffect(() => {
        // 取得個人帳戶資訊
        dispatch(ProfileAction.fetchInfo());

        // 取得匯款銀行資訊
        if (get(depositBank, 'length', 0) === 0) {
            dispatch(SystemAction.fetchDepositBank());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * 上傳大頭貼
     */
    // const uploadAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const file = event.target.files ? event.target.files[0] : undefined;
    //
    //     if (file) {
    //         dispatch(ProfileAction.uploadAvatar({avatar: file}));
    //     }
    // };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>我的帳戶</MobileTitle>

                    <IconButton/>
                </MobileHeader>
            </Container>

            <ProfileContainer className="d-flex flex-column">

                {/* 讀取狀態 */}
                {isFetching && (
                    <Row>
                        <Col col>
                            <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                        </Col>
                    </Row>
                )}

                {/* 大頭貼 && 帳號 */}
                <ProfileInfo onClick={() => setVisibleProfile(!isVisibleProfile)}>
                    <Avatar src={avatarUrl}>
                        {/*<Edit>Edit</Edit>*/}

                        {/*<AvatarInput*/}
                        {/*    type="file"*/}
                        {/*    accept="image/png, image/jpeg"*/}
                        {/*    onChange={uploadAvatar}*/}
                        {/*/>*/}
                    </Avatar>

                    <AccountInfo>
                        <AccountName>{payload?.account}</AccountName>
                        <Phone>{phone}</Phone>
                        <Email>{email}</Email>
                    </AccountInfo>

                    <VipSection className="d-flex">
                        <Vip isVip={level?.code === 'vip'}>{level?.name}</Vip>

                        <ChevronButton type="button" className="d-md-none">
                            <Icon code="chevron-fillet-down" size={10} color="#d8d8d8" rotate={isVisibleProfile ? 180 : 0}/>
                        </ChevronButton>
                    </VipSection>
                </ProfileInfo>

                {/* 個人資料 */}
                <ProfileSection isVisible={isVisibleProfile}>
                    <ProfileTitle>個人資料：</ProfileTitle>

                    <ProfileRow>
                        <ProfileCol col={24} md={12}>
                            <CustomInput
                                placeholder="姓名"
                                value={realName}
                                readonly
                            />
                        </ProfileCol>
                        <ProfileCol col={24} md={12}>
                            <CustomInput
                                placeholder="Line ID"
                                value={lineId}
                                readonly
                            />
                        </ProfileCol>
                        <ProfileCol col={24} md={12}>
                            <CustomInput
                                placeholder="電話/手機"
                                value={phone}
                                readonly
                            />
                        </ProfileCol>
                        <ProfileCol col={24} md={12}>
                            <CustomInput
                                placeholder="電子信箱"
                                value={email}
                                readonly
                            />
                        </ProfileCol>
                        <ProfileCol col={24}>
                            <CustomInput
                                placeholder="配送地址"
                                value={deliveryAddress}
                                readonly
                            />
                        </ProfileCol>
                    </ProfileRow>

                    <ProfileDesc>- 如需修改請洽客服人員 -</ProfileDesc>
                </ProfileSection>

                {/* 金額 */}
                <AmountRow className="d-flex align-items-center">
                    <AmountCol col={8} className="d-flex flex-column">
                        <Amount>$ {formatCurrency(walletAmount)} 元</Amount>
                        <AmountDesc>帳戶餘額</AmountDesc>
                    </AmountCol>
                    <AmountCol col={8} className="d-flex flex-column">
                        <Amount>- $ {unpaidAmount} 元</Amount>
                        <AmountDesc>待付金額</AmountDesc>
                    </AmountCol>
                    <AmountCol col={8}>
                        <Recharge onClick={() => setVisibleModal(true)}>
                            <HiddenButton className="flex-column align-items-center justify-content-center">
                                <RechargeIcon code="plus-circle" size={20} color={site.theme.thirdColor}/>
                                <RechargeDesc>匯款充值</RechargeDesc>
                            </HiddenButton>

                            <HiddenAmount className="flex-column align-items-center justify-content-center">
                                <Amount>$ {formatCurrency(walletAmount - unpaidAmount)} 元</Amount>
                                <AmountDesc>可用餘額</AmountDesc>
                            </HiddenAmount>
                        </Recharge>
                    </AmountCol>
                </AmountRow>

                {/* 促銷代碼 */}
                <Promotion>
                    <PromotionTitle className="d-none d-md-block">促銷代碼：</PromotionTitle>
                    <CustomInput
                        placeholder="促銷代碼"
                        value={promotionCode}
                        readonly
                    />
                </Promotion>

                {/* Menu */}
                <div className="d-md-none">
                    {menuList.filter(o => o.isMobileVisible)
                        .sort((a, b) => (get(a, 'order', 0) - get(b, 'order', 0)))
                        .map(row => (
                            <MenuItem
                                key={row.code}
                                onClick={() => history.push(row.href)}
                            >
                                <div className="position-relative">
                                    {get(row, 'num', 0) > 0 && <Num>{row.num}</Num>}
                                    <Icon code={row.iconCode} color="#9d9a9a" size={20}/>
                                </div>
                                <MenuName>{row.name}</MenuName>
                            </MenuItem>
                        ))}

                    <MenuDesc>＊＊資料保留期間：半年內</MenuDesc>
                </div>
            </ProfileContainer>

            {/* 匯款充值光箱 */}
            <DepositNoticeModal
                isVisible={isVisibleModal}
                bankList={depositBank}
                onSubmitForm={(data: IFormData) => dispatch(DepositAction.fetchPending({fromName: data.fromName, fromDepositAt: data.fromDepositAt, fromLast5Number: data.fromLast5Number, amount: data.amount, toBankId: data.toBankId, remark: data.remark}))}
                onClose={() => setVisibleModal(false)}
            />
        </>
    );
};

export default Account;

const MenuDesc = styled.span`
    font-size: 12px;
    color: #9b9b9b;
    padding-bottom: 10px;
    display: block;
    text-align: center;
`;

const MenuName = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #9d9a9a;
    padding-left: 15px;
`;

const Num = styled.div`
    width: 15px;
    height: 15px;
    border: solid 0.5px #fbfbfb;
    background-color: #ff7d6a;
    position: absolute;
    left: -7px;
    top: -7px;
    font-size: 9px;
    font-weight: bold;
    color: #ffffff;
    z-index: 5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MenuItem = styled.div`
    width: 100%;
    height: 44px;
    background-color: rgba(248, 248, 248, 0.5);
    margin-bottom: 10px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const PromotionTitle = styled.span`
    font-size: 18px;
    font-weight: 600;
    color: #6e6e6e;
    padding-bottom: 10px;

    ${media.xl`
        font-size: 20px;
    `}
`;

const Promotion = styled.div`
    ${media.md`
        order: 2;
        padding-bottom: 10px;
    `}

    ${media.xl`
        padding-bottom: 12px;
    `}
`;

const RechargeDesc = styled.span`
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    color: #9c92ff;

    ${media.md`
        font-size: 16px;
    `}
`;

const RechargeIcon = styled(Icon)`
    margin-bottom: 4px;

    ${media.md`
        font-size: 30px;
        margin-bottom: 10px;
    `}
`;

const HiddenAmount = styled.div`
    display: none;

    ${media.md`
        opacity: 1;
        transition: opacity .3s;
        display: flex;
    `}
`;

const HiddenButton = styled.div`
    display: flex;

    ${media.md`
        display: none;
        opacity: 0;
        transition: opacity .3s;
    `}
`;

const Recharge = styled.div`
    width: 100%;
    height: 61px;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #9c92ff;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${media.md`
        height: 90px;
        border: none;
        box-shadow: none;
        background-color: transparent;

        &:hover{
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px #9c92ff;
            background-color: #fbfbfb;

            ${HiddenButton} {
                opacity: 1;
                display: flex;
            }
            ${HiddenAmount} {
                opacity: 0;
                display: none;
            }
        }
    `}
`;

const AmountDesc = styled.span`
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    color: #9d9a9a;

    ${media.md`
        font-size: 16px;
    `}
`;

const Amount = styled.span`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #ff7d6a;
    padding-bottom: 3px;

    ${media.md`
        font-size: 20px;
    `}
`;

const AmountCol = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;

    ${media.md`
        padding-left: 15px;
        padding-right: 15px;
   `}

    ${media.xl`
        padding-left: 10px;
        padding-right: 10px;
   `}
`;

const AmountRow = styled(Row)`
    margin-bottom: 10px;
    margin-left: -5px;
    margin-right: -5px;

    ${media.md`
        order: 1;
        margin-bottom: 20px;
        margin-left: -15px;
        margin-right: -15px;
   `}

    ${media.xl`
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 30px;
   `}
`;

const ProfileDesc = styled.span`
    display: block;
    font-size: 12px;
    text-align: center;
    color: #9d9a9a;
    padding-top: 5px;
    padding-bottom: 30px;

    ${media.md`
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 50px;
    `}

    ${media.xl`
        padding-top: 40px;
    `}
`;

const CustomInput = styled(Input)`
    margin-bottom: 10px;
`;

const ProfileCol = styled(Col)`
    ${media.md`
        padding-left: 5px;
        padding-right: 5px;
    `}
`;

const ProfileRow = styled(Row)`
    ${media.md`
        margin-right: -5px;
        margin-left: -5px;
    `}
`;

const ProfileTitle = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;
    padding-bottom: 10px;
    display: block;

    ${media.md`
        font-size: 18px;
        font-weight: 600;
    `}

    ${media.xl`
        font-size: 20px;
    `}
`;

const ProfileSection = styled.div<any>`
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s;

    ${media.md`
        max-height: 100vh;
        order: 3;
    `}

    ${props => props.isVisible && css`
        max-height: 100vh;
    `}
`;

const ChevronButton = styled.button`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 6px;
`;

const Vip = styled.div<any>`
    height: 20px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    ${media.md`
        width: 84px;
        height: 30px;
    `}

    ${props => props.isVip && css`background-color: ${site.theme.thirdColor};`}
    ${props => !props.isVip && css`background-color: #ff8e3b;`}
`;

const VipSection = styled.div`
    position: absolute;
    right: 10px;

    ${media.md`
        position: initial;
    `}
`;

const Email = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.md`
        font-size: 16px;
    `}
`;

const Phone = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.md`
        font-size: 16px;
        padding-bottom: 3px;
    `}
`;

const AccountName = styled.span`
    font-size: 14px;
    color: #6e6e6e;
    padding-bottom: 6px;

    ${media.md`
        font-size: 20px;
        padding-bottom: 12px;
        font-weight: bold;
    `}
`;

const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

// const AvatarInput = styled.input`
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     opacity: 0;
//     cursor: pointer;
// `;

// const Edit = styled.div`
//     width: 100%;
//     height: 21px;
//     background-color: rgba(0, 0, 0, 0.5);
//     position: absolute;
//     bottom: 0;
//     font-size: 11px;
//     color: #ffffff;
//     display: none;
//     align-items: center;
//     justify-content: center;
//
//     ${media.md`
//         display: flex;
//     `}
// `;

const Avatar = styled.div<any>`
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #edeff2;
    margin-right: 10px;
    background-image: url(${(props: any) => props.src});
    background-size: contain;
    position: relative;
    overflow: hidden;
    //cursor: pointer;

    ${media.md`
        width: 80px;
        height: 80px;
        margin-right: 30px;
    `}

    ${media.md`
        margin-right: 23px;
    `}
`;

const ProfileInfo = styled.div`
    width: 100%;
    height: 80px;
    border-radius: 4px;
    border: solid 0.5px #d8d8d8;
    background-color: #ffffff;
    padding: 0 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    ${media.md`
        cursor: auto;
        height: 120px;
        padding: 0 40px;
        margin-bottom: 20px;
    `}

    ${media.xl`
        height: 140px;
        padding: 0 30px;
    `}
`;

const ProfileContainer = styled(Container)`
    ${media.xl`
        max-width: 770px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
