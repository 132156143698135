import React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {Row, Col, media} from 'library/styled-bs-grid';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';
import {checkIsMobile} from 'utils/browser';
import {useDispatch, useSelector} from 'react-redux';

// Component
import Icon from 'components/atoms/Icon';
import HeartButton from 'components/atoms/Button/HeartButton';
import Tag from 'components/atoms/Tag';

// Store
import {Selector as AuthSelector} from 'store/auth';
import {Actions as ProductActions} from 'store/product';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    isVisibleHeart?: boolean;
    isVisiblePrice?: boolean;
    isVisibleDetail?: boolean;
    isVisibleSpeed?: boolean;
    id: number;
    brandName: string;
    name: string;
    thumbUrl: string;
    downloadThumbUrl: string;
    model: string;
    size: string;
    color: string;
    price: number;
    prePrice: number;
    createDate: string;
    isOrderNormalGoods?: boolean;
    isNew: boolean;
    isInStock: boolean;
    isSale: boolean;
    isHot: boolean;
    isOutStock: boolean;
    isVipGoods: boolean;
    isFavorite: boolean;
    detailPath: string;
    onSpeedOrder: Function;
    onClickFavorite: Function;
}

/**
 * ProductTags 產品標籤
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProductCard: React.FC<IProps> = ({
    className,
    style,
    isVisibleHeart = true,
    isVisiblePrice = true,
    isVisibleDetail = true,
    isVisibleSpeed = true,
    id,
    brandName,
    name,
    thumbUrl,
    downloadThumbUrl,
    model,
    size,
    color,
    price,
    prePrice,
    createDate,
    isOrderNormalGoods = false,
    isNew = false,
    isInStock = false,
    isSale = false,
    isHot = false,
    isOutStock = false,
    isVipGoods = false,
    isFavorite = false,
    detailPath,
    onSpeedOrder,
    onClickFavorite,
}) => {

    const dispatch = useDispatch();

    // let lastTouchEnd = 0;

    //
    // const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    //     lastTouchEnd = (new Date()).getTime();
    // };
    //
    //
    // const onMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    //     const now = (new Date()).getTime();
    //     if (now - lastTouchEnd <= 300) {
    //         event.preventDefault();
    //
    //         if (!checkIsMobile()) {
    //             window.open(detailPath);
    //         }
    //     }
    // };

    // const logoRef = useRef<HTMLImageElement>(null);
    //
    // const onEnterView = (entries: any[], observer: any) => {
    //     for (let entry of entries) {
    //         if (entry.isIntersecting) {
    //             // 監視目標進入畫面
    //             const img = entry.target;
    //             img.setAttribute('style', `background-image: url(${isEmpty(img.dataset.src) ? asset('/images/goods/default.jpg') : img.dataset.src}); opacity: 1`);
    //             img.removeAttribute('data-src');
    //             observer.unobserve(img);
    //         }
    //     }
    // };
    //
    // useEffect(() => {
    //     const watcher = new IntersectionObserver(onEnterView);
    //     if(logoRef.current){
    //         watcher.observe(logoRef.current); // 開始監視
    //     }
    // }, []);

    const {isAuth} = useSelector(state => ({
        isAuth: AuthSelector.isAuth(state),
    }));

    const handlePressSpeed = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // 终止事件冒泡
        onSpeedOrder();
    };

    /**
     * 下載圖片
     */
    const download = (event: React.MouseEvent<HTMLButtonElement>, src: string) => {
        event.stopPropagation(); // 终止事件冒泡


        dispatch(ProductActions.downloadImages({id}));
    };

    return (
        <ProductCardRoot
            className={className}
            style={style}
            isVisibleDetail={isVisibleDetail}
            isVisibleSpeed={isVisibleSpeed}
            isMobile={checkIsMobile()}
            onClick={() => {
                if (!checkIsMobile()) {
                    window.open(detailPath);
                }
            }}
        >
            <ImgSection>
                <ProductBg
                    // ref={logoRef}
                    // data-src={thumbUrl}
                    // style={{backgroundImage: `url(${asset('/images/goods/default.jpg')})`}}
                    thumbUrl={isEmpty(thumbUrl) ? asset('/images/goods/default.jpg') : thumbUrl}
                />

                <ProductTagsRow noGutters>
                    <Col col>
                        <TagRow>
                            {isNew && (
                                <TagCol col="auto">
                                    <Tag theme="default" text="New"/>
                                </TagCol>
                            )}

                            {isInStock && (
                                <TagCol col="auto">
                                    <Tag theme="orange" text="現貨"/>
                                </TagCol>
                            )}

                            {isSale && (
                                <TagCol col="auto">
                                    <Tag theme="danger" text="特價"/>
                                </TagCol>
                            )}

                            {isHot && (
                                <TagCol col="auto">
                                    <Tag theme="purple" text="熱賣"/>
                                </TagCol>
                            )}

                            {isVipGoods && (
                                <TagCol col="auto">
                                    <Tag theme="yellow" isStar/>
                                </TagCol>
                            )}
                        </TagRow>
                    </Col>

                    {/* 加入追蹤清單 */}
                    {isVisibleHeart && (
                        <Col col="auto">
                            <CustomHeartButton
                                size={45}
                                isActive={isFavorite}
                                onClickFavorite={onClickFavorite}
                            />
                        </Col>
                    )}
                </ProductTagsRow>

                {isOutStock && <OutStock>已售完</OutStock>}

                <Brand>
                    <BrandName>{brandName}</BrandName>
                </Brand>

                <HoverBg>
                    {isVisibleSpeed && (
                        <OrderButton
                            type="button"
                            onClick={handlePressSpeed}
                        >
                            <CustomIcon code="plus-circle" size={15} color="#fff"/>
                            快速下單
                        </OrderButton>
                    )}

                    {isVisibleDetail && (
                        <DetailButton
                            href={detailPath}
                            target="_blank"
                        >
                            <CustomIcon code="ellipsis-h-circle" size={15} color="#fff"/>
                            商品詳情
                        </DetailButton>
                    )}

                    {/* 下載圖片按鈕 */}
                    {isAuth && (
                        <DownloadButton
                            type="button"
                            onClick={(e) => download(e, downloadThumbUrl)}
                        >
                            <Icon code="image-download" size={25} color="#fff"/>
                        </DownloadButton>
                    )}
                </HoverBg>
            </ImgSection>

            <Name isBlod>{name}</Name>
            <SizeSection>
                <Size>Size：{size}</Size>
            </SizeSection>
            <Color>顏色：{color}</Color>

            {(isVisiblePrice && isOrderNormalGoods) && (
                <PriceArea>
                    {/* [特價] 且 [前次售價金額不為0] 且 [前次售價金額不等於特價金額] */}
                    {(isSale && prePrice > 0 && price !== prePrice) && <PrePrice>${prePrice}</PrePrice>}
                    <Price isSale={isSale}>$ {price}</Price>
                </PriceArea>
            )}

            <div className="d-flex justify-content-between">
                <Model>{model}</Model>
                <CreatAt>{createDate}</CreatAt>
            </div>
        </ProductCardRoot>
    );
};

export default ProductCard;

const PriceArea = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 5px;
`;

const PrePrice = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    color: #6e6e6e;
    text-decoration: line-through;
    padding-right: 4px;

    ${media.md`
        font-size: 14px;
        padding-right: 19px;
    `}

    ${media.xl`
        font-size: 16px;
        padding-right: 10px;
    `}
`;

const Price = styled.span<any>`
    font-size: 16px;
    font-weight: bold;
    color: #6e6e6e;

    ${media.xl`
        font-size: 20px;
    `}

    ${props => props.isSale && css`
        color: #ff7d6a;
    `}
`;

const Name = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;
    display: block;
    padding-bottom: 5px;

    ${props => props.isBlod && css`
        font-weight: 600;
    `}

    ${media.xl`
        font-size: 14px;
    `}
`;

const CreatAt = styled(Name)`
    padding-bottom: 0;
`;

const Model = styled(Name)`
    padding-bottom: 0;
`;

const Size = styled(Name)`
    padding-bottom: 0;
`;

const SizeSection = styled.div`
    max-height: 60px;
    overflow-y: scroll;
    margin-bottom: 5px;
`;

const Color = styled(Name)``;

const CustomIcon = styled(Icon)`
    margin-right: 7px;

    ${media.md`
        margin-right: 13px;
        font-size: 25px;
    `}
`;

const DownloadButton = styled.button`
    position: absolute;
    bottom: 23px;
    padding: 0;

    ${media.sm`
        bottom: 40px;

        i{
            font-size: 35px;
        }
    `}

    ${media.xl`
        bottom: 45px;

        i{
            font-size: 40px;
        }
    `}
`;

const DetailButton = styled.a`
    width: 110px;
    height: 30px;
    border-radius: 2px;
    border: solid 1px #ffffff;
    background-color: rgba(168, 213, 128, 0);
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    &:active {
        color: #ffffff;
    }

    ${media.md`
        width: 150px;
        height: 40px;
        font-size: 16px;
        border-radius: 5px;
    `}
`;

const OrderButton = styled.button<any>`
    width: 110px;
    height: 30px;
    border-radius: 2px;
    background-color: #8ec5ff;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    margin: 5px 0;
    pointer-events: none;

    ${media.md`
        width: 150px;
        height: 40px;
        font-size: 16px;
        border-radius: 5px;
    `}
`;

const HoverBg = styled.div`
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .3s;
`;

const BrandName = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;


    ${media.md`
        font-size: 16px;
    `}
`;

const Brand = styled.div`
    width: 100%;
    height: 20px;
    background-color: rgba(40, 39, 49, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;

    ${media.md`
        height: 30px;
    `}
`;

const OutStock = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: opacity .3s;

    ${media.sm`
        width: 100px;
        height: 100px;
        font-size: 14px;
    `}

    ${media.md`
        font-size: 16px;
    `}

    ${media.xl`
        width: 120px;
        height: 120px;
    `}
`;

const CustomHeartButton = styled(HeartButton)<any>`
    z-index: 1;
    border: solid 1px transparent;
`;

const TagCol = styled(Col)`
    padding: 0 2px;

    ${media.md`
        padding: 0 2.5px;
    `}
`;

const TagRow = styled(Row)`
    margin: 0 -2px;
    padding-top: 5px;
    padding-left: 4px;
    opacity: 1;
    transition: opacity .3s;

    ${media.md`
        margin: 0 -2.5px;
        padding-top: 10px;
        padding-left: 10px;
    `}
`;

const ProductTagsRow = styled(Row)`
    position: absolute;
    width: 100%;
    top: 0;
`;

const ProductBg = styled.div<any>`
    width: 100%;
    padding-bottom: 114%;
    background-image: url("${props => props.thumbUrl}");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    //opacity: 0;
    transition: transform .5s, opacity .3s;
`;

const ImgSection = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 5px;
    position: relative;
`;

const ProductCardRoot = styled.div<any>`
    ${props => (props.isVisibleDetail || props.isVisibleSpeed) && css`
        cursor: pointer;

        &:hover {
            ${HoverBg} {
                opacity: 1;
            }

            ${OutStock} {
                opacity: 0;
            }

            ${ProductBg} {
                transform: scale(1.1);
            }

            ${TagRow} {
                opacity: 0;
            }

            ${OrderButton} {
                pointer-events: auto;
            }

            ${(props: any) => props.isMobile && css`
                ${DetailButton} {
                    pointer-events: auto;
                }
            `}
        }
    `}
`;

