// Libraries
import api from 'library/apisauce';

import {TGetPreSaleProduct, TPostPreSaleBatch, TPostPreSalePick} from './types';

export default {
    /**
     * 查詢 預先下特清單
     */
    getPreSaleProduct: (startDate?: string, endDate?: string, model?: string): Promise<TGetPreSaleProduct> => {
        return api.get('/member/preSale', {startDate, endDate, model}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 商品到預先下特(整批)
     */
    postPreSaleBatch: (productGoodsId: number, batchQty: number): Promise<TPostPreSaleBatch> => {
        return api.post('/member/preSale/batch', {productGoodsId, batchQty}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 商品到預先下特(挑碼)
     */
    postPreSalePick: (productGoodsId: number, sizeQty: string, isInStock: boolean): Promise<TPostPreSalePick> => {
        return api.post('/member/preSale/pick', {productGoodsId, sizeQty, isInStock}, {
            headers: {isAuth: true},
        });
    },
};
