import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {GridThemeProvider, Container, Row, Col, media} from 'library/styled-bs-grid';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import site from 'config/site';
import {get} from 'lodash';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {isEmpty} from 'utils/equal';

// Component
import HomeBannerCarousel from 'components/organisms/HomeBannerCarousel';
import MallCarousel from 'components/organisms/MallCarousel';
import ProductCarousel from 'components/organisms/ProductCarousel';
import HotProductCarousel from 'components/organisms/HotProductCarousel';
import Button from 'components/atoms/Button';
import Tabs from 'components/atoms/Tabs';

// Reducer
import {Actions as ProductAction} from 'store/product';
import {Actions as AdAction} from 'store/ad';
import {Selector as AuthSelector} from 'store/auth';


/**
 * 首頁
 */
const Home: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState<number|''>('');

    const {isAuth, isFetching, mallList, homeCarousel, placementAd, otherRelated, hotBrand, productGoods} = useSelector(state => ({
        isAuth: AuthSelector.isAuth(state),

        isFetching: state.product.isFetching,
        mallList: state.product.mallList,
        otherRelated: state.product.otherRelated,
        hotBrand: state.product.hotBrand,
        productGoods: state.product.productGoods||[],

        homeCarousel: state.ad.homeCarousel,
        placementAd: state.ad.placementAd||[],
    }));

    useEffect(() => {
        // 取得商城列表
        if (get(mallList, 'length', 0) === 0) {
            dispatch(ProductAction.fetchProductMall());
        }

        // 取得首頁廣告輪播
        if (get(homeCarousel, 'length', 0) === 0) {
            dispatch(AdAction.fetchHomeCarousel());
        }

        // 取得其他綜合相關商品
        if (
            get(otherRelated, 'newProductGoods.length', 0) === 0 ||
            get(otherRelated, 'inStockProductGoods.length', 0) === 0 ||
            get(otherRelated, 'saleProductGoods.length', 0) === 0 ||
            get(otherRelated, 'hotProductGoods.length', 0) === 0
        ) {
            dispatch(ProductAction.fetchOtherRelated());
        }

        // 取得熱門品牌
        if (get(hotBrand, 'length', 0) === 0) {
            dispatch(ProductAction.fetchHotBrand());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        // 預設選取第一個熱門品牌
        if (isEmpty(activeIndex)) {
            setActiveIndex(get(hotBrand, '0.id'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotBrand]);


    useEffect(() => {
        // 取得熱門品牌商品
        if (!isEmpty(activeIndex)) {
            const brands = [activeIndex];

            if (isAuth) {
                // @ts-ignore
                dispatch(ProductAction.fetchProductGoods({brands}));
            } else {
                // @ts-ignore
                dispatch(ProductAction.fetchProductGoodsGuest({brands}));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex]);



    // 各區塊置入廣告資訊
    const homeMiddleLeftAd = placementAd.find(o => o.code === 'homeMiddleLeft');
    const homeMiddleRightTopAd = placementAd.find(o => o.code === 'homeMiddleRightTop');
    const homeMiddleRightBottomAd = placementAd.find(o => o.code === 'homeMiddleRightBottom');
    const homeBottomAd = placementAd.find(o => o.code === 'homeBottom');

    return (
        <>
            {/* Banner輪播 */}
            <CustomHomeBannerCarousel data={homeCarousel}/>


            {/* 最新商品 */}
            <ProductCarouselRoot>
                <Title>
                    <ChineseTitle isNew>最新商品</ChineseTitle>
                    <EnglishTitle>NEW ARRIVALS</EnglishTitle>
                    <All onClick={() => history.push('/product?popularId=2')}>查看全部</All>
                </Title>
            </ProductCarouselRoot>
            {(get(otherRelated, 'newProductGoods.length', 0) === 0 && isFetching) && (
                <Row>
                    <Col col>
                        <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                    </Col>
                </Row>
            )}
            <CustomProductCarousel
                isAuth={isAuth}
                data={get(otherRelated, 'newProductGoods', [])}
            />

            {/* 現貨商品 */}
            <ProductCarouselRoot>
                <Title>
                    <ChineseTitle isInStock>現貨商品</ChineseTitle>
                    <EnglishTitle>IN-STOCK</EnglishTitle>
                    <All onClick={() => history.push('/product?popularId=1')}>查看全部</All>
                </Title>
            </ProductCarouselRoot>
            {(get(otherRelated, 'inStockProductGoods.length', 0) === 0 && isFetching) && (
                <Row>
                    <Col col>
                        <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                    </Col>
                </Row>
            )}
            <CustomProductCarousel
                isAuth={isAuth}
                data={get(otherRelated, 'inStockProductGoods', [])}
            />



            {/* 特價商品 */}
            <ProductCarouselRoot>
                <Title>
                    <ChineseTitle isOnSale>特價商品</ChineseTitle>
                    <EnglishTitle>Special offer</EnglishTitle>
                    <All onClick={() => history.push('/product?popularId=3')}>查看全部</All>
                </Title>
            </ProductCarouselRoot>
            {(get(otherRelated, 'saleProductGoods.length', 0) === 0 && isFetching) && (
                <Row>
                    <Col col>
                        <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                    </Col>
                </Row>
            )}
            <CustomProductCarousel
                isAuth={isAuth}
                data={get(otherRelated, 'saleProductGoods', [])}
            />


            {/* 置入廣告（homeMiddleLeft, homeMiddleRightT, homeMiddleRightB）*/}
            <AdContainer className="d-none d-md-flex">
                <AdRow>
                    <AdLeftCol col={24} xl={12}>
                        <MiddleLeftAd imageUrl={get(homeMiddleLeftAd, 'imageUrl')}>
                            <MiddleLeftShopButton
                                themeType="whiteBorderBlur"
                                size="middle"
                                shape="raised"
                                isBlurBg
                                onClick={() => history.push(get(homeMiddleLeftAd, 'routePath', ''))}
                            >
                                SHOP NOW
                            </MiddleLeftShopButton>
                        </MiddleLeftAd>
                    </AdLeftCol>

                    <AdRightCol col={24} xl={12}>
                        <AdRow>
                            <Col col={24} style={{padding: 0}}>
                                <MiddleRightAd imageUrl={get(homeMiddleRightTopAd, 'imageUrl')}>
                                    <MiddleRightShopButton
                                        themeType="whiteBorderBlur"
                                        size="middle"
                                        shape="raised"
                                        isBlurBg
                                        onClick={() => history.push(get(homeMiddleRightTopAd, 'routePath', ''))}
                                    >
                                        SHOP NOW
                                    </MiddleRightShopButton>
                                </MiddleRightAd>
                            </Col>
                            <Col col={24} style={{padding: 0}}>
                                <MiddleRightBottomAd imageUrl={get(homeMiddleRightBottomAd, 'imageUrl')}>
                                    <MiddleRightShopButton
                                        themeType="whiteBorderBlur"
                                        size="middle"
                                        shape="raised" isBlurBg
                                        onClick={() => history.push(get(homeMiddleRightBottomAd, 'routePath', ''))}
                                    >
                                        SHOP NOW
                                    </MiddleRightShopButton>
                                </MiddleRightBottomAd>
                            </Col>
                        </AdRow>
                    </AdRightCol>
                </AdRow>
            </AdContainer>


            {/* 熱賣商品 */}
            <GridThemeProvider
                gridTheme={{
                    gridColumns: 30,
                }}
            >
                <HotProductCarouselRoot>
                    <Row>
                        {/* 置入廣告（homeBottom）*/}
                        <AdBottomCol className="d-none d-xl-block" col>
                            <AdBottom imageUrl={get(homeBottomAd, 'imageUrl')}>
                                <BottomShopButton
                                    themeType="whiteBorderBlur"
                                    size="middle"
                                    shape="raised"
                                    isBlurBg
                                    onClick={() => history.push(get(homeBottomAd, 'routePath', ''))}
                                >
                                    SHOP NOW
                                </BottomShopButton>
                            </AdBottom>
                        </AdBottomCol>

                        {/* 熱賣商品 */}
                        <Col col={30} xl={18}>
                            <Title>
                                <ChineseTitle isHot>熱賣商品</ChineseTitle>
                                <EnglishTitle>BEST SELLERS</EnglishTitle>
                                <All onClick={() => history.push('/product?popularId=0')}>查看全部</All>
                            </Title>
                            {(get(otherRelated, 'hotProductGoods.length', 0) === 0 && isFetching) && (
                                <Row>
                                    <Col col>
                                        <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                                    </Col>
                                </Row>
                            )}
                            <CustomHotProductCarousel
                                isAuth={isAuth}
                                data={get(otherRelated, 'hotProductGoods', [])}
                            />
                        </Col>
                    </Row>
                </HotProductCarouselRoot>
            </GridThemeProvider>


            {/* 置入廣告（homeBottom）*/}
            <AdBottom className="d-none d-md-block d-xl-none" imageUrl={get(homeBottomAd, 'imageUrl')}>
                <BottomShopButton
                    themeType="whiteBorderBlur"
                    size="middle"
                    shape="raised"
                    isBlurBg
                    onClick={() => history.push(get(homeBottomAd, 'routePath', ''))}
                >
                    SHOP NOW
                </BottomShopButton>
            </AdBottom>


            {/* 熱賣品牌 */}
            <ProductCarouselRoot className="d-none d-lg-block">
                <HotTitle>熱賣品牌</HotTitle>
                <TabsSection className="d-flex justify-content-between align-items-center">
                    <CustomTabs
                        item={hotBrand.map(row => (
                            {code: row.id, name: row.name}
                        ))}
                        activeCode={activeIndex || 0}
                        onChangeIndex={(code: number) => setActiveIndex(code)}
                    />
                    <ReadMore onClick={() => history.push('brand')}>全部品牌</ReadMore>
                </TabsSection>
            </ProductCarouselRoot>
            {(get(productGoods, 'length', 0) === 0 && isFetching) && (
                <Row className="d-none d-lg-flex">
                    <Col col>
                        <HalfCircleSpinner style={{margin: '0 auto'}} size={30} color="#bdbdbd"/>
                    </Col>
                </Row>
            )}
            <HotBrandProductCarousel
                isAuth={isAuth}
                className="d-none d-lg-flex"
                data={productGoods}
            />


            {/* 商城清單輪播 */}
            <CustomMallCarousel data={mallList}/>
        </>
    );
};

export default Home;

const CustomMallCarousel = styled(MallCarousel)`
    margin-bottom: 80px;

    ${media.md`
        margin-bottom: 45px;
    `}

    ${media.lg`
        margin-bottom: 60px;
    `}

    ${media.xl`
        margin-bottom: 74px;
    `}
`;

const HotBrandProductCarousel = styled(ProductCarousel)`
    margin-bottom: 60px;

    ${media.xl`
        margin-bottom: 84px;
    `}
`;

const ReadMore = styled.div`
    min-width: 64px;
    font-size: 16px;
    color: ${site.theme.primaryColor};
    cursor: pointer;
`;

const CustomTabs = styled(Tabs)`
    ::-webkit-scrollbar {
        display: none;
    }

    ${media.xl`
        ul{
            li{
                padding: 0 70px 0 0;
            }
        }
    `}
`;

const TabsSection = styled.div`
    margin-bottom: 30px;

    ${media.xl`
        margin-bottom: 40px;
    `}
`;

const HotTitle = styled.div`
    font-size: 36px;
    font-weight: 600;
    color: #2d2c38;
    margin-bottom: 20px;

    ${media.xl`
        font-size: 40px;
        margin-bottom: 30px;
    `}
`;

const CustomHotProductCarousel = styled(HotProductCarousel)`
    ${media.xl`
        padding-bottom: 30px;
    `}
`;

const AdBottom = styled.div<any>`
    background-image: url("${props => props.imageUrl}");
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-bottom: 65%;
    margin-bottom: 65px;

    ${media.lg`
        padding-bottom: 50%;
        margin-bottom: 30px;
    `}

    ${media.xl`
        height: 100%;
        padding-bottom: 0;
    `}
`;

const AdBottomCol = styled(Col)`
    padding-right: 55px;

    ${media.xxl`
        padding-right: 68px;
    `}
`;

const MiddleRightAd = styled.div<any>`
    background-image: url("${props => props.imageUrl}");
    width: 100%;
    background-size: cover;
    padding-bottom: 34%;
    margin-bottom: 30px;
    position: relative;

    ${media.lg`
        margin-bottom: 20px;
    `}

    ${media.xxl`
        margin-bottom: 30px;
    `}
`;

const MiddleRightBottomAd = styled(MiddleRightAd)`
    ${media.lg`
        margin-bottom: 50px;
    `}

    ${media.xl`
        margin-bottom: 0;
    `}
`;

const MiddleLeftAd = styled.div<any>`
    background-image: url("${props => props.imageUrl}");
    width: 100%;
    background-size: cover;
    padding-bottom: 65%;
    margin-bottom: 30px;
    position: relative;

    ${media.lg`
        padding-bottom: 50%;
        margin-bottom: 20px;
    `}

    ${media.xl`
        margin-bottom: 0;
        height: 100%;
    `}
`;

const AdRightCol = styled(Col)`
    padding: 0;

    ${media.xl`
        padding-left: 10px;
    `}

    ${media.xxl`
        padding-left: 15px;
    `}
`;

const AdLeftCol = styled(Col)`
    padding: 0;

    ${media.xl`
        padding-right: 10px;
    `}

    ${media.xxl`
        padding-right: 15px;
    `}
`;

const AdRow = styled(Row)`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
`;
const AdContainer = styled(Container)`
    width: 100%;
    max-width: initial;
    padding: 0;

    ${media.xl`
        max-width: 1140px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 50px;
    `}

    ${media.xxl`
        max-width: 1460px;
        padding-left: 40px;
        padding-right: 40px;
    `}
`;

const ShopButton = styled(Button)`
    width: 100px;
    height: 30px;
    min-height: auto;
    padding: 0;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;

    ${media.md`
        width: 132px;
        height: 40px;
        font-size: 16px;
    `}

    ${media.lg`
        width: 174px;
        height: 46px;
    `}
`;

const MiddleLeftShopButton = styled(ShopButton)`
    width: 174px;
    height: 46px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 68px;
    margin: auto;

    ${media.lg`
        bottom: 88px;
    `}

    ${media.xl`
        bottom: 60px;
    `}

    ${media.xxl`
        bottom: 68px;
    `}
`;

const MiddleRightShopButton = styled(MiddleLeftShopButton)`
    bottom: 0;
    top: 0;

    ${media.lg`
        bottom: 0;
    `}

    ${media.xl`
        bottom: 0;
    `}

    ${media.xxl`
        bottom: 0;
    `}
`;

const BottomShopButton = styled(MiddleLeftShopButton)`
    ${media.md`
        bottom: 60px;
    `}

    ${media.lg`
        bottom: 68px;
    `}

    ${media.xl`
        bottom: 100px;
    `}

    ${media.xxl`
        bottom: 200px;
    `}
`;

const CustomProductCarousel = styled(ProductCarousel)`
    margin-bottom: 30px;

    ${media.lg`
        margin-bottom: 50px;
    `}
`;

const All = styled.span`
    font-size: 12px;
    color: ${props => props.theme.primaryColor};
    cursor: pointer;

    ${media.md`
        font-size: 14px;
        position: absolute;
        right: 0;
        bottom: 20px;
    `}

    ${media.lg`
        font-size: 16px;
    `}

    ${media.xl`
        bottom: 50px;
    `}
`;

const EnglishTitle = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e6e6e;
    flex: 1 1 auto;

    ${media.md`
        font-size: 16px;
    `}

    ${media.lg`
        font-size: 20px;
    `}

    ${media.xl`
        font-size: 24px;
    `}
`;

const ChineseTitle = styled.span<any>`
    font-size: 16px;
    font-weight: 600;
    padding-right: 6px;

    ${props => props.isNew && css `color: ${site.theme.primaryColor};`}
    ${props => props.isInStock && css `color: ${site.theme.thirdColor};`}
    ${props => props.isOnSale && css `color: #ff8e3b;`}
    ${props => props.isHot && css `color: #fd8974;`}

    ${media.md`
          font-size: 30px;
          padding-right: 0;
          padding-bottom: 10px;
    `}

    ${media.lg`
          font-size: 36px;
    `}

    ${media.xl`
          font-size: 40px;
    `}
`;

const Title = styled.div`
    padding-bottom: 10px;
    display: flex;
    align-items: flex-end;
    position: relative;

    ${media.md`
          padding-bottom: 20px;
          flex-direction: column;
          align-items: center;
    `}

    ${media.xl`
          padding-bottom: 50px;
    `}
`;

const ProductCarouselRoot = styled(Container)`
    ${media.xl`
        padding-left: 30px;
        padding-right: 30px;
    `}

    ${media.xxl`
        padding-left: 40px;
        padding-right: 40px;
    `}
`;

const HotProductCarouselRoot = styled(ProductCarouselRoot)`
    margin-bottom: 30px;

    ${media.lg`
        margin-bottom: 50px;
    `}

    ${media.xl`
        margin-bottom: 80px;
    `}

    ${media.xxl`
        margin-bottom: 100px;
    `}
`;

const CustomHomeBannerCarousel = styled(HomeBannerCarousel)`
    margin-bottom: 30px;

    ${media.md`
        margin-bottom: 40px;
    `}

    ${media.xl`
        margin-bottom: 50px;
    `}
`;
