import {Reducer as startUp} from './startUp';
import {Reducer as language} from './language';
import {Reducer as system} from './system';
import {Reducer as auth} from './auth';
import {Reducer as level} from './level';
import {Reducer as uiBlock} from './uiBlock/reducer';
import {Reducer as uiDialog} from './uiDialog/reducer';

import {Reducer as profile} from './profile';
import {Reducer as message} from './message';
import {Reducer as track} from './track';
import {Reducer as preSale} from './preSale';
import {Reducer as order} from './order';
import {Reducer as shipment} from './shipment';
import {Reducer as trade} from './trade';

import {Reducer as product} from './product';
import {Reducer as cart} from './cart';
import {Reducer as ad} from './ad';


import {Reducer as deposit} from './deposit';

/** -----------------------------------------
            Assemble The Reducers
 /** ---------------------------------------*/
export const rootReducers = {
    startUp,
    language,
    system,
    auth,
    level,
    uiBlock,
    uiDialog,

    profile,
    message,
    track,
    preSale,
    order,
    shipment,
    trade,

    product,
    cart,
    ad,

    deposit,
};
