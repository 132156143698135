// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetTradeHistory} from 'services/trade';


/**
 * 取得交易紀錄清單
 */
function* fetchTradeHistory(action: IPickAction<IActionCreators, 'fetchTradeHistory'>) {
    yield put(Actions.fetchTradeHistoryBegin());

    try {
        const {currentPage, pageLimit} = action.payload;
        const [{body}]: [TGetTradeHistory] = yield all([
            call(ApiService.getTradeHistory, currentPage, pageLimit),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, ...meta} = body.data;
        yield put(Actions.fetchTradeHistorySuccess({tradeHistory: rows, tradeHistoryPagination: meta}));

    } catch (err) {
        yield put(Actions.fetchTradeHistoryFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}



export default [
    takeLatest(ActionTypes.FETCH_TRADE_HISTORY, fetchTradeHistory),
];
