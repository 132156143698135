import {ApiResponse, ApisauceInstance, create} from 'apisauce';
import {AxiosRequestConfig} from "axios";
import {autoMapper} from 'utils/format';
import {api} from 'config/app';

export const apiService: ApisauceInstance = create({
    baseURL: api.baseUrl,
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    },
    timeout: api.timeout,
});

const reMapping: <T>(apiRequest: ApiResponse<T>) => any = (apiRequest) => autoMapper(apiRequest, {data: 'body'});

export default {
    get: async (url: string, params?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.get(url, params, config)),
    delete: async (url: string, data?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.delete(url, {}, {...config, data: data})),
    post: async (url: string, data?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.post(url, data, config)),
    put: async (url: string, data?: {}, config?: AxiosRequestConfig) => reMapping(await apiService.put(url, data, config)),
};
