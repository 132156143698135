import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import {isEmpty} from 'lodash';
import get from 'lodash/get';
import site from 'config/site';
import {Col, Container, media, Row} from 'library/styled-bs-grid';
import {useDispatch, useSelector} from 'react-redux';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {formatCurrency} from 'utils/number';
import dayjs from 'dayjs';

// Component
import Icon from 'components/atoms/Icon';
import StatusTag from 'components/atoms/Tag/StatusTag';
import DepositNoticeModal from 'components/organisms/DepositNoticeModal';
import Pagination from 'components/atoms/Pagination';

// Reducers
import {Actions as DepositAction} from 'store/deposit';
import {Actions as SystemAction} from 'store/system';
import {IFormData} from './types';



// 一頁顯示筆數
const pageLimit = 20;
// 審核狀態
const statusConfig = {
    pending: {themeType: 'default', text: '審核中'},
    success: {themeType: 'green', text: '已確認'},
    fail: {themeType: 'danger', text: '失敗'},
};



/**
 *  匯款狀態通知
 * @constructor
 */
const Remittance: React.FC = () => {
    const history = useHistory();
    const [isVisibleModal, setVisibleModal] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const dispatch = useDispatch();
    const {depositBank, isFetching, remitList, remitPagination} = useSelector(state => ({
        depositBank: state.system.depositBank,
        isFetching: state.deposit.isFetching,
        remitList: state.deposit.remitList,
        remitPagination: state.deposit.remitPagination,
    }));

    useEffect(() => {
        // 取得匯款銀行資訊
        if (get(depositBank, 'length', 0) === 0) {
            dispatch(SystemAction.fetchDepositBank());
        }

        // 取得匯款紀錄
        dispatch(DepositAction.fetchRemit({currentPage, pageLimit}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/account')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>匯款狀態</MobileTitle>

                    <IconButton/>
                </MobileHeader>
            </Container>

            {/* 填寫存款通知單按鈕 */}
            <div className="d-flex justify-content-center">
                <DepositButton
                    type="button"
                    onClick={() => setVisibleModal(true)}
                >
                    <Icon code="plus-circle" size={20} color={site.theme.thirdColor}/>
                    <DepositText>填寫存款通知單</DepositText>
                </DepositButton>
            </div>

            <RemittanceContainer>
                <RemittanceForm>
                        {/* 標題 */}
                        <Header className="d-none d-md-flex">
                            <Time>匯款時間</Time>
                            <Name>匯款人姓名</Name>
                            <Account>帳號後5碼</Account>
                            <Price>匯款金額</Price>
                            <Bank>入款銀行</Bank>
                            <Status>審核狀態</Status>
                            <Remark>備註</Remark>
                        </Header>

                        {isFetching && (
                            <Row>
                                <Col col>
                                    <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                                </Col>
                            </Row>
                        )}

                        {!isFetching && remitList.map(row => (
                            <Content key={row.id}>
                                <MobileCardHeader className="d-md-none">
                                    <CustomStatusTag theme={get(statusConfig, row.statusCode)?.themeType ?? ''} text={get(statusConfig, row.statusCode)?.text ?? ''}/>
                                    <div>
                                        <MobilePrice
                                            isFailed={(row.statusCode === 'fail' || (row.statusCode === 'success' && row.amount !== row.doneAmount))}
                                        >$ {formatCurrency(get(row, 'amount'))}</MobilePrice>
                                        {(row.statusCode === 'fail' || (row.statusCode === 'success' && row.amount !== row.doneAmount)) && (<MobilePrice>$ {formatCurrency(get(row, 'doneAmount'))}</MobilePrice>)}
                                    </div>
                                </MobileCardHeader>

                                <Time>
                                    <FieldTitle>匯款時間</FieldTitle>
                                    <FieldValue>{dayjs(get(row, 'fromDepositAt')).format('YYYY-MM-DD HH:mm:ss')}</FieldValue>
                                </Time>
                                <Name>
                                    <FieldTitle>匯款人姓名</FieldTitle>
                                    <FieldValue>{get(row, 'fromName')}</FieldValue>
                                </Name>
                                <Account>
                                    <FieldTitle>帳號後5碼</FieldTitle>
                                    <FieldValue>{get(row, 'fromLast5Number')}</FieldValue>
                                </Account>
                                <Price className="d-none d-md-flex flex-column justify-content-center align-items-start">
                                    <FieldValue
                                        isBold
                                        isFailed={(row.statusCode === 'fail' || (row.statusCode === 'success' && row.amount !== row.doneAmount))}
                                    >
                                        $ {formatCurrency(get(row, 'amount'))}
                                    </FieldValue>
                                    {(row.statusCode === 'fail' || (row.statusCode === 'success' && row.amount !== row.doneAmount)) && (<FieldValue isBold>$ {formatCurrency(get(row, 'doneAmount'))}</FieldValue>)}
                                </Price>
                                <Bank className="d-flex flex-md-column align-items-center justify-content-md-center align-items-md-start">
                                    <FieldTitle>入款銀行</FieldTitle>
                                    <FieldValue>{get(row, 'toBankName')}</FieldValue>
                                    <AccountValue>{get(row, 'toBankAccount')}</AccountValue>
                                </Bank>
                                <Status className="d-none d-md-block">
                                    <CustomStatusTag theme={get(statusConfig, row.statusCode)?.themeType ?? ''} text={get(statusConfig, row.statusCode)?.text ?? ''}/>
                                </Status>
                                <Remark>
                                    <FieldTitle>備註詳情</FieldTitle>
                                    <div className="d-flex flex-column">
                                        <FieldValue>{isEmpty(get(row, 'remark')) ? '' : get(row, 'remark')}</FieldValue>
                                        <FieldValue isGreen>回覆：{get(row, 'replayRemark')}</FieldValue>
                                    </div>
                                </Remark>
                            </Content>
                        ))}
                </RemittanceForm>
            </RemittanceContainer>


            {/* 分頁 */}
            <CustomPagination
                page={currentPage}
                totalPage={get(remitPagination, 'totalPages')}
                totalItems={get(remitPagination, 'totalItems')}
                onChange={(currentPage: number) => setCurrentPage(currentPage)}
            />

            {/* 匯款充值光箱 */}
            <DepositNoticeModal
                isVisible={isVisibleModal}
                bankList={depositBank}
                onSubmitForm={(data: IFormData) => dispatch(DepositAction.fetchPending({fromName: data.fromName, fromDepositAt: data.fromDepositAt, fromLast5Number: data.fromLast5Number, amount: data.amount, toBankId: data.toBankId, remark: data.remark}))}
                onClose={() => setVisibleModal(false)}
            />
        </>
    );
};

export default Remittance;

const CustomPagination = styled(Pagination)`
    margin: 25px auto;

    ${media.lg`
        margin: 30px auto 50px auto;
    `}
`;

const CustomStatusTag = styled(StatusTag)`
    width: 64px;
    height: 20px;
    border-radius: 2px;

    ${media.md`
        width: 90px;
        height: 32px;
        border-radius: 4px;
    `}
`;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isBold && css`
        font-weight: bold;
    `}

    ${props => props.isFailed && css`
        text-decoration: line-through;
    `}

    ${props => props.isGreen && css`
        color: #a8d580;
    `}
`;

const AccountValue = styled(FieldValue)`
    padding-left: 3px;

    ${media.md`
        padding-left: 0;
    `}
`;

const FieldTitle = styled.span`
    width: 60px;
    min-width: 60px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 8px;

    ${media.md`
        display: none;
    `}
`;

const MobilePrice = styled.span<any>`
    font-size: 16px;
    font-weight: bold;
    color: #ff7d6a;
    padding-left: 5px;

    ${props => props.isFailed && css`
        text-decoration: line-through;
        color: #6e6e6e;
    `}
`;

const MobileCardHeader = styled.div`
    width: 100%;
    height: 30px;
    border-radius: 2px;
    background-color: #f8f8f8;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
`;

const Content = styled.div`
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    ${media.md`
        height: 80px;
        flex-direction: row;
        align-items: center;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;

        &:hover{
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
            background-color: #ffffff;
        }
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const Remark = styled(HeaderFieldName)`
    padding-bottom: 6px;

    ${media.md`
        min-width: 228px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: scroll;
        padding: 5px 0;
    `}

    ${media.xxl`
        min-width: 314px;
    `}
`;

const Status = styled(HeaderFieldName)`
    ${media.md`
        min-width: 130px;
    `}

    ${media.xxl`
        min-width: 163px;
    `}
`;

const Bank = styled(HeaderFieldName)`
    padding-bottom: 4px;

    ${media.md`
        min-width: 200px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 250px;
    `}
`;

const Price = styled(HeaderFieldName)`
    ${media.md`
        min-width: 130px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 167px;
    `}
`;

const Account = styled(HeaderFieldName)`
    padding-bottom: 4px;

    ${media.md`
        min-width: 130px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 162px;
    `}
`;

const Name = styled(HeaderFieldName)`
    margin-bottom: 4px;

    ${media.md`
        border-bottom: solid 1px #f2f2f2;
        min-width: 130px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    `}

    ${media.xxl`
        min-width: 182px;
    `}
`;

const Time = styled(HeaderFieldName)`
    padding-bottom: 4px;

    ${media.md`
        min-width: 170px;
        padding-bottom: 0;
        padding-left: 10px;
    `}

    ${media.xxl`
        min-width: 202px;
        padding-left: 42px;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 22px;
    margin-bottom: 20px;
    border-bottom: solid 1px #edeff2;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const RemittanceForm = styled.form`
    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const DepositText = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: ${site.theme.thirdColor};
    padding-left: 8px;

    ${media.md`
        font-size: 16px;
        padding-left: 0;
        padding-top: 6px;
    `}
`;

const DepositButton = styled.button`
    width: 280px;
    height: 30px;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px ${site.theme.thirdColor};
    background-color: #fbfbfb;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    ${media.md`
        width: 590px;
        height: 100px;
        border: solid 2px ${site.theme.thirdColor};
        margin: 10px 0 40px 0;
        flex-direction: column;

        i {
            font-size: 30px;
        }
    `}
`;

const RemittanceContainer = styled(Container)`
    display: flex;
    flex-direction: column;

    ${media.md`
        overflow-x: auto;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
