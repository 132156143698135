import * as React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {media} from 'library/styled-bs-grid';
import uniqueId from 'lodash/uniqueId';
import {useCallback} from "react";

interface IProps {
    style?: CSS.Properties;
    className?: string;
    forwardRef?: Function;
    name: string;
    text: string;
    value: string | number;
    onChange?: Function;
}

/**
 * RadioButton
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RadioButton: React.FC<IProps> = ({
    style,
    className,
    name,
    text,
    value,
    onChange = () => {},
}) => {
    const radioId = uniqueId('radioButton_');

    /**
     * 處理值改變
     */
    const handleChange = useCallback((inputValue: string) => {
        if (onChange) {
            onChange(inputValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <RadioButtonRoot style={style} className={className}>
            <RadioInput
                id={radioId}
                name={name}
                type="radio"
                value={value}
                onChange={event => handleChange(event.target.value)}
            />
            <Label htmlFor={radioId}>{text}</Label>
        </RadioButtonRoot>
    );
};

export default RadioButton;

const Label = styled.label`
    font-size: 14px;
    color: #6e6e6e;
    font-weight: 500;
    padding-left: 26px;
    cursor: pointer;
    margin: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 1px #6e6e6e;
        background-color: #ffffff;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    :after {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    ${media.lg`
        font-size: 20px;
        font-weight: 600;
        padding-left: 37px;

        :before {
            width: 24px;
            height: 24px;
        }

        :after {
            left: 7px;
        }
    `};
`;

const RadioInput = styled.input`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

const RadioButtonRoot = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    input[type="radio"]:checked + ${Label} {
        :before {
            background-color: ${props => props.theme.primaryColor};
            border: solid 1px ${props => props.theme.primaryColor};
        }
    }
`;
