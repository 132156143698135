// Libraries
import api from 'library/apisauce';

import {TGetTrackProduct, TDeleteTrackProduct, TPutTrackProduct} from './types';

export default {
    /**
     * 查詢 追蹤商品清單
     */
    getTrackProduct: (currentPage: number = 1, pageLimit: number = 20): Promise<TGetTrackProduct> => {
        return api.get('/member/trackProduct', {currentPage, pageLimit}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 清空 追蹤商品
     */
    clearTrackProduct: (): Promise<TDeleteTrackProduct> => {
        return api.delete('/member/trackProduct/clear', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 刪除 多筆追蹤商品
     */
    deleteTrackProduct: (productGoodsId: Array<Number>): Promise<TDeleteTrackProduct> => {
        return api.delete('/member/trackProduct', {productGoodsId}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 新增 商品到追蹤清單
     */
    postTrackProduct: (productGoodsId: Number, isTrack: boolean): Promise<TPutTrackProduct> => {
        return api.post('/member/trackProduct', {productGoodsId, isTrack}, {
            headers: {isAuth: true},
        });
    },
};
