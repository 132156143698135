import React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media, Container} from 'library/styled-bs-grid';
import {get} from 'lodash';
import site from 'config/site';
import {motion} from 'framer-motion'
import {getOs} from 'utils/browser';

// Component
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onBack?: () => void;
    onClose?: Function;
    isInStock?: boolean;
    id: number;
    brandName: string;
    name: string;
    model: string;
    price: number;
    prePrice: number;
    isSale?: boolean;
    inStock: Array<{
        id: number;
        size: string;
        qty: number;
    }>;
    size: Array<{
        size: string;
        isInStock: boolean;
    }>;
    imageUrl: string;
    inStockSizeQty?: Array<{size: string, qty: number}>;
    setInStockSizeQty?: Function
    pickSizeQty?: Array<{size: string, qty: number}>;
    setPickSizeQty?: Function
}

/**
 * PickModal
 * @param props
 * @returns {*}
 * @constructor
 */
const PickModal: React.FC<IProps> = ({
    className,
    style,
    onBack,
    onClose = () => {},
    isInStock = false,
    brandName,
    name,
    model,
    price,
    prePrice,
    isSale,
    inStock,
    size,
    imageUrl,
    inStockSizeQty = [],
    setInStockSizeQty = () => {},
    pickSizeQty = [],
    setPickSizeQty = () => {},
}) => {
    const sizeList: Array<{id?: number, size: string, qty?: number, isInStock?: boolean}> = isInStock ? inStock : size;
    const sizeQty = isInStock ? inStockSizeQty : pickSizeQty;
    const setSizeQty = isInStock ? setInStockSizeQty : setPickSizeQty;

    // 總件數
    const totalQty = sizeQty.reduce((accum,item) => accum + item.qty, 0);

    /**
     *  計算各尺寸件數
     */
    const handleCountQty = (sizeText: string, type: string) => {
        let cloneData = [...sizeQty];
        const currentIndex = cloneData.findIndex(row => row.size === sizeText);
        const index = currentIndex === -1 ? get(sizeQty, 'length', 0) : currentIndex;

        if (type === 'add') {
            cloneData[index] = {
                size: sizeQty[currentIndex] ? sizeQty[currentIndex].size : sizeText,
                qty: sizeQty[currentIndex] ? Number(sizeQty[currentIndex].qty) + 1 : 1,
            };
        } else {
            if (sizeQty[currentIndex] && Number(sizeQty[currentIndex].qty) - 1 === 0) {
                cloneData.splice(index, 1);
            } else {
                cloneData[index] = {
                    size: sizeQty[currentIndex] ? sizeQty[currentIndex].size : sizeText,
                    qty: sizeQty[currentIndex] ? Number(sizeQty[currentIndex].qty) - 1 : 0,
                };
            }
        }

        setSizeQty(cloneData);
    };


    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    return (
        <Modal
            className={className}
            style={style}
            variants={containerVariant}
            transition={{type: "spring", duration: 0.5}}
        >
            <Container>
                <Header>
                    <IconButton type="button" onClick={onBack}>
                        {typeof onBack !== 'undefined' && (<Icon code="arrow-right" size={15} color="#d8d8d8" rotate={180}/>)}
                    </IconButton>

                    <IconButton type="button" onClick={() => {
                        onClose();

                        if (typeof onBack !== 'undefined') {
                            onBack();
                        }
                    }}>
                        <Icon code="times" size={15} color="#d8d8d8"/>
                    </IconButton>
                </Header>

                <div className="d-flex flex-column align-items-center">
                    {/* 商品資訊 */}
                    <ProductInfo className="d-flex w-100">
                        <ProductImg src={imageUrl}/>
                        <div className="d-flex flex-column">
                            <BrandName>{brandName}</BrandName>
                            <ProductName>{name}</ProductName>
                            <Model>{`商品貨號：${model}`}</Model>

                            <div>
                                {isSale && <PrePrice>NT$ {prePrice}</PrePrice>}
                                <Price>NT$ {price}</Price>
                            </div>
                        </div>
                    </ProductInfo>

                    {/* [挑碼] or [現貨] 下單數量 */}
                    <OrderSection>
                        <OrderTitle>{isInStock ? '現貨下單' : '挑碼下單'}：</OrderTitle>

                        <div className="w-100 d-flex flex-column">
                            {sizeList.map(row => {
                                // 現貨下單下單數量不可以超過庫存數量
                                const sizeQtyInfo = sizeQty.find(o => o.size === row.size);

                                return (
                                    <OrderSize className="d-flex align-items-center" key={row.size}>
                                        <Size>{row.size}</Size>

                                        <HandOrderButton
                                            type="button"
                                            disabled={get(sizeQtyInfo, 'qty', 0) === 0}
                                            onClick={() => handleCountQty(row.size, 'minus')}
                                        >
                                            <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                        </HandOrderButton>

                                        <HandOrderInput
                                            name={row.size}
                                            value={get(sizeQtyInfo, 'qty', 0)}
                                            disabled
                                        />

                                        <HandOrderButton
                                            type="button"
                                            onClick={() => handleCountQty(row.size, 'add')}
                                            disabled={isInStock && Number(get(sizeQtyInfo, 'qty', 0)) === row.qty}
                                        >
                                            <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                        </HandOrderButton>
                                    </OrderSize>
                                )
                            })}
                        </div>
                    </OrderSection>

                    <TotalOrder>{`${totalQty}(件) / NT$ ${totalQty * price} 元`}</TotalOrder>
                    <SubmitButton onClick={onBack}>確認</SubmitButton>
                </div>
            </Container>
        </Modal>
    );
};

export default PickModal;

const SubmitButton = styled(Button)`
    width: 240px;
    height: 44px;
    min-height: auto;
    margin-bottom: 10px;
    font-size: 14px;

    ${media.md`
        margin-bottom: 0;
        font-size: 16px;
    `}
`;

const TotalOrder = styled.span`
    font-size: 12px;
    color: #9d9a9a;
    margin-bottom: 10px;

    ${media.md`
        font-size: 14px;
    `}
`;

const HandOrderInput = styled.input<any>`
    width: 60px;
    height: 26px;
    font-size: 14px;
    font-weight: bold;
    color: ${site.theme.primaryColor};
    background-color: #f2f4f9;
    margin: 0 10px;
    padding: 0;
    text-align: center;
    border: none;

    ${media.md`
        font-size: 16px;
    `}
`;

const HandOrderButton = styled.button<any>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 1px #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    ${props => props.disabled && css`
        i{
            opacity: 0.4;
        }
    `}
`;

const Size = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: #9d9a9a;
    flex: 1 1 auto;

    ${media.md`
        font-size: 16px;
    `}
`;

const OrderSize = styled.div`
    margin-bottom: 8px;

    ${media.md`
        margin-bottom: 10px;
    `}
`;

const OrderTitle = styled.div`
    min-width: 82px;
    height: 26px;
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;
    display: flex;
    align-items: center;

    ${media.md`
        min-width: 120px;
        font-size: 14px;
    `}
`;

const OrderSection = styled.div`
    width: 100%;
    border-radius: 5px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding: 10px 20px 2px 20px;
    margin-bottom: 20px;
    display: flex;

    ${media.md`
        margin-bottom: 30px;
    `}
`;

const Price = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: #ff7d6a;
`;

const PrePrice = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    padding-right: 4px;
    text-decoration: line-through;

    ${media.md`
        font-size: 14px;
        padding-right: 6px;
    `}
`;

const Model = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #9d9a9a;
    padding-bottom: 6px;

    ${media.md`
        font-size: 14px;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #282731;

    ${media.md`
        font-size: 16px;
    `}
`;

const BrandName = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #282731;

    ${media.md`
        font-size: 16px;
    `}
`;

const ProductImg = styled.img`
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 5px;

    ${media.md`
        width: 90px;
        height: 90px;
        margin-right: 15px;
    `}
`;

const ProductInfo = styled.div`
    margin-bottom: 20px;

    ${media.md`
        margin-bottom: 25px;
    `}
`;

const IconButton = styled.button<any>`
    width: 16px;
    height: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 20px;
        height: 20px;

        i {
            font-size: 20px;
        }
    `}
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    ${media.md`
        margin-bottom: 20px;
    `}
`;

const Modal = styled(motion.div)`
    width: 100%;
    min-height: 420px;
    max-height: 90%;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff;
    overflow-y: scroll;
    padding: 15px 10px 0 10px;
    position: absolute;

    ${media.md`
        width: 460px;
        border-radius: 10px;
        padding: 30px 40px;
    `}
`;
