// Libraries & Utils
import Immutable from 'seamless-immutable';
import { TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'uiDialog';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isVisible: false,
    type: undefined,
    title: '',
    message: '',
    code: '',
    buttons: [],
});


/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    openSuccess: (state, {payload}) => {
        return state.merge({
            isVisible: true,
            type: 'success',
            title: '成功',
            code: 'SUCCESS',
            message: payload ?.message,
            buttons: [{
                text: '確定', type: 'default', onClick: payload ?.onClick
            }]
        });
    },
    openError: (state, {payload}) => {
        return state.merge({
            isVisible: true,
            type: 'error',
            title: '失敗',
            message: payload ?.message,
            code: payload ?.code,
            buttons: [{
                text: '確定', type: 'danger', onClick: payload ?.onClick
            }]
        });
    },
    openConfirm: (state, {payload}) => {
        return state.merge({
            isVisible: true,
            type: 'confirm',
            title: '確認',
            message: payload ?.message,
            buttons: [
                {text: '取消', type: 'danger'},
                {text: '確定', type: 'default', onClick: payload ?.onClick}
            ]
        });
    },
    openCustom: (state, {payload}) => {
        return state.merge({
            isVisible: true,
            type: payload ?.type,
            title: payload ?.title,
            message: payload ?.message,
            code: payload ?.code,
            buttons: payload ?.button
        });
    },
    close: (state) => {
        return state.merge({
            isVisible: false,
            type: undefined,
            title: '',
            message: '',
            code: '',
            buttons: []
        })
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
