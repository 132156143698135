// Libraries & Utils
import Immutable from 'seamless-immutable';
import { TReducers} from "library/redux";
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from "./types";


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'startUp';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isReady: false,
    isChecking: false,
    message: '',
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 登入系統
     */
    checkIn: (state) => {
        return state;
    },
    checkInBegin: (state) => {
        return state.merge({isChecking: true, message: ''})
    },
    checkInSuccess: (state, {payload}) => {
        return state.merge({isChecking: false})
    },
    checkInFail: (state, {payload}) => {
        return state.merge({isChecking: false})
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
