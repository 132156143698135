import React, {useCallback, useState} from "react";


interface IState {
    step1Data: {
        account: string,
        confirmPassword: string,
        deliveryAddress: string,
        deliveryCity: string,
        deliveryTownship: string,
        email: string,
        lineId: string,
        password: string,
        phone: string,
        postalCode: string,
        realName: string,
    };
}
interface IContext {formTempState: IState, dispatchFormData: (payload: IState) => void}

const defaultState = {
    step1Data: {
        account: '',
        confirmPassword: '',
        deliveryAddress: '',
        deliveryCity: '',
        deliveryTownship: '',
        email: '',
        lineId: '',
        password: '',
        phone: '',
        postalCode: '',
        realName: '',
    },
};

export const StoreContext = React.createContext<IContext>({
    formTempState: defaultState,
    dispatchFormData: payload => payload
});


const FormContext: React.FC = ({
    children
}) => {
    const [formState, setFormData] = useState<IState>(defaultState);

    const dispatchFormData = useCallback((payload: IState) => {
        setFormData(state => {
            return {
                ...state, ...payload
            }
        })
    }, []);

    return (
        <StoreContext.Provider value={{formTempState: formState, dispatchFormData}}>
            {children}
        </StoreContext.Provider>
    )
};

export default FormContext;

