// Libraries
import api from 'library/apisauce';

import {TGetMemberLevel} from './types';

export default {
    /**
     * 查詢 會員等級特性
     */
    getMemberLevel: (): Promise<TGetMemberLevel> => {
        return api.get('/member/level/feature', {}, {
            headers: {isAuth: true},
        });
    },
};
