// Libraries & Utils
import Immutable from 'seamless-immutable';
import { TReducers} from "library/redux";
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from "./types";


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'system';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    isDone: false,
    message: '',
    company: {
        address: '',
        tel: '',
        mobile: '',
        email: '',
        line: '',
        fb: '',
    },
    depositBank: [],
    guideDate: [],
    faqDate: [],
    hotKeywords: [],
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得系統設定
     */
    fetchSetting: (state, action) => {
        return state;
    },
    fetchSettingBegin: (state) => {
        return state.merge({isFetching: true, isDone: false, message: '正在讀取網站設定'})
    },
    fetchSettingSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', isDone: true, ...payload});
    },
    fetchSettingFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得匯款銀行清單
     */
    fetchDepositBank: (state, action) => {
        return state;
    },
    fetchDepositBankBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取匯款銀行清單'})
    },
    fetchDepositBankSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', depositBank: payload.depositBank})
    },
    fetchDepositBankFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得購物說明設定
     */
    fetchGuide: (state, action) => {
        return state;
    },
    fetchGuideBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取購物說明設定'})
    },
    fetchGuideSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', guideDate: payload.guideDate})
    },
    fetchGuideFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得常見問題
     */
    fetchFaq: (state, action) => {
        return state;
    },
    fetchFaqBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取常見問題'})
    },
    fetchFaqSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', faqDate: payload.faqDate})
    },
    fetchFaqFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得商品熱搜關鍵字
     */
    fetchHotKeyword: (state, action) => {
        return state;
    },
    fetchHotKeywordBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取熱門搜尋關鍵字'})
    },
    fetchHotKeywordSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', hotKeywords: payload.hotKeywords})
    },
    fetchHotKeywordFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
};

export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
