// Libraries
import api from 'library/apisauce';

import {TGetOrderHead, TGetOrderHeadDetail, TGetStockCategory, TGetStockProduct, TPostCancelApply} from './types';

export default {
    /**
     * 查詢 我的訂單列表
     */
    getOrderHead: (startDate?: string, endDate?: string, orderId?: string, currentPage: number = 1, pageLimit: number = 20): Promise<TGetOrderHead> => {
        return api.get('/order/head', {startDate, endDate, orderId, currentPage, pageLimit}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 我的訂單明細
     */
    getOrderHeadDetail: (id: number): Promise<TGetOrderHeadDetail> => {
        return api.get(`/order/head/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 商品備貨狀態類別
     */
    getStockCategory: (startDate?: string, endDate?: string, orderId?: string, itemModel?: string): Promise<TGetStockCategory> => {
        return api.get('/order/stock/category', {
            orderDate: startDate,
            stockDate: endDate,
            orderId,
            itemModel,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 商品備貨狀態資料 (依狀態類別)
     */
    getStockProduct: (categoryId: number|null, startDate?: string, endDate?: string, goodsModel?: string, goodsName?: string, brandName?: string, orderId?: string, currentPage: number = 1, pageLimit: number = 20): Promise<TGetStockProduct> => {
        return api.get(`/order/stock/category/${categoryId}`, {
            orderDate: startDate,
            stockDate: endDate,
            goodsModel,
            goodsName,
            brandName,
            orderId,
            currentPage,
            pageLimit,
        }, {
            headers: {isAuth: true},
        });
    },
    /**
     * 商品取消申請
     */
    postCancelApply: (id: number): Promise<TPostCancelApply> => {
        return api.post(`/order/stock/applyCancel/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
};
