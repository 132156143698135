import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Container, media, Row} from 'library/styled-bs-grid';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {parseQueryString} from 'utils/uri';
import {formatCurrency} from 'utils/number';
import {get} from 'lodash';
import {checkIsMobile} from 'utils/browser';

import Icon from 'components/atoms/Icon';
import OrderAccordion from 'components/molecules/OrderAccordion/OrderAccordion';
import Pagination from 'components/atoms/Pagination';
import Search from '../Search';

// Reducers
import {Actions as OrderAction} from 'store/order';


// 一頁顯示筆數
const pageLimit = 20;

/**
 *  我的訂單
 * @constructor
 */
const Order: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const useFormParams = useForm();
    const {handleSubmit} = useFormParams;

    const [isVisibleSearch, setVisibleSearch] = useState(false);
    const [visibleId, setVisibleId]: any = useState('');
    const [currentPage, setCurrentPage] = useState<number>(1);

    const {isFetching, isFetchingDetail, orderHeadList, orderHeadPagination, orderHeadDetail} = useSelector(state => ({
        isFetching: state.order.isFetching,
        isFetchingDetail: state.order.isFetchingDetail,
        orderHeadList: state.order.orderHeadList,
        orderHeadPagination: state.order.orderHeadPagination,
        orderHeadDetail: state.order.orderHeadDetail,
    }));

    const {search} = useLocation();
    const queryString = parseQueryString(search);
    const startDate = get(queryString, 'startDate', '');
    const endDate = get(queryString, 'endDate', '');
    const searchInput = get(queryString, 'search', '');
    const searchPage = get(queryString, 'page', 1);

    useEffect(() => {
        // 取得訂單列表
        setCurrentPage(Number(searchPage));
        dispatch(OrderAction.fetchOrderHead({startDate, endDate, orderId: searchInput, currentPage: Number(searchPage), pageLimit}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, searchInput, searchPage]);


    /**
     *  搜尋商品
     */
    const handleSearch = (formData: any) => {
        setVisibleSearch(false);

        history.push(`/profile/order?startDate=${formData?.startDate ?? ''}&endDate=${formData?.endDate ?? ''}&search=${formData?.search ?? ''}&page=${currentPage}`);
    };

    const handleSelectedId = (id: number) => {
        if (checkIsMobile()) {
            history.push(`/profile/order/detail/${id}`);
        } else {
            if (id === visibleId) {
                setVisibleId('');
            } else {
                setVisibleId(id);

                // 取得該筆訂單的明細api
                dispatch(OrderAction.fetchOrderHeadDetail({id}));
            }
        }
    };


    /**
     * 換頁
     */
    const handleChangePage = (p: number) => {
        history.push(`/profile/order?startDate=${startDate}&endDate=${endDate}&search=${searchInput}&page=${p}`);
    };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/account')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>我的訂單</MobileTitle>

                    <IconButton onClick={() => setVisibleSearch(true)}>
                        <Icon code="search" size={20} color="#282731"/>
                    </IconButton>
                </MobileHeader>
            </Container>

            <form onSubmit={handleSubmit(handleSearch)}>

                {/* 搜尋 */}
                <Search
                    isVisible={isVisibleSearch}
                    onClose={() => setVisibleSearch(false)}
                    useFormParams={useFormParams}
                    placeholder="透過訂單編號搜尋"
                />

                <AdvanceOrderContainer>
                    {/* 標題 */}
                    <Header className="d-none d-md-flex">
                        <NumSection/>
                        <Model>訂單編號</Model>
                        <Time>訂購時間</Time>
                        <Payment>付款方式</Payment>
                        <Price>訂購金額</Price>
                        <Detail>詳情備註</Detail>
                    </Header>

                    {/* 讀取狀態 */}
                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}

                    {/* 明細 */}
                    {!isFetching && orderHeadList.map(row => (
                        <OrderAccordion
                            key={get(row, 'id')}
                            id={get(row, 'id')}
                            oldId={get(row, 'oldId')}
                            orderNumber={get(row, 'id')}
                            orderTime={get(row, 'createdAt')}
                            payWay={get(row, 'payWay')}
                            orderAmount={formatCurrency(get(row, 'amount'))}
                            remark={get(row, 'remark')}
                            onClick={() => handleSelectedId(row.id)}
                            isSelected={visibleId === row.id}
                            details={orderHeadDetail}
                            isFetching={isFetchingDetail}
                        />
                    ))}
                </AdvanceOrderContainer>
            </form>


            {/* 分頁 */}
            <CustomPagination
                page={currentPage}
                totalPage={get(orderHeadPagination, 'totalPages')}
                totalItems={get(orderHeadPagination, 'totalItems')}
                onChange={(currentPage: number) => handleChangePage(currentPage)}
            />
        </>
    );
};

export default Order;

const CustomPagination = styled(Pagination)`
    margin: 10px auto 25px auto;

    ${media.lg`
        margin: 30px auto 50px auto;
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const Detail = styled(HeaderFieldName)`
    min-width: 442px;

    ${media.xxl`
        min-width: 545px;
    `}
`;

const Price = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 150px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 211px;
    `}
`;

const Payment = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 140px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 164px;
    `}
`;

const Time = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 217px;;
        padding-bottom: 0;
    `}
`;

const Model = styled(HeaderFieldName)`
    margin-bottom: 8px;

    ${media.md`
        border-bottom: solid 1px #f2f2f2;
        min-width: 150px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    `}

    ${media.xxl`
        min-width: 203px;
    `}
`;

const NumSection = styled(HeaderFieldName)<any>`
    display: flex;
    justify-content: center;

    ${media.md`
        min-width: 30px;
        position: initial;
        top: auto;
        left: auto;
    `}

    ${media.xxl`
        min-width: 100px;
    `}

    ${props => props.isBlod && css`
        font-weight: bold;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: solid 1px #edeff2;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const AdvanceOrderContainer = styled(Container)`
    padding-bottom: 20px;

    ${media.md`
        overflow-x: auto;
        padding-bottom: 30px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
