// Libraries
import api from 'library/apisauce';

import {TGetTradeHistory} from './types';

export default {
    /**
     * 查詢 交易紀錄清單
     */
    getTradeHistory: (currentPage: number = 1, pageLimit: number = 20): Promise<TGetTradeHistory> => {
        return api.get('/member/transactionHistory', {currentPage, pageLimit}, {
            headers: {isAuth: true},
        });
    },
};
