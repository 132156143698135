// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {replace} from 'connected-react-router';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as ProfileActions} from 'store/profile';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {IActionCreators} from './types';

// Services
import ApiService, {TPostCartBatch, TPostCartPick, TGetMemberCart, TDeleteMemberCart, TGetCartOrderDetail, TPostCartOrder} from 'services/cart';


/**
 * 送出商品到購物車（整批）
 */
function* submitCartBatch(action: IPickAction<IActionCreators, 'submitCartBatch'>) {
    yield put(Actions.submitCartBatchBegin());
    yield put(UiBlockActions.visible({message: '正在加入購物袋'}));

    try {
        const {productGoodsId, batchQty} = action.payload;
        const [{body}]: [TPostCartBatch] = yield all([
            call(ApiService.postCartBatch, productGoodsId, batchQty),
            delay(fetchDataDelayMinMs),
        ]);

        const {totalItems} = body.data;
        // 設定購物袋數量
        yield put(ProfileActions.setShoppingCartItem({shoppingCartCount: totalItems}));

        yield put(Actions.submitCartBatchSuccess());
    } catch (err) {
        yield put(Actions.submitCartBatchFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

/**
 * 送出商品到購物車（挑碼 or 現貨）
 */
function* submitCartPick(action: IPickAction<IActionCreators, 'submitCartPick'>) {
    yield put(Actions.submitCartPickBegin());
    yield put(UiBlockActions.visible({message: '正在加入購物袋'}));

    try {
        const {productGoodsId, sizeQty, isInStock} = action.payload;
        const [{body}]: [TPostCartPick] = yield all([
            call(ApiService.postCartPick, productGoodsId, sizeQty, isInStock),
            delay(fetchDataDelayMinMs),
        ]);

        const {totalItems} = body.data;
        // 設定購物袋數量
        yield put(ProfileActions.setShoppingCartItem({shoppingCartCount: totalItems}));

        yield put(Actions.submitCartPickSuccess());
    } catch (err) {
        yield put(Actions.submitCartPickFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 查詢購物車所有商品
 */
function* fetchMemberCart(action: IPickAction<IActionCreators, 'fetchMemberCart'>) {
    yield put(Actions.fetchMemberCartBegin());

    try {
        const [{body}]: [TGetMemberCart] = yield all([
            call(ApiService.getMemberCart),
            delay(fetchDataDelayMinMs),
        ]);

        const {rows, totalAmount, totalItems} = body.data;

        // 更新購物袋數量
        yield put(ProfileActions.setShoppingCartItem({shoppingCartCount: totalItems}));

        yield put(Actions.fetchMemberCartSuccess({memberCart: rows, memberCartTotal: {totalAmount, totalItems}}));
    } catch (err) {
        yield put(Actions.fetchMemberCartFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 刪除購物車中的所有商品
 */
function* cleanMemberCart(action: IPickAction<IActionCreators, 'cleanMemberCart'>) {
    yield put(Actions.cleanMemberCartBegin());
    yield put(UiBlockActions.visible({message: '正在清除購物袋'}));

    try {
        const [{body}]: [TDeleteMemberCart] = yield all([
            call(ApiService.cleanMemberCart),
            delay(fetchDataDelayMinMs),
        ]);

        const {totalItems} = body.data;

        // 重新取得故物袋清單
        yield put(Actions.fetchMemberCart());
        // 設定購物袋數量
        yield put(ProfileActions.setShoppingCartItem({shoppingCartCount: totalItems}));

        yield put(Actions.cleanMemberCartSuccess());
    } catch (err) {
        yield put(Actions.cleanMemberCartFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 刪除 購物車中的商品(多筆)
 */
function* deleteMemberCart(action: IPickAction<IActionCreators, 'deleteMemberCart'>) {
    yield put(Actions.deleteMemberCartBegin());
    yield put(UiBlockActions.visible({message: '正在刪除選取中的商品'}));

    try {
        const {id} = action.payload;
        const [{body}]: [TDeleteMemberCart] = yield all([
            call(ApiService.deleteMemberCart, id),
            delay(fetchDataDelayMinMs),
        ]);

        const {totalItems} = body.data;

        // 重新取得購物袋清單
        yield put(Actions.fetchMemberCart());
        // 設定購物袋數量
        yield put(ProfileActions.setShoppingCartItem({shoppingCartCount: totalItems}));

        yield put(Actions.deleteMemberCartSuccess());
    } catch (err) {
        yield put(Actions.deleteMemberCartFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 查詢購物車商品下單明細
 */
function* fetchCartOrderDetail(action: IPickAction<IActionCreators, 'fetchCartOrderDetail'>) {
    yield put(Actions.fetchCartOrderDetailBegin());
    yield put(UiBlockActions.visible({message: '正在讀取商品明細'}));

    try {
        const {id} = action.payload;
        const [{body}]: [TGetCartOrderDetail] = yield all([
            call(ApiService.getCartOrderDetail, id),
            delay(fetchDataDelayMinMs),
        ]);

        const {data} = body;

        yield put(Actions.fetchCartOrderDetailSuccess({orderDetail: data}));
    } catch (err) {
        yield put(Actions.fetchCartOrderDetailFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 更改 商品到購物車(整批) 尺寸數量
 */
function* putCartBatch(action: IPickAction<IActionCreators, 'putCartBatch'>) {
    yield put(Actions.putCartBatchBegin());
    yield put(UiBlockActions.visible({message: '正在更改商品下單數量'}));

    try {
        const {id, batchQty} = action.payload;
        yield all([
            call(ApiService.putCartBatch, id, batchQty),
            delay(fetchDataDelayMinMs),
        ]);

        // 重新取得購物車清單
        yield put(Actions.fetchMemberCart());

        yield put(Actions.putCartBatchSuccess());
    } catch (err) {
        yield put(Actions.putCartBatchFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 更改 商品到購物車(挑碼) 尺寸數量
 */
function* putCartPick(action: IPickAction<IActionCreators, 'putCartPick'>) {
    yield put(Actions.putCartPickBegin());
    yield put(UiBlockActions.visible({message: '正在更改商品下單數量'}));

    try {
        const {id, sizeQty, isInStock} = action.payload;
        yield all([
            call(ApiService.putCartPick, id, sizeQty, isInStock),
            delay(fetchDataDelayMinMs),
        ]);

        // 重新取得購物車清單
        yield put(Actions.fetchMemberCart());

        yield put(Actions.putCartPickSuccess());
    } catch (err) {
        yield put(Actions.putCartPickFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 新增 購物車商品到訂單
 */
function* submitCartOrder(action: IPickAction<IActionCreators, 'submitCartOrder'>) {
    yield put(Actions.submitCartOrderBegin());
    yield put(UiBlockActions.visible({message: '訂單成立中'}));

    try {
        const {cartId, comment, payWayId} = action.payload;
        const [{body}]: [TPostCartOrder] = yield all([
            call(ApiService.postCartOrder, cartId, comment, payWayId),
            delay(fetchDataDelayMinMs),
        ]);
        const {totalItems, walletAmount, unpaidAmount} = body.data;

        // 設定購物袋數量
        yield put(ProfileActions.setShoppingCartItem({shoppingCartCount: totalItems}));

        // 更新會員餘額
        yield put(ProfileActions.setWalletAmount({walletAmount, unpaidAmount}));

        // 導頁回購物袋
        yield put(replace('/shop/bag'));

        yield put(Actions.submitCartOrderSuccess());
        yield put(UiDialogActions.openSuccess({message: '訂單建立成功'}));
    } catch (err) {
        yield put(Actions.submitCartOrderFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}



export default [
    takeLatest(ActionTypes.SUBMIT_CART_BATCH, submitCartBatch),
    takeLatest(ActionTypes.SUBMIT_CART_PICK, submitCartPick),
    takeLatest(ActionTypes.FETCH_MEMBER_CART, fetchMemberCart),
    takeLatest(ActionTypes.CLEAN_MEMBER_CART, cleanMemberCart),
    takeLatest(ActionTypes.DELETE_MEMBER_CART, deleteMemberCart),
    takeLatest(ActionTypes.FETCH_CART_ORDER_DETAIL, fetchCartOrderDetail),
    takeLatest(ActionTypes.PUT_CART_BATCH, putCartBatch),
    takeLatest(ActionTypes.PUT_CART_PICK, putCartPick),
    takeLatest(ActionTypes.SUBMIT_CART_ORDER, submitCartOrder),
];
