import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components/macro';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import * as CSS from 'csstype';
import debounce from 'lodash/debounce';
import {media} from 'library/styled-bs-grid';
import {initDataList, checkIsMobile, generateMedia} from './utils';

type slidesPerView = number;
type slidesPerGroup = number;
type isEnableLoop = boolean;
type spaceBetween = number;
type isEnablePagination = boolean;
type isEnableNavButton = boolean;

interface BreakpointSettingInterface {
    slidesPerView: slidesPerView;
    slidesPerGroup: slidesPerGroup;
    isEnableLoop: isEnableLoop;
    isEnablePagination: isEnableLoop;
    isEnableNavButton: isEnableNavButton;
    spaceBetween: spaceBetween;
}

export interface BreakpointsInterface {
    [key: number]: {
        slidesPerView: slidesPerView;
        slidesPerGroup?: slidesPerGroup;
        isEnableLoop?: isEnableLoop;
        isEnablePagination?: isEnableLoop;
        isEnableNavButton?: isEnableNavButton;
        spaceBetween?: spaceBetween;
    };
}

export type toPrevType = () => void
export type toNextType = () => void

interface IProps {
    style?: CSS.Properties;
    className?: string;
    children: React.ReactNode;
    slidesPerView?: slidesPerView;
    slidesPerGroup?: slidesPerGroup;
    isEnableLoop?: isEnableLoop;
    isEnableMouseMove?: boolean;
    isEnablePagination?: isEnablePagination;
    paginationColor?: string;
    isEnableNavButton?: isEnableNavButton;
    moveTime?: number;
    autoPlayTime?: number;
    isDebug?: boolean;
    breakpoints?: BreakpointsInterface;
    spaceBetween?: spaceBetween;
    renderNavButton?: (A: toPrevType, B: toNextType) => void;
}

type slideIndexType = number;

const elClassName = {
    content: 'im-carousel__content',
    navPrevButton: 'im-carousel__nav-prev-button',
    navNextButton: 'im-carousel__nav-next-button',
    paginationGroup: 'im-carousel__pagination-group',
    paginationButton: 'im-carousel__pagination-button',
};

const Carousel: React.FC<IProps> = ({
    style,
    className,
    children,
    slidesPerView = 1,
    slidesPerGroup = 1, // 不可為小數
    isEnableLoop = false,
    moveTime = 300,
    breakpoints = {},
    isEnableMouseMove = false,
    paginationColor = 'rgba(255, 255, 255, 0.4)',
    isEnablePagination = false,
    isEnableNavButton = false,
    isDebug = false,
    spaceBetween = 0,
    autoPlayTime = 0,
    renderNavButton = (handleToPrev, handleToNext) => (
        <>
            <NavPre type="button" className={elClassName.navPrevButton} onClick={handleToPrev}>{'<'}</NavPre>
            <NavNext type="button" className={elClassName.navNextButton} onClick={handleToNext}>{'>'}</NavNext>
        </>
    ),
}) => {

    const slideItemRef = useRef<Array<HTMLDivElement>>([]);
    const carouselRef = useRef<HTMLHeadingElement>(null);
    const pageRef = useRef<Array<HTMLDivElement>>([]);
    const [size, setSize] = useState(0);
    let timer: number;

    const childArray = React.Children.toArray(children);

    // 目前索引位置
    let activeActualIndex = 0;

    // 目前頁數
    let activePage = 1;
    let touchStart = {
        x: 0,
        y: 0,
        movePositionX: 0,
        movePositionY: 0,
    };

    // 滑動觸發移動距離
    const triggerTouchDistance = 60;

    /**
     * 取得響適應設定
     */
    const getMediaWidth = (): BreakpointSettingInterface => {
        const windowSize = typeof document !== 'undefined' ? window.innerWidth : 0;

        // @ts-ignore
        const filterArray: number[] = Object.keys(breakpoints).filter(size => Number(size) <= windowSize);
        filterArray.sort((a, b) => Number(b) - Number(a));

        // 預設值
        let setting = {
            slidesPerGroup,
            slidesPerView,
            spaceBetween,
            isEnableLoop,
            isEnableNavButton,
            isEnablePagination,
        };

        if (filterArray.length > 0) {
            setting = Object.assign(setting, breakpoints[filterArray[0]]);
        }

        // 若顯示項目大於來源項目, 則關閉Loop
        if (setting.slidesPerView > childArray.length) {
            setting.isEnableLoop = false;
        }

        // return default
        return setting;
    };
    const media = getMediaWidth();

    /**
     * 初始化參數
     */
    const initInfo = () => {
        const formatElement = initDataList(
            childArray,
            media.slidesPerView,
            media.slidesPerGroup,
            media.isEnableLoop,
        );
        const sourceTotal = childArray.length;
        const elementTotal = formatElement.length;
        const cloneBeforeTotal = media.isEnableLoop ? media.slidesPerView : 0;
        const cloneAfterTotal = media.isEnableLoop ? media.slidesPerView : 0;
        const actualMinIndex = 0;
        const actualMaxIndex = elementTotal - 1;

        if (media.slidesPerGroup > media.slidesPerView) {
            throw Error(`slidesPerGroup(${media.slidesPerGroup}) can't > slidesPerView(${media.slidesPerView})`);
        } else if (Math.ceil(media.slidesPerGroup) !== media.slidesPerGroup) {
            throw Error(`slidesPerGroup(${media.slidesPerGroup}) can't has floating .xx`);
        }

        return {
            formatElement,
            sourceTotal, // 來源總數
            // 從0開始
            element: {
                total: elementTotal,
                firstIndex: 0,
                lastIndex: elementTotal - 1,
            },
            // 0為實際一開始的位置(往前為負數), 結束值為最後結束位置
            actual: {
                minIndex: actualMinIndex,
                maxIndex: actualMaxIndex,
                firstIndex: Math.ceil(cloneBeforeTotal),
                lastIndex: Math.ceil((sourceTotal + cloneAfterTotal) - 1),
            },
            // 總頁數
            pageTotal: Math.ceil(sourceTotal / media.slidesPerGroup),
            isDivisible: (elementTotal % media.slidesPerGroup) === 0,
            residue: elementTotal % media.slidesPerGroup,
            isVisiblePagination: media.isEnablePagination && formatElement.length > 0,
            isVisibleNavButton: media.isEnableNavButton && formatElement.length > 0,
        };
    };


    const info = initInfo();

    // 若當資料異動的時候, 進行初始化
    useEffect(() => {

        const element = carouselRef.current as HTMLElement;

        if (childArray.length > 0) {

            goToActualIndex(info.actual.firstIndex, false);

            // 視窗大小變更時
            window.addEventListener('resize', debounce(handleResize, 200));

            // 移動動畫結束復歸
            element.addEventListener('transitionend', resetPageByLoop);

            // @ts-ignore
            if (checkIsMobile()) {
                // Mobile 壓住拖動
                element.addEventListener('touchstart', mobileTouchStart);

            } else if (isEnableMouseMove) {
                // 電腦網頁滑鼠拖拉
                element.onmousedown = webMouseStart;
            }

            // 自動播放
            handleAutoPlay();
        }

        return () => {
            window.removeEventListener('resize', debounce(handleResize, 200));
            element.removeEventListener('transitionend', resetPageByLoop);

            if (timer) {
                clearTimeout(timer);
            }

            if (checkIsMobile()) {
                element.removeEventListener('touchstart', mobileTouchStart);
                element.removeEventListener('touchmove', mobileTouchMove);
                element.removeEventListener('touchstart', mobileTouchEnd);
            } else if (isEnableMouseMove) {
                element.onmousedown = null;
                element.onmousemove = null;
                element.onmouseup = null;
            }

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childArray, size]);

    /**
     * 更改尺寸
     */
    const handleResize = () => {
        setSize(window.innerWidth);
    };

    /**
     * 取得虛擬Index
     */
    const checkActualIndexInRange = (slideIndex: slideIndexType): boolean => slideIndex <= info.actual.maxIndex && slideIndex >= info.actual.minIndex;

    /**
     * 重置頁面位置 (LoopMode)
     */
    const resetPageByLoop = () => {
        if (get(info.formatElement, `${activeActualIndex}.isClone`, false) === true) {
            goToActualIndex(info.formatElement[activeActualIndex].matchIndex, false);
        }
    };

    /**
     * 取得 transform 3d x 移動參數
     * @param el
     */
    const getTranslateParams = (el: any) => {
        const values = el.style.transform.split(/\w+\(|\);?/);
        if (!values[1] || !values[1].length) {
            return {x: 0, y: 0, z: 0};
        }

        const result = values[1].split(',');
        return {
            x: Number(result[0].replace('px', '')),
            y: Number(result[1].replace('px', '')),
            z: Number(result[2].replace('px', '')),
        };
    };

    /**
     * 自動播放功能
     */
    const handleAutoPlay = () => {
        if (media.isEnableLoop && autoPlayTime > 0) {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                toNext();
                handleAutoPlay();
            }, autoPlayTime);
        }
    };

    /**
     * 手機手指按壓
     * @param event
     */
    const mobileTouchStart = (event: TouchEvent): void => {
        // event.preventDefault();

        const element = carouselRef.current as HTMLElement;
        const movePosition = getTranslateParams(element);

        // 紀錄位置
        touchStart = {
            x: event.touches[0].pageX - movePosition.x,
            y: event.touches[0].pageY - element.offsetTop,
            movePositionX: movePosition.x,
            movePositionY: movePosition.y,
        };

        element.addEventListener('touchmove', mobileTouchMove);
        element.addEventListener('touchend', mobileTouchEnd);

        clearTimeout(timer);
    };

    /**
     * 手機手指移動
     * @param event
     */
    const mobileTouchMove = (event: TouchEvent) => {
        event.preventDefault();
        const moveX = event.touches[0].pageX;

        elementMove(moveX);
    };

    /**
     * 手機手指放開
     * @param event
     */
    const mobileTouchEnd = (event: TouchEvent) => {
        // event.preventDefault();

        const element = carouselRef.current as HTMLElement;
        element.removeEventListener('touchmove', mobileTouchMove);
        element.removeEventListener('touchend', mobileTouchEnd);

        elementMoveDone();
    };

    /**
     * 網頁滑鼠按下
     * @param event
     */
    const webMouseStart = (event: MouseEvent): void => {
        event.preventDefault();

        const element = carouselRef.current as HTMLElement;
        const movePosition = getTranslateParams(element);
        touchStart = {
            x: event.clientX - movePosition.x,
            y: event.clientY - element.offsetTop,
            movePositionX: movePosition.x,
            movePositionY: movePosition.y,
        };

        element.onmousemove = webMouseMove;
        element.onmouseup = webMouseEnd;

        clearTimeout(timer);
    };

    /**
     * 網頁滑鼠移動
     * @param e
     */
    const webMouseMove = (event: MouseEvent) => {
        event.preventDefault();
        const moveX = event.clientX;

        elementMove(moveX);
    };

    /**
     * 網頁滑鼠放開
     * @param event
     */
    const webMouseEnd = (event: MouseEvent) => {
        event.preventDefault();

        const element = carouselRef.current as HTMLElement;
        element.onmousemove = null;
        element.onmouseup = null;

        elementMoveDone();
    };

    /**
     * 取得目標項目距離寬度(px)
     * @param slideIndex
     */
    const getMoveDistance = (slideIndex: slideIndexType): number => {
        const dom = slideItemRef.current[0];
        if (dom) {
            return -dom.clientWidth * slideIndex;
        }
        return 0;
    };

    /**
     * 物件移動結束
     */
    const elementMoveDone = (): void => {
        const element = carouselRef.current;

        // 取得移動位置
        const movePosition = getTranslateParams(element).x;

        // 確認移動距離
        const checkMove = movePosition - touchStart.movePositionX;

        if(checkMove <= triggerTouchDistance && checkMove >= -triggerTouchDistance){
            goToActualIndex(activeActualIndex);
        }else if (checkMove >= -triggerTouchDistance) {
            toPrev();
        } else if (checkMove <= triggerTouchDistance) {
            toNext();
        }

    };

    /**
     *
     * @param moveX 移動X軸
     */
    const elementMove = (moveX: number) => {

        const translateX = moveX - touchStart.x;
        const element = carouselRef.current as HTMLElement;

        // 取得移動限制
        const distance = {
            min: getMoveDistance(info.actual.minIndex),
            max: getMoveDistance(info.actual.lastIndex + 1),
        };

        if (distance.max >= translateX) {
            // 若移動到實際索引最後一個, 則移動到實際位置第一個
            goToActualIndex(info.actual.firstIndex, false);

        } else if (distance.min <= translateX) {
            // 若移動到實際索引第一個, 則移動到實際位置最後一個
            goToActualIndex(info.formatElement[0].matchIndex, false);

        } else {
            // 拖動
            element.style.transform = `translate3d(${translateX}px, 0px, 0px)`;
            element.style.transitionDuration = '0ms';

        }
    };

    /**
     * 移動到指定位置
     * @param slideIndex
     * @param isUseAnimation
     */
    const goToActualIndex = (slideIndex: slideIndexType, isUseAnimation = true) => {

        if (Math.ceil(slideIndex) !== slideIndex) {
            throw Error(`slideIndex(${slideIndex}) can't has floating .xx`);
        }

        // 檢查:
        // 1. 移動是否在範圍內
        if (checkActualIndexInRange(slideIndex)) {

            // 計時器重新計時
            handleAutoPlay();

            // 套用目前位置
            activeActualIndex = slideIndex;

            // 計算目前正在第幾頁頁數
            activePage = info.formatElement[activeActualIndex].inPage;

            // 移動EL位置
            const position = getMoveDistance(activeActualIndex);
            const element = carouselRef.current as HTMLElement;
            element.style.visibility = 'visible';
            element.style.transitionDuration = isUseAnimation ? `${moveTime}ms` : '0ms';
            element.style.transform = `translate3d(${position}px, 0px, 0px)`;

            // 更改顯示在第幾個 (父元件使用可判定樣式設定)
            slideItemRef.current.forEach((row, index) => {
                if (row) {
                    if (index === activeActualIndex) {
                        row.setAttribute('data-active', 'true');
                    } else if (row) {
                        row.removeAttribute('data-active');
                    }
                }
            });

            // 更改顯示在第幾頁的樣式 (父元件使用可判定樣式設定)
            if (info.isVisiblePagination) {
                pageRef.current.forEach((row, index) => {
                    if (activePage === (index + 1)) {
                        row.setAttribute('data-active', 'true');
                    } else if (row) {
                        row.removeAttribute('data-active');
                    }
                });
            }

        }
    };

    /**
     * 前往下一個
     */
    const toNext: toNextType = () => {

        const nextPage = activePage + 1;
        if (media.isEnableLoop && nextPage > info.pageTotal && info.residue > 0) {
            // 若為Loop(最後一頁移動在不整除的時候, 移動位置需要復歸到第一個)
            goToActualIndex(activeActualIndex + info.residue);

        } else if (
            Math.ceil(media.slidesPerView) < info.formatElement.length
            && (activeActualIndex + Math.floor(media.slidesPerView) <= (info.formatElement.length - 1))
        ) {
            // 正常移動到下一頁
            goToActualIndex(activeActualIndex + media.slidesPerGroup);

        } else {
            // 回到原地 (對滑動移動有用)
            goToActualIndex(activeActualIndex);

        }
    };

    /**
     * 前往上一個
     */
    const toPrev: toPrevType = () => {
        if (media.isEnableLoop && activePage === 1 && info.residue > 0) {
            // 檢查若為Loop(第一頁移動不整除的時候, 移動位置需要復歸到第一個)
            goToActualIndex(activeActualIndex - info.residue);

        } else if (media.slidesPerView < info.formatElement.length) {
            // 正常移動到上一個
            goToActualIndex(activeActualIndex - media.slidesPerGroup);

        } else {
            // 回到原地 (對滑動移動有用)
            goToActualIndex(activeActualIndex);

        }
    };

    /**
     * 前往頁面
     */
    const goToPage = (page: number) => {
        goToActualIndex(page * media.slidesPerGroup);
    };

    /**
     * 渲染頁數
     */
    const renderPagination = () => {
        const pageElement = [];

        for (let i = 0; i < info.pageTotal; i++) {
            pageElement.push(<PaginationBullet
                ref={(el: any) => {
                    pageRef.current[i] = el;
                    return false;
                }}
                key={uniqueId('page_')}
                role="button"
                onClick={() => goToPage(i + 1)}
                className={elClassName.paginationButton}
                backgroundColor={paginationColor}
            />);
        }
        return pageElement;
    };

    return (
        <Root style={style} className={className}>
            {info.isVisibleNavButton && renderNavButton(toPrev, toNext)}

            <Content className={elClassName.content}>
                <CarouselContainer
                    ref={carouselRef}
                    slidesPerView={media.slidesPerView}
                    breakpoints={breakpoints}
                    spaceBetween={media.spaceBetween}
                    data-actual={`${info.actual.minIndex},${info.actual.firstIndex}-${info.actual.lastIndex},${info.actual.maxIndex}`}
                >
                    {info.formatElement.map((row, i) => (
                        <SlideItem
                            key={uniqueId('carousel_')}
                            ref={(el: any) => {
                                slideItemRef.current[i] = el;
                                return false;
                            }}
                            data-actual={row.actualIndex}
                            data-match={row.isClone === true ? row.matchIndex : undefined}
                            data-is-clone={row.isClone === true ? true : undefined}
                        >
                            {row.element}

                            {isDebug && <TestNumber>{i}</TestNumber>}
                        </SlideItem>
                    ))}
                </CarouselContainer>
            </Content>

            {info.isVisiblePagination && (
                <Pagination className={elClassName.paginationGroup}>
                    {info.formatElement.length > 0 && renderPagination()}
                </Pagination>
            )}

        </Root>
    );
};

export default Carousel;

const PaginationBullet = styled.div<any>`
    width: 6px;
    height: 6px;
    border-radius: 7px;
    background-color: ${props => props.backgroundColor};
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;

    &[data-active]{
        opacity: 1;
        width: 20px;
        background-color: #8ec5ff;
    }

    ${media.md`
        width: 8px;
        height: 8px;
    `}

    ${media.xl`
        width: 10px;
        height: 10px;

        &[data-active]{
            width: 30px;
        }
    `}
`;

const Pagination = styled.div`
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    z-index: 10;
`;

const TestNumber = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
`;

const NavButton = styled.button`
    border: none;
    outline: none;
    color: #fff;
    font-size: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: red;

    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    margin: auto 0;
`;

const NavPre = styled(NavButton)`
    left: 0;

`;

const NavNext = styled(NavButton)`
    right: 0;
`;

const SlideItem = styled.div<any>`
    transition-property: transform;
    position: relative;
`;

const CarouselContainer = styled.div<any>`
    position: relative;
    display: flex;
    visibility: hidden; // 避免瞬間移動, 故移動完才改為顯示
    ${props => props.spaceBetween && css`
       margin-left: -${props.spaceBetween / 2}px;
       margin-right: -${props.spaceBetween / 2}px;
    `}

    &[draggable="true"] {
      /*
       To prevent user selecting inside the drag source
      */
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    ${SlideItem}{
        flex: 1 0 ${props => 100 / props.slidesPerView}%;
        max-width: ${props => 100 / props.slidesPerView}%;

        ${props => props.spaceBetween && css`
            padding-left: ${props.spaceBetween / 2}px;
            padding-right: ${props.spaceBetween / 2}px;
        `}

        // RWD
        ${props => props.breakpoints && generateMedia(props.breakpoints)}
    }
`;

const Content = styled.div`
    overflow-x: hidden;
`;

const Root = styled.div<any>`
    position: relative;
    display: flex;
    flex-direction: column;
`;
