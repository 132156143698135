// Libraries
import api from 'library/apisauce';

import {TGetInfoRes, TPostAvatarRes, TGetWalletAmount} from './types';


export default {
    /**
     * 查詢 個人資料
     */
    getInfo: (): Promise<TGetInfoRes> => {
        return api.get('/member/user/profile', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 上傳 會員個人大頭貼
     * @param avatar 大頭貼
     */
    uploadIcCard: (avatar: File): Promise<TPostAvatarRes> => {
        const formData = new FormData();

        formData.append('avatar', avatar);

        return api.post('/member/user/avatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                isAuth: true
            },
            timeout: 60000,
        });

    },
    /**
     * 查詢 會員餘額
     */
    getWalletAmount: (): Promise<TGetWalletAmount> => {
        return api.get('/member/user/walletAmount', {}, {
            headers: {isAuth: true},
        });
    },
};
