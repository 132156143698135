import React from 'react';
import {Switch, Route} from 'react-router-dom';
import NotFound from 'views/NotFound';
import Home from 'views/Home';
import Menu from 'views/Menu';
import SignUp from 'views/SignUp';
import Profile from 'views/Profile';
import Guide from 'views/Guide';
import About from 'views/About';
import Brand from 'views/Brand';
import Product from 'views/Product';
import ProductDetail from 'views/Product/Detail';
import ShopBag from 'views/Shop';
import ShopCheckout from 'views/Shop';
import {PrivateRoute} from 'library/react-router';
import Message from "../Profile/Message";
import MessageDetail from "../Profile/Message/Detail/Detail";
import Favorite from "../Profile/Favorite";

const Router: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/signUp" component={SignUp}/>
            <Route exact path="/menu" component={Menu}/>
            <Route exact path="/guide" component={Guide}/>
            <Route exact path="/about" component={About}/>

            <PrivateRoute exact path="/profile/message" component={Message}/>
            <PrivateRoute exact path="/profile/message/:categoryId/detail" component={MessageDetail}/>
            <PrivateRoute exact path="/profile/favorite" component={Favorite}/>
            <PrivateRoute path="/profile" component={Profile}/>
            <PrivateRoute exact path="/brand" component={Brand}/>
            <PrivateRoute exact path="/product" component={Product}/>
            <PrivateRoute exact path="/product/detail/:memberId/:id" component={ProductDetail}/>
            <PrivateRoute exact path="/shop/bag" component={ShopBag}/>
            <PrivateRoute exact path="/shop/checkout" component={ShopCheckout}/>

            <Route path="*" component={NotFound}/>
        </Switch>
    );
};

export default Router;
