// Libraries & Utils
import Immutable from 'seamless-immutable';
import { TReducers} from "library/redux";
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from "./types";


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'deposit';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    message: '',

    remitList: [],
    remitPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        pageLimit: 0,
    }
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 新增匯款單
     */
    fetchPending: (state, action) => {
        return state;
    },
    fetchPendingBegin: (state) => {
        return state.merge({isFetching: true, message: '正在新增存款通知單'})
    },
    fetchPendingSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    fetchPendingFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 查詢匯款單列表
     */
    fetchRemit: (state, action) => {
        return state;
    },
    fetchRemitBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取匯款紀錄'})
    },
    fetchRemitSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', remitList: payload.remitList, remitPagination: payload.remitPagination});
    },
    fetchRemitFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
