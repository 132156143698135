import React, {useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import * as CSS from 'csstype';
import {media} from 'library/styled-bs-grid';
import {checkIsMobile} from 'utils/browser';
import DatePicker from 'components/atoms/DateTimePicker';
import Icon from 'components/atoms/Icon';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    label?: string;
    name?: string;
    value?: string;
    isSetTodayVisible?: boolean;
    showTime?: boolean;
    onChange?: Function;
}

/**
 * DatePicker Input
 * @param props
 * @constructor
 */
const DateTimeInput: React.FC<IProps> = ({
    className,
    style,
    label,
    isSetTodayVisible = false,
    showTime,
    value,
    name,
    onChange = () => {},
}) => {
    const mobileInputRef: any = useRef();
    const [selectedDate, setSelectedDate] = useState(value);
    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

    const handleOnChange = (dateValue: string) => {
        setSelectedDate(dateValue);

        if (onChange) {
            onChange(dateValue);
        }
    };

    const handleClearDate = (e: any) => {
        e.stopPropagation();
        setSelectedDate(undefined);
        onChange(undefined);
    };

    const handleDatePickerVisible = (isVisible = false) => {
        setIsDatePickerVisible(isVisible);
    };

    const renderDatePicker = () => {
        if (checkIsMobile()) {
            return (
                <MobileInput
                    ref={e => {
                        mobileInputRef.current = e;
                    }}
                    name={name}
                    type="date"
                    step={1}
                    onChange={() => handleOnChange(mobileInputRef.current.value)}
                />
            );
        }
        return (
            <DatePickerContainer isVisible={isDatePickerVisible}>
                <DatePicker
                    name={name}
                    value={selectedDate}
                    onChange={handleOnChange}
                    onClose={() => handleDatePickerVisible(false)}
                    isSetTodayVisible={isSetTodayVisible}
                    showTime={showTime}
                />
            </DatePickerContainer>
        );
    };

    return (
        <InputContainer
            className={className}
            style={style}
            selectedDate={selectedDate}
            isDatePickerVisible={isDatePickerVisible}
        >
            <FakeInput onClick={() => handleDatePickerVisible(true)}>
                <Label>{label}</Label>
                <Value>{selectedDate}</Value>

                <CalendarIcon>
                    <Icon code="chevron-fillet-down" color="#9d9a9a"/>
                </CalendarIcon>

                <ClearIcon onClick={handleClearDate}>
                    <Icon code="times-circle" color="#9d9a9a"/>
                </ClearIcon>
            </FakeInput>

            {renderDatePicker()}

            {isDatePickerVisible && (
                <CloseArea onClick={() => handleDatePickerVisible(false)}/>
            )}
        </InputContainer>
    );
};

export default DateTimeInput;

const MobileInput = styled.input`
    width: 100%;
    height: inherit;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    opacity: 0;
`;

const CloseArea = styled.div<any>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
`;

const CustomIcon = styled.div`
    width: 20px;
    height: 20px;
    transition: opacity .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 15px;
    }

    ${media.lg`
        width: 28px;
        height: 28px;

        i {
            font-size: 17px;
        }
    `}
`;

const CalendarIcon = styled(CustomIcon)`
    position: absolute;
    right: 10px;

    ${media.lg`
        right: 20px;
    `};
`;

const ClearIcon = styled(CustomIcon)`
    position: absolute;
    right: 10px;
    opacity: 0;
    pointer-events: none;

    ${media.lg`
        right: 20px;

        i {
            font-size: 20px;
        }
    `}
`;

const Value = styled.span<any>`
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    left: 10px;
    color: #6e6e6e;
    bottom: 4px;
    visibility: hidden;

    ${media.lg`
        font-size: 17px;
        left: 20px;
        bottom: 5px;
    `}
`;

const Label = styled.span<any>`
    font-size: 14px;
    transition: transform .3s ease;

    ${media.lg`
        font-size: 17px;
    `}
`;

const DatePickerContainer = styled.div<any>`
    position: absolute;
    left: 50%;
    top: calc(100% + 1px);
    transform: translateX(-50%);
    visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    opacity: ${props => (props.isVisible ? 1 : 0)};
    z-index: 5;
    transition: opacity .5s ease;
`;

const FakeInput = styled.div<any>`
    color: #9d9a9a;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const InputContainer = styled.div<any>`
    width: 100%;
    height: 44px;
    padding-left: 10px;
    border: solid 1px #9d9a9a;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;

    ${props => props.selectedDate && css`
        &:hover {
            ${ClearIcon} {
                opacity: 1;
                pointer-events: auto;
            }

            ${CalendarIcon} {
                opacity: 0;
                pointer-events: none;
            }
        }
    `};

    ${props => props.isDatePickerVisible && css`
        border: solid 1px ${props.theme.thirdColor};

        ${Label} {
            color: ${props.theme.thirdColor};
        }
    `};

    ${props => (props.isDatePickerVisible || props.selectedDate) && css`
        ${Value} {
            visibility: visible;
        }

        ${Label} {
            transform: translateY(-55%);
            font-size: 12px;
        }
    `};

    ${media.lg`
        height: 56px;
        padding-left: 20px;

        ${(props: any) => (props.isDatePickerVisible || props.selectedDate) && css`
            ${Label} {
                transform: translateY(-50%);
                font-size: 14px;
            }
        `}
    `}
`;

