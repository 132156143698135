import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {asset} from 'config/app';
import {HEXToRGBA} from 'utils/format';
import {formatCurrency} from 'utils/number';
import {useDispatch, useSelector} from 'react-redux';
import {HalfCircleSpinner} from 'react-epic-spinners';
import get from 'lodash/get';
import ReactHtmlParser from 'react-html-parser';

// Component
import MallCarousel from 'components/organisms/MallCarousel';
import Icon from 'components/atoms/Icon';
import MemberTooltips from 'components/molecules/MemberTooltips';
import CollapsibleItem from 'components/atoms/Collapsible/CollapsibleItem';
import Button from 'components/atoms/Button';

// Reducer
import {Actions as SystemAction} from 'store/system';
import {Actions as LevelAction} from 'store/level';
import {Actions as ProductAction} from 'store/product';
import {Actions as AuthAction, Selector as AuthSelector} from 'store/auth';


const aboutData = [
    {code: 'gopuram', iconColor: '#ff7d6a', circleColor: 'rgba(255, 125, 106, 0.2)', title: '南大門所有商品皆可瀏覽', content: '人在台灣輕鬆逛遍韓國南大門所有店家，不用再為了採購找貨搭飛機到韓國。'},
    {code: 'balance-scale', iconColor: '#9c92ff', circleColor: 'rgba(156, 146, 255, 0.2)', title: '追蹤比較再採買', content: '您在南大門商場採購是無法將感興趣和類似商品放入追蹤清單，但在韓爸這卻可以放入追蹤清單後比較，再決定是否要採購。'},
    {code: 'online-order', iconColor: '#f4da56', circleColor: 'rgba(255, 237, 146, 0.2)', title: '線上下單採買', content: '韓爸專業採購人員會按照您每日下的訂單進行採購，不必因兌換匯率匯差及手續費煩腦，也不需帶著現金在南大門商場奔波提貨。'},
    {code: 'computer-account', iconColor: '#ff8e3b', circleColor: 'rgba(255, 142, 59, 0.2)', title: '網站帳款查詢', content: '網站系統裡您可直接在線上查詢入帳與採購金額，可以很清楚的自己的帳款，不需按計算機換算也不怕超支。'},
    {code: 'hand-holding-clock', iconColor: '#8ec5ff', circleColor: 'rgba(142, 197, 255, 0.2)', title: '採購進度查詢', content: '線上即可清楚查詢廠商已配貨的日期及數量，也能讓您清楚知道目前採購進度。'},
    {code: 'globe-asia', iconColor: '#a8d580', circleColor: 'rgba(168, 213, 128, 0.2)', title: '專業國際物流配送', content: '韓爸有專業台韓國際物流團隊，整合所有會員貨物統一報關進口，您不用辛苦的批貨後，還得到韓國包裝店去議價點貨。'},
];

const About: React.FC = () => {
    const dispatch = useDispatch();

    const {isAuth, faqDate, isFetchingFaq, levelInfo, isFetchingLevel, mallList, isFetchingMall} = useSelector(state => ({
        isAuth: AuthSelector.isAuth(state),
        isFetchingFaq: state.system.isFetching,
        faqDate: state.system.faqDate,
        levelInfo: state.level.levelInfo,
        isFetchingLevel: state.level.isFetching,
        mallList: state.product.mallList,
        isFetchingMall: state.product.isFetching,
    }));

    useEffect(() => {
        // 取得常見問題
        if (get(faqDate, 'length', 0) === 0) {
            dispatch(SystemAction.fetchFaq());
        }

        // 取會員等級特性
        if (get(levelInfo, 'length', 0) === 0) {
            dispatch(LevelAction.fetchMemberLevel());
        }

        // 取得商城列表
        if (get(mallList, 'length', 0) === 0) {
            dispatch(ProductAction.fetchProductMall());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AboutRoot>
            <AboutBg
                mobileUrl={asset('/images/about/about-mobile.jpg')}
                pcUrl={asset('/images/about/about-pc.jpg')}
            >
                <AboutSection>
                    <AboutTitle>關於韓爸</AboutTitle>
                    <AboutDesc>韓爸為老闆們提供累積多年營運技巧獨特優勢，與韓國南大門直接合作，所有商品皆自韓國進口。</AboutDesc>
                    {!isAuth && (
                        <SignUpButton
                            themeType="whiteBorderBlur"
                            size="middle"
                            shape="raised"
                            isBlurBg
                            onClick={() => dispatch(AuthAction.openSignUpModal())}
                        >
                            註冊
                        </SignUpButton>
                    )}
                </AboutSection>

                {/* 電腦版合作廠商 */}
                <MallSection>
                    {mallList.map(row => (
                        <ItemBg>
                            <MallItem src={row.logoUrl}/>
                        </ItemBg>
                    ))}
                </MallSection>
            </AboutBg>

            {isFetchingMall && (
                <Row>
                    <Col col>
                        <HalfCircleSpinner style={{margin: '10px auto'}} size={20} color="#bdbdbd"/>
                    </Col>
                </Row>
            )}
            <CustomMallCarousel
                className="d-lg-none"
                data={mallList}
            />

            <AboutContainer>
                <ServiceTitle>服務與優勢</ServiceTitle>
                <ServiceContent>韓爸會每日按照您下單的品項，直接由南大門專業團隊進行採購，您只需要在線上即可掌握所有進度，達成最高經濟效益！並讓您保有利潤，選擇韓爸將會是您事業擴展最有利的幫手。</ServiceContent>

                <Row>
                    {aboutData.map(row => (
                        <Col key={row.code} col={24} md={12} lg={8}>
                            <Item
                                className="d-flex flex-column align-items-center"
                                bgColor={row.circleColor}
                            >
                                <ItemIcon code={row.code} size={100} color={row.iconColor}/>
                                <Title>{row.title}</Title>
                                <Content>{row.content}</Content>
                            </Item>
                        </Col>
                    ))}
                </Row>
            </AboutContainer>

            <PlacementImg
                mobileUrl={asset('/images/about/about-signup-mobile.jpg')}
                pcUrl={asset('/images/about/about-signup-pc.jpg')}
            >
                <PlacementSection>
                    <div className="flex-md-grow-1">
                        <PlacementTitle>您還在親自飛往韓國挑貨嗎？</PlacementTitle>
                        <PlacementDesc>韓爸提供了韓國南大門最新的商品訊息， 快省下您寶貴的時間與成本，加入會員即可享受喔！！</PlacementDesc>
                    </div>
                    {!isAuth && (
                        <PlacementButton
                            themeType="whiteBorderBlur"
                            size="middle"
                            shape="raised"
                            isBlurBg
                            onClick={() => dispatch(AuthAction.openSignUpModal())}
                        >
                            免費加入
                        </PlacementButton>
                    )}
                </PlacementSection>
            </PlacementImg>

            <FeatureContainer>
                <Row>
                    {isFetchingLevel && (
                        <Col col>
                            <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                        </Col>
                    )}

                    {levelInfo.map(row => {
                        const borderColor = HEXToRGBA(get(row, 'themeColor', '#fffff'), 0.3);

                        return (
                            <FeatureCol key={get(row, 'id')} md={12}>
                                <Feature
                                    themeColor={get(row, 'themeColor')}
                                    borderColor={borderColor}
                                >
                                    <Name>{get(row, 'name')}</Name>
                                    <EnglishName>{get(row, 'englishName')}</EnglishName>
                                    <PreDepositAmount>{get(row, 'preDepositAmount', 0) === 0 ? '無需儲值金' : `儲值金${get(row, 'preDepositAmount', 0)/10000}萬`}</PreDepositAmount>
                                    <div className="d-flex flex-column">
                                        {row.features.map(item => (
                                            <FeatureItem key={get(item, 'id')}>
                                                <CheckIcon code="check-bold" size={20} color={get(item, 'isEnable', false) ? get(row, 'themeColor') : 'transparent'}/>
                                                <FeatureTitle isEnable={get(item, 'isEnable', false)}>{get(item, 'title')}</FeatureTitle>
                                            </FeatureItem>
                                        ))}
                                    </div>
                                    <Amount>
                                        <AmountDesc>{`NT. ${formatCurrency(get(row, 'preDepositAmount', 0))}元 /月`}</AmountDesc>
                                    </Amount>

                                    <CustomMemberTooltips
                                        isVip={row.name === 'VIP會員'}
                                        title={`基本標準：一個月最少需出貨$${formatCurrency(get(row, 'needShippingAmount', 0))}元以上`}
                                        content={row.content}
                                    />
                                </Feature>
                            </FeatureCol>
                        );
                    })}
                </Row>

                <Faq>
                    <FaqTitle className="d-none d-lg-block">FAQ</FaqTitle>

                    {isFetchingFaq && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}

                    {faqDate.map(row => (
                        <CollapsibleItem
                            key={row.id}
                            title={row.question}
                            content={ReactHtmlParser(row.answer.replaceAll("\n", "<br/>"))}
                        />
                    ))}
                </Faq>
            </FeatureContainer>

            <Footer className="d-md-none">
                <Desc>Copyright © 韓國童裝女裝玩具雜貨☆韓爸有衣☆批發網</Desc>
            </Footer>
        </AboutRoot>
    );
};

export default About;

const MallItem = styled.img<any>`
    width: 100%;
    height: auto;
`;

const ItemBg = styled.div`
    width: 100%;
    min-height: 75px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    ${media.xl`
        min-height: 94px;
    `}
`;

const MallSection = styled.div`
    display: none;
    flex-direction: column;
    position: absolute;
    right: 56px;
    width: 200px;
    height: 100%;
    padding: 15px 32px 0 32px;
    background-color: rgba(255, 255, 255, 0.2);
    overflow-y: scroll;

    ${media.lg`
        display: flex;
    `}

    ${media.xl`
        width: 240px;
        padding: 15px 37px 0 37px;
    `}
`;

const Desc = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
`;

const Footer = styled.div`
    width: 100%;
    height: 29px;
    background-color: #282731;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 99;
    margin-top: 30px;
`;

const FaqTitle = styled.span`
    font-size: 26px;
    font-weight: 600;
    color: #6e6e6e;
    text-align: center;
    margin-bottom: 30px;

    ${media.xl`
        font-size: 30px;
        margin-bottom: 50px;
    `}
`;

const Faq = styled.div`
    width: 100%;

    ${media.md`
        margin-bottom: 50px;
        padding: 0 50px;
    `}

    ${media.lg`
        max-width: 800px;
        margin-bottom: 70px;
        padding: 0;
    `}

    ${media.xl`
        margin-bottom: 100px;
    `}
`;

const CustomMemberTooltips = styled(MemberTooltips)`
    div{
        opacity: 0;
        transition: opacity .3s;
    }
`;

const AmountDesc = styled.span`
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
`;

const Amount = styled.div`
    width: 200px;
    height: 50px;
    border-radius: 30px;
    margin: 5px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FeatureTitle = styled.span<any>`
    min-width: 140px;
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.isEnable ? '#6e6e6e' : 'rgba(0, 0, 0, 0.1)'};
`;

const CheckIcon = styled(Icon)`
    margin-right: 20px;
`;

const FeatureItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const PreDepositAmount = styled.div`
    font-size: 14px;
    text-align: center;
    position: relative;
    margin-bottom: 20px;

    &:before{
        content: '';
        width: 70px;
        height: 1px;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(-20px);
    }

    &:after{
        content: '';
        width: 70px;
        height: 1px;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(20px);
    }
`;

const EnglishName = styled.span`
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #9d9a9a;
    padding-bottom: 10px;
`;

const Name = styled.span`
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 2px;
`;

const Feature = styled.div<any>`
    width: 100%;
    border-radius: 4px;
    border: solid 1px ${props => props.themeColor};
    background-color: #ffffff;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    transition: box-shadow .2s;

    &:hover{
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

        ${CustomMemberTooltips} {
            div{
                opacity: 1;
            }
        }
    }

    ${Name} {
        color: ${props => props.themeColor};
    }

    ${PreDepositAmount} {
        color: ${props => props.themeColor};

        &:before, &:after{
            background-color: ${props => `rgba(${props.borderColor[0]}, ${props.borderColor[1]}, ${props.borderColor[2]}, ${props.borderColor[3]})`};
        }
    }

    ${Amount} {
        background-color: ${props => props.themeColor};
    }

    ${media.md`
        min-width: 280px;
    `}

    ${media.lg`
        min-width: 280px;
        margin-bottom: 70px;
    `}

    ${media.xl`
        min-width: 400px;
        margin-bottom: 125px;
    `}
`;

const PlacementButton = styled(Button)`
    width: 160px;
    height: 50px;
    min-height: auto;

    ${media.xxl`
        width: 250px;
        height: 80px;
    `}
`;

const PlacementDesc = styled.span`
    font-size: 12px;
    font-weight: 500;
    display: block;
    color: #ffffff;
    padding-bottom: 26px;
    text-align: center;

    ${media.md`
        padding-bottom: 0;
        max-width: 300px;
        text-align: left;
    `}

    ${media.lg`
        font-size: 14px;
        max-width: 400px;
    `}

    ${media.xxl`
        font-size: 20px;
        max-width: 500px;
    `}
`;

const PlacementTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
    display: block;
    color: #ffffff;
    padding-bottom: 12px;
    text-align: center;

    ${media.md`
        text-align: left;
    `}

    ${media.lg`
        font-size: 24px;
    `}

    ${media.xxl`
        font-size: 30px;
        padding-bottom: 16px;
    `}
`;

const PlacementSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    position: absolute;

    ${media.md`
        flex-direction: row;
        padding: 0 30px;
    `}

    ${media.lg`
        padding: 0 60px;
    `}

    ${media.xxl`
        padding: 0 95px;
    `}
`;

const PlacementImg = styled.div<any>`
    background-image: url("${props => props.mobileUrl}");
    padding-bottom: 78%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 50px;
    position: relative;

    ${media.md`
        background-image: url("${(props: any) => props.pcUrl}");
        padding-bottom: 15%;
    `}

    ${media.xl`
        margin-bottom: 100px;
    `}
`;

const Content = styled.span`
    max-width: 280px;
    font-size: 12px;
    text-align: center;
    color: #6e6e6e;
    padding: 0 20px;
`;

const Title = styled.span`
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #6e6e6e;
    margin-bottom: 8px;
    padding: 0 20px;

    ${media.xl`
        font-size: 16px;
    `}
`;

const ItemIcon = styled(Icon)`
    margin-bottom: 10px;
`;

const Item = styled.div<any>`
    width: 100%;
    min-height: 240px;
    position: relative;
    padding-top: 10px;

    &:after{
        content: '';
        width: 180px;
        height: 180px;
        border-radius: 50%;
        background-color: ${props => props.bgColor};
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: border-radius .2s;
    }

    ${media.lg`
        padding-top: 10px;

        &:hover{
            &:after{
                width: 100%;
                height: 100%;
                border-radius: 5px;;
            }
        }
    `}
`;

const ServiceContent = styled.p`
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #6e6e6e;
    margin-bottom: 20px;

    ${media.md`
        max-width: 350px;
    `}

    ${media.lg`
        font-size: 14px;
        max-width: 500px;
    `}

    ${media.lg`
        max-width: 600px;
    `}
`;

const ServiceTitle = styled.p`
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #6e6e6e;
    margin-bottom: 10px;

    ${media.lg`
        font-size: 22px;
    `}

    ${media.xl`
        font-size: 30px;
        margin-bottom: 20px;
    `}
`;

const FeatureCol = styled(Col)`
    ${media.md`
        padding-left: 15px;
        padding-right: 15px;
    `}

    ${media.xl`
        padding-left: 50px;
        padding-right: 50px;
    `}
`;

const FeatureContainer = styled(Container)`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const AboutContainer = styled(Container)`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;

    ${media.md`
        margin-bottom: 50px;
    `}

    ${media.xl`
        margin-bottom: 100px;
    `}
`;

const CustomMallCarousel = styled(MallCarousel)`
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 38px;
`;

const SignUpButton = styled(Button)`
    width: 100px;
    height: 30px;
    min-height: auto;

    ${media.lg`
        width: 133px;
        height: 46px;
    `}
`;

const AboutDesc = styled.span`
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    padding-bottom: 10px;

    ${media.lg`
        font-size: 14px;
        padding-bottom: 16px;
    `}

    ${media.xl`
        font-size: 16px;
        padding-bottom: 22px;
    `}
`;

const AboutTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    padding-bottom: 2px;

    ${media.md`
        font-size: 30px;
    `}

    ${media.lg`
        font-size: 45px;
    `}

    ${media.xl`
        font-size: 60px;
    `}
`;

const AboutSection = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    ${media.lg`
        padding-left: 60px;
        align-items: flex-start;
    `}

    ${media.xl`
        padding-left: 95px;
    `}
`;

const AboutBg = styled.div<any>`
    background-image: url("${props => props.mobileUrl}");
    padding-bottom: 47%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 12px;
    position: relative;

    ${media.md`
        margin-bottom: 30px;
    `}

    ${media.lg`
        padding-bottom: 31%;
        background-image: url("${(props: any) => props.pcUrl}");
    `}

    ${media.xl`
        margin-bottom: 50px;
    `}
`;

const AboutRoot = styled.div``;
