import React, {useEffect, useState, useRef} from 'react';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import dayjs from 'dayjs';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {HEXToRGBA} from 'utils/format';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {get, groupBy} from 'lodash';
import {isEmpty} from 'utils/equal';
import {asset} from 'config/app';
import site from 'config/site';
import {HalfCircleSpinner} from 'react-epic-spinners';

// Component
import Icon from 'components/atoms/Icon';
import ImagePreViewModal from 'components/organisms/ImagePreViewModal';

// Reducer
import {Actions as MessageAction} from 'store/message';

const Message: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const {register, handleSubmit, watch, setValue} = useForm();
    const {content, image} = watch();

    const [currentCategoryId, setCategoryId] = useState<number>();
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string|ArrayBuffer|null>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isVisibleImageModal, setVisibleImageModal] = useState<boolean>(false);

    const {isFetching, isFetchingContent, messageCategory, messageContent, isReadonly} = useSelector(state => ({
        isFetching: state.message.isFetching,
        isFetchingContent: state.message.isFetchingContent,
        messageCategory: state.message.messageCategory,
        messageContent: state.message.messageContent,
        isReadonly: state.message.isReadonly,
    }));


    // 信息內容以日期分群
    const contentByDate = groupBy(messageContent, function (date) {
        return dayjs(date.createdAt).format('YYYY/MM/DD');
    });


    useEffect(() => {
        // 取訊息類別
        dispatch(MessageAction.fetchCategory());

        if (!isEmpty(currentCategoryId)) {
            dispatch(MessageAction.fetchContent({categoryId: currentCategoryId}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCategoryId]);


    // 當訊息內容改變，自動scroll to bottom
    useEffect(() => {
        contentRef.current.scrollTo({top: contentRef.current.scrollHeight});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageContent]);


    /**
     * 選擇訊息類別
     * */
    const handleClickCategory = (id: number) => {
        setCategoryId(id);
        if (currentCategoryId !== id) {
            setValue('content', '');
            setValue('image', '');
        }
    };


    /**
     * 訊息類別loading
     */
    const renderLoading = () => {
        let loadingCategory = [];
        for (let i = 0; i < 7; i ++) {
            loadingCategory.push((
                <Category className="d-flex align-items-center justify-content-center" key={i}>
                    <Row>
                        <Col col>
                            <HalfCircleSpinner style={{margin: 'auto'}} size={20} color="#bdbdbd"/>
                        </Col>
                    </Row>
                </Category>
            ));
        }
        return loadingCategory;
    };


    /**
     * 上傳圖片
     * */
    const handleImageChange = (e: React.ChangeEvent<Element>) => {
        const reader = new FileReader();
        const file = get(e, 'target.files.0');

        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    /**
     *  送出
     * */
    const onSubmit = (formData: any) => {
        if (currentCategoryId) {
            dispatch(MessageAction.submitMessage({categoryId: currentCategoryId, content: formData.content, image: formData.image[0], imagePreviewUrl: imagePreviewUrl}));
            setValue('content', '');
            setValue('image', '');
            setImagePreviewUrl('');
        }
    };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>訊息</MobileTitle>

                    <IconButton/>
                </MobileHeader>
            </Container>

            {/* Header */}
            <Header className="d-none d-md-flex flex-column align-items-center justify-content-center">
                <Title>訊息</Title>

                <Container>
                    <Row>
                        <Col col={8}>
                            <Breadcrumbs>Home / 訊息</Breadcrumbs>
                        </Col>
                        <Col col={8} className="d-flex justify-content-center">
                            <EnglishTitle>Message</EnglishTitle>
                        </Col>
                        <Col col={8} className="d-flex justify-content-end">
                            <Desc>＊＊資料保留期間：半年內</Desc>
                        </Col>
                    </Row>
                </Container>
            </Header>

            <MessageContainer>
                <form
                    className="d-md-flex"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {/* 訊息類別loading */}
                    {(get(messageCategory, 'length', 0) === 0 && isFetching) && <div>{renderLoading()}</div>}


                    {/* 訊息類別 */}
                    <div>
                        {messageCategory.map(row => {
                            const today = dayjs();
                            const isToday = today.isSame(row.createdAt, 'date');
                            const tagBg = HEXToRGBA(row.themeColor, 0.1);

                            // 顯示「訊息輸入中」文字
                            const isVisibleEntering = (!isEmpty(row.lastContent) && row.id === currentCategoryId && !isEmpty(content));

                            return (
                                <CategoryRoot
                                    key={row.id}
                                    onClick={() => handleClickCategory(row.id)}
                                >
                                    <Category
                                        onClick={() => history.push(`/profile/message/${row.id}/detail`)}
                                        isSelected={row.id === currentCategoryId}
                                        bgColor={row.themeColor}
                                        tagBg={tagBg}
                                    >
                                        <CategoryTag theme={row.themeColor}>
                                            <CategoryShortName>{row.shortName}</CategoryShortName>
                                        </CategoryTag>
                                        <div className="d-flex flex-column flex-grow-1">
                                            <CategoryName>{row.name}</CategoryName>
                                            {!isEmpty(row.lastContent) && (
                                                <LastContent isVisible={!isVisibleEntering}>{!isVisibleEntering && row.lastContent}</LastContent>
                                            )}
                                        </div>

                                        {isVisibleEntering && (
                                            <Prompt>訊息輸入中…</Prompt>
                                        )}


                                        {/* 未讀訊息筆數 */}
                                        {row.unreadCount > 0 && (
                                            <UnRead>
                                                <UnReadNum>{row.unreadCount}</UnReadNum>
                                            </UnRead>
                                        )}


                                        {/* 建立時間 */}
                                        {isEmpty(row.createdAt) ?
                                            (row.id === currentCategoryId && !isEmpty(content)) ? (
                                                <Prompt>訊息輸入中…</Prompt>
                                            ) : (
                                                <Prompt>詢問相關問題</Prompt>
                                            )
                                            :
                                            (
                                                <CreatedAt>
                                                    {isToday ?
                                                        dayjs(row.createdAt).format('HH:mm')
                                                        :
                                                        dayjs(row.createdAt).format('YY/MM/DD')
                                                    }
                                                </CreatedAt>
                                            )}
                                    </Category>
                                </CategoryRoot>
                            )
                        })}
                    </div>

                    {/* 訊息內容 */}
                    <Detail className="d-none d-md-flex">
                        <Content ref={contentRef} hasImg={get(image, 'length', 0) > 0}>
                            {/* 訊息日期 */}
                            {!isEmpty(currentCategoryId) && Object.keys(contentByDate).map(date => (
                                <div key={date}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Date>{date}</Date>
                                    </div>

                                    {/* 訊息內容 */}
                                    {contentByDate[date].map((row) => {
                                        // 判斷上下午
                                        const timeDesc = dayjs(get(row, 'createdAt')).hour() > 11 ? '下午' : '上午';

                                        if (!get(row, 'isMemberPost', false)) {
                                            return (
                                                <div key={get(row, 'id')}>
                                                    <System>
                                                        <LogoBg/>
                                                        <MessageBg>
                                                            <MessageText>{get(row, 'content')}</MessageText>
                                                        </MessageBg>
                                                        <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                    </System>

                                                    {!isEmpty(row.imageUrl) && (
                                                        <System>
                                                            <LogoBg/>
                                                            <MessageImage
                                                                src={row.imageUrl}
                                                                onClick={() => {
                                                                    setVisibleImageModal(true);
                                                                    setImageUrl(row.imageUrl);
                                                                }}
                                                            />
                                                            <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                        </System>
                                                    )}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={get(row, 'id')}>
                                                    {!isEmpty(row.content) && (
                                                        <User>
                                                            <div className="d-flex align-items-center">
                                                                {get(row, 'isSubmitting', false) && (<Icon code="redo" size={15} color="#bbb8b8" isRotateAnimation/>)}
                                                                <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                            </div>
                                                            <UserMessageBg>
                                                                <MessageText>{row.content}</MessageText>
                                                            </UserMessageBg>
                                                        </User>
                                                    )}

                                                    {!isEmpty(row.imageUrl) && (
                                                        <User>
                                                            <div className="d-flex align-items-center">
                                                                {get(row, 'isSubmitting', false) && (<Icon code="redo" size={15} color="#bbb8b8" isRotateAnimation/>)}
                                                                <TimeText>{timeDesc} {dayjs(get(row, 'createdAt')).format('HH:mm')}</TimeText>
                                                            </div>
                                                            <MessageImage
                                                                src={row.imageUrl}
                                                                onClick={() => {
                                                                    setVisibleImageModal(true);
                                                                    setImageUrl(row.imageUrl);
                                                                }}
                                                            />
                                                        </User>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            ))}


                            {/* 訊息內容讀取樣式 */}
                            {((!isEmpty(currentCategoryId) && isFetchingContent)) && (
                                <Row className="h-100 d-flex align-items-center">
                                    <Col col>
                                        <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                                    </Col>
                                </Row>
                            )}


                            {(!isEmpty(currentCategoryId) && !isFetchingContent && isEmpty(contentByDate)) && (
                                <NoMessage>（目前尚無訊息...）</NoMessage>
                            )}


                            {/* 未選擇訊息類別時 */}
                            {isEmpty(currentCategoryId) && (
                                <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                                    <PenIcon code="pen" size={50} color={site.theme.primaryColor}/>
                                    <WriteDesc>請選擇左側類別建立新訊息</WriteDesc>
                                </div>
                            )}
                        </Content>

                        {/* 上傳圖片 && 輸入訊息 */}
                        {!isReadonly && (
                            <FillSection>
                                {/* 上傳圖片 */}
                                <UploadButton type="button">
                                    <Icon code="image" size={30} color={isEmpty(currentCategoryId) ? '#bbb8b8' : site.theme.primaryColor}/>
                                    <UploadInput
                                        ref={(e: HTMLInputElement) => register(e)}
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={(e) => handleImageChange(e)}
                                        disabled={isEmpty(currentCategoryId)}
                                    />
                                </UploadButton>

                                {/* 上傳圖片預覽 */}
                                {get(image, 'length', 0) > 0 && (
                                    <PreView>
                                        <PreViewImg src={imagePreviewUrl}/>
                                        <CloseButton
                                            type="button"
                                            onClick={() => {
                                                setValue('image', null);
                                                setImagePreviewUrl('');
                                            }}
                                        >
                                            <Icon code="times" size={15} color="#9d9a9a"/>
                                        </CloseButton>
                                    </PreView>
                                )}

                                <MessageInput
                                    ref={(e: HTMLInputElement) => register(e)}
                                    name="content"
                                    placeholder="輸入訊息…"
                                    disabled={isEmpty(currentCategoryId)}
                                />

                                <SendButton disabled={isEmpty(content) && get(image, 'length', 0) === 0}>
                                    <Icon code="paper-plane" size={30} color={(isEmpty(content) && get(image, 'length', 0) === 0) ? '#bbb8b8' : site.theme.primaryColor}/>
                                </SendButton>
                            </FillSection>
                        )}
                    </Detail>
                </form>

                {/* 圖片放大 */}
                <ImagePreViewModal
                    imageUrl={imageUrl}
                    isVisible={isVisibleImageModal}
                    onClose={() => setVisibleImageModal(false)}
                />
            </MessageContainer>
        </>
    );
};

export default Message;

const NoMessage = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #bbb8b8;
`;

const Desc = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.xl`
        font-size: 16px;
    `}
`;

const EnglishTitle = styled.span`
    font-size: 14px;
    color: #9d9a9a;
    text-align: center;

    ${media.xl`
        font-size: 16px;
    `}
`;

const Breadcrumbs = styled.span`
    font-size: 14px;
    color: #9d9a9a;

    ${media.xl`
        font-size: 16px;
    `}
`;

const Title = styled.span`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #282731;
    padding-bottom: 12px;
`;

const Header = styled.div`
    width: 100%;
    height: 112px;
    background-color: #fbfbfb;
    margin-bottom: 20px;

    ${media.xl`
        height: 132px;
    `}
`;

const MessageInput = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    font-size: 16px;
    color: #2d2c38;
    background-color: transparent;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #bbb8b8;
    }
`;


const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
`;

const PreViewImg = styled.img<any>`
    width: auto;
    height: 100px;

    ${media.xl`
        height: 120px;
    `}
`;

const PreView = styled.div`
    width: 100%;
    height: 120px;
    background-color: #fff;
    border: solid 1px #ebebeb;
    border-radius: 6px 6px 0 0;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 10px 0;
    display: flex;
    justify-content: center;

    ${media.xl`
        height: 140px;
    `}
`;

const UploadInput = styled.input`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
`;

const UploadButton = styled.button`
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    position: relative;
`;

const SendButton = styled(UploadButton)`
    margin-right: 0;
`;

const FillSection = styled.div`
    width: 100%;
    height: 60px;
    border-radius: 6px;
    border: solid 1px #ebebeb;
    margin: 10px 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    position: relative;

    ${media.xxl`
        padding: 0 35px;
    `}
`;

const WriteDesc = styled.span`
    font-size: 14px;
    color: #282731;
`;

const PenIcon = styled(Icon)`
    color: #eeeeef;
    margin-bottom: 20px;

    ${media.xxl`
        font-size: 70px;
        margin-bottom: 30px;
    `}
`;

const UserMessageBg = styled.div`
    max-width: 240px;
    border-radius: 20px 20px 0 20px;
    background-color: rgba(142, 197, 255, 0.1);
    padding: 10px 20px;
    margin-left: 10px;

    ${media.xxl`
        max-width: 445px;
    `}
`;

const User = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

const MessageImage = styled.img`
    width: auto;
    height: 150px;
    border-radius: 20px 20px 0 20px;
    margin-left: 10px;
    cursor: pointer;

    ${media.xl`
        height: 200px;
    `}

    ${media.xxl`
        height: 250px;
    `}
`;

const TimeText = styled.span`
    font-size: 16px;
    color: #bbb8b8;
    padding-left: 5px;
`;

const MessageText = styled.span`
    font-size: 16px;
    color: #6e6e6e;
    display: block;
    word-break: break-all;
`;

const MessageBg = styled.div`
    max-width: 240px;
    border-radius: 20px 20px 20px 0;
    background-color: #fafafa;
    padding: 10px 20px;
    margin-right: 10px;

    ${media.xxl`
        max-width: 445px;
    `}
`;

const LogoBg = styled.div`
    width: 40px;
    height: 40px;
    background-color: #fafafa;
    border-radius: 50%;
    background-image: url("${asset('/images/home/message-logo.png')}");
    background-size: cover;
    margin-right: 10px;
`;

const System = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
`;

const Date = styled.span`
    font-size: 14px;
    color: #bbb8b8;
    display: block;
    text-align: center;
    margin: 30px;
    position: relative;

    &:before{
        content: '';
        width: 60px;
        height: 1px;
        background-color: #ebebeb;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(-10px);
    }

    &:after{
        content: '';
        width: 60px;
        height: 1px;
        background-color: #ebebeb;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(10px);
    }
`;

const Content = styled.div<any>`
    width: 100%;
    height: 470px;
    border-radius: 6px;
    border: solid 1px #ebebeb;
    margin-bottom: 10px;
    padding: 0 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    ${media.md`
        ${(props: any) => props.hasImg && css`
            padding-bottom: 100px;
        `}
    `}

    ${media.xl`
        ${(props: any) => props.hasImg && css`
            padding-bottom: 120px;
        `}
    `}
`;

const Detail = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    ${media.xxl`
        margin-left: 30px;
    `}
`;

const CreatedAt = styled.span`
    font-size: 10px;
    color: #bbb8b8;
    position: absolute;
    top: 5px;
    right: 10px;

    ${media.md`
        right: 15px;
    `}
`;

const Prompt = styled.span`
    font-size: 12px;
    color: #bbb8b8;
`;

const UnReadNum = styled.span`
    font-size: 10px;
    font-weight: bold;
    color: #ffffff;

    ${media.md`
        font-size: 12px;
    `}
`;

const UnRead = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ff7d6a;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 20px;
        height: 20px;
    `}
`;

const LastContent = styled.div<any>`
    width: calc(100vw - 100px);
    max-width: 0;
    min-height: 22px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 2px;

    ${props => props.isVisible && css`
        max-width: 440px;
    `}

    ${media.md`
        font-size: 14px;

        ${(props: any) => props.isVisible && css`
            max-width: 140px;
        `}
    `}
`;

const CategoryName = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;

    ${media.md`
        font-size: 14px;
    `}
`;

const CategoryShortName = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;

    ${media.md`
        font-size: 16px;
    `}
`;

const CategoryTag = styled.div<any>`
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${props => props.theme};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    ${media.md`
        min-width: 40px;
        height: 40px;
        margin-right: 10px;
    `}
`;

const Category = styled.div<any>`
    width: 100%;
    height: 60px;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #ffffff;
    margin-bottom: 5px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: border-color .2s, background-color .2s;

    ${media.md`
        width: 250px;
        height: 70px;
        border-radius: 6px;
        padding: 0 15px;
        margin-bottom: 10px;
        pointer-events: none;

        ${(props: any) => props.isSelected && css`
            border: solid 1px ${props.bgColor};
            background-color: ${`rgba(${props.tagBg[0]}, ${props.tagBg[1]}, ${props.tagBg[2]}, ${props.tagBg[3]})`};
        `}
    `}

    ${media.xxl`
        width: 380px;
    `}
`;

const CategoryRoot = styled.div`
    cursor: pointer;
`;

const MessageContainer = styled(Container)`
    padding-right: 5px;
    padding-left: 5px;

    ${media.md`
        padding: 20px 10px 40px 10px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
