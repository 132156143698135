import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {Container, media, Row, Col} from 'library/styled-bs-grid';
import get from 'lodash/get';
import {parseQueryString} from 'utils/uri';
import dayjs from 'dayjs';
import {checkIsMobile} from 'utils/browser';
import {HalfCircleSpinner} from 'react-epic-spinners';

// Component
import Icon from 'components/atoms/Icon';
import ShipAccordion from 'components/molecules/ShipAccordion';
import Pagination from 'components/atoms/Pagination';
import PrintModal from '../Ship/PrintModal';
import Search from '../Search';

// Reducer
import {Actions as ShipmentAction} from 'store/shipment';


// 一頁顯示筆數
const pageLimit = 20;

/**
 *  出貨明細
 * @constructor
 */
const Ship: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isVisibleSearch, setVisibleSearch] = useState(false);
    const [visibleId, setVisibleId]: any = useState('');
    const [isVisibleModal, setVisibleModal]: any = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const useFormParams = useForm();
    const {handleSubmit} = useFormParams;

    const {isFetching, isFetchingDetail, orderShipperList, orderShipperPagination, orderShipperDetail} = useSelector(state => ({
        isFetching: state.shipment.isFetching,
        isFetchingDetail: state.shipment.isFetchingDetail,
        orderShipperList: state.shipment.orderShipperList,
        orderShipperPagination: state.shipment.orderShipperPagination,
        orderShipperDetail: state.shipment.orderShipperDetail,
    }));

    const {search} = useLocation();
    const queryString = parseQueryString(search);
    const startDate = get(queryString, 'startDate', '');
    const endDate = get(queryString, 'endDate', '');
    const shipperId = get(queryString, 'shipperId', '');
    const searchPage = get(queryString, 'page', 1);


    useEffect(() => {
        setCurrentPage(Number(searchPage));

        // 取得出貨單列表
        dispatch(ShipmentAction.fetchOrderShipper({startDate, endDate, shipperId, currentPage: Number(searchPage), pageLimit}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, shipperId, searchPage]);


    /**
     *  搜尋商品
     */
    const handleSearch = (formData: any) => {
        setVisibleSearch(false);

        history.push(`/profile/ship?startDate=${formData?.startDate ?? ''}&endDate=${formData?.endDate ?? ''}&shipperId=${formData?.search ?? ''}&page=1`);
    };


    /**
     * 換頁
     */
    const handleChangePage = (p: number) => {
        history.push(`/profile/ship?startDate=${startDate}&endDate=${endDate}&shipperId=${shipperId}&page=${p}`);
    };


    /**
     * 顯示明細開關
     */
    const handleSelectedId = (id: any) => {
        if (checkIsMobile()) {
            history.push(`/profile/ship/detail/${id}`);
        } else {
            if (id === visibleId) {
                setVisibleId('');
            } else {
                setVisibleId(id);

                // 取得該筆出貨單的明細api
                dispatch(ShipmentAction.fetchOrderShipperDetail({id}));
            }
        }
    };

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/account')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>出貨明細</MobileTitle>

                    <IconButton onClick={() => setVisibleSearch(true)}>
                        <Icon code="search" size={20} color="#282731"/>
                    </IconButton>
                </MobileHeader>
            </Container>

            <form onSubmit={handleSubmit(handleSearch)}>

                {/* 搜尋 */}
                <Search
                    isVisible={isVisibleSearch}
                    onClose={() => setVisibleSearch(false)}
                    useFormParams={useFormParams}
                    placeholder="透過出貨單編號搜尋"
                />

                <ShipContainer>
                    {/* 標題 */}
                    <Header className="d-none d-md-flex">
                        <NumSection/>
                        <Model>出貨單號</Model>
                        <Time>出貨時間</Time>
                        <Price>出貨金額</Price>
                        <Company>物流公司</Company>
                        <Address>出貨地址</Address>
                    </Header>

                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}

                    {/* 明細 */}
                    {!isFetching && orderShipperList.map(row => (
                        <ShipAccordion
                            key={get(row, 'id')}
                            id={get(row, 'id')}
                            shipNumber={get(row, 'id')}
                            shipTime={dayjs(get(row, 'shippedAt')).format('YYYY-MM-DD')}
                            shipAmount={get(row, 'totalAmount')}
                            logisticsCompany={get(row, 'freightCompany')}
                            freightNumber={get(row, 'freightNumber')}
                            address={get(row, 'deliveryAddress')}
                            onClick={() => handleSelectedId(row.id)}
                            onClickPrint={() => setVisibleModal(true)}
                            isSelected={visibleId === row.id}
                            details={orderShipperDetail}
                            isFetching={isFetchingDetail}
                        />
                    ))}
                </ShipContainer>
            </form>


            {/* 分頁 */}
            <CustomPagination
                page={currentPage}
                totalPage={get(orderShipperPagination, 'totalPages')}
                totalItems={get(orderShipperPagination, 'totalItems')}
                onChange={(currentPage: number) => handleChangePage(currentPage)}
            />


            {/* 出貨單光箱 */}
            {isVisibleModal && (
                <PrintModal
                    onClose={() => setVisibleModal(false)}
                    id={visibleId}
                    isFetching={isFetchingDetail}
                />
            )}
        </>
    );
};

export default Ship;

const CustomPagination = styled(Pagination)`
    margin: 25px auto;

    ${media.lg`
        margin: 15px auto 50px auto;
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const Address = styled(HeaderFieldName)`
    min-width: 550px;

    ${media.xxl`
        min-width: 532px;
    `}
`;

const Company = styled(HeaderFieldName)`
    min-width: 150px;

    ${media.xxl`
        min-width: 322px;
    `}
`;

const Price = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 140px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 195px;
    `}
`;

const Time = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 140px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 165px;
    `}
`;

const Model = styled(HeaderFieldName)`
    margin-bottom: 8px;

    ${media.md`
        border-bottom: solid 1px #f2f2f2;
        min-width: 130px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    `}

    ${media.xxl`
        min-width: 165px;
    `}
`;

const NumSection = styled(HeaderFieldName)<any>`
    display: flex;
    justify-content: center;

    ${media.md`
        min-width: 20px;
        position: initial;
        top: auto;
        left: auto;
    `}

    ${media.xxl`
        min-width: 60px;
    `}

    ${props => props.isBlod && css`
        font-weight: bold;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: solid 1px #edeff2;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const ShipContainer = styled(Container)`
    ${media.md`
        overflow-x: auto;
        padding-bottom: 15px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
