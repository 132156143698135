import React from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {Row, Col, media} from 'library/styled-bs-grid';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {get} from 'lodash';
import {formatCurrency} from 'utils/number';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';
import site from 'config/site';
import {useSelector} from 'react-redux';

// Stores
import {Selector as AuthSelector} from 'store/auth';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    isFetching?: boolean;
    id: number;
    oldId: number;
    orderNumber: number;
    orderTime: string;
    payWay: string;
    orderAmount: number|string;
    remark: string;
    isSelected: boolean;
    onClick: Function;
    details: Array<{
        id: number,
        sizeQty: string,
        totalQty: number,
        updateTotalQty: number,
        price: number,
        totalAmount: number,
        productGoods: {
            id: number,
            model: string,
            name: string,
            subName: string,
            color: string,
            brandName: string,
            thumbUrl: string,
            isExtraFare: boolean,
        }
    }>;
}

/**
 * OrderAccordion
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OrderAccordion: React.FC<IProps> = ({
    style,
    className,
    isFetching = false,
    id,
    oldId,
    orderNumber,
    orderTime,
    payWay,
    orderAmount,
    remark,
    isSelected,
    onClick = () => {},
    details,
}) => {
    const {payload} = useSelector(state => ({
        payload: AuthSelector.payload(state),
    }));

    return (
        <>
            <AccordionRoot
                className={className}
                style={style}
                isSelected={isSelected}
                onClick={onClick}
            >
                <Num className="d-none d-md-block"/>
                <Number>
                    <MobileTitle>訂單編號</MobileTitle>
                    <div className="d-flex flex-md-column">
                        {orderNumber}
                        {!isEmpty(oldId) && <OldId>({oldId})</OldId>}
                    </div>
                    <MobilePrice>{`$ ${orderAmount}`}</MobilePrice>
                </Number>
                <DateTime>
                    <MobileTitle>訂購時間</MobileTitle>
                    {orderTime}
                    <ViewDetail className="d-md-none">查看訂單</ViewDetail>
                </DateTime>
                <PayWay>
                    <MobileTitle>付款方式</MobileTitle>
                    {payWay}
                </PayWay>
                <Amount className="d-none d-md-block">{`$ ${orderAmount}`}</Amount>
                <Remark>
                    <MobileTitle>詳情備註</MobileTitle>
                    {remark}
                </Remark>
                <View className="d-none d-md-block">查看訂單</View>

            </AccordionRoot>

            {isSelected && (
                <Detail className="d-none d-md-block">
                    <Header>
                        <OrderNumber/>
                        <Product>商品貨號/品牌</Product>
                        <Category>顏色</Category>
                        <Size>購買SIZE</Size>
                        <Price>單價</Price>
                        <Quantity>數量</Quantity>
                        <Total>總計</Total>
                    </Header>

                    {/* 讀取狀態 */}
                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}

                    {!isFetching && details.map((row, index) => (
                        <Body key={row.id}>
                            <OrderNumber>{index + 1}</OrderNumber>
                            <Product>
                                <ProductImg src={isEmpty(get(row, 'productGoods.thumbUrl', '')) ? asset('/images/goods/default.jpg') : get(row, 'productGoods.thumbUrl')}/>
                                <div className="d-flex flex-column justify-content-center align-items-start">
                                    <ProductNumber onClick={() => window.open(`/product/detail/${payload?.id}/${row.productGoods.id}`)}>{get(row, 'productGoods.model')}</ProductNumber>
                                    <BrandName>{get(row, 'productGoods.brandName')}</BrandName>
                                    <ProductName>{get(row, 'productGoods.name')}</ProductName>
                                    <SubName>{get(row, 'productGoods.subName')}</SubName>
                                </div>
                            </Product>
                            <Category>{get(row, 'productGoods.color')}</Category>
                            <Size>{get(row, 'sizeQty')}</Size>
                            <Price>
                                <FieldValue>{`$ ${formatCurrency(get(row, 'price'))}`}</FieldValue>
                                <FieldValue isExtraFare={row.productGoods.isExtraFare}>{row.productGoods.isExtraFare ? '（含關運）' : '（不含關運）'}</FieldValue>
                            </Price>
                            <Quantity className="d-flex flex-column align-items-center justify-content-center">
                                <Qty isDelete={row.totalQty !== row.updateTotalQty}>{row.totalQty}</Qty>
                                {row.totalQty !== row.updateTotalQty && (<UpdateQty>{row.updateTotalQty}</UpdateQty>)}
                            </Quantity>
                            <Total isBold>{`$ ${formatCurrency(get(row, 'totalAmount'))}`}</Total>
                        </Body>
                    ))}
                </Detail>
            )}
        </>
    );
};

export default OrderAccordion;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isExtraFare && css`
        color: ${site.theme.primaryColor};
    `}
`;

const HeaderTitle = styled.div<any>`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    text-align: left;

    ${props => props.isCenter && css`
        text-align: center;
    `};
`;

const Word = styled.div`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}
`;

const OldId = styled.div`
    font-size: 12px;
    color: #6e6e6e;
    font-weight: 300;
    padding-left: 3px;

    ${media.md`
        font-size: 16px;
        padding-left: 0;
    `}
`;

const Total = styled(HeaderTitle)`
    width: 130px;
    display: flex;
    justify-content: center;

    ${props => props.isBold && css`
        font-weight: bold;
    `}

    ${media.xxl`
        width: 154px;
    `}
`;

const UpdateQty = styled(HeaderTitle)`
    color: #ff7d6a;
`;

const Qty = styled(HeaderTitle)<any>`
    ${props => props.isDelete && css`
        text-decoration: line-through;
    `}
`;

const Quantity = styled(HeaderTitle)`
    min-width: 100px;
    display: flex;
    justify-content: center;

    ${media.xxl`
        width: 111px;
    `}
`;

const Price = styled(HeaderTitle)`
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${media.xxl`
        width: 139px;
    `}
`;

const Size = styled(HeaderTitle)`
    min-width: 270px;
    max-width: 270px;

    ${media.xxl`
        width: 376px;
        max-width: 376px;
    `}
`;

const Category = styled(HeaderTitle)`
    min-width: 100px;

    ${media.xxl`
        width: 172px;
    `}
`;

const SubName = styled(Word)`

`;

const ProductName = styled(Word)`

`;

const BrandName = styled(Word)`

`;

const ProductNumber = styled(Word)`
    font-weight: bold;
    cursor: pointer;
`;

const ProductImg = styled.img`
    width: 70px;
    height: 80px;
    margin-right: 10px;
    background-color: lightgray;
`;

const Product = styled(HeaderTitle)`
    max-width: 360px;
    min-width: 360px;
    display: flex;
    align-items: center;

    ${media.xxl`
        width: 399px;
    `}
`;

const OrderNumber = styled(Word)`
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    font-weight: bold;

    ${media.xxl`
        width: 109px;
    `}
`;

const Header = styled.div`
    width: 100%;
    height: 44px;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    border-bottom: solid 1px #edeff2;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const Body = styled(Header)`
    height: 100px;
    align-items: center;
    margin-top: 0;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const Detail = styled.div<any>`
    width: 100%;
`;

const View = styled(Word)`
    font-weight: 600;
    color: #acacac;
    text-align: center;
    visibility: hidden;
    width: 154px;
    transition: color .2s;

    &:hover{
       color: ${site.theme.primaryColor};
    }
`;

const Remark = styled(Word)`
    padding: 0 10px 4px 10px;

    ${media.md`
        width: 279px;
        padding: 0;
    `}

    ${media.xxl`
        width: 391px;
    `}
`;

const Amount = styled(Word)`
    width: 150px;
    font-weight: bold;

    ${media.xxl`
        min-width: 211px;
    `}
`;

const PayWay = styled(Word)`
    padding: 0 10px 4px 10px;

    ${media.md`
        width: 140px;
        padding: 0;
    `}

    ${media.xxl`
        min-width: 164px;
    `}
`;

const DateTime = styled(Word)`
    padding: 0 10px 4px 10px;
    display: flex;
    align-items: center;

    ${media.md`
        width: 217px;
        padding: 0;
    `}
`;

const MobilePrice = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: #ff7d6a;
    flex: 1;

    ${media.md`
        display: none;
    `}
`;

const ViewDetail = styled.span`
    font-size: 12px;
    color: #d8d8d8;
    text-align: right;
    flex: 1;
`;

const MobileTitle = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    padding-right: 8px;

    ${media.md`
        display: none;
    `}
`;

const Number = styled(Word)`
    width: 100%;
    min-height: 30px;
    border-radius: 2px;
    background-color: #f8f8f8;
    padding: 0 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    ${media.md`
        width: 150px;
        min-height: auto;
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
    `}

    ${media.xxl`
        min-width: 203px;
    `}
`;

const Num = styled(Word)`
    width: 30px;

    ${media.xxl`
        width: 100px;
    `}
`;

const AccordionRoot = styled.div<any>`
    width: 100%;
    min-height: 102px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    cursor: pointer;

    ${media.md`
        width: 1120px;
        height: 56px;
        min-height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: none;
        margin-top: 10px;
        margin-bottom: 0;

        &:hover {
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
            ${View} {
                visibility: visible;
            }
        }

        ${(props: any) => props.isSelected && css`
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

            ${View} {
                visibility: visible;
            }
        `};
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;
