// Libraries & Utils
import Immutable from 'seamless-immutable';
import {TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'shipment';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    isFetchingDetail: false,
    message: '',

    orderShipperList: [],
    orderShipperPagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 0,
        pageLimit: 0,
    },

    orderShipperDetail: [],
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 取得出貨單列表
     */
    fetchOrderShipper: (state) => {
        return state;
    },
    fetchOrderShipperBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取出貨單列表'});
    },
    fetchOrderShipperSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', orderShipperList: payload.orderShipperList, orderShipperPagination: payload.orderShipperPagination});
    },
    fetchOrderShipperFail: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 取得出貨單明細
     */
    fetchOrderShipperDetail: (state) => {
        return state;
    },
    fetchOrderShipperDetailBegin: (state) => {
        return state.merge({isFetchingDetail: true, message: '正在讀取出貨單明細', orderShipperDetail: []});
    },
    fetchOrderShipperDetailSuccess: (state, {payload}) => {
        return state.merge({isFetchingDetail: false, message: '', orderShipperDetail: payload.orderShipperDetail});
    },
    fetchOrderShipperDetailFail: (state, {payload}) => {
        return state.merge({isFetchingDetail: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
