import jwtDecodeLib from "jwt-decode";
import {TPayload} from './types';

/**
 * JWTDecode 與 Decode Fail 預設回傳格式
 * @param token
 */
export function jwtDecode(token: string): TPayload{
    try {
        return jwtDecodeLib(token);
    } catch (e) {
        return undefined;
    }
}
