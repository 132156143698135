// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import trim from 'lodash/trim';
import {replace} from 'connected-react-router';
import {fetchDataDelayMinMs} from 'config/app';
import {isEmpty} from 'utils/equal';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {Actions as ProfileActions} from 'store/profile';
import {Actions as AdActions} from 'store/ad';
import {IActionCreators} from './types';

// Services
import ApiService, {TLoginRes} from 'services/auth';


/**
 * 系統登入
 * @param action
 */
function* login(action: IPickAction<IActionCreators, 'login'>)  {
    yield put(Actions.loginBegin());
    yield put(UiBlockActions.visible({message: '正在進行登入'}));

    try {

        const {account, password} = action.payload;
        const [{body}]: [TLoginRes] = yield all([
            call(ApiService.login, trim(account), trim(password), 'windows', 'chrome'),
            delay(fetchDataDelayMinMs),
        ]);

        const {accessToken, isOrderPreSale, isOrderVipGoods, isOrderNormalGoods, levelCode, levelName, walletAmount, unpaidAmount, realName, email, avatarUrl, messageUnreadCount, trackProductCount, shoppingCartCount, noticeMessage} = body.data;

        // 設定個人資訊
        yield put(ProfileActions.setProfileInfo({isOrderPreSale, isOrderVipGoods, isOrderNormalGoods, levelCode, levelName, email, walletAmount, unpaidAmount, realName, avatarUrl, messageUnreadCount, trackProductCount, shoppingCartCount}));
        // 取得登入後廣告資訊
        yield put(AdActions.fetchSignInNews());
        // 關閉登入光箱
        yield put(Actions.closeLoginModal());

        yield put(Actions.loginSuccess({memberToken: accessToken}));

        // 導到商品列表頁
        yield put(replace('/product'));

        if (!isEmpty(noticeMessage)) {
            yield put(UiDialogActions.openCustom({
                type: 'success',
                title: '提示',
                message: noticeMessage,
                button: [{text: '確定', type: 'default'}],
            }));
        }
    } catch (err) {
        yield put(Actions.loginFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

/**
 * 系統登出
 */
function* logout(action: IPickAction<IActionCreators, 'logout'>) {
    yield put(Actions.logoutBegin());
    yield put(UiBlockActions.visible({message: '正在進行登出'}));

    try {
        yield all([
            call(ApiService.logout),
            delay(fetchDataDelayMinMs),
        ]);

        yield put(Actions.logoutSuccess());

        // 導回首頁
        yield put(replace(''));

        // 清空個人資訊
        yield put(ProfileActions.clearProfileInfo());
    } catch (err) {
        yield put(Actions.logoutFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));

        // 登出錯誤, 強制踢出
        yield put(Actions.kickOut());

        // 導回首頁
        yield put(replace(''));

        // 清空個人資訊
        yield put(ProfileActions.clearProfileInfo());

    } finally {
        yield put(UiBlockActions.hidden());
    }
}



/**
 * 驗證 帳號信箱是否重複
 * @param action
 */
function* submitVerify(action: IPickAction<IActionCreators, 'submitVerify'>)  {
    yield put(Actions.submitVerifyBegin());
    yield put(UiBlockActions.visible({message: '正在進行驗證'}));

    try {
        const {account, email} = action.payload;

        yield all([
            call(ApiService.postVerify, trim(account), email),
            delay(fetchDataDelayMinMs),
        ]);

        // 註冊成功後跳轉回首頁
        yield put(replace('/signUp/2'));

        yield put(Actions.submitVerifySuccess());
    } catch (err) {
        yield put(Actions.submitVerifyFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 註冊 會員
 * @param action
 */
function* signUp(action: IPickAction<IActionCreators, 'signUp'>)  {
    yield put(Actions.signUpBegin());
    yield put(UiBlockActions.visible({message: '正在進行註冊'}));

    try {
        const {
            account,
            password,
            realName,
            email,
            phone,
            lineId,
            deliveryCity,
            deliveryTownship,
            deliveryAddress,
            storeTypeId,
            storeName,
            storeWebsite,
            storeCity,
            storeTownship,
            storeAddress,
            isBuyOtherStoreBefore,
            otherStoreName,
            otherStoreWebsite,
        } = action.payload;

        yield all([
            call(ApiService.signUp,
                trim(account),
                trim(password),
                realName,
                email,
                phone,
                lineId,
                deliveryCity,
                deliveryTownship,
                deliveryAddress,
                storeTypeId,
                storeName,
                storeWebsite,
                storeCity,
                storeTownship,
                storeAddress,
                isBuyOtherStoreBefore,
                otherStoreName,
                otherStoreWebsite,
            ),
            delay(fetchDataDelayMinMs),
        ]);

        // 註冊成功後跳轉回首頁
        yield put(replace(''));

        yield put(UiDialogActions.openSuccess({message: '註冊成功'}));

        yield put(Actions.signUpSuccess());
    } catch (err) {
        yield put(Actions.signUpFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

export default [
    takeLatest(ActionTypes.LOGIN, login),
    takeLatest(ActionTypes.LOGOUT, logout),
    takeLatest(ActionTypes.SUBMIT_VERIFY, submitVerify),
    takeLatest(ActionTypes.SIGN_UP, signUp),
];
