// Libraries & Utils
import Immutable from 'seamless-immutable';
import { TReducers} from "library/redux";
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from "./types";


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'uiBlock';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isVisible: false,
    message: '',
    visibleCount: 0,
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 顯示
     */
    visible: (state, {payload}) => {
        return state.merge({visibleCount: state.visibleCount+1, isVisible: true, message: payload ?.message || 'loading...'});
    },

    /**
     * 關閉
     * @param state
     */
    hidden: (state) => {
        const count = state.visibleCount-1;
        return state.merge({visibleCount: count, isVisible: count > 0})
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
