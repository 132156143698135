import React, {useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import * as CSS from 'csstype';
import get from 'lodash/get';
import cx from 'classnames';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';
import Checkbox from 'components/atoms/Checkbox';
import Button from 'components/atoms/Button';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    useFormParams?: any;
    brandList: Array<{
        firstLetter: string;
        detail: Array<{
            id: number;
            name: string;
            totalProductGoods: number,
        }>;
    }>;
    name: string;
}

/**
 * MultipleSelect
 * form type in text...
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const MultipleSelect: React.FC<IProps> = ({
    className,
    style,
    useFormParams,
    brandList = [],
    name,
}) => {
    const {watch, control, getValues, setValue}: any = useFormParams;
    const checkedArray = watch([name])[name];
    const checkedIds: Array<number> = [];
    for(const id in checkedArray){
        // 過濾掉不等於 true 的 Key
        if(checkedArray[id]){
            checkedIds.push(Number(id));
        }
    }

    const searchRef: any = useRef();
    const [isVisible, setVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    /**
     * 搜尋品牌名稱
     */
    const handleSearchBrand = () => {
        setSearchText(searchRef.current.value);
    };

    /**
     * 過濾搜尋條件
     * @param brandName
     */
    const checkIsVisibleCheckbox = (brandName: string) => {
        const lowerSearchWord = searchText.toLowerCase();
        const formatBrandName = brandName.toLowerCase();
        return formatBrandName.includes(lowerSearchWord);
    };

    /**
     * 偵測搜尋input點擊鍵盤enter事件
     */
    const handleInputKeyPress = (ev: any) => {
        if (ev.keyCode === 13) {
            ev.preventDefault();
            handleSearchBrand();
        }
    };

    /**
     * ResetAll
     */
    const handleResetAll = () => {
        const keys = getValues();
        for(const row of Object.keys(keys)){
            if (row.search(name) !== -1 ) {
                setValue(row, false);
            }
        }

        searchRef.current.value = '';
        setSearchText('');
    };

    return (
        <CustomSelectRoot
            className={className}
            style={style}
            isVisible={isVisible}
        >
            <SelectSection onClick={() => setVisible(!isVisible)}>
                <CheckedCount
                    type="text"
                    value={get(checkedIds, 'length', 0) === 0 ? '品牌' : `品牌 (已選 ${checkedIds.length} 個)`}
                    readOnly
                />
                <CustomIcon code="chevron-fillet-down" size={10} color="#c4c4c4" rotate={isVisible ? 180 : 0}/>
            </SelectSection>

            <OptionList>
                <Search>
                    <SearchInput
                        ref={searchRef}
                        placeholder="搜尋品牌名稱..."
                        onKeyDown={(e: any) => handleInputKeyPress(e)}
                    />
                    <SearchButton
                        type="button"
                        onClick={() => handleSearchBrand()}
                    >
                        <Icon code="search" color="#9d9a9a" size={20}/>
                    </SearchButton>
                </Search>

                <CategorySection>
                    {brandList.map(row => {
                        let isShow = false;
                        row.detail.map(o => {
                            if (checkIsVisibleCheckbox(o.name)) {
                                isShow = true;
                            }
                            return true;
                        });

                        return (
                            <Category key={`alphabet_${row.firstLetter}`} className={cx({'d-none': !isShow})}>
                                <BrandCategory>{row.firstLetter}</BrandCategory>

                                {row.detail.map(item => (
                                    <Controller
                                        key={`brand_${get(item, 'id')}`}
                                        control={control}
                                        name={`${name}[${item.id}]`}
                                        valueName="checked"
                                        className={cx({'d-none': !checkIsVisibleCheckbox(get(item, 'name'))})}
                                        as={<BrandCheckbox
                                            value={String(item.id)}
                                            text={`${get(item, 'name')} (${get(item, 'totalProductGoods', 0)})`}
                                        />}
                                    />
                                ))}
                            </Category>
                        )
                    })}
                </CategorySection>

                <Footer>
                    <ClearA type="button" onClick={() => handleResetAll()}>全部清除</ClearA>
                    <CustomButton
                        type="button"
                        disabledShadow
                        onClick={() => setVisible(false)}
                    >
                        OK
                    </CustomButton>
                </Footer>
            </OptionList>

            {isVisible && <CloseArea onClick={() => setVisible(false)}/>}
        </CustomSelectRoot>
    );
};

MultipleSelect.defaultProps = {
};

export default MultipleSelect;

const CheckedCount = styled.input`
    display: inline-flex;
    width: auto;
    border: none;
    background: none;
    pointer-events: none;
    font-size: 14px;
    font-weight: 500;
    color: #6e6e6e;
    padding: 0;

    ${media.lg`
        font-size: 16px;
        font-weight: 600;
    `}
`;

const CloseArea = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
`;

const CustomButton = styled(Button)`
    width: 80px;
    height: 27px;
    min-height: auto;
    border-radius: 2px;
`;

const ClearA = styled.button`
    font-size: 14px;
    color: #9d9a9a;
    padding-left: 3px;
    text-decoration: underline;
    cursor: pointer;
`;

const Footer = styled.div`
    padding: 0 15px 6px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const BrandCheckbox = styled(Checkbox)<any>`
    margin-bottom: 9px;

    label {
        span {
            white-space: normal;
        }
    }

    ${media.lg`
        label {
            span {
                white-space: nowrap
            }
        }
    `}
`;

const BrandCategory = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #9d9a9a;
    display: block;
    padding-bottom: 8px;
`;

const Category = styled.div`
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 10px;

    :nth-last-child(1) {
        margin-bottom: 0;
    }
`;

const CategorySection = styled.div`
    max-height: 120px;
    overflow-y: scroll;
    margin-bottom: 10px;
    padding: 0 15px;

    ${media.md`
        max-height: 160px;
    `}

    ${media.lg`
        max-height: 400px;
    `}
`;

const SearchButton = styled.button`
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    padding: 0;
`;

const SearchInput = styled.input`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #edeff2;
    border: none;
    font-size: 14px;
    color: #9d9a9a;
    padding-left: 18px;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9d9a9a;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9d9a9a;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #9d9a9a;
    }

    ${media.lg`
        font-size: 16px;
    `}
`;

const Search = styled.div`
    position: relative;
    margin: 10px 15px 12px 15px;
`;

const OptionList = styled.div<any>`
    width: 100%;
    min-width: 240px;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    position: absolute;
    z-index: 4;
    top: 100%;
    left: 0;
    margin-top: 10px;
    display: none;
`;

const CustomIcon = styled(Icon)`

`;

const SelectSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px  0 30px;
    cursor: pointer;
`;

const CustomSelectRoot = styled.div<any>`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f2f2f2;
    position: relative;
    -moz-user-select: none;  /* for Firefox */
    -webkit-user-select: none;   /* for Chrome */

    ${media.lg`
        height: 50px;
    `}

    ${props => props.isVisible && css`
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;

        ${OptionList} {
            display: inline-table;
        }
    `}
`;
