import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {media} from 'library/styled-bs-grid';
import {motion, AnimatePresence} from 'framer-motion'
import {checkIsMobile} from 'utils/browser';
import ReactHtmlParser from 'react-html-parser';

// Component
import Carousel from 'components/atoms/Carousel/Carousel';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

interface IProps {
    isVisible: boolean;
    className?: string;
    style?: CSS.Properties;
    data: Array<{
        id: number,
        title: string,
        desc: string,
        imageUrl: string,
        routePath: string,
    }>;
    onClose: Function;
    transitionTiming?: number;
}

/**
 * AdvertModal
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const AdvertModal: React.FC<IProps> = ({
    isVisible,
    className,
    style,
    data = [],
    onClose = () => {},
    transitionTiming= 300,
}) => {
    const history = useHistory();

    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    let containerVariant: any = {
        initial: {transform: 'scale(0)'},
        isOpen: {transform: 'scale(1)'},
        exit: {transform: 'scale(0)'},
    };
    // 電腦版
    if (!checkIsMobile()) {
        containerVariant= {
            initial: {top: '100%', right: '20px'},
            isOpen: {top: 'unset', bottom: '20px'},
            exit: {top: '100%', right: '20px'}
        };
    }

    const param = {
        slidesPerView: 1,
        slidesPerGroup: 1,
        isEnableMouseMove: true,
        isEnablePagination: true,
        paginationColor: 'rgba(0, 0, 0, 0.1)',
        isEnableLoop: true,
        autoPlayTime: 5000,
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <Overlay
                    className={className}
                    style={style}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <AdvertModalRoot
                        variants={containerVariant}
                        transition={{type: "spring", duration: 0.5}}
                    >
                        <CustomCarousel {...param}>
                            {data.map(row => (
                                <CarouselItem key={row.id}>
                                    <AdImage src={row.imageUrl}/>
                                    <Title>{row.title}</Title>
                                    <Desc>{ReactHtmlParser(row.desc.replaceAll("<<", "《").replaceAll(">>", "》").replaceAll("\n", "<br />"))}</Desc>
                                    <DetailButton
                                        type="button"
                                        onClick={() => history.push(row.routePath)}
                                    >
                                        查看商品
                                    </DetailButton>
                                </CarouselItem>
                            ))}
                        </CustomCarousel>

                        <CloseButton type="button" onClick={onClose}>
                            <Icon code="times" size={10} color="#9d9a9a"/>
                        </CloseButton>
                    </AdvertModalRoot>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default AdvertModal;

const CloseButton = styled.button<any>`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;

    ${media.lg`
        width: 30px;
        height: 30px;

        i {
            font-size: 25px;
        }
    `}
`;

const DetailButton = styled(Button)`
    width: 180px;
    height: 40px;
    min-height: auto;
    background-color: #8ec5ff;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;

    ${media.lg`
        width: 200px;
        height: 50px;
        font-size: 16px;
    `}
`;

const Desc = styled.span`
    font-size: 12px;
    color: #9d9a9a;
    padding: 0 15px 20px 15px;

    ${media.lg`
        font-size: 14px;
        padding-bottom: 30px;
    `}
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a48a77;
    padding-bottom: 2px;

    ${media.lg`
        font-size: 24px;
        padding-bottom: 4px;
    `}
`;

const AdImage = styled.img`
    width: 100%;
    height: 200px;
    margin-bottom: 10px;

    ${media.lg`
        height: 260px;
        margin-bottom: 20px;
    `}
`;

const CarouselItem = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
`;

const CustomCarousel = styled(Carousel)`
    height: 100%;
`;

const AdvertModalRoot = styled(motion.div)`
    width: 280px;
    min-height: 350px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;

    ${media.lg`
        width: 400px;
        min-height: 457px;
        position: absolute;
        right: 20px;
        bottom: 20px;
        pointer-events: auto;
    `}
`;

const Overlay = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;

    ${media.lg`
        background-color: transparent;
        pointer-events: none;
    `}
`;
