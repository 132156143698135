// Libraries
import api from 'library/apisauce';

import {TGetSignInNews, TGetHomeCarousel, TGetPlacementAd} from './types';

export default {
    /**
     * 查詢 登入後廣告
     */
    getSignInNews: (): Promise<TGetSignInNews> => {
        return api.get('/ad/signInNews', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 首頁輪播橫幅
     */
    getHomeCarousel: (): Promise<TGetHomeCarousel> => {
        return api.get('/ad/homeCarousel', {});
    },
    /**
     * 查詢 各區塊置入廣告
     */
    getPlacementAd: (): Promise<TGetPlacementAd> => {
        return api.get('/ad/placement', {});
    },
};
