import React from 'react';
import styled, {css} from 'styled-components';
import {Controller} from 'react-hook-form';
import * as CSS from 'csstype';
import {
    Container, Row, Col, media,
} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';
import DateTimeInput from 'components/atoms/DateTimeInput';
import Button from 'components/atoms/Button/Button';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    isVisible: boolean;
    onClose: Function;
    placeholder?: string;
    startLabel?: string;
    endLabel?: string;
    useFormParams?: any;
}

/**
 * 搜尋區塊
 * @param isVisible
 * @param onClose
 * @param useFormParams
 * @param placeholder
 * @constructor
 */
const Search: React.FC<IProps> = ({
    isVisible,
    onClose = () => {},
    useFormParams,
    placeholder= '透過商品貨號搜尋',
    startLabel = '開始日期',
    endLabel = '結束日期',
}) => {
    const {control}: any = useFormParams;

    return (
        <SearchContainer isVisible={isVisible}>
            <SearchTitle>尋找您的商品</SearchTitle>

            <CloseButton
                type="button"
                className="d-md-none"
                onClick={onClose}
            >
                <Icon code="times" size={15} color="#ebebeb"/>
            </CloseButton>

            <SearchRow>
                <SearchCol col={12} md xl="auto">
                    <Controller
                        control={control}
                        name="startDate"
                        as={<CustomDateTimeInput
                            label={startLabel}
                            isSetTodayVisible
                        />}
                    />
                </SearchCol>
                <SearchCol col={12} md xl="auto">
                    <Controller
                        control={control}
                        name="endDate"
                        as={<CustomDateTimeInput
                            label={endLabel}
                            isSetTodayVisible
                        />}
                    />
                </SearchCol>
                <SearchInputCol col={24} md="auto">
                    <Controller
                        control={control}
                        name="search"
                        as={<SearchInput
                            placeholder={placeholder}
                        />}
                    />

                    <SearchIcon className="d-md-none" code="search" size={15} color="#9d9a9a"/>
                </SearchInputCol>
                <SearchCol col={24} md="auto" className="d-flex justify-content-end">
                    <SubmitButton type="submit">
                        <SearchIcon className="d-none d-md-block" code="search" size={25} color="#ffffff"/>
                        搜尋
                    </SubmitButton>
                </SearchCol>
            </SearchRow>
        </SearchContainer>
    );
};

export default Search;

const SubmitButton = styled(Button)`
    width: 90px;
    height: 40px;
    min-height: auto;
    padding: 0;
    font-size: 12px;
    font-weight: 500;

    ${media.md`
        width: 100px;
        height: 44px;
        font-size: 16px;
        font-weight: 600;
    `}

    ${media.lg`
        height: 56px;
    `}

    ${media.xxl`
        width: 118px;
    `}
`;

const SearchIcon = styled(Icon)`
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    margin: auto;

    ${media.md`
        position: relative;
        left: 0;
        margin-right: 5px;
        margin-left: 0;
    `}

    ${media.xxl`
        margin-right: 10px;
    `}
`;

const SearchInput = styled.input`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f2f4f9;
    border: none;
    padding-left: 34px;
    font-size: 12px;
    color: #6e6e6e;

    &::placeholder {
        color: #9d9a9a;
    }

    ${media.md`
        font-size: 16px;
        width: 200px;
        height: 44px;
        padding: 0 10px;
    `}

    ${media.lg`
        width: 280px;
        height: 56px;
        padding: 0 12px;
    `}

    ${media.xl`
        width: 300px;
        padding: 0 20px;
    `}

    ${media.xxl`
        width: 330px;
        padding: 0 30px;
    `}
`;

const CustomDateTimeInput = styled(DateTimeInput)`
    width: 100%;
    margin: 0 0 10px 0;

    ${media.md`
        margin: 0;
    `}

    ${media.xl`
        width: 180px;
    `}
`;

const SearchCol = styled(Col)`
    padding-right: 5px;
    padding-left: 5px;
    position: relative;
`;

const SearchInputCol = styled(SearchCol)`
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 0;
    `}
`;

const SearchRow = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: -5px;
    margin-right: -5px;

    ${media.md`
        margin-bottom: 50px;
        justify-content: center;
    `}
`;

const CloseButton = styled.button<any>`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 15px;
    padding: 0;
`;

const SearchTitle = styled.span`
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #6e6e6e;
    padding-top: 12px;
    padding-bottom: 10px;

    ${media.md`
        font-size: 20px;
        font-weight: 600;
        padding-top: 20px;
    `}
`;

const SearchContainer = styled(Container)<any>`
    position: fixed;
    top: 0;
    z-index: 99;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
    max-width: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 0;
    max-height: 0;
    transition: min-height .2s;

    ${props => props.isVisible && css`
        min-height: 206px;
    `}

    ${media.md`
        display: flex;
        position: initial;
        top: auto;
        min-height: auto;
        max-height: initial;
        box-shadow: none;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 726px;
        overflow: initial;
    `}

    ${media.xl`
        max-width: 1140px;
    `}
`;
