import React, {useCallback, useState} from "react";


interface IState {
    cartData: {
        cartId: Array<number>,
        totalItems: number,
        totalAmount: number,
    };
}
interface IContext {formTempState: IState, dispatchFormData: (payload: IState) => void}

const defaultState = {
    cartData: {
        cartId: [],
        totalItems: 0,
        totalAmount: 0,
    },
};

export const StoreContext = React.createContext<IContext>({
    formTempState: defaultState,
    dispatchFormData: payload => payload
});


const FormContext: React.FC = ({
    children
}) => {
    const [formState, setFormData] = useState<IState>(defaultState);

    const dispatchFormData = useCallback((payload: IState) => {
        setFormData(state => {
            return {
                ...state, ...payload
            }
        })
    }, []);

    return (
        <StoreContext.Provider value={{formTempState: formState, dispatchFormData}}>
            {children}
        </StoreContext.Provider>
    )
};

export default FormContext;

