// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {IActionCreators} from './types';

// Services
import ApiService, {TGetInfoRes, TPostAvatarRes, TGetWalletAmount} from 'services/profile';



/**
 * 取個人資料
 */
function* fetchInfo(action: IPickAction<IActionCreators, 'fetchInfo'>) {
    yield put(Actions.fetchInfoBegin());

    try {
        const [{body}]: [TGetInfoRes] = yield all([
            call(ApiService.getInfo),
            delay(fetchDataDelayMinMs),
        ]);

        const {data} = body;
        const responseData = {
            email: data.email,
            avatarUrl: data.avatarUrl,
            realName: data.realName,
            walletAmount: data.walletAmount,
            unpaidAmount: data.unpaidAmount,
            promotionCode: data.promotionCode,
            isOrderNormalGoods: data.level.isOrderNormalGoods,
            isOrderVipGoods: data.level.isOrderVipGoods,
            level: data.level,
            lineId: data.lineId,
            phone: data.phone,
            deliveryAddress: data.deliveryAddress,
        };

        yield put(Actions.fetchInfoSuccess(responseData));
    } catch (err) {
        yield put(Actions.fetchInfoFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}


/**
 * 上傳大頭貼
 */
function* uploadAvatar(action: IPickAction<IActionCreators, 'uploadAvatar'>) {
    yield put(Actions.uploadAvatarBegin());
    yield put(UiBlockActions.visible({message: '正在上傳大頭貼'}));

    const {avatar} = action.payload;

    try {
        const [{body}]: [TPostAvatarRes] = yield all([
            call(ApiService.uploadIcCard, avatar),
            delay(fetchDataDelayMinMs),
        ]);
        const {avatarUrl} = body.data;

        yield put(Actions.uploadAvatarSuccess({avatarUrl}));
    } catch (err) {
        yield put(Actions.uploadAvatarFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}


/**
 * 取得會員餘額
 */
function* fetchWalletAmount(action: IPickAction<IActionCreators, 'fetchWalletAmount'>) {
    yield put(Actions.fetchWalletAmountBegin());
    yield put(UiBlockActions.visible({message: '正在讀取會員餘額'}));

    try {
        const [{body}]: [TGetWalletAmount] = yield all([
            call(ApiService.getWalletAmount),
            delay(fetchDataDelayMinMs),
        ]);
        const {walletAmount, unpaidAmount} = body.data;

        yield put(Actions.fetchWalletAmountSuccess({walletAmount, unpaidAmount}));
    } catch (err) {
        yield put(Actions.fetchWalletAmountFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

export default [
    takeLatest(ActionTypes.FETCH_INFO, fetchInfo),
    takeLatest(ActionTypes.UPLOAD_AVATAR, uploadAvatar),
    takeLatest(ActionTypes.FETCH_WALLET_AMOUNT, fetchWalletAmount),
];
