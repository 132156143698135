// Libraries
import api from 'library/apisauce';
import {isEmpty} from 'utils/equal';

// Types
import {
    TGetProductMall,
    TGetBrandGroup,
    TGetHotBrand,
    TGetCategoryGroup,
    TGetProductSeason,
    TGetProductGoods,
    TGetProductGoodsDetail,
    TGetRelatedProducts,
    TGetProductDesc,
    TGetOtherRelated, TDownloadImagesRes,
} from './types';
import {sec2ms} from "../../utils/number";


export default {
    /**
     * 查詢 專屬產品列表
     */
    getProductMall: (): Promise<TGetProductMall> => {
        return api.get('/product/mall', {});
    },
    /**
     * 查詢 品牌列表(群組化)
     */
    getBrandGroup: (): Promise<TGetBrandGroup> => {
        return api.get('/product/brand/group', {});
    },
    /**
     * 查詢 熱門品牌
     */
    getHotBrand: (): Promise<TGetHotBrand> => {
        return api.get('/product/brand/hot', {});
    },
    /**
     * 查詢 商品類別(含子類)
     */
    getCategoryGroup: (): Promise<TGetCategoryGroup> => {
        return api.get('/product/category/group', {});
    },
    /**
     * 查詢 商品季節列表
     */
    getProductSeason: (): Promise<TGetProductSeason> => {
        return api.get('/product/season', {});
    },
    /**
     * 查詢 產品列表 (Guest)
     */
    getProductGoodsGuest: (currentPage: number = 1, pageLimit: number = 20, brands?: Array<number>): Promise<TGetProductGoods> => {
        return api.get('/product/goods/guest', {currentPage, pageLimit, brands});
    },
    /**
     * 查詢 產品列表
     */
    getProductGoods: (
        currentPage: number = 1,
        pageLimit: number = 20,
        categoryGroupId?: number,
        categoryMainId?: number,
        categorySubId?: number,
        name?: string,
        model?: string,
        brandName?: string,
        brands?: Array<number>,
        season?: number,
        isHot?: boolean,
        isInStock?: boolean,
        isNew?: boolean,
        isSale?: boolean,
        isVip?: boolean,
        isOrderPreSale?: boolean,
        isTodaySale?: boolean,
        isTodayNew?: boolean,
        sort?: string,
    ): Promise<TGetProductGoods> => {
        let search: any = {};

        search.currentPage = currentPage;
        search.pageLimit = pageLimit;

        if (!isEmpty(categoryGroupId)) {
            search.categoryGroupId = categoryGroupId;
        }
        if (!isEmpty(categoryMainId)) {
            search.categoryMainId = categoryMainId;
        }
        if (!isEmpty(categorySubId)) {
            search.categorySubId = categorySubId;
        }
        if (!isEmpty(name)) {
            search.name = name;
        }
        if (!isEmpty(model)) {
            search.model = model;
        }
        if (!isEmpty(brandName)) {
            search.brandName = brandName;
        }
        if (!isEmpty(brands)) {
            search.brands = brands;
        }
        if (!isEmpty(season)) {
            search.season = season;
        }
        if (isHot) {
            search.isHot = true;
        }
        if (isInStock) {
            search.isInStock = true;
        }
        if (isNew) {
            search.isNew = true;
        }
        if (isSale) {
            search.isSale = true;
        }
        if (isVip) {
            search.isVip = true;
        }
        if (isOrderPreSale) {
            search.isOrderPreSale = true;
        }
        if (isTodaySale) {
            search.isTodaySale = true;
        }
        if (isTodayNew) {
            search.isTodayNew = true;
        }
        if (!isEmpty(sort)) {
            search.sort = sort;
        }

        return api.get('/product/goods', search, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 產品明細
     */
    getProductGoodsDetail: (id: number): Promise<TGetProductGoodsDetail> => {
        return api.get(`/product/goods/detail/${id}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 關聯產品
     */
    getRelatedProducts: (id: number): Promise<TGetRelatedProducts> => {
        return api.get(`/product/goods/detail/${id}/relatedProducts`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 商品說明
     */
    getProductDesc: (productGoodsId: number): Promise<TGetProductDesc> => {
        return api.get(`/product/desc/${productGoodsId}`, {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 其他綜合相關商品
     */
    getOtherRelated: (): Promise<TGetOtherRelated> => {
        return api.get('/product/goods/otherRelated', {});
    },
    /**
     * 下載圖片
     */
    downloadImages: (id: number): Promise<TDownloadImagesRes> => {
        return api.post('/product/goodsDownloadImages', {id}, {
            headers: {isAuth: true},
            responseType: 'blob',
            timeout: sec2ms(300),
        });
    },
};



