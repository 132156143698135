// Libraries & Utils
import Immutable from 'seamless-immutable';
import dayjs from "dayjs";
import get from "lodash/get";
import { TReducers} from "library/redux";
import createReduxSauce from 'library/redux/createReduxSauce';
import { isEmpty } from 'utils/equal';
import {jwtDecode} from './utils';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from "./types";


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'auth';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isSubmitting: false,
    message: '',
    memberToken: '',

    isVisibleLoginModal: false,
    isVisibleSignUpModal: false,
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {
    memberToken: (state) => state[storeKey].memberToken,
    payload: (state) => jwtDecode(state[storeKey].memberToken),
    isAuth: (state) => {
        const payload = jwtDecode(state[storeKey].memberToken);
        if(!isEmpty(payload)){
            const expiredTime = get(payload, 'exp', 0);

            // 比對現在時間是否超出過期時間
            return dayjs().isBefore(dayjs.unix(expiredTime));
        }
        return false;
    },
};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 登入系統
     */
    login: (state) => {
        return state;
    },
    loginBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在進行登入'})
    },
    loginSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: '', memberToken: payload.memberToken})
    },
    loginFail: (state) => {
        return state.merge({isSubmitting: false, message: ''})
    },
    /**
     * 登出系統
     */
    logout: (state) => {
        return state;
    },
    logoutBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在進行登出'})
    },
    logoutSuccess: (state, action) => {
        return state.merge({isSubmitting: false, memberToken: ''})
    },
    logoutFail: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''})
    },
    /**
     * 踢出系統
     */
    kickOut: (state) => {
        return state.merge({memberToken: ''});
    },
    /**
     * 開啟登入光箱
     */
    openLoginModal: (state) => {
        return state.merge({isVisibleLoginModal: true})
    },
    /**
     * 關閉登入光箱
     */
    closeLoginModal: (state) => {
        return state.merge({isVisibleLoginModal: false})
    },
    /**
     * 開啟登入光箱
     */
    openSignUpModal: (state) => {
        return state.merge({isVisibleSignUpModal: true})
    },
    /**
     * 關閉登入光箱
     */
    closeSignUpModal: (state) => {
        return state.merge({isVisibleSignUpModal: false})
    },
    /**
     * 驗證帳號及信箱
     */
    submitVerify: (state) => {
        return state;
    },
    submitVerifyBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在進行驗證'})
    },
    submitVerifySuccess: (state, action) => {
        return state.merge({isSubmitting: false, memberToken: ''})
    },
    submitVerifyFail: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''})
    },
    /**
     * 註冊 會員
     */
    signUp: (state) => {
        return state;
    },
    signUpBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在進行註冊'})
    },
    signUpSuccess: (state, action) => {
        return state.merge({isSubmitting: false, memberToken: ''})
    },
    signUpFail: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''})
    },
    /**
     * 設定Token
     */
    setToken: (state, {payload}) => {
        return state.merge({memberToken: payload.token})
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
