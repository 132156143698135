import * as React from 'react';
import * as CSS from 'csstype';
import {
    media,
} from 'library/styled-bs-grid';
import styled, {css} from 'styled-components';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    isInStock?: boolean;
    text: string;
}

/**
 * Size
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Size: React.FC<IProps> = ({
    style,
    className,
    isInStock = false,
    text,
}) => (
    <SizeRoot style={style} className={className} isInStock={isInStock}>
        {text}

        <ToolTips>
            現貨
            <TooltipsArrow/>
        </ToolTips>
    </SizeRoot>
);

export default Size;

const ToolTips = styled.div`
    width: 42px;
    height: 18px;
    position: absolute;
    bottom: 100%;
    transform: translate(0, -5px);
    background-color: ${props => props.theme.thirdColor};
    font-size: 10px;
    font-weight: bold;
    color: #ffffff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity .3s;
    pointer-events: none;

    ${media.lg`
        height: 20px;
        font-size: 12px
    `}
`;

const SizeRoot = styled.div<any>`
    border-radius: 2px;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    color: #9d9a9a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    margin-right: 10px;
    position: relative;
    transition: background-color .3s;

    ${props => props.isInStock && css`
        color: ${props.theme.thirdColor};

        &:hover {
            background-color: #f2f4f9;

            ${ToolTips} {
                opacity: 1;
            }
        }
    `};

    ${media.lg`
        padding: 5px 10px;
        margin-right: 17px;
    `};
`;

const TooltipsArrow = styled.div`
    position: absolute;
    top: 100%;
    transform: translate(0, -1px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0;
    border-color: ${props => props.theme.thirdColor} transparent transparent;
`;
