import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
import get from 'lodash/get';
import site from 'config/site';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {formatCurrency} from 'utils/number';
import {HEXToRGB} from 'utils/format';
import {HalfCircleSpinner} from 'react-epic-spinners';
import ReactHtmlParser from 'react-html-parser';

// Component
import Icon from 'components/atoms/Icon';
import StatusTag from 'components/atoms/Tag/StatusTag';
import Pagination from 'components/atoms/Pagination';

// Reducer
import {Actions as TradeAction} from 'store/trade';


// 一頁顯示筆數
const pageLimit = 20;

/**
 *  交易紀錄
 * @constructor
 */
const Trade: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState<number>(1);

    const {isFetching, tradeHistory, tradeHistoryPagination} = useSelector(state => ({
        isFetching: state.trade.isFetching,
        tradeHistory: state.trade.tradeHistory,
        tradeHistoryPagination: state.trade.tradeHistoryPagination,
    }));

    useEffect(() => {
        dispatch(TradeAction.fetchTradeHistory({currentPage, pageLimit}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    return (
        <>
            {/* Mobile Header */}
            <Container className="d-md-none">
                <MobileHeader>
                    <IconButton onClick={() => history.push('/profile/account')}>
                        <Icon code="long-arrow-left" size={20} color="#282731"/>
                    </IconButton>

                    <MobileTitle>餘額紀錄</MobileTitle>

                    <IconButton/>
                </MobileHeader>
            </Container>

            <TradeContainer>
                <TradeForm>
                    {/* 標題 */}
                    <Header className="d-none d-md-flex">
                        <Num>序號</Num>
                        <Time>交易時間</Time>
                        <Model>訂單編號</Model>
                        <Status>狀態</Status>
                        <TradeAmount>匯款/退款</TradeAmount>
                        <DeductAmount>扣款</DeductAmount>
                        <AccountBalance>帳戶餘額</AccountBalance>
                        <Remark>備註</Remark>
                    </Header>


                    {isFetching && (
                        <Row>
                            <Col col>
                                <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                            </Col>
                        </Row>
                    )}


                    {!isFetching && tradeHistory.map(row => (
                        <Content key={row.id}>
                            <Num>
                                <FieldTitle>序號</FieldTitle>
                                <FieldValue>{get(row, 'id')}</FieldValue>
                            </Num>
                            <Time>
                                <FieldTitle>交易時間</FieldTitle>
                                <FieldValue>{dayjs(get(row, 'createdAt')).format('YYYY-MM-DD HH:mm:ss')}</FieldValue>
                            </Time>
                            <Model>
                                <FieldTitle>訂單編號</FieldTitle>
                                <FieldValue>{get(row, 'orderId')}</FieldValue>
                            </Model>
                            <Status>
                                <CustomStatusTag
                                    text={get(row, 'category.name')}
                                    color={get(row, 'category.themeColor')}
                                    background={`rgba(${HEXToRGB(get(row, 'category.themeColor'))[0]}, ${HEXToRGB(get(row, 'category.themeColor'))[1]}, ${HEXToRGB(get(row, 'category.themeColor'))[2]}, 0.1)`}
                                />
                            </Status>
                            <TradeAmount className="d-none d-md-block">
                                <AmountValue>{get(row, 'add', 0) === 0 ? '-' : `+ $${formatCurrency(get(row, 'add'))}`}</AmountValue>
                            </TradeAmount>
                            <DeductAmount className="d-none d-md-block">
                                <AmountValue>{get(row, 'sub', 0) === 0 ? '-' : `- $${formatCurrency(get(row, 'sub'))}`}</AmountValue>
                            </DeductAmount>
                            <AccountBalance className="d-none d-md-block">
                                <AmountValue>$ {formatCurrency(get(row, 'afterBalance'))}</AmountValue>
                            </AccountBalance>
                            <Remark className="d-flex align-items-start align-items-md-center">
                                <FieldTitle>備註詳情</FieldTitle>
                                <RemarkValue>{isEmpty(get(row, 'remark')) ? '-' : ReactHtmlParser(row.remark.replaceAll("\n", "<br/>"))}</RemarkValue>
                            </Remark>

                            <MobileAmountSection>
                                <AmountCol col={8}>
                                    <AmountTitle>匯款/退款</AmountTitle>
                                    <Amount>{get(row, 'add', 0) === 0 ? '-' : `+ $${formatCurrency(get(row, 'add'))}`}</Amount>
                                </AmountCol>
                                <AmountCol col={8} hasBorder>
                                    <AmountTitle>扣款</AmountTitle>
                                    <Amount>{get(row, 'sub', 0) === 0 ? '-' : `- $${formatCurrency(get(row, 'sub'))}`}</Amount>
                                </AmountCol>
                                <AmountCol col={8}>
                                    <AmountTitle>帳戶餘額</AmountTitle>
                                    <Amount isPurple>$ {formatCurrency(get(row, 'afterBalance'))}</Amount>
                                </AmountCol>
                            </MobileAmountSection>
                        </Content>
                    ))}
                </TradeForm>
            </TradeContainer>

            {/* 分頁 */}
            <CustomPagination
                page={currentPage}
                totalPage={get(tradeHistoryPagination, 'totalPages')}
                totalItems={get(tradeHistoryPagination, 'totalItems')}
                onChange={(currentPage: number) => setCurrentPage(currentPage)}
            />
        </>
    );
};

export default Trade;

const CustomPagination = styled(Pagination)`
    margin: 25px auto;

    ${media.lg`
        margin: 30px auto 50px auto;
    `}
`;

const Amount = styled.span<any>`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #6e6e6e;

    ${props => props.isPurple && css`
        color: ${site.theme.thirdColor};
    `}
`;

const AmountTitle = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    text-align: center;
    padding-bottom: 3px;
`;

const AmountCol = styled(Col)<any>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    position: relative;

    ${props => props.hasBorder && css`
        &:after{
            content: '';
            width: 1px;
            height: 30px;
            background-color: rgba(156, 146, 255, 0.1);
            position: absolute;
            right: 0;
        }
        &:before{
            content: '';
            width: 1px;
            height: 30px;
            background-color: rgba(156, 146, 255, 0.1);
            position: absolute;
            left: 0;
        }
    `}
`;

const MobileAmountSection = styled(Row)`
    width: 100%;
    height: 50px;
    background-color: rgba(156, 146, 255, 0.05);
    display: flex;
    align-self: center;

    ${media.md`
        display: none;
    `}
`;

const CustomStatusTag = styled(StatusTag)<any>`
    width: 64px;
    height: 20px;
    border-radius: 2px;
    position: absolute;
    right: 10px;
    top: 7px;
    color: ${props => props.color};
    border: solid 1px ${props => props.color};
    background-color: ${props => props.background};

    ${media.md`
        width: 90px;
        height: 32px;
        border-radius: 4px;
        position: initial;
    `}
`;

const FieldValue = styled.span<any>`
    font-size: 12px;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isBold && css`
        font-weight: bold;
    `}
`;

const RemarkValue = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    padding-right: 10px;
`;

const AmountValue = styled.span`
    font-size: 22px;
    font-weight: bold;
    color: #6e6e6e;
`;

const FieldTitle = styled.span`
    min-width: 48px;
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 8px;

    ${media.md`
        display: none;
    `}
`;

const Content = styled.div`
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-top: 7px;
    position: relative;

    ${media.md`
        min-height: 56px;
        flex-direction: row;
        align-items: center;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
        padding-top: 0;

        &:hover{
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
            background-color: #ffffff;
        }
    `}
`;

const HeaderFieldName = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #6e6e6e;
    display: flex;
    align-items: center;
`;

const Remark = styled(HeaderFieldName)`
    padding-bottom: 8px;

    ${media.md`
        min-width: 260px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 425px;
    `}
`;

const AccountBalance = styled(HeaderFieldName)`
    ${media.md`
        min-width: 130px;
    `}

    ${media.xxl`
        min-width: 174px;
    `}
`;

const DeductAmount = styled(HeaderFieldName)`
    ${media.md`
        min-width: 130px;
    `}

    ${media.xxl`
        min-width: 145px;
    `}
`;

const TradeAmount = styled(HeaderFieldName)`
    ${media.md`
        min-width: 130px;
    `}

    ${media.xxl`
        min-width: 162px;
    `}
`;

const Status = styled(HeaderFieldName)`
     ${media.md`
        min-width: 110px;
    `}

     ${media.xxl`
        min-width: 150px;
    `}
`;

const Model = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 130px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        min-width: 133px;
    `}
`;

const Time = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 130px;
        word-break: break-word;
        padding-bottom: 0;
    `}
`;

const Num = styled(HeaderFieldName)`
    padding-bottom: 2px;

    ${media.md`
        min-width: 100px;
        padding-bottom: 0;
        padding-left: 21px;
    `}

    ${media.xxl`
        min-width: 121px;
    `}
`;

const Header = styled.div`
    display: flex;
    padding-bottom: 22px;
    margin-bottom: 20px;
    border-bottom: solid 1px #edeff2;

    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const TradeForm = styled.form`
    ${media.md`
        width: 1120px;
    `}

    ${media.xxl`
        width: 1440px;
    `}
`;

const TradeContainer = styled(Container)`
    display: flex;
    flex-direction: column;

    ${media.md`
        overflow-x: auto;
        padding-top: 10px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
