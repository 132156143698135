import React, {useState, useContext, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Container, media, Row, Col} from 'library/styled-bs-grid';
import site from 'config/site';
import get from 'lodash/get';
import {isEmpty} from 'utils/equal';
import {formatCurrency} from 'utils/number';
import ReactHtmlParser from 'react-html-parser';

// Component
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';

// Stores
import {StoreContext} from '../FormContext';
import {Actions as ProfileAction} from 'store/profile';
import {Actions as CartAction} from 'store/cart';
import {Actions as UiDialogActions} from 'store/uiDialog';



// 訂單金額
const shippingWayDirections = '請自行查看備貨狀態『已入庫清單』若要出貨請至訊息選擇《出貨通知》分類留言';
const shippingFeeDirections = '請於15:00前通知，VIP會員滿2萬免運直接出貨。\n若未達2萬，台灣本島地區每次出貨會收取新台幣70元的國內運費 \n訂購商品若已全數入庫，台灣小幫會直接出貨，不另行通知。\n註：外島(金門、澎湖、馬祖)不適用免運費';

/**
 * 結帳
 * @constructor
 */
const Checkout: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [payWayId, setPayWayId] = useState<number>(0);
    const {handleSubmit, control}: any = useForm();


    const {walletAmount, unpaidAmount, deliveryAddress, realName, phone, email} = useSelector(state => ({
        walletAmount: state.profile.walletAmount,
        unpaidAmount: state.profile.unpaidAmount,
        deliveryAddress: state.profile.deliveryAddress,
        realName: state.profile.realName,
        phone: state.profile.phone,
        email: state.profile.email,
    }));

    const {formTempState} = useContext(StoreContext);
    // 訂單數量
    const totalItem = get(formTempState, 'cartData.totalItems', 0);
    // 訂單金額
    const totalAmount = formatCurrency(get(formTempState, 'cartData.totalAmount', 0));


    useEffect(() => {
        // 未選取購物車商品時返回購物車
        if (get(formTempState, 'cartData.cartId.length', 0) === 0) {
            history.push('/shop/bag')
        }


        // 取得個人資料
        if (
            isEmpty(deliveryAddress) ||
            isEmpty(realName) ||
            isEmpty(phone) ||
            isEmpty(email)
        ) {
            dispatch(ProfileAction.fetchInfo());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const payAmount = get(formTempState, 'cartData.totalAmount', 0);

        // 錢包金額大於訂單總金額，預設選取 "餘額抵扣"
        if ((Number(walletAmount) - Number(unpaidAmount)) >= Number(payAmount)) {
            setPayWayId(1);
        } else {
            setPayWayId(2);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAmount, unpaidAmount]);


    /**
     * 送出
     */
    const onSubmit = (formData: any) => {
        dispatch(CartAction.submitCartOrder({
            cartId: get(formTempState, 'cartData.cartId'),
            comment: formData.comment,
            payWayId,
        }));
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Mobile Header */}
                <Container className="d-md-none">
                    <MobileHeader>
                        <IconButton
                            type="button"
                            onClick={() => history.push('/shop/bag')}
                        >
                            <Icon code="long-arrow-left" size={20} color="#282731"/>
                        </IconButton>

                        <MobileTitle>選擇付款方式</MobileTitle>

                        <RightButton/>
                    </MobileHeader>
                </Container>

                {/* PC Header */}
                <HeaderBg className="d-none d-md-flex">
                    <BagContainer>
                        <Total className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <CheckoutText>結帳</CheckoutText>
                                <CheckoutNum>{`商品數量 x ${totalItem}`}</CheckoutNum>
                            </div>

                            <div className="d-flex align-items-center">
                                <TotalAmountText>顯示訂單總金額 ： </TotalAmountText>
                                <TotalAmount>{`NT$ ${totalAmount}`}</TotalAmount>
                            </div>
                        </Total>
                    </BagContainer>
                </HeaderBg>

                <BagContainer className="d-md-flex justify-content-md-center">
                    <BagTitleSection className="d-md-none">
                        <BagText>購物袋總金額</BagText>
                        <BagAmount>{`NT$ ${totalAmount}`}</BagAmount>
                    </BagTitleSection>


                    <Content>
                        <Title className="d-none d-md-block">選擇付款方式</Title>

                        <CustomRow>
                            <CustomCol col={12}>
                                {/* 餘額抵扣 */}
                                <FieldTitle>餘額抵扣：</FieldTitle>
                                <Balance
                                    isActive={payWayId === 1}
                                    // onClick={() => setPayWayId(1)}
                                >
                                    <div>
                                        <BalanceTitle>帳戶餘額</BalanceTitle>
                                        <BalanceValue>NT$ {formatCurrency(walletAmount - unpaidAmount)}</BalanceValue>
                                    </div>
                                    <CartAmount>-NT$ {totalAmount}</CartAmount>
                                </Balance>
                                <Desc>帳戶充值存款</Desc>
                            </CustomCol>
                            <CustomCol col={12}>
                                {/* ATM轉帳 */}
                                <FieldTitle>ATM轉帳：</FieldTitle>
                                <Atm
                                    isActive={payWayId === 2}
                                    onClick={() => {
                                        if ((Number(walletAmount) - Number(unpaidAmount)) >= Number(get(formTempState, 'cartData.totalAmount', 0))) {
                                            dispatch(UiDialogActions.openError({message: '親愛的會員您好, 您餘額足夠結帳本次訂單, 請使用餘額扣款'}));
                                        }
                                    }}
                                >
                                    <AtmTitle>ATM轉帳</AtmTitle>
                                </Atm>
                            </CustomCol>
                        </CustomRow>

                        {/* 備註客人訊息 */}
                        <FieldTitle>備註客人訊息：</FieldTitle>
                        <Controller
                            control={control}
                            name="comment"
                            as={<RemarkInput
                                placeholder="備註訊息"
                            />}
                        />
                        <Desc>您可備註下單用戶名稱方便您追蹤進度。 ex: Apple1220</Desc>


                        {/* 配送地址 */}
                        <FieldTitle>配送地址：</FieldTitle>
                        <AddressInput
                            placeholder="地址"
                            value={deliveryAddress}
                            readonly
                        />

                        {/* 你的聯絡資訊 */}
                        <FieldTitle>你的聯絡資訊：</FieldTitle>
                        <CustomInput
                            placeholder="姓名"
                            value={realName}
                            readonly
                        />
                        <CustomInput
                            placeholder="電話/手機"
                            value={phone}
                            readonly
                        />
                        <EmailInput
                            placeholder="Email"
                            value={email}
                            readonly
                        />

                        <FieldTitle>出貨方式：</FieldTitle>
                        <DescContent>{shippingWayDirections}</DescContent>

                        <FieldTitle>運費說明：</FieldTitle>
                        <DescContent>{ReactHtmlParser(shippingFeeDirections.replaceAll('\n', '<br/>'))}</DescContent>

                        <div className="d-flex justify-content-center">
                            <CheckoutButton
                                type="submit"
                                disabled={isEmpty(payWayId)}
                            >
                                確認付款
                            </CheckoutButton>
                        </div>
                    </Content>

                </BagContainer>
            </form>
        </>
    );
};

export default Checkout;

const CheckoutButton = styled(Button)`
    width: 240px;
    height: 44px;
    min-height: auto;
    margin: 10px auto 30px auto;
    display: initial;

    ${media.md`
        margin: 15px auto 50px auto;
    `}

    ${media.lg`
        width: 300px;
        height: 56px;
        margin: 30px auto 80px auto;
    `}

    ${media.xxl`
        margin: 30px auto 100px auto;
    `}
`;

const DescContent = styled.div`
    width: 100%;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    font-size: 12px;
    color: #9d9a9a;
    padding: 10px;
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 15px;
        font-size: 14px;
    `}

    ${media.lg`
        margin-bottom: 20px;
        font-size: 16px;
        padding: 10px 20px;
    `}

    ${media.xxl`
        font-size: 17px;
    `}
`;

const EmailInput = styled(Input)`
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 15px;
    `}

    ${media.lg`
        margin-bottom: 20px;
    `}
`;

const CustomInput = styled(Input)`
    margin-bottom: 10px;
`;

const RemarkInput = styled(Input)`
    margin-bottom: 4px;

    ${media.lg`
        margin-bottom: 6px;
    `}
`;

const AddressInput = styled(Input)`
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 15px;
    `}
    ${media.lg`
        margin-bottom: 20px;
    `}
`;

const AtmTitle = styled.div`
    font-size: 12px;
    color: #6e6e6e;
    transition: color .3s;

    ${media.md`
        font-size: 14px;
    `}

    ${media.lg`
        font-size: 16px;
    `}

    ${media.xxl`
        font-size: 17px;
    `}
`;

const Desc = styled.div`
    font-size: 10px;
    color: #9d9a9a;
    margin-bottom: 10px;

    ${media.md`
        font-size: 12px;
        margin-bottom: 15px;
    `}

    ${media.lg`
        font-size: 14px;
        margin-bottom: 20px;
    `}
`;

const CartAmount = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: #ff7d6a;

    ${media.md`
        font-size: 14px;
    `}

    ${media.md`
        font-size: 16px;
    `}
`;

const BalanceValue = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;

    ${media.md`
        font-size: 14px;
    `}

    ${media.lg`
        font-weight: normal;
    `}
`;

const BalanceTitle = styled.div`
    font-size: 10px;
    color: #6e6e6e;
    margin-bottom: 2px;
    transition: color .3s;

    ${media.md`
        font-size: 12px;
        margin-bottom: 0;
    `}

    ${media.lg`
        font-size: 14px;
    `}

    ${media.xxl`
        font-size: 17px;
        margin-bottom: 7px;
    `}
`;

const Payment = styled.div`
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: solid 1px #9d9a9a;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: border .3s, box-shadow .3s;

    ${media.lg`
        height: 56px;
        padding: 0 20px;

        // &:hover{
        //     border: solid 1px ${site.theme.thirdColor};
        //     box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        //
        //     ${AtmTitle} {
        //         color: ${site.theme.thirdColor};
        //     }
        //     ${BalanceTitle} {
        //         color: ${site.theme.thirdColor};
        //     }
        // }
    `}

    ${media.xxl`
        height: 70px;
    `}
`;

const Atm = styled(Payment)<any>`
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 15px;
    `}

    ${media.lg`
        margin-bottom: 50px;
    `}

    ${props => props.isActive && css`
        border: solid 1px ${site.theme.thirdColor};
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

        ${AtmTitle} {
            color: ${site.theme.thirdColor};
        }
    `}
`;

const Balance = styled(Payment)<any>`
    margin-bottom: 4px;

    ${media.lg`
        margin-bottom: 6px;
    `}

    ${media.xxl`
        margin-bottom: 10px;
    `}

    ${props => props.isActive && css`
        border: solid 1px ${site.theme.thirdColor};
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

        ${BalanceTitle} {
          color: ${site.theme.thirdColor};
        }
    `}
`;

const FieldTitle = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: #6e6e6e;
    margin-bottom: 4px;
    margin-left: 2px;

    ${media.md`
        font-size: 14px;
    `}

    ${media.lg`
        font-size: 16px;
        margin-bottom: 10px;
    `}

    ${media.xxl`
        font-size: 20px;
    `}
`;

const CustomCol = styled(Col)`
    padding-left: 5px;
    padding-right: 5px;

    ${media.xl`
        padding-left: 10px;
        padding-right: 10px;
    `}
`;

const CustomRow = styled(Row)`
    margin-left: -5px;
    margin-right: -5px;

    ${media.xl`
        margin-left: -10px;
        margin-right: -10px;
    `}
`;

const Title = styled.div`
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #282731;
    margin-bottom: 20px;

    ${media.xxl`
        font-size: 40px;
        margin-bottom: 45px;
    `}
`;

const Content = styled.div`
    max-width: 750px;
`;

const BagAmount = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #ff7d6a;
    margin-right: 5px;
`;

const BagText = styled.div`
    font-size: 12px;
    color: #6e6e6e;
    margin-right: 6px;
`;

const BagTitleSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
`;

const TotalAmount = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #6e6e6e;
    margin-right: 20px;
`;

const TotalAmountText = styled.div`
    font-size: 14px;
    color: #6e6e6e;
    margin-right: 16px;
`;

const CheckoutNum = styled.div`
    font-size: 14px;
    color: #6e6e6e;
`;

const CheckoutText = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #6e6e6e;
    margin-right: 30px;
`;

const Total = styled.div`
    height: 55px;
    border-bottom: solid 1px #ebebeb;
`;

const BagContainer = styled(Container)`
    ${media.md`
        padding: 0;
    `}
`;

const HeaderBg = styled.div`
    width: 100%;
    background-color: #fbfbfb;
    margin-bottom: 30px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;

    ${media.lg`
        margin-bottom: 40px;
    `}

    ${media.xxl`
        margin-bottom: 50px;
    `}
`;

const MobileTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
`;

const IconButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5px;
`;

const RightButton = styled(IconButton)`
    left: auto;
    right: 5px;
`;

const MobileHeader = styled.div`
    width: 100%;
    height: 55px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;
