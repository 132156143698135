export default {
    /* 系統錯誤訊息 */
    'errorHttp.401': '請進行登入後再繼續操作',
    'errorHttp.404': '請求找不到路徑/路由',
    'errorHttp.413': '發出的請求/檔案 超過伺服器限制大小',
    'errorHttp.500': '內部服務器錯誤',
    'errorHttp.504': '網路發生異常',
    'errorHttp.511': '地區無法提供服務',
    'errorHttp.CLIENT_ERROR': '任何非特定的400系列錯誤',
    'errorHttp.SERVER_ERROR': '任何500系列錯誤',
    'errorHttp.TIMEOUT_ERROR': '伺服器逾時超出{sec}秒無回應，請確認您的網路連線狀態或與客服聯繫',
    'errorHttp.CONNECTION_ERROR': '服務器無法使用，DNS錯誤',
    'errorHttp.NETWORK_ERROR': '您的行動網路連線不穩定，請檢查您的網路連線狀態後再嘗試一次',
    'errorHttp.CANCEL_ERROR': '請求已被取消。僅在配置中提供`cancelToken`時才可能，請參見axios`Cancellation`',
};
