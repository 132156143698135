import React, {useEffect, useState} from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {media} from 'library/styled-bs-grid';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import {asset} from 'config/app';
import ScrollView from 'components/atoms/ScrollView';
import {motion, AnimatePresence} from 'framer-motion'
import {useSelector} from "react-redux";
import {getOs} from 'utils/browser';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onClose?: Function;
}

/**
 * SignUpModal
 * @param props
 * @returns {*}
 * @constructor
 */
const SignUpModal: React.FC<IProps> = ({
    className,
    style,
    onClose = () => {},
}) => {
    const history = useHistory();
    const [second, setSecond] = useState<number>(10);
    const {isVisible} = useSelector(state => ({
        isVisible: state.auth.isVisibleSignUpModal,
    }));

    useEffect(() => {
        if (isVisible && second > 0) {
            const interval = setInterval(() => {
                if (second === 0) {
                    clearInterval(interval);
                } else {
                    setSecond(sec => sec - 1);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isVisible, second]);

    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    return (
        <AnimatePresence>
            {isVisible && (
                <Overlay
                    className={className}
                    style={style}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <ScrollView className="d-flex align-items-end align-items-md-center justify-content-md-center">
                        <Modal
                            variants={containerVariant}
                            transition={{type: "spring", duration: 0.5}}
                        >
                            <SignBg
                                mobileUrl={asset('/images/signUp/signUp-mobile.jpg')}
                                pcUrl={asset('/images/signUp/signUp-pc.jpg')}
                            >
                                <SignUpText>會員註冊事項</SignUpText>
                                <CloseButton type="button" onClick={onClose}>
                                    <Icon code="times" color="#ebebeb" size={15}/>
                                </CloseButton>
                            </SignBg>

                            <SignUpContent>
                                <p>【會員註冊】</p>
                                <p>1.註冊資格:必須為銷售業者,謝絕零售自用客人註冊。</p>
                                <p>2.註冊資料:必須完整、真實。</p>
                                <p>3.網路賣家:必須提供銷售之網址、頁面。</p>
                                <p>4.實體賣家:必須提供店家住址、電話。</p>
                                <p>會員認證填寫完會員申請資料後,不表示已完成會員申請。</p>
                                <p>請務必以「電話」向本公司客服人員聯絡,經過審核後開通會員資格如有下列情形,</p>
                                <p>無法開通會員資格</p>
                                <p>1.填寫資料不完整或不真實</p>
                                <p>2.非批發客人,請勿加入本網站</p>
                                <p>3.無法提供店家資料或是網站資訊</p>
                                <p>【取消會員資格】</p>
                                <p>1.帳號開通後有一定的瀏覽期，瀏覽期限內未完成任何交易者，即取消會員資格</p>
                                <p>2.新註冊會員:加入會員7天仍無完成任何一筆交易者，有權關閉會員權限。</p>
                                <p>3.舊會員:期限內未完成交易,系統將會自動關閉您的帳號，酌收費用使得重啟權限。</p>
                                <br/>
                                <p>【會員義務】</p>
                                <p>1.會員一律不得以任何行式轉讓會員帳號予其它人。</p>
                                <p>2.會員一律不得任意散播本站不實消息</p>
                                <p>3.會員有義務填寫真實資料，包含真實姓名(店名)、有效聯絡電話、完整寄送地址、完整販賣地址或網址。</p>
                                <br/>
                                <br/>
                                <p>以上加入會員之各條款，本站保有最終解釋之權利。</p>
                            </SignUpContent>

                            <AgreeSection className="d-md-none"/>
                            <AgreeButton
                                disabled={second > 0}
                                onClick={() => {
                                    onClose();
                                    history.push('/signUp/1');
                                }}
                            >
                                {second > 0 ? `我同意以上條款 ${second}s` : '我同意以上條款'}
                            </AgreeButton>
                        </Modal>
                    </ScrollView>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default SignUpModal;

const AgreeButton = styled(Button)`
    width: 240px;
    height: 44px;
    min-height: auto;
    position: absolute;
    bottom: 20px;

    ${media.md`
        position: initial;
        margin-bottom: 30px;
    `}

    ${media.md`
        width: 209px;
        height: 56px;
        margin-bottom: 25px;
    `}
`;

const AgreeSection = styled.div`
    width: 100%;
    height: 66px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 42px;
`;

const SignUpContent = styled.div`
    padding: 20px 20px 25px 20px;
    width: 100%;
    font-size: 12px;
    color: #9d9a9a;
    flex: 1 1 auto;
    overflow-y: scroll;
    margin-bottom: 40px;

    ${media.md`
        padding: 30px;
        margin-bottom: 0;

        //捲軸底色
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #fafafa;
        }
        //捲軸寬度
        ::-webkit-scrollbar {
            width: 6px;
            background-color: black;
        }
        //捲軸本體顏色
        ::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
        }
    `}

    ${media.xl`
        font-size: 17px;
        padding: 18px 40px;
    `}
`;

const SignUpText = styled.span`
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CloseButton = styled.button<any>`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 16px;
    right: 16px;

    ${media.md`
        width: 30px;
        height: 30px;
        top: 20px;
        right: 20px;

        i{
            font-size: 20px;
            color: #282731;
        }
    `}
`;

const SignBg = styled.div<any>`
    background-image: url("${props => props.mobileUrl}");
    width: 100%;
    background-size: cover;
    padding-bottom: 25%;
    position: relative;

    ${media.md`
        padding-bottom: 20%;
        background-image: url("${(props: any) => props.pcUrl}");
    `}

    ${media.xl`
        padding-bottom: 15%;
    `}
`;

const Modal = styled(motion.div)`
    width: 100%;
    height: 548px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: absolute;

    ${media.md`
        width: 500px;
        height: 60vh;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        border-radius: 0;
    `}

    ${media.xl`
        width: 840px;
        height: 90vh;
        max-height: 739px;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    `}
`;

const Overlay = styled(motion.div)`
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
`;
