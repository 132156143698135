import React, { useEffect } from 'react';
import {useLocation} from 'react-router-dom';


/**
 * 自動捲動到最上方
 * @constructor
 */
const AutoScrollTop: React.FC = () => {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [pathname]);

    return null;
};

export default AutoScrollTop;
