import React from 'react';

/**
 * 初始化資料
 * @param sourceList
 * @param slidesPerView
 * @param slidesPerGroup
 * @param isLoop
 */
const initDataList = (sourceList: Array<any> = [], slidesPerView =1, slidesPerGroup = 1, isLoop= false): Array<{
    actualIndex: number;
    matchIndex: number;
    inPage: number;
    isClone: boolean;
    element: React.ReactNode;
}> => {
    const formatList = [];
    const isClone = isLoop && typeof window !== 'undefined';
    let index = 0;
    const formatSlidesPerView = Math.ceil(slidesPerView);

    if (isClone) {
        // 複製最後面, 放在最前面

        const cloneStart = (sourceList.length - formatSlidesPerView);
        // eslint-disable-next-line no-restricted-syntax
        for (const row of sourceList.slice(-formatSlidesPerView)) {
            formatList[index] = {
                actualIndex: index,
                matchIndex: formatSlidesPerView + cloneStart + index,
                inPage: 0,
                isClone: true,
                element: row,
            };
            index += 1;
        }
    }

    let matchFirstIndex = index;
    let pageFirstIndex = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const row of sourceList) {
        formatList[index] = {
            actualIndex: index,
            matchIndex: -999,
            inPage: Math.ceil((pageFirstIndex + 1) / slidesPerGroup),
            isClone: false,
            element: row,
        };
        index += 1;
        pageFirstIndex += 1;
    }

    if (isClone) {
        // 複製前面的(需顯示總數) 放在最後面

        // eslint-disable-next-line no-restricted-syntax
        for (const row of sourceList.slice(0, Math.ceil(formatSlidesPerView))) {
            formatList[index] = {
                actualIndex: index,
                matchIndex: matchFirstIndex,
                inPage: 0,
                isClone: true,
                element: row,
            };
            index += 1;
            matchFirstIndex += 1;
        }
    }
    return formatList;
};

/**
 * 檢查是否為行動裝置
 */
const checkIsMobile = () => {
    try { document.createEvent('TouchEvent'); return true; } catch (e) { return false; }
};

const getCss = (slidesPerView: number) => `
flex: 1 0 ${100 / slidesPerView}%;
max-width: ${100 / slidesPerView}%;
`;

const generateMedia = (breakpoints: any) => Object.keys(breakpoints)
    .filter(size => typeof breakpoints[size] !== 'undefined')
    .map(size => {
        return `@media screen and (min-width: ${size}px){
            ${getCss(breakpoints[size].slidesPerView)};
        }`;
    });

export {initDataList, checkIsMobile, generateMedia};
