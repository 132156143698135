import {Saga as startUp} from './startUp';
// import {Saga as language} from './language';
import {Saga as system} from './system';
import {Saga as auth} from './auth';
import {Saga as level} from './level';

// import {Saga as gameLobby} from './gameLobby';
// import {Saga as wallet} from './wallet';
// import {Saga as promotion} from './promotion';
// import {Saga as customer} from './customer';
import {Saga as profile} from './profile';
import {Saga as message} from './message';
import {Saga as track} from './track';
import {Saga as preSale} from './preSale';
import {Saga as order} from './order';
import {Saga as shipment} from './shipment';
import {Saga as trade} from './trade';

import {Saga as product} from './product';
import {Saga as cart} from './cart';
import {Saga as ad} from './ad';

// import {Saga as historyDeposit} from './historyDeposit';
// import {Saga as historyWithdrawal} from './historyWithdrawal';

// import {Saga as withdrawal} from './withdrawal';
import {Saga as deposit} from './deposit';
// import {Saga as depositDPay} from './depositDPay';
// import {Saga as depositICard} from './depositICard';
// import {Saga as depositAtm} from './depositAtm';

import { all } from 'redux-saga/effects';

/** -----------------------------------------
            Connect Sagas
 /** --------------------------------------*/
export default function* sagas() {
    yield all([
        ...startUp,
        // ...language,
        ...system,
        ...auth,
        ...level,
        //
        // ...gameLobby,
        // ...wallet,
        // ...promotion,
        // ...customer,
        ...profile,
        ...message,
        ...track,
        ...preSale,
        ...order,
        ...shipment,
        ...trade,

        ...product,
        ...cart,
        ...ad,
        //
        // ...historyDeposit,
        // ...historyWithdrawal,
        //
        // ...withdrawal,
        ...deposit,
        // ...depositDPay,
        // ...depositICard,
        // ...depositAtm,
    ])
}
