import React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {media, Container} from 'library/styled-bs-grid';
import BearCarousel, {BearSlideItem, elClassName} from 'bear-react-carousel';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    data: Array<{
        id: number;
        logoUrl: string;
    }>;
}

/**
 * MallCarousel
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const MallCarousel: React.FC<IProps> = ({
    className,
    style,
    data = [],
}) => {

    const sliderDataList = data.map(row => {

        return {
            key: row.id,
            children: <BearSlideItem key={row.id} imageUrl={row.logoUrl} imageSize="100%"/>,
        }
    });

    return (
        <MallCarouselRoot style={style} className={className}>
            <BearCarousel
                slidesPerView={4}
                spaceBetween={4}
                isEnableAutoPlay
                isEnableLoop
                autoPlayTime={5000}
                isEnablePagination={false}
                isEnableNavButton={false}
                data={sliderDataList}
                staticHeight="40px"
                breakpoints={{
                    1200: {
                        slidesPerView: 6,
                        spaceBetween: 30,
                    },
                    992: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 6,
                        spaceBetween: 20,
                    },
                }}
            />

        </MallCarouselRoot>
    );
};

export default MallCarousel;


const MallCarouselRoot = styled(Container)`

    .${elClassName.container}{
      min-height: 0;
    }
    ${media.xxl`
        max-width: 1170px;
    `}
`;
