import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {get} from 'lodash';
import {formatCurrency} from 'utils/number';
import {media, Container} from 'library/styled-bs-grid';
import site from 'config/site';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {motion, AnimatePresence} from 'framer-motion'
import {getOs} from 'utils/browser';
import {asset} from 'config/app';
import {isEmpty} from 'utils/equal';
import {disableBodyScroll, enableBodyScroll} from 'library/body-scroll-lock';

// Component
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import PageScrollView from 'components/atoms/PageScrollView';
import Size from 'components/molecules/Size';
import BatchModal from './BatchModal';
import PickModal from './PickModal';

// Reducer
import {Actions as CartAction} from 'store/cart';
import {Actions as PreSaleAction} from 'store/preSale';
import {Selector as AuthSelector} from 'store/auth';


interface IProps {
    className?: string;
    style?: CSS.Properties;
    onClose?: Function;
    onTrack?: Function;
    isVisible?: boolean;
    isFetching?: boolean;
    type?: 'edit';
    cartId?: number;
    id: number;
    brandName: string;
    name: string;
    model: string;
    price: number;
    prePrice: number;
    color: string;
    createDate?: string;
    isSale?: boolean;
    isNew?: boolean;
    isHot?: boolean;
    isOutStock?: boolean;
    isCanChooseSize: boolean;
    isFavorite?: boolean;
    isVipItem?: boolean;
    isOrderPreSale?: boolean,
    isOrderNormalGoods?: boolean,
    isTrack?: boolean,
    inStock: Array<{
        id: number;
        size: string;
        qty: number;
    }>;
    size: Array<{
        size: string;
        isInStock: boolean;
    }>;
    batchPrice: number;
    batchUnitQty: number;
    images: Array<{
        id: number;
        index: number;
        url: string;
    }>;
    batchQty?: number,
    pickType?: {
        id: number,
        name: string,
        code: string,
    };
    sizeQty?: Array<{
        size: string,
        qty: number,
    }>;
    totalQty?: number,
}

/**
 * SpeedOrderModal
 * @param props
 * @returns {*}
 * @constructor
 */
const SpeedOrderModal: React.FC<IProps> = ({
    className,
    style,
    onClose = () => {},
    onTrack = () => {},
    isVisible = false,
    isFetching = false,
    type,
    cartId = null,
    id,
    brandName,
    name,
    model,
    price,
    prePrice,
    isSale,
    isOrderPreSale,
    isOrderNormalGoods = false,
    isTrack,
    inStock,
    size,
    color,
    isOutStock= false,
    isCanChooseSize,
    images,
    batchPrice,
    batchUnitQty,
    batchQty = 0,
    pickType,
    sizeQty = [],
    totalQty    ,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const batchExpandRef = useRef<HTMLElement>(null);
    const inStockExpandRef = useRef<HTMLElement>(null);
    const pickExpandRef = useRef<HTMLElement>(null);

    const [isVisibleBatch, setVisibleBatch] = useState<boolean>(false);
    const [isVisiblePick, setVisiblePick] = useState<boolean>(false);
    const [isInStock, setInStock] = useState<boolean>(false);

    const {payload} = useSelector(state => ({
        payload: AuthSelector.payload(state),
    }));

    // 電腦版整手下單下拉
    const [isVisiblePcBatch, setVisiblePcBatch] = useState<boolean>(false);
    // 電腦版現貨下單下拉
    const [isVisiblePcInStock, setVisiblePcInStock] = useState<boolean>(false);
    // 電腦版挑碼下單下拉
    const [isVisiblePcPick, setVisiblePcPick] = useState<boolean>(false);

    // 現貨下單尺寸與件數
    const [inStockSizeQty, setInStockSizeQty] = useState<Array<{size: string, qty: number}>>([]);
    // 挑碼下單尺寸與件數
    const [pickSizeQty, setPickSizeQty] = useState<Array<{size: string, qty: number}>>([]);

    // 整手下單總數量
    const [batchTotalQty, setBatchTotalQty] = useState<number>(0);
    // 現貨下單總件數
    const inStockTotalQty = inStockSizeQty.reduce((accum,item) => accum + item.qty, 0);
    // 挑碼下單總件數
    const pickTotalQty = pickSizeQty.reduce((accum,item) => accum + item.qty, 0);


    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    useEffect(() => {
        if(isVisible){
            // 鎖背景
            disableBodyScroll();
            return () => {
                enableBodyScroll();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);


    useEffect(() => {
        if (type === 'edit') {
            if (get(pickType, 'code') === 'batch') {
                // 整手
                setBatchTotalQty(batchQty);
                setVisiblePcBatch(true);

                setInStockSizeQty([]);
                setPickSizeQty([]);
            } else if (get(pickType, 'code') === 'inStock') {
                // 現貨
                setInStockSizeQty(sizeQty);
                setVisiblePcInStock(true);

                setBatchTotalQty(0);
                setPickSizeQty([]);
            } else if (get(pickType, 'code') === 'pickSize') {
                // 挑碼
                setPickSizeQty(sizeQty);
                setVisiblePcPick(true);

                setBatchTotalQty(0);
                setInStockSizeQty([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, pickType]);

    useEffect(() => {
        if (type !== 'edit') {
            setBatchTotalQty(0);
            setInStockSizeQty([]);
            setPickSizeQty([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    /**
     *  計算現貨下單各尺寸件數
     */
    const handleCountInStockQty = (sizeText: string, type: string, qty?: number) => {
        let cloneData = [...inStockSizeQty];
        const currentIndex = cloneData.findIndex(row => row.size === sizeText);
        const index = currentIndex === -1 ? get(inStockSizeQty, 'length', 0) : currentIndex;

        if (type === 'add') {
            cloneData[index] = {
                size: inStockSizeQty[currentIndex] ? inStockSizeQty[currentIndex].size : sizeText,
                qty: inStockSizeQty[currentIndex] ? Number(inStockSizeQty[currentIndex].qty) + 1 : 1,
            };
        } else if (type === 'minus') {
            if (inStockSizeQty[currentIndex] &&  Number(inStockSizeQty[currentIndex].qty) - 1 === 0) {
                cloneData.splice(index, 1);
            } else {
                cloneData[index] = {
                    size: inStockSizeQty[currentIndex] ? inStockSizeQty[currentIndex].size : sizeText,
                    qty: inStockSizeQty[currentIndex] ? Number(inStockSizeQty[currentIndex].qty) - 1 : 0,
                };
            }
        } else {
            cloneData[index] = {
                size: inStockSizeQty[currentIndex] ? inStockSizeQty[currentIndex].size : sizeText,
                qty: Number(qty),
            };
        }

        setInStockSizeQty(cloneData);
    };

    /**
     *  計算挑碼下單各尺寸件數
     */
    const handleCountPickQty = (sizeText: string, type: string, qty?: number) => {
        let cloneData = [...pickSizeQty];
        const currentIndex = cloneData.findIndex(row => row.size === sizeText);
        const index = currentIndex === -1 ? get(pickSizeQty, 'length', 0) : currentIndex;

        if (type === 'add') {
            cloneData[index] = {
                size: pickSizeQty[currentIndex] ? pickSizeQty[currentIndex].size : sizeText,
                qty: pickSizeQty[currentIndex] ? Number(pickSizeQty[currentIndex].qty) + 1 : 1,
            };
        } else if (type === 'minus') {
            if (pickSizeQty[currentIndex] && Number(pickSizeQty[currentIndex].qty) - 1 === 0) {
                cloneData.splice(index, 1);
            } else {
                cloneData[index] = {
                    size: pickSizeQty[currentIndex] ? pickSizeQty[currentIndex].size : sizeText,
                    qty: pickSizeQty[currentIndex] ? Number(pickSizeQty[currentIndex].qty) - 1 : 0,
                };
            }
        } else {
            cloneData[index] = {
                size: pickSizeQty[currentIndex] ? pickSizeQty[currentIndex].size : sizeText,
                qty: Number(qty),
            };
        }

        setPickSizeQty(cloneData);
    };

    /**
     * 計算總金額
     */
    const countTotalPrice = () => {
        let totalPrice = 0;
        if (isVisiblePcBatch) {
            totalPrice = batchTotalQty * batchPrice;
        } else if (isVisiblePcInStock) {
            totalPrice = price * inStockTotalQty;
        } else if (isVisiblePcPick) {
            totalPrice = price * pickTotalQty;
        }

        return `加入購物袋 NT$ ${formatCurrency(totalPrice)}`;
    };


    /**
     * 檢查送出按鈕
     */
    const checkIsDisabled = () => {
        if (isVisiblePcBatch && batchTotalQty > 0) {
            return false;
        } else if (isVisiblePcInStock && inStockTotalQty > 0) {
            return false;
        } else  if (isVisiblePcPick && pickTotalQty > 0) {
            return false;
        }

        return true;
    };

    /**
     * 加入購物車
     */
    const handleAddCart = () => {
        if (type === 'edit') {
            if (isVisiblePcBatch) {
                // 更改購物車整手下單

                // @ts-ignore
                dispatch(CartAction.putCartBatch({id: cartId, batchQty: batchTotalQty}))
            } else if (isVisiblePcInStock) {
                // 更改購物車現貨下單

                // @ts-ignore
                dispatch(CartAction.putCartPick({id: cartId, sizeQty: JSON.stringify(inStockSizeQty), isInStock: true}))
            } else if (isVisiblePcPick) {
                // 更改購物車挑碼下單

                // @ts-ignore
                dispatch(CartAction.putCartPick({id: cartId, sizeQty: JSON.stringify(pickSizeQty), isInStock: false}))
            }
        } else {
            if (isVisiblePcBatch) {
                // 整手下單
                dispatch(CartAction.submitCartBatch({productGoodsId: id, batchQty: batchTotalQty}));
            } else if (isVisiblePcInStock) {
                // 現貨下單
                dispatch(CartAction.submitCartPick({productGoodsId: id, sizeQty: JSON.stringify(inStockSizeQty), isInStock: true}));
            } else if (isVisiblePcPick) {
                // 挑碼下單
                dispatch(CartAction.submitCartPick({productGoodsId: id, sizeQty: JSON.stringify(pickSizeQty), isInStock: false}));
            }
        }

        handleCloseModal();
    };

    /**
     * 加入預先下特
     */
    const handleAddPreSale = () => {
        if (isVisiblePcBatch) {
            // 整手下單
            dispatch(PreSaleAction.submitPreSaleBatch({productGoodsId: id, batchQty: batchTotalQty}));
        } else if (isVisiblePcInStock) {
            // 現貨下單
            dispatch(PreSaleAction.submitPreSalePick({productGoodsId: id, sizeQty: JSON.stringify(inStockSizeQty), isInStock: true}));
        } else if (isVisiblePcPick) {
            // 挑碼下單
            dispatch(PreSaleAction.submitPreSalePick({productGoodsId: id, sizeQty: JSON.stringify(pickSizeQty), isInStock: false}));
        }

        handleCloseModal();
    };

    /**
     * 關閉光箱並清除狀態
     */
    const handleCloseModal = () => {
        setBatchTotalQty(0);
        setInStockSizeQty([]);
        setPickSizeQty([]);

        setVisiblePcBatch(false);
        setVisiblePcInStock(false);
        setVisiblePcPick(false);

        onClose();
    };

    const getHeight = useCallback((expandRef) => {
        return expandRef?.current?.scrollHeight;
    }, []);

    return (
        <AnimatePresence>
            {(isVisible) && (
                <Overlay
                    className={className}
                    style={style}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <PageScrollView className="d-flex align-items-end justify-content-center align-items-md-center">
                        {isFetching && (
                            <LoadingBg>
                                <HalfCircleSpinner style={{margin: 'auto'}} size={30} color="#bdbdbd"/>
                            </LoadingBg>
                        )}

                        {!isFetching && (
                            <Modal
                                isHidden={(isVisibleBatch || isVisiblePick)}
                                variants={containerVariant}
                                transition={{type: "spring", duration: 0.5}}
                            >
                                <CloseButton type="button" onClick={() => handleCloseModal()}>
                                    <Icon code="times" size={15} color="#cecece"/>
                                </CloseButton>

                                <ProductImg className="d-none d-xl-block" src={isEmpty(get(images, '0.url', '')) ? asset('/images/goods/default.jpg') : get(images, '0.url')}/>

                                <div>
                                    {/* 商品資訊 */}
                                    <ProductInfo className="d-flex">
                                        <ProductImg className="d-xl-none" src={isEmpty(get(images, '0.url', '')) ? asset('/images/goods/default.jpg') : get(images, '0.url')}/>
                                        <div className="d-flex flex-column">
                                            <BrandName>{brandName}</BrandName>
                                            <ProductName>{name}</ProductName>
                                            <Model>{`商品貨號：${model}`}</Model>

                                            {isOrderNormalGoods && (
                                                <div>
                                                    {isSale && <PrePrice>NT$ {prePrice}</PrePrice>}
                                                    <Price>NT$ {price}</Price>
                                                </div>
                                            )}
                                        </div>
                                    </ProductInfo>

                                    {/* 顏色 */}
                                    <RelatedSection>
                                        <SectionContainer className="d-flex align-items-center h-100">
                                            <RelatedTitle>COLOR：</RelatedTitle>
                                            <OrderTitle>{color}</OrderTitle>
                                        </SectionContainer>
                                    </RelatedSection>

                                    {/* SIZE */}
                                    <SizeSection>
                                        <SectionContainer className="d-flex align-items-center h-100">
                                            <SizeTitle>SIZES：</SizeTitle>

                                            <div className="d-flex flex-wrap">
                                                {size.map(row => (
                                                    <CustomSize
                                                        key={row.size}
                                                        isInStock={row.isInStock}
                                                        text={row.size.toUpperCase()}
                                                    />
                                                ))}
                                            </div>
                                        </SectionContainer>
                                    </SizeSection>

                                    {/* 整手下單 */}
                                    {(!isOutStock && isOrderNormalGoods) && (
                                        <HandOrder>
                                            <SectionContainer className="d-flex align-items-center h-100 align-items-xl-start">
                                                <HandOrderTitle>整手下單：</HandOrderTitle>

                                                {/* 手機版整手下單 */}
                                                <div className="w-100 d-flex d-xl-none align-items-center">
                                                    <div className="d-flex align-items-center flex-grow-1">
                                                        <HandOrderButton
                                                            type="button"
                                                            disabled={batchTotalQty === 0}
                                                            onClick={() => {
                                                                setBatchTotalQty(batchTotalQty - 1);
                                                                setVisiblePcBatch(true);
                                                                setVisiblePcInStock(false);
                                                                setVisiblePcPick(false);
                                                            }}

                                                        >
                                                            <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                                        </HandOrderButton>

                                                        <HandOrderNum>{batchTotalQty}</HandOrderNum>

                                                        <HandOrderButton
                                                            type="button"
                                                            onClick={() => {
                                                                setBatchTotalQty(batchTotalQty + 1);
                                                                setVisiblePcBatch(true);
                                                                setVisiblePcInStock(false);
                                                                setVisiblePcPick(false);
                                                            }}
                                                        >
                                                            <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                                        </HandOrderButton>
                                                    </div>

                                                    <ShowNum
                                                        isActive={isVisiblePcBatch && batchTotalQty > 0}
                                                    >
                                                        {`${batchTotalQty}(手)`}
                                                    </ShowNum>

                                                    <RightButton
                                                        onClick={() => {
                                                            setVisibleBatch(true);
                                                            setVisiblePcBatch(true);
                                                            setVisiblePcPick(false);
                                                            setVisiblePcInStock(false);
                                                        }}
                                                    >
                                                        <Icon code="chevron-right" color="#9d9a9a" size={10}/>
                                                    </RightButton>
                                                </div>


                                                {/* 電腦版整手下單 */}
                                                <OrderDetail className="d-none d-xl-flex flex-column flex-grow-1">
                                                    <OrderInfo
                                                        className="d-flex align-items-center justify-content-between flex-grow-1"
                                                        onClick={() => {
                                                            setVisiblePcBatch(!isVisiblePcBatch);
                                                            setVisiblePcPick(false);
                                                            setVisiblePcInStock(false);
                                                        }}
                                                    >
                                                        <OrderTitle>Quantity</OrderTitle>
                                                        <ShowNum
                                                            isActive={isVisiblePcBatch && batchTotalQty > 0}
                                                        >
                                                            {`${batchTotalQty}(手)`}
                                                        </ShowNum>

                                                        <RightButton className="d-flex">
                                                            <Icon code="chevron-right" color="#9d9a9a" size={10} rotate={isVisiblePcBatch ? 270 : 90}/>
                                                        </RightButton>
                                                    </OrderInfo>

                                                    <SizeAccordion
                                                        ref={batchExpandRef}
                                                        style={{height: isVisiblePcBatch ? `${getHeight(batchExpandRef)}px` : '0px'}}
                                                    >
                                                        <AccordionContent className="d-flex align-items-center" style={{paddingBottom: 13}}>
                                                            <div className="d-flex align-items-center flex-grow-1">
                                                                <HandOrderButton
                                                                    type="button"
                                                                    disabled={batchTotalQty === 0}
                                                                    onClick={() => setBatchTotalQty(batchTotalQty - 1)}
                                                                >
                                                                    <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                                                </HandOrderButton>

                                                                <HandOrderInput
                                                                    name={batchTotalQty}
                                                                    value={batchTotalQty}
                                                                    defaultValue={0}
                                                                    onChange={(events: any) => {
                                                                        if (!isEmpty(events.target.value) && !/^\d+$/.test(events.target.value)) {
                                                                            alert('請輸入數字');
                                                                        } else {
                                                                            setBatchTotalQty(Number(events.target.value));
                                                                        }
                                                                    }}
                                                                />

                                                                <HandOrderButton
                                                                    type="button"
                                                                    onClick={() => setBatchTotalQty(batchTotalQty + 1)}
                                                                >
                                                                    <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                                                </HandOrderButton>
                                                            </div>

                                                            <ShowNum>{`${batchUnitQty}(件) / NT$ ${batchPrice} 元`}</ShowNum>

                                                        </AccordionContent>
                                                    </SizeAccordion>
                                                </OrderDetail>


                                            </SectionContainer>
                                        </HandOrder>
                                    )}

                                    {/* 現貨下單 */}
                                    {(isOrderNormalGoods && get(inStock, 'length', 0) > 0) && (
                                        <InStock className="d-flex align-items-center">
                                            <SectionContainer className="d-flex align-items-center h-100 align-items-xl-start">
                                                <InStockTitle>現貨下單：</InStockTitle>

                                                <OrderDetail className="d-flex flex-column flex-grow-1">

                                                    {/* 手機版點擊彈出挑碼下單光箱 */}
                                                    <OrderInfo
                                                        className="d-flex d-xl-none align-items-center justify-content-between flex-grow-1"
                                                        onClick={() => {
                                                            setInStock(true);
                                                            setVisiblePick(true);
                                                            setVisiblePcBatch(false);
                                                            setVisiblePcInStock(true);
                                                            setVisiblePcPick(false);
                                                        }}
                                                    >
                                                        <OrderTitle>Size / Quantity</OrderTitle>
                                                        <ShowNum isActive={isVisiblePcInStock && inStockTotalQty > 0}>{`${inStockTotalQty}(件)`}</ShowNum>

                                                        <RightButton>
                                                            <Icon code="chevron-right" color="#9d9a9a" size={10}/>
                                                        </RightButton>
                                                    </OrderInfo>

                                                    {/* 電腦碼點擊顯示挑碼下單下拉 */}
                                                    <OrderInfo
                                                        className="d-none d-xl-flex align-items-center justify-content-between flex-grow-1"
                                                        onClick={() => {
                                                            setVisiblePcInStock(!isVisiblePcInStock);
                                                            setVisiblePcBatch(false);
                                                            setVisiblePcPick(false);
                                                        }}
                                                    >
                                                        <OrderTitle>Size / Quantity</OrderTitle>
                                                        <ShowNum isActive={isVisiblePcInStock && inStockTotalQty > 0}>{`${inStockTotalQty}(件)`}</ShowNum>

                                                        <RightButton>
                                                            <Icon code="chevron-right" color="#9d9a9a" size={10} rotate={isVisiblePcInStock ? 270 : 90}/>
                                                        </RightButton>
                                                    </OrderInfo>

                                                    {/* 電腦版現貨下單Size數量 */}
                                                    <SizeAccordion
                                                        className="d-none d-xl-flex flex-md-column"
                                                        ref={inStockExpandRef}
                                                        style={{height: isVisiblePcInStock ? `${getHeight(inStockExpandRef)}px` : '0px'}}
                                                    >
                                                        <AccordionContent>
                                                            {inStock.map(row => {
                                                                // 現貨下單下單數量不可以超過庫存數量
                                                                const inStockSizeQtyInfo = inStockSizeQty.find(o => o.size === row.size);

                                                                return (
                                                                    <OrderSize key={row.id} className="d-flex align-items-center">
                                                                        <SizeText>{row.size}</SizeText>

                                                                        <div className="w-100 d-flex justify-content-end align-items-center">
                                                                            <HandOrderButton
                                                                                type="button"
                                                                                onClick={() => handleCountInStockQty(row.size, 'minus')}
                                                                                disabled={get(inStockSizeQtyInfo, 'qty', 0) === 0}
                                                                            >
                                                                                <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                                                            </HandOrderButton>

                                                                            <HandOrderInput
                                                                                name={row.size}
                                                                                value={get(inStockSizeQtyInfo, 'qty', 0)}
                                                                                defaultValue={0}
                                                                                onChange={(events: any) => {
                                                                                    if (!isEmpty(events.target.value) && !/^\d+$/.test(events.target.value)) {
                                                                                        alert('請輸入數字');
                                                                                    } else {
                                                                                        handleCountInStockQty(row.size, 'enter', events.target.value)
                                                                                    }
                                                                                }}
                                                                            />

                                                                            <HandOrderButton
                                                                                type="button"
                                                                                onClick={() => handleCountInStockQty(row.size, 'add')}
                                                                                disabled={Number(get(inStockSizeQtyInfo, 'qty', 0)) === row.qty}
                                                                            >
                                                                                <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                                                            </HandOrderButton>
                                                                        </div>
                                                                    </OrderSize>
                                                                );
                                                            })}
                                                        </AccordionContent>
                                                    </SizeAccordion>
                                                </OrderDetail>
                                            </SectionContainer>
                                        </InStock>
                                    )}

                                    {/* 挑碼下單 */}
                                    {(!isOutStock && isCanChooseSize && isOrderNormalGoods) && (
                                        <InStock className="d-flex align-items-center">
                                            <SectionContainer className="d-flex align-items-center h-100 align-items-xl-start">
                                                <InStockTitle>挑碼下單：</InStockTitle>

                                                <OrderDetail className="d-flex flex-column flex-grow-1">

                                                    {/* 手機版點擊彈出挑碼下單光箱 */}
                                                    <OrderInfo
                                                        className="d-flex d-xl-none align-items-center justify-content-between flex-grow-1"
                                                        onClick={() => {
                                                            setInStock(false);
                                                            setVisiblePick(true);
                                                            setVisiblePcBatch(false);
                                                            setVisiblePcInStock(false);
                                                            setVisiblePcPick(true);
                                                        }}
                                                    >
                                                        <OrderTitle>Size / Quantity</OrderTitle>
                                                        <ShowNum isActive={isVisiblePcPick && pickTotalQty > 0}>{`${pickTotalQty}(件)`}</ShowNum>

                                                        <RightButton>
                                                            <Icon code="chevron-right" color="#9d9a9a" size={10}/>
                                                        </RightButton>
                                                    </OrderInfo>

                                                    {/* 電腦碼點擊顯示挑碼下單下拉 */}
                                                    <OrderInfo
                                                        className="d-none d-xl-flex align-items-center justify-content-between flex-grow-1"
                                                        onClick={() => {
                                                            setVisiblePcPick(!isVisiblePcPick);
                                                            setVisiblePcBatch(false);
                                                            setVisiblePcInStock(false);
                                                        }}
                                                    >
                                                        <OrderTitle>Size / Quantity</OrderTitle>
                                                        <ShowNum isActive={isVisiblePcPick && pickTotalQty > 0}>{`${pickTotalQty}(件)`}</ShowNum>

                                                        <RightButton>
                                                            <Icon code="chevron-right" color="#9d9a9a" size={10} rotate={isVisiblePcPick ? 270 : 90}/>
                                                        </RightButton>
                                                    </OrderInfo>

                                                    {/* 電腦版挑碼下單Size數量 */}
                                                    <SizeAccordion
                                                        className="d-none d-xl-flex flex-md-column"
                                                        ref={pickExpandRef}
                                                        style={{height: isVisiblePcPick ? `${getHeight(pickExpandRef)}px` : '0px'}}
                                                    >
                                                        <AccordionContent>
                                                            {size.map(row => {
                                                                const pickSizeQtyInfo = pickSizeQty.find(o => o.size === row.size);

                                                                return (
                                                                    <OrderSize key={row.size} className="d-flex align-items-center">
                                                                        <SizeText>{row.size}</SizeText>

                                                                        <div className="w-100 d-flex justify-content-end align-items-center">
                                                                            <HandOrderButton
                                                                                type="button"
                                                                                disabled={get(pickSizeQtyInfo, 'qty', 0) === 0}
                                                                                onClick={() => handleCountPickQty(row.size, 'minus')}
                                                                            >
                                                                                <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                                                            </HandOrderButton>

                                                                            <HandOrderInput
                                                                                name={row.size}
                                                                                value={get(pickSizeQtyInfo, 'qty', 0)}
                                                                                defaultValue={0}
                                                                                onChange={(events: any) => {
                                                                                    if (!isEmpty(events.target.value) && !/^\d+$/.test(events.target.value)) {
                                                                                        alert('請輸入數字');
                                                                                    } else {
                                                                                        handleCountPickQty(row.size, 'enter', events.target.value);
                                                                                    }
                                                                                }}
                                                                            />

                                                                            <HandOrderButton
                                                                                type="button"
                                                                                onClick={() => handleCountPickQty(row.size, 'add')}
                                                                            >
                                                                                <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                                                            </HandOrderButton>
                                                                        </div>
                                                                    </OrderSize>
                                                                );
                                                            })}
                                                        </AccordionContent>
                                                    </SizeAccordion>
                                                </OrderDetail>
                                            </SectionContainer>
                                        </InStock>
                                    )}

                                    {((!isOutStock || get(inStock, 'length', 0) > 0) && isOrderNormalGoods) && (
                                        <SubmitSection>
                                            {/* 預先下特按鈕 */}
                                            {isOrderPreSale && (
                                                <PreSaleButton
                                                    themeType="danger"
                                                    disabled={checkIsDisabled()}
                                                    onClick={() => handleAddPreSale()}
                                                >
                                                    預先下特
                                                </PreSaleButton>
                                            )}

                                            {/* 加入購物袋按鈕 */}
                                            <SubmitButton
                                                isSingle={!isOrderPreSale}
                                                disabled={checkIsDisabled()}
                                                onClick={() => handleAddCart()}
                                            >
                                                {type === 'edit' ? '確認修改' : countTotalPrice()}
                                            </SubmitButton>

                                            <FavoriteButton
                                                type="button"
                                                className="d-none d-xl-block"
                                                onClick={onTrack}
                                            >
                                                <Icon code={isTrack ? 'heart-solid' : 'heart'} color={isTrack ? '#ff7d6a' : '#9d9a9a'} size={40}/>
                                            </FavoriteButton>
                                        </SubmitSection>
                                    )}

                                    <Browse
                                        className="align-items-center"
                                        onClick={() => history.push(`/product/detail/${payload?.id}/${id}`)}
                                    >
                                        <BrowseText>瀏覽產品詳情</BrowseText>
                                        <Icon code="arrow-right" color="#282731" size={20}/>
                                    </Browse>
                                </div>
                            </Modal>
                        )}

                        {/* 整手下單光箱 */}
                        {isVisibleBatch && (
                            <BatchModal
                                onBack={() => setVisibleBatch(false)}
                                onClose={onClose}
                                id={id}
                                brandName={brandName}
                                name={name}
                                model={model}
                                price={price}
                                prePrice={prePrice}
                                isSale={isSale}
                                size={size}
                                batchPrice={batchPrice}
                                batchUnitQty={batchUnitQty}
                                imageUrl={isEmpty(get(images, '0.url', '')) ? asset('/images/goods/default.jpg') : get(images, '0.url', '')}
                                batchQty={batchTotalQty}
                                setBatchQty={(num: number) => setBatchTotalQty(num)}
                            />
                        )}


                        {/* [挑碼] or [現貨] 下單光箱 */}
                        {isVisiblePick && (
                            <PickModal
                                isInStock={isInStock}
                                onBack={() => setVisiblePick(false)}
                                onClose={onClose}
                                id={id}
                                brandName={brandName}
                                name={name}
                                model={model}
                                price={price}
                                prePrice={prePrice}
                                isSale={isSale}
                                size={size}
                                inStock={inStock}
                                imageUrl={isEmpty(get(images, '0.url', '')) ? asset('/images/goods/default.jpg') : get(images, '0.url', '')}
                                inStockSizeQty={inStockSizeQty}
                                setInStockSizeQty={(sizeArray: Array<{size: string, qty: number}>) => setInStockSizeQty(sizeArray)}
                                pickSizeQty={pickSizeQty}
                                setPickSizeQty={(sizeArray: Array<{size: string, qty: number}>) => setPickSizeQty(sizeArray)}
                            />
                        )}
                    </PageScrollView>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default SpeedOrderModal;

const BrowseText = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #6e6e6e;
    margin-right: 17px;
`;

const Browse = styled.div`
    cursor: pointer;
    display: none;

    ${media.xl`
        display: inline-flex;
    `}
`;

const FavoriteButton = styled.button<any>`
    width: 46px;
    height: 46px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
`;

const SubmitButton = styled(Button)<any>`
    width: 160px;
    height: 44px;
    min-height: auto;
    font-size: 14px;

    ${props => props.isSingle && css`
        width: 240px;
    `}

    ${media.xl`
        width: 100%;
        font-size: 16px;
        height: 46px;
    `}
`;

const PreSaleButton = styled(Button)`
    width: 110px;
    height: 44px;
    min-height: auto;
    font-size: 14px;
    margin: 0 10px 0 0;

    ${media.xl`
        min-width: 170px;
        height: 46px;
    `}
`;

const SubmitSection = styled.div`
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.xl`
        height: auto;
        margin-top: 10px;
        margin-bottom: 17px;
    `}
`;

const HandOrderInput = styled.input<any>`
    width: 80px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: ${site.theme.primaryColor};
    background-color: #f2f4f9;
    margin: 0 10px;
    padding: 0;
    text-align: center;
    border: none;
`;

const SizeText = styled.div`
    min-height: 40px;
    color: #9d9a9a;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
`;

const OrderSize = styled.div`
    margin-bottom: 4px;
`;

const AccordionContent = styled.div`
    padding: 6px 40px 3px 0;
`;

const SizeAccordion = styled.div<any>`
    overflow: hidden;
    padding: 0;
    transition: height .2s;
`;

const OrderTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;
    flex: 1 1 auto;

    ${media.md`
        font-size: 14px;
    `}

    ${media.xl`
        font-size: 16px;
    `}
`;

const OrderInfo = styled.div`
    cursor: pointer;

    ${media.xl`
        display: inline-flex;
        height: 50px;
        max-height: 50px;
    `}
`;

const OrderDetail = styled.div`
    height: 100%;

    ${media.xl`
        border-bottom: solid 1px #e8e8e8;
    `}
`;

const InStockTitle = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;

    ${media.md`
        font-size: 14px;
        margin-right: 10px;
    `}

    ${media.xl`
        font-size: 16px;
        width: 110px;
        height: 50px;
        margin-right: 0;
        display: flex;
        align-items: center;
    `}
`;

const InStock = styled.div`
    width: 100%;
    min-height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;

    ${media.xl`
        margin-bottom: 0;
        width: 500px;
    `}
`;

const ShowNum = styled.span<any>`
    font-size: 12px;
    color: #9d9a9a;
    margin-right: 10px;

    ${media.md`
        font-size: 14px;
    `}

    ${media.xl`
        font-size: 16px;
    `}

    ${props => props.isActive && css`
        color: ${site.theme.primaryColor};
    `}
`;

const HandOrderNum = styled.div`
    width: 60px;
    height: 26px;
    font-size: 14px;
    font-weight: bold;
    color: ${site.theme.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    ${media.xl`
        width: 80px;
        height: 30px;
        font-size: 20px;
    `}
`;

const HandOrderButton = styled.button`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 1px #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: box-shadow .3s;

    i{
        transition: color .3s;
    }

    ${media.xl`
        width: 30px;
        height: 30px;

        i{
            font-size: 15px;
        }

        ${(props: any) => !props.disabled && css`
            &:hover{
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

                i{
                    color: #8ec5ff;
                }
            }
        `}
    `}

    ${props => props.disabled && css`
        i{
            opacity: 0.4;
        }
    `}
`;

const HandOrderTitle = styled.span`
    min-width: 70px;
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;

    ${media.md`
        font-size: 14px;
        margin-right: 10px;
    `}

    ${media.xl`
        width: 110px;
        height: 50px;
        font-size: 16px;
        margin-right: 0;
        display: flex;
        align-items: center;
    `}
`;

const HandOrder = styled.div`
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;

    ${media.xl`
        margin-bottom: 0;
        width: 500px;
        min-height: 56px;
        height: auto;
    `}
`;

const RightButton = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${media.xl`
        width: 30px;
        height: 30px;

        i {
            font-size: 15px;
        }
    `}
`;

const CustomSize = styled(Size)`
    ${media.xl`
        min-width: 30px;
        height: 30px;
        font-size: 20px;
    `}
`;

const SizeTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;
    margin-right: 10px;
    display: flex;
    align-items: center;

    ${media.md`
        font-size: 14px;
    `}

    ${media.xl`
        width: 110px;
        margin-right: 0;
        font-size: 16px;
    `}
`;

const SizeSection = styled.div`
    width: 100%;
    min-height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 15px 0;

    ${media.xl`
        margin-bottom: 0;
    `}
`;

const RelatedTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;
    margin-right: 10px;

    ${media.md`
        font-size: 14px;
    `}

    ${media.xl`
        width: 110px;
        margin-right: 0;
        font-size: 16px;
    `}
`;

const SectionContainer = styled(Container)`
    ${media.xl`
        padding: 0;
    `}
`;

const RelatedSection = styled.div`
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;

    ${media.xl`
        margin-bottom: 15px;
    `}
`;

const Price = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: #ff7d6a;

    ${media.xl`
        font-size: 32px;
    `}
`;

const PrePrice = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    padding-right: 4px;
    text-decoration: line-through;

    ${media.md`
        font-size: 14px;
        padding-right: 6px;
    `}

    ${media.xl`
        font-size: 20px;
        padding-right: 10px;
    `}
`;

const Model = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #9d9a9a;
    padding-bottom: 6px;

    ${media.md`
        font-size: 14px;
    `}

    ${media.xl`
        padding-bottom: 4px;
    `}
`;

const ProductName = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${media.xl`
        padding-bottom: 4px;
    `}
`;

const BrandName = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
    `}

    ${media.xl`
        font-size: 24px;
        padding-bottom: 4px;
    `}
`;

const ProductImg = styled.img`
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 5px;

    ${media.md`
        width: 90px;
        height: 90px;
        margin-right: 15px;
    `}

    ${media.xl`
        width: 442px;
        height: 508px;
        margin-right: 30px;
        border-radius: 0;
        margin-top: 0;
    `}
`;

const ProductInfo = styled.div`
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 10px;

    ${media.xl`
        padding: 0;
        margin-bottom: 15px;
    `}
`;

const CloseButton = styled.button<any>`
    width: 16px;
    height: 16px;
    padding: 0;
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.md`
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px;
    `}

    ${media.xl`
        width: 30px;
        height: 30px;
        top: 30px;
        right: 30px;

        i {
            font-size: 20px;
            color: #282731;
        }
    `}
`;

const LoadingBg = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 21;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Modal = styled(motion.div)<any>`
    width: 100%;
    max-height: 90%;
    border-radius: 10px 10px 0 0;
    background-color: #fafafa;
    position: relative;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    ${media.md`
        width: 460px;
        border-radius: 10px;
        padding: 30px 50px;
    `}

    ${media.xl`
        width: 1040px;
        height: auto;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-radius: 0;
        padding: 20px 0;
        flex-direction: row;
    `}

    ${props => props.isHidden && css`
        display: none;
    `}
`;

const Overlay = styled(motion.div)`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;

    ${media.md`
        align-items: center;
    `}
`;
