import React from 'react';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';
import * as CSS from "csstype";
import {isEmpty} from 'utils/equal';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    title?: string;
    name?: string;
    option: Array<{
        value: number|string;
        text: string;
    }>;
    onChange?: Function;
    value?: string|number;
}

const Select: React.FC<IProps> = ({
    className,
    style,
    title,
    name,
    option,
    onChange = () => {},
    value,
}) => {
    const handleOnChange = (e: any) => {
        if (onChange) {
            onChange(e);
        }
    };

    const activeOption = option ? option.find(row => String(row.value) === String(value)) : undefined;
    const isSelected = !isEmpty(activeOption ?.value);

    return (
        <InputContainer className={className} style={style}>
            <Label>{activeOption ?.text}</Label>
            <SelectDropdown
                name={name}
                value={value}
                onChange={(e: any) => handleOnChange(e)}
            >
                {
                    option.map(row => (
                        <Option key={`option_${row.value}`} value={row.value}>{row.text}</Option>
                    ))
                }
            </SelectDropdown>

            <IconContainer>
                <Icon code="chevron-right" size={13} color="#c3c3c3" rotate={90}/>
            </IconContainer>

            {/* 外框 */}
            <Border isSelected={isSelected}>
                <MovePlaceholder>{title}</MovePlaceholder>
            </Border>
        </InputContainer>
    );
};

export default Select;

const IconContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    ${media.lg`
        right: 20px;
    `}
`;

const Label = styled.span`
    font-size: 14px;
    line-height: 1;
    margin: 0;
    color: #6e6e6e;
    font-weight: bold;
    padding-top: 18px;

    ${media.lg`
        font-size: 17px;
        padding-top: 26px;
    `}
`;

const MovePlaceholder = styled.div`
    font-size: 14px;
    padding: 0 0 0 10px;
    color: #9d9a9a;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s ease;

    ${media.lg`
        font-size: 17px;
        padding: 0 0 0 20px;
    `}
`;

const Title = styled.legend`
    font-size: 14px;
    margin: 0 0 0 5px;
    width: auto;
    height: 1px;
    padding: 0;
    position: relative;

    &:before, &:after {
        content: '';
        display: block;
        width: 51%;
        height: 1px;
        background-color: #8d8d8d;
        position: absolute;
        top: 0;
        z-index: 1;
        transition: width .2s ease;
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }

    >span {
        opacity: 0;
    }

    ${media.lg`
        font-size: 14px;
        margin: 0 0 0 15px;
    `}
`;

const Border = styled.fieldset<any>`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    border: solid 1px #9d9a9a;
    border-radius: 5px;
    pointer-events: none;

    ${props => props.isSelected && css`
        ${Title} {
            padding: 0 5px;
            &:before, &:after {
                width: 0;
                opacity: 0;
                transition: all .2s ease;
            }
        }

        ${MovePlaceholder} {
            font-size: 12px;
            top: 12px;
        }

        ${media.lg`
            ${Title} {
                padding: 0 5px;
            }

            ${MovePlaceholder} {
                font-size: 14px;
                top: 17px;
            }
        `}
    `}
`;

const Option = styled.option``;

const SelectDropdown = styled.select<any>`
    height: 100%;
    width: 100%;
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;

    &:focus~${Border} {
        border: solid 1px ${props => props.theme.thirdColor};

        ${MovePlaceholder} {
            color: ${props => props.theme.thirdColor};
        }
    }
`;

const InputContainer = styled.div`
    width: 100%;
    height: 44px;
    padding-left: 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    ${media.lg`
        height: 56px;
        padding-left: 20px;
    `}
`;
