// Libraries & Utils
import {all, call, delay, put, takeLatest} from 'redux-saga/effects';
import {IPickAction} from 'library/redux';
import {fetchDataDelayMinMs} from 'config/app';

// Reducers & Types
import {Actions, ActionTypes} from './reducer';
import {Actions as UiDialogActions} from 'store/uiDialog';
import {Actions as UiBlockActions} from 'store/uiBlock';
import {IActionCreators} from './types';

// Services
import SystemApi, {TGetSetting, TGetBank, TShippingGuide, TSettingFaq, THotKeyword} from 'services/system';


/**
 * 取得系統設定
 */
function* fetchSetting(action: IPickAction<IActionCreators, 'fetchSetting'>) {
    yield put(Actions.fetchSettingBegin());
    yield put(UiBlockActions.visible({message: '正在讀取網站設定'}));

    try{
        const {body}: TGetSetting = yield call(SystemApi.getSetting);
        const responseData  = body.data;

        const settings = {
            company: responseData.company,
        };

        yield put(Actions.fetchSettingSuccess(settings));
    } catch(err){
        yield put(Actions.fetchSettingFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

/**
 * 取得匯款銀行清單
 * @param action
 */
function* fetchDepositBank(action: IPickAction<IActionCreators, 'fetchDepositBank'>) {
    yield put(Actions.fetchDepositBankBegin());
    yield put(UiBlockActions.visible({message: '正在讀取匯款銀行清單'}));

    try{
        const [{body}]: [TGetBank] = yield all([
            call(SystemApi.getDepositBank),
            delay(fetchDataDelayMinMs),
        ]);
        const responseData  = body.data;

        const bankData = {
            depositBank: responseData.rows
        };

        yield put(Actions.fetchDepositBankSuccess(bankData));
    } catch(err){
        yield put(Actions.fetchDepositBankFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    } finally {
        yield put(UiBlockActions.hidden());
    }
}

/**
 * 取得購物說明設定
 * @param action
 */
function* fetchGuide(action: IPickAction<IActionCreators, 'fetchGuide'>) {
    yield put(Actions.fetchGuideBegin());

    try{
        const [{body}]: [TShippingGuide] = yield all([
            call(SystemApi.getShippingGuide),
            delay(fetchDataDelayMinMs),
        ]);
        const {rows} = body.data;

        yield put(Actions.fetchGuideSuccess({guideDate: rows}));
    } catch(err){
        yield put(Actions.fetchGuideFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得常見問題
 * @param action
 */
function* fetchFaq(action: IPickAction<IActionCreators, 'fetchFaq'>) {
    yield put(Actions.fetchFaqBegin());

    try{
        const [{body}]: [TSettingFaq] = yield all([
            call(SystemApi.getSettingFaq),
            delay(fetchDataDelayMinMs),
        ]);
        const {rows} = body.data;

        yield put(Actions.fetchFaqSuccess({faqDate: rows}));
    } catch(err){
        yield put(Actions.fetchFaqFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

/**
 * 取得商品熱搜關鍵字
 * @param action
 */
function* fetchHotKeyword(action: IPickAction<IActionCreators, 'fetchHotKeyword'>) {
    yield put(Actions.fetchHotKeywordBegin());

    try{
        const [{body}]: [THotKeyword] = yield all([
            call(SystemApi.getHotKeyword),
            delay(fetchDataDelayMinMs),
        ]);
        const {rows} = body.data;

        yield put(Actions.fetchHotKeywordSuccess({hotKeywords: rows}));
    } catch(err){
        yield put(Actions.fetchHotKeywordFail());
        yield put(UiDialogActions.openError({message: err.message, code: err.code}));
    }
}

export default [
    takeLatest(ActionTypes.FETCH_SETTING, fetchSetting),
    takeLatest(ActionTypes.FETCH_DEPOSIT_BANK, fetchDepositBank),
    takeLatest(ActionTypes.FETCH_GUIDE, fetchGuide),
    takeLatest(ActionTypes.FETCH_FAQ, fetchFaq),
    takeLatest(ActionTypes.FETCH_HOT_KEYWORD, fetchHotKeyword),
];
