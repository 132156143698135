import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, media} from 'library/styled-bs-grid';
import {asset} from 'config/app';
import {useDispatch, useSelector} from 'react-redux';
import {Actions as SystemAction} from 'store/system';
import {HalfCircleSpinner} from 'react-epic-spinners';
import get from 'lodash/get';
import ReactHtmlParser from 'react-html-parser';

// Component
import Collapsible from 'components/atoms/Collapsible';
import CollapsibleItem from 'components/atoms/Collapsible/CollapsibleItem';


const Guide: React.FC = () => {
    const dispatch = useDispatch();

    const {guideDate, isFetching} = useSelector(state => ({
        isFetching: state.system.isFetching,
        guideDate: state.system.guideDate,
    }));

    useEffect(() => {
        if (get(guideDate, 'length', 0) === 0) {
            dispatch(SystemAction.fetchGuide());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GuideRoot>
            <GuideBg
                mobileUrl={asset('/images/guide/guide-mobile.jpg')}
                pcUrl={asset('/images/guide/guide-pc.jpg')}
            >
                <Content>
                    <Title>購物說明</Title>
                    <Subtitle>韓爸所代批的商品皆來自韓國南大門童裝批發市場，請您下單前必須詳閱購物需知。</Subtitle>
                </Content>
            </GuideBg>

            {isFetching && (
                <Row>
                    <Col col>
                        <HalfCircleSpinner style={{margin: '40px auto'}} size={30} color="#bdbdbd"/>
                    </Col>
                </Row>
            )}

            <GuideContainer>
                <Row>
                    {guideDate.map(row => (
                        <Col col={24} key={row.id}>
                            <Collapsible
                                title={row.title}
                                content={ReactHtmlParser(row.content.replaceAll("\n", "<br/>"))}
                            >
                                {row.details.map(item => (
                                    <CollapsibleItem
                                        key={item.id}
                                        title={item.title}
                                        iconPath={item.iconPath}
                                        content={ReactHtmlParser(item.content.replaceAll("\n", "<br/>"))}
                                    />
                                ))}
                            </Collapsible>
                        </Col>
                    ))}
                </Row>
            </GuideContainer>
        </GuideRoot>
    );
};

export default Guide;

const GuideContainer = styled(Container)`
    ${media.lg`
        max-width: 840px;
        margin-bottom: 30px;
    `}

    ${media.xl`
        margin-bottom: 70px;
    `}
`;

const Subtitle = styled.div`
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    padding: 0 25px;

    ${media.md`
        font-size: 14px;
        width: 380px;
    `}

    ${media.xl`
        font-size: 16px;
        width: 420px;
    `}
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    margin-top: 22px;
    padding-bottom: 2px;

    ${media.md`
        font-size: 40px;
        margin-top: 50px;
        padding-bottom: 5px;
    `}

    ${media.lg`
        margin-top: 0;
    `}

    ${media.xl`
        font-size: 50px;
        padding-bottom: 0;
    `}

    ${media.xxl`
        font-size: 60px;
    `}
`;

const Content = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.lg`
        justify-content: center;
    `}
`;

const GuideBg = styled.div<any>`
    background-image: url("${props => props.mobileUrl}");
    padding-bottom: 47%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    position: relative;

    ${media.lg`
        background-image: url("${(props: any) => props.pcUrl}");
        padding-bottom: 31%;
        margin-bottom: 50px;
    `}
`;

const GuideRoot = styled.div``;
