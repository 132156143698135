/**
 * 熱門選項
 */
export const getPopularOptions = (isOrderVipGoods: boolean, isOrderPreSale: boolean) => {
    const popularOptions = [
        {code: '', name: '選擇特性'},
        {code: 6, name: '今日特價', isRed: true},
        {code: 7, name: '今日新品', isRed: true, isVisibleLine: true},
        {code: 0, name: '熱門'},
        {code: 1, name: '現貨'},
        {code: 2, name: '新品'},
        {code: 3, name: '特價'},
    ];

    // 隱藏 Vip
    if (isOrderVipGoods) {
        popularOptions.push(
            {code: 4, name: ''}
        );
    }

    if (isOrderPreSale) {
        popularOptions.push(
            {code: 5, name: '預先下特'}
        );
    }

    return popularOptions;
};
