import React from 'react';
import * as CSS from 'csstype';
import {Controller, useForm} from 'react-hook-form';
import styled from 'styled-components';
import {media} from 'library/styled-bs-grid';
import {asset} from 'config/app';
import {motion, AnimatePresence} from 'framer-motion'
import {useSelector} from 'react-redux';
import {getOs} from 'utils/browser';

// Component
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import ScrollView from 'components/atoms/ScrollView';

interface IProps {
    className?: string;
    style?: CSS.Properties;
    onClose?: Function;
    onClickLogin?: Function;
    onClickSignUp?: Function;
    adImageUrl: string;
    transitionTiming?: number;
}

/**
 * LoginModal
 * @param props
 * @returns {*}
 * @constructor
 */
const LoginModal: React.FC<IProps> = ({
    className,
    style,
    onClose = () => {},
    onClickLogin = () => {},
    onClickSignUp = () => {},
    adImageUrl,
}) => {
    const {handleSubmit, control}: any = useForm();
    const {isVisible} = useSelector(state => ({
        isVisible: state.auth.isVisibleLoginModal,
    }));

    const modalVariant = {
        initial: {opacity: 0, transition: {type:'spring'}},
        isOpen: {opacity: 1},
        exit: {opacity: 0}
    };

    // 手機版
    let containerVariant: any = {
        initial: {top: '100%'},
        isOpen: {top: 'unset', bottom: '0'},
        exit: {top: '100%'}
    };
    // 電腦或平板
    if (getOs().isPc || getOs().isTablet) {
        containerVariant = {
            initial: {transform: 'scale(0)'},
            isOpen: {transform: 'scale(1)'},
            exit: {transform: 'scale(0)'},
        };
    }

    /**
     * 送出
     */
    const onSubmit = (formData: {account: string, password: string}) => {
        onClickLogin(formData);
    };

    return (
        <AnimatePresence>
            {isVisible && (
                <Overlay
                    className={className}
                    style={style}
                    initial={'initial'}
                    animate={'isOpen'}
                    exit={'exit'}
                    variants={modalVariant}
                >
                    <ScrollView className="d-flex justify-content-md-center">
                        <form onSubmit={handleSubmit(onSubmit)} className="w-100 d-flex align-items-end align-items-md-center justify-content-md-center">
                            <Modal
                                variants={containerVariant}
                                transition={{type: "spring", duration: 0.5}}
                            >
                                <AdImg className="d-none d-xl-block" src={adImageUrl}/>
                                <Content className="d-flex flex-column align-items-center justify-content-center">
                                    <CloseButton type="button" onClick={onClose}>
                                        <Icon code="times" color="#ebebeb" size={15}/>
                                    </CloseButton>

                                    <Logo src={asset('/images/home/header-logo.png')}/>
                                    <Welcome>歡迎回來！</Welcome>

                                    <Controller
                                        control={control}
                                        name="account"
                                        rules={{required: true}}
                                        as={<PasswordInput
                                            placeholder="帳號"
                                        />}
                                    />

                                    <Controller
                                        control={control}
                                        name="password"
                                        rules={{required: true}}
                                        as={<PasswordInput
                                            type="password"
                                            placeholder="密碼"
                                        />}
                                    />

                                    <ForgetPassword>忘記密碼？</ForgetPassword>
                                    <ForgetPasswordDesc>若要查詢帳號密碼，請務必以「電話 or LINE：r79789」向本公司客服人員聯絡。</ForgetPasswordDesc>
                                    <LoginButton
                                        themeType="purple"
                                        type="submit"
                                    >
                                        登入
                                    </LoginButton>

                                    <div className="w-100 d-flex justify-content-center align-items-center">
                                        <Line/>
                                        <Or>OR</Or>
                                        <Line/>
                                    </div>

                                    <SignButton
                                        themeType="darkGrayBorderFill"
                                        onClick={onClickSignUp}
                                    >
                                        加入會員
                                    </SignButton>
                                </Content>
                            </Modal>
                        </form>
                    </ScrollView>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default LoginModal;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
`;

const Or = styled.div`
    min-width: 50px;
    font-size: 12px;
    color: #9d9a9a;
    margin: 20px 0;
    text-align: center;
`;

const SignButton = styled(Button)`
    width: 100%;
    height: 44px;
    min-height: auto;

    ${media.xl`
        height: 56px;
    `}
`;

const LoginButton = styled(Button)`
    width: 100%;
    height: 44px;
    min-height: auto;

    ${media.xl`
        height: 56px;
    `}
`;

const ForgetPasswordDesc = styled.div`
    font-size: 12px;
    color: #9d9a9a;
    margin-bottom: 40px;
    align-self: flex-start;

    ${media.md`
        margin-bottom: 20px;
    `}

    ${media.xl`
        margin-bottom: 55px;
    `}
`;

const ForgetPassword = styled.div`
    font-size: 12px;
    color: #9d9a9a;
    margin-bottom: 4px;
    align-self: flex-start;

    ${media.md`
        margin-bottom: 6px;
    `}

    ${media.xl`
        font-size: 16px;
        margin-bottom: 5px;
    `}
`;

const PasswordInput = styled(Input)`
    margin-bottom: 20px;

    ${media.md`
        margin-bottom: 22px;
    `}

    ${media.xl`
        margin-bottom: 15px;
    `}
`;

const Welcome = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #282732;
    margin-bottom: 30px;

    ${media.md`
        font-size: 20px;
    `}

    ${media.xl`
        margin-bottom: 25px;
    `}
`;

const Logo = styled.img`
    width: 118px;
    height: 29px;
    margin-bottom: 27px;

    ${media.md`
        width: 155px;
        height: 39px;
        margin-bottom: 20px;
    `}

    ${media.xl`
        margin-bottom: 25px;
    `}
`;

const CloseButton = styled.button<any>`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    top: 16px;
    right: 16px;

    ${media.md`
        width: 30px;
        height: 30px;

        i{
            font-size: 25px;
            color: #282731;
        }
    `}

    ${media.xl`
        top: 20px;
        right: 20px;
    `}
`;

const Content = styled.div`
    padding: 0 40px;

    ${media.md`
        padding: 0 100px;
    `}

    ${media.xl`
        min-width: 530px;
        padding: 0 50px;
    `}
`;

const AdImg = styled.img`
    width: 310px;
    height: 100%;
`;

const Modal = styled(motion.div)`
    width: 100%;
    height: 548px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    ${media.md`
        width: 500px;
        height: 600px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
        border-radius: 0;
    `}

    ${media.xl`
        width: 840px;
        height: 621px;
    `}
`;

const Overlay = styled(motion.div)`
    align-items: flex-end;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;

    ${media.md`
        align-items: center;
    `}
`;
