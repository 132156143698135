import React, {useState, useEffect, useRef, useCallback} from 'react';
import styled, {css} from 'styled-components';
import site from 'config/site';
import {Container, media} from 'library/styled-bs-grid';
import {get} from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {isEmpty} from 'utils/equal';
import {formatCurrency} from 'utils/number';
import {checkIsMobile} from 'utils/browser';
import {asset} from 'config/app';
import {motion, AnimatePresence} from 'framer-motion'
import {disableBodyScroll, enableBodyScroll} from 'library/body-scroll-lock';

// Component
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Size from 'components/molecules/Size';
import Tabs from 'components/atoms/Tabs';
import PageScrollView from 'components/atoms/PageScrollView';
import RelatedModal from 'components/organisms/SpeedOrderModal/RelatedModal';
import BatchModal from 'components/organisms/SpeedOrderModal/BatchModal';
import PickModal from 'components/organisms/SpeedOrderModal/PickModal';
import SizeDescModal from 'components/organisms/SpeedOrderModal/SizeDescModal';
import Tag from 'components/atoms/Tag';
// import ProductCarousel from 'components/organisms/ProductCarousel';

// Reducer
import {Actions as ProductAction} from 'store/product';
import {Actions as CartAction} from 'store/cart';
import {Actions as PreSaleAction} from 'store/preSale';
import {Actions as TrackAction} from 'store/track';
import {Selector as AuthSelector} from 'store/auth';

const tabList = [
    {code: 0, name: '商品規格'},
    {code: 1, name: '商品說明'},
    {code: 2, name: '注意事項'},
];

/**
 * 商品詳情
 * @constructor
 */
const Detail: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();

    const imageRef = useRef<Array<HTMLImageElement>>([]);
    const imagePcRef = useRef<Array<HTMLImageElement>>([]);

    const batchExpandRef = useRef<HTMLElement>(null);
    const inStockExpandRef = useRef<HTMLElement>(null);
    const pickExpandRef = useRef<HTMLElement>(null);

    const {productDetail, relatedProducts, productDesc, shoppingCartCount, isOrderNormalGoods, payload} = useSelector(state => ({
        productDetail: state.product.productDetail,
        relatedProducts: state.product.relatedProducts,
        productDesc: state.product.productDesc,
        shoppingCartCount: state.profile.shoppingCartCount,
        isOrderNormalGoods: state.profile.isOrderNormalGoods,
        payload: AuthSelector.payload(state),
    }));


    useEffect(() => {
        // 取得產品明細
        dispatch(ProductAction.fetchProductDetail({id}));

        // 取相關產品
        dispatch(ProductAction.fetchRelatedProducts({id}));

        // 取產品說明
        if (isEmpty(get(productDesc, 'desc', null))) {
            dispatch(ProductAction.fetchProductDesc({productGoodsId: id}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [activeTabs, setActiveTabs] = useState(0);
    // 手機版相關圖片光箱
    const [isVisibleRelated, setVisibleRelated] = useState<boolean>(false);
    // 手機版整手下單光箱
    const [isVisibleBatch, setVisibleBatch] = useState<boolean>(false);
    // 手機版挑碼下單光箱
    const [isVisiblePick, setVisiblePick] = useState<boolean>(false);
    // 手機版「現貨」「挑碼」共用同一個光箱，判斷是否為現貨
    const [isInStock, setInStock] = useState<boolean>(false);
    const [isVisibleSizeDesc, setVisibleSizeDesc] = useState<boolean>(false);

    // 電腦版整手下單下拉
    const [isVisiblePcBatch, setVisiblePcBatch] = useState<boolean>(false);
    // 電腦版現貨下單下拉
    const [isVisiblePcInStock, setVisiblePcInStock] = useState<boolean>(false);
    // 電腦版挑碼下單下拉
    const [isVisiblePcPick, setVisiblePcPick] = useState<boolean>(false);


    // 現貨下單尺寸與件數
    const [inStockSizeQty, setInStockSizeQty] = useState<Array<{size: string, qty: number}>>([]);
    // 挑碼下單尺寸與件數
    const [pickSizeQty, setPickSizeQty] = useState<Array<{size: string, qty: number}>>([]);


    // 整手下單總數量
    const [batchQty, setBatchQty] = useState<number>(0);
    // 現貨下單總件數
    const inStockTotalQty = inStockSizeQty.reduce((accum,item) => accum + item.qty, 0);
    // 挑碼下單總件數
    const pickTotalQty = pickSizeQty.reduce((accum,item) => accum + item.qty, 0);


    useEffect(() => {
        if(isVisibleRelated || isVisibleBatch || isVisiblePick || isVisibleSizeDesc){
            // 鎖背景
            disableBodyScroll();
            return () => {
                enableBodyScroll();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisibleRelated, isVisibleBatch, isVisiblePick, isVisibleSizeDesc]);


    /**
     *  計算現貨下單各尺寸件數
     */
    const handleCountInStockQty = (sizeText: string, type: string, qty?: number) => {
        let cloneData = [...inStockSizeQty];
        const currentIndex = cloneData.findIndex(row => row.size === sizeText);
        const index = currentIndex === -1 ? get(inStockSizeQty, 'length', 0) : currentIndex;

        if (type === 'add') {
            cloneData[index] = {
                size: inStockSizeQty[currentIndex] ? inStockSizeQty[currentIndex].size : sizeText,
                qty: inStockSizeQty[currentIndex] ? Number(inStockSizeQty[currentIndex].qty) + 1 : 1,
            };
        } else if (type === 'minus') {
            if (inStockSizeQty[currentIndex] &&  Number(inStockSizeQty[currentIndex].qty) - 1 === 0) {
                cloneData.splice(index, 1);
            } else {
                cloneData[index] = {
                    size: inStockSizeQty[currentIndex] ? inStockSizeQty[currentIndex].size : sizeText,
                    qty: inStockSizeQty[currentIndex] ? Number(inStockSizeQty[currentIndex].qty) - 1 : 0,
                };
            }
        } else {
            cloneData[index] = {
                size: inStockSizeQty[currentIndex] ? inStockSizeQty[currentIndex].size : sizeText,
                qty: Number(qty),
            };
        }

        setInStockSizeQty(cloneData);
    };

    /**
     *  計算挑碼下單各尺寸件數
     */
    const handleCountPickQty = (sizeText: string, type: string, qty?: number) => {
        let cloneData = [...pickSizeQty];
        const currentIndex = cloneData.findIndex(row => row.size === sizeText);
        const index = currentIndex === -1 ? get(pickSizeQty, 'length', 0) : currentIndex;

        if (type === 'add') {
            cloneData[index] = {
                size: pickSizeQty[currentIndex] ? pickSizeQty[currentIndex].size : sizeText,
                qty: pickSizeQty[currentIndex] ? Number(pickSizeQty[currentIndex].qty) + 1 : 1,
            };
        } else if (type === 'minus') {
            if (pickSizeQty[currentIndex] && Number(pickSizeQty[currentIndex].qty) - 1 === 0) {
                cloneData.splice(index, 1);
            } else {
                cloneData[index] = {
                    size: pickSizeQty[currentIndex] ? pickSizeQty[currentIndex].size : sizeText,
                    qty: pickSizeQty[currentIndex] ? Number(pickSizeQty[currentIndex].qty) - 1 : 0,
                };
            }
        } else {
            cloneData[index] = {
                size: pickSizeQty[currentIndex] ? pickSizeQty[currentIndex].size : sizeText,
                qty: Number(qty),
            };
        }

        setPickSizeQty(cloneData);
    };


    /**
     * 計算總金額
     */
    const countTotalPrice = () => {
        let price = 0;
        if (isVisiblePcBatch) {
            price = batchQty * get(productDetail, 'batchPrice', 0);
        } else if (isVisiblePcInStock) {
            price = get(productDetail, 'price', 0) * inStockTotalQty;
        } else if (isVisiblePcPick) {
            price = get(productDetail, 'price', 0) * pickTotalQty;
        }

        return `加入購物袋 NT$ ${formatCurrency(price)}`;
    };

    /**
     * 加入購物車
     */
    const handleAddCart = () => {
        if (isVisiblePcBatch) {
            // 整手下單
            dispatch(CartAction.submitCartBatch({productGoodsId: id, batchQty: batchQty}));
        } else if (isVisiblePcInStock) {
            // 現貨下單
            dispatch(CartAction.submitCartPick({productGoodsId: id, sizeQty: JSON.stringify(inStockSizeQty), isInStock: true}));
        } else if (isVisiblePcPick) {
            // 挑碼下單
            dispatch(CartAction.submitCartPick({productGoodsId: id, sizeQty: JSON.stringify(pickSizeQty), isInStock: false}));
        }
    };

    /**
     * 加入預先下特
     */
    const handleAddPreSale = () => {
        if (isVisiblePcBatch) {
            // 整手下單
            dispatch(PreSaleAction.submitPreSaleBatch({productGoodsId: id, batchQty: batchQty}));
        } else if (isVisiblePcInStock) {
            // 現貨下單
            dispatch(PreSaleAction.submitPreSalePick({productGoodsId: id, sizeQty: JSON.stringify(inStockSizeQty), isInStock: true}));
        } else if (isVisiblePcPick) {
            // 挑碼下單
            dispatch(PreSaleAction.submitPreSalePick({productGoodsId: id, sizeQty: JSON.stringify(pickSizeQty), isInStock: false}));
        }
    };

    /**
     * 檢查送出按鈕
     */
    const checkIsDisabled = () => {
        if (isVisiblePcBatch && batchQty > 0) {
            return false;
        } else if (isVisiblePcInStock && inStockTotalQty > 0) {
            return false;
        } else  if (isVisiblePcPick && pickTotalQty > 0) {
            return false;
        }

        return true;
    };

    const getHeight = useCallback((expandRef) => {
        return expandRef?.current?.scrollHeight;
    }, []);

    // 隱藏圖片
    const handleHiddenImage = (i: number, ref: any) => {
        ref.current[i].style.display = "none";
    };

    return (
        <>
            <DetailRoot>
                <DetailContainer>
                    <Title className="d-none d-md-block">{`Home / ${get(productDetail, 'categoryGroup.name')} / ${get(productDetail, 'categoryMain.name')} / ${get(productDetail, 'name')}`}</Title>
                </DetailContainer>

                <div className="d-xl-flex">
                    <InfoSection>
                        {/* 手機版商品圖片（第一張）*/}
                        <div className="position-relative d-md-flex justify-content-center d-xl-none">
                            <ProductBg
                                src={isEmpty(get(productDetail, 'images.0.url', '')) ? asset('/images/goods/default.jpg') : get(productDetail, 'images.0.url', '')}
                                onError={(e: any) => e.target.src = asset('/images/goods/default.jpg')}
                            />

                            <BackButton type="button" className="d-md-none" onClick={() => history.push('/product')}>
                                <Icon code="long-arrow-left" size={20} color="#282731"/>
                            </BackButton>

                            <RightSection className="d-md-none">
                                <IconButton
                                    type="button"
                                    onClick={() => dispatch(TrackAction.putTrackProduct({productGoodsId: id, isTrack: !get(productDetail, 'isTrack', false), callBack: () => dispatch(ProductAction.fetchProductDetail({id}))}))}
                                >
                                    <Icon code={productDetail.isTrack ? 'heart-solid' : 'heart'} color={productDetail.isTrack ? '#ff7d6a' : '#282731'} size={20}/>
                                </IconButton>

                                <BagButton
                                    type="button"
                                    onClick={() => history.push('/shop/bag')}
                                >
                                    <Icon code="shopping-bag" size={20} color="#282731"/>
                                    <BagNum>{shoppingCartCount}</BagNum>
                                </BagButton>
                            </RightSection>

                            <TagSection>
                                {productDetail.isNew && <CustomTag theme="default" text="New"/>}
                                {productDetail.isInStock && <CustomTag theme="orange" text="現貨"/>}
                                {productDetail.isSale && <CustomTag theme="danger" text="特價"/>}
                                {productDetail.isHot && <CustomTag theme="purple" text="熱賣"/>}
                                {productDetail.isOutStock && <CustomTag theme="black" text="斷貨"/>}
                                {productDetail.isVipGoods && <CustomTag theme="yellow" isStar/>}
                            </TagSection>
                        </div>

                        {/* 商品資訊 */}
                        <ProductInfo>
                            <DetailContainer className="d-flex justify-content-between flex-md-column justify-content-md-start">
                                <div className="d-flex flex-column">
                                    <BrandSection>
                                        <div className="d-none d-md-flex align-items-center">
                                            {productDetail.isNew && <CustomTag theme="default" text="New"/>}
                                            {productDetail.isInStock && <CustomTag theme="orange" text="現貨"/>}
                                            {productDetail.isSale && <CustomTag theme="danger" text="特價"/>}
                                            {productDetail.isHot && <CustomTag theme="purple" text="熱賣"/>}
                                            {productDetail.isOutStock && <CustomTag theme="black" text="斷貨"/>}
                                            {productDetail.isVipGoods && <CustomTag theme="yellow" isStar/>}
                                        </div>
                                        <BrandName onClick={() => history.push(`/product?brands=[${productDetail?.brandId}]`)}>{get(productDetail, 'brandName')}</BrandName>
                                    </BrandSection>
                                    <Name>{get(productDetail, 'name')}</Name>
                                    <Model>商品貨號：{get(productDetail, 'model')}</Model>
                                </div>

                                <div className="d-flex flex-column align-items-end justify-content-between align-items-md-start justify-content-md-start">
                                    {isOrderNormalGoods && (
                                        <PriceSection className="d-md-flex align-items-md-end">
                                            {/* [特價] 且 [前次售價金額不為0] 且 [前次售價金額不等於特價金額] */}
                                            {(productDetail.isSale && productDetail.prePrice > 0 && productDetail.prePrice !== productDetail.price) && <PrePrice>NT$ {productDetail.prePrice}</PrePrice>}
                                            <Price isSale={productDetail.isSale}>NT$ {productDetail.price}</Price>

                                            <PriceDesc isExtraFare={get(productDetail, 'isExtraFare', false)}>
                                                {get(productDetail, 'isExtraFare', false) ? '（含國際運費及關稅）' : '（不含國際運費及關稅）'}
                                            </PriceDesc>
                                        </PriceSection>
                                    )}

                                    <SizeDesc
                                        className="d-flex align-items-center d-md-none"
                                        onClick={() => setVisibleSizeDesc(true)}
                                    >
                                        <SizeIcon code="ruler-horizontal" size={15} color="#9d9a9a"/>
                                        <SizeDirections>尺寸說明</SizeDirections>
                                    </SizeDesc>
                                </div>
                            </DetailContainer>
                        </ProductInfo>

                        {/* 相關產品 */}
                        <RelatedSection>
                            <DetailContainer className="d-flex align-items-center h-100">
                                <RelatedTitle>相關產品：</RelatedTitle>

                                {/* 手機版相關產品圖片 */}
                                <div
                                    className="d-flex d-md-none flex-grow-1"
                                    onClick={() => setVisibleRelated(true)}
                                >
                                    <div className="d-flex flex-grow-1">
                                        {relatedProducts && relatedProducts.map(row => (
                                            <button key={row.id} style={{padding: 0}} onClick={() => checkIsMobile() ? {} : window.open(`/product/detail/${payload?.id}/${row.id}`)}>
                                                <RelatedImg src={isEmpty(row.thumbUrl) ? asset('/images/goods/default.jpg') : row.thumbUrl}/>
                                            </button>
                                        ))}
                                    </div>

                                    <RightButton>
                                        <Icon code="chevron-right" color="#9d9a9a" size={10}/>
                                    </RightButton>
                                </div>

                                {/* 電腦版相關產品圖片 */}
                                <div className="d-none d-md-flex flex-grow-1">
                                    {relatedProducts && relatedProducts.map(row => (
                                        <button key={row.id} style={{padding: 0}} onClick={() => checkIsMobile() ? {} : window.open(`/product/detail/${payload?.id}/${row.id}`)}>
                                            <RelatedImg src={isEmpty(row.thumbUrl) ? asset('/images/goods/default.jpg') : row.thumbUrl}/>
                                        </button>
                                    ))}
                                </div>

                            </DetailContainer>
                        </RelatedSection>

                        {/* 顏色 */}
                        {!isEmpty(productDetail.color) && (
                            <SizeSection>
                                <DetailContainer className="d-flex align-items-center h-100">
                                    <SizeTitle>Color：</SizeTitle>
                                    <Color>{productDetail.color}</Color>
                                </DetailContainer>
                            </SizeSection>
                        )}

                        {/* 材質 */}
                        {!isEmpty(productDetail.material) && (
                            <SizeSection>
                                <DetailContainer className="d-flex align-items-center h-100">
                                    <SizeTitle>材質：</SizeTitle>
                                    <Color>{productDetail.material}</Color>
                                </DetailContainer>
                            </SizeSection>
                        )}

                        {/* Size */}
                        <SizeSection>
                            <DetailContainer className="d-flex align-items-center h-100">
                                <SizeTitle>SIZES：</SizeTitle>

                                <div className="d-flex flex-wrap flex-grow-1">
                                    {get(productDetail, 'sizes').map(row => (
                                        <CustomSize
                                            key={row.size}
                                            isInStock={row.isInStock}
                                            text={row.size.toUpperCase()}
                                        />
                                    ))}
                                </div>

                                <div className="d-none d-md-flex align-items-center justify-content-end">
                                    <SizeDesc
                                        className="d-flex align-items-center"
                                        onClick={() => setVisibleSizeDesc(true)}
                                    >
                                        <SizeIcon code="ruler-horizontal" size={15} color="#9d9a9a"/>
                                        <SizeDirections>尺寸說明</SizeDirections>
                                    </SizeDesc>
                                </div>
                            </DetailContainer>
                        </SizeSection>

                        {/* 整手下單 */}
                        {(!productDetail.isOutStock && isOrderNormalGoods) && (
                            <HandOrder>
                                <DetailContainer className="d-flex align-items-center h-100 align-items-md-start">
                                    <HandOrderTitle>整手下單：</HandOrderTitle>

                                    {/* 手機版整手下單 */}
                                    <div className="w-100 d-flex d-md-none align-items-center">
                                        <div className="d-flex align-items-center flex-grow-1">
                                            <HandOrderButton
                                                type="button"
                                                disabled={batchQty === 0}
                                                onClick={() => {
                                                    setBatchQty(batchQty - 1);
                                                    setVisiblePcBatch(true);
                                                    setVisiblePcInStock(false);
                                                    setVisiblePcPick(false);
                                                }}
                                            >
                                                <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                            </HandOrderButton>

                                            <HandOrderNum>{batchQty}</HandOrderNum>

                                            <HandOrderButton
                                                type="button"
                                                onClick={() => {
                                                    setBatchQty(batchQty + 1);
                                                    setVisiblePcBatch(true);
                                                    setVisiblePcInStock(false);
                                                    setVisiblePcPick(false);
                                                }}
                                            >
                                                <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                            </HandOrderButton>
                                        </div>

                                        <ShowNum
                                            className="d-md-none"
                                            isActive={isVisiblePcBatch && batchQty > 0}
                                        >
                                            {`${batchQty}(手)`}
                                        </ShowNum>

                                        <RightButton
                                            className="d-md-none"
                                            onClick={() => {
                                                setVisibleBatch(true);
                                                setVisiblePcBatch(true);
                                                setVisiblePcPick(false);
                                                setVisiblePcInStock(false);
                                            }}
                                        >
                                            <Icon code="chevron-right" color="#9d9a9a" size={10}/>
                                        </RightButton>
                                    </div>

                                    {/* 電腦版整手下單 */}
                                    <OrderDetail className="d-none d-md-flex flex-column flex-grow-1">
                                        <OrderInfo
                                            className="d-flex align-items-center justify-content-between flex-grow-1"
                                            onClick={() => {
                                                setVisiblePcBatch(!isVisiblePcBatch);
                                                setVisiblePcPick(false);
                                                setVisiblePcInStock(false);
                                            }}
                                        >
                                            <OrderTitle>Quantity</OrderTitle>
                                            <ShowNum
                                                isActive={isVisiblePcBatch && batchQty > 0}
                                            >
                                                {`${batchQty}(手)`}
                                            </ShowNum>

                                            <RightButton className="d-flex">
                                                <Icon code="chevron-right" color="#9d9a9a" size={10} rotate={isVisiblePcBatch ? 270 : 90}/>
                                            </RightButton>
                                        </OrderInfo>

                                        <SizeAccordion
                                            ref={batchExpandRef}
                                            style={{height: isVisiblePcBatch ? `${getHeight(batchExpandRef)}px` : '0px'}}
                                        >
                                            <AccordionContent className="d-flex align-items-center" style={{paddingBottom: 13}}>
                                                <div className="d-flex align-items-center flex-grow-1">
                                                    <HandOrderButton
                                                        type="button"
                                                        disabled={batchQty === 0}
                                                        onClick={() => setBatchQty(batchQty - 1)}
                                                    >
                                                        <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                                    </HandOrderButton>

                                                    <HandOrderInput
                                                        name={batchQty}
                                                        value={batchQty}
                                                        defaultValue={0}
                                                        onChange={(events: any) => {
                                                            if (!isEmpty(events.target.value) && !/^\d+$/.test(events.target.value)) {
                                                                alert('請輸入數字');
                                                            } else {
                                                                setBatchQty(Number(events.target.value));
                                                            }
                                                        }}
                                                    />

                                                    <HandOrderButton
                                                        type="button"
                                                        onClick={() => setBatchQty(batchQty + 1)}
                                                    >
                                                        <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                                    </HandOrderButton>
                                                </div>

                                                <ShowNum>{`${get(productDetail, 'batchUnitQty', 0)}(件) / NT$ ${get(productDetail, 'batchPrice')} 元`}</ShowNum>

                                            </AccordionContent>
                                        </SizeAccordion>
                                    </OrderDetail>


                                </DetailContainer>
                            </HandOrder>
                        )}

                        {/* 現貨下單 */}
                        {(isOrderNormalGoods && get(productDetail, 'inStock.length', 0) > 0) && (
                            <InStock>
                                <DetailContainer className="d-flex align-items-center h-100 align-items-md-start">
                                    <InStockTitle>現貨下單：</InStockTitle>

                                    <OrderDetail className="d-flex flex-column flex-grow-1">

                                        {/* 手機版點擊彈出現貨下單光箱 */}
                                        <OrderInfo
                                            className="d-flex d-md-none align-items-center justify-content-between flex-grow-1"
                                            onClick={() => {
                                                setInStock(true);
                                                setVisiblePick(true);
                                                setVisiblePcBatch(false);
                                                setVisiblePcInStock(true);
                                                setVisiblePcPick(false);
                                            }}
                                        >
                                            <OrderTitle>Size / Quantity</OrderTitle>
                                            <ShowNum
                                                isActive={isVisiblePcInStock && inStockTotalQty > 0}
                                            >
                                                {`${inStockTotalQty}(件)`}
                                            </ShowNum>

                                            <RightButton>
                                                <Icon code="chevron-right" color="#9d9a9a" size={10}/>
                                            </RightButton>
                                        </OrderInfo>

                                        {/* 電腦碼點擊顯示現貨下單下拉 */}
                                        <OrderInfo
                                            className="d-none d-md-flex align-items-center justify-content-between flex-grow-1"
                                            onClick={() => {
                                                setVisiblePcInStock(!isVisiblePcInStock);
                                                setVisiblePcBatch(false);
                                                setVisiblePcPick(false);
                                            }}
                                        >
                                            <OrderTitle>Size / Quantity</OrderTitle>
                                            <ShowNum
                                                isActive={isVisiblePcInStock && inStockTotalQty > 0}
                                            >
                                                {`${inStockTotalQty}(件)`}
                                            </ShowNum>

                                            <RightButton>
                                                <Icon code="chevron-right" color="#9d9a9a" size={10} rotate={isVisiblePcInStock ? 270 : 90}/>
                                            </RightButton>
                                        </OrderInfo>

                                        <SizeAccordion
                                            className="d-none d-md-flex flex-md-column"
                                            ref={inStockExpandRef}
                                            style={{height: isVisiblePcInStock ? `${getHeight(inStockExpandRef)}px` : '0px'}}
                                        >
                                            <AccordionContent>
                                                {productDetail.inStock.map(row => {
                                                    // 現貨下單下單數量不可以超過庫存數量
                                                    const inStockSizeQtyInfo = inStockSizeQty.find(o => o.size === row.size);

                                                    return (
                                                        <OrderSize key={row.id} className="d-flex align-items-center">
                                                            <SizeText>{row.size}</SizeText>

                                                            <div className="w-100 d-flex justify-content-end align-items-center">
                                                                <HandOrderButton
                                                                    type="button"
                                                                    disabled={get(inStockSizeQtyInfo, 'qty', 0) === 0}
                                                                    onClick={() => handleCountInStockQty(row.size, 'minus')}
                                                                >
                                                                    <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                                                </HandOrderButton>

                                                                <HandOrderInput
                                                                    name={row.size}
                                                                    value={get(inStockSizeQtyInfo, 'qty', 0)}
                                                                    defaultValue={0}
                                                                    onChange={(events: any) => {
                                                                        if (!isEmpty(events.target.value) && !/^\d+$/.test(events.target.value)) {
                                                                            alert('請輸入數字');
                                                                        } else {
                                                                            handleCountInStockQty(row.size, 'enter', events.target.value)
                                                                        }
                                                                    }}
                                                                />

                                                                <HandOrderButton
                                                                    type="button"
                                                                    onClick={() => handleCountInStockQty(row.size, 'add')}
                                                                    disabled={Number(get(inStockSizeQtyInfo, 'qty', 0)) === row.qty}
                                                                >
                                                                    <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                                                </HandOrderButton>
                                                            </div>
                                                        </OrderSize>
                                                    );
                                                })}
                                            </AccordionContent>
                                        </SizeAccordion>
                                    </OrderDetail>
                                </DetailContainer>
                            </InStock>
                        )}

                        {/* 挑碼下單 */}
                        {(!productDetail.isOutStock && productDetail.isCanChooseSize && isOrderNormalGoods) && (
                            <InStock>
                                <DetailContainer className="d-flex align-items-center h-100 align-items-md-start">
                                    <InStockTitle>挑碼下單：</InStockTitle>

                                    <OrderDetail className="d-flex flex-column flex-grow-1">

                                        {/* 手機版點擊彈出挑碼下單光箱 */}
                                        <OrderInfo
                                            className="d-flex d-md-none align-items-center justify-content-between flex-grow-1"
                                            onClick={() => {
                                                setInStock(false);
                                                setVisiblePick(true);
                                                setVisiblePcBatch(false);
                                                setVisiblePcInStock(false);
                                                setVisiblePcPick(true);
                                            }}
                                        >
                                            <OrderTitle>Size / Quantity</OrderTitle>
                                            <ShowNum isActive={isVisiblePcPick && pickTotalQty > 0}>{`${pickTotalQty}(件)`}</ShowNum>

                                            <RightButton>
                                                <Icon code="chevron-right" color="#9d9a9a" size={10}/>
                                            </RightButton>
                                        </OrderInfo>

                                        {/* 電腦碼點擊顯示挑碼下單下拉 */}
                                        <OrderInfo
                                            className="d-none d-md-flex align-items-center justify-content-between flex-grow-1"
                                            onClick={() => {
                                                setVisiblePcPick(!isVisiblePcPick);
                                                setVisiblePcBatch(false);
                                                setVisiblePcInStock(false);
                                            }}
                                        >
                                            <OrderTitle>Size / Quantity</OrderTitle>
                                            <ShowNum isActive={isVisiblePcPick && pickTotalQty > 0}>{`${pickTotalQty}(件)`}</ShowNum>

                                            <RightButton>
                                                <Icon code="chevron-right" color="#9d9a9a" size={10} rotate={isVisiblePcPick ? 270 : 90}/>
                                            </RightButton>
                                        </OrderInfo>

                                        <SizeAccordion
                                            className="d-none d-md-flex flex-md-column"
                                            ref={pickExpandRef}
                                            style={{height: isVisiblePcPick ? `${getHeight(pickExpandRef)}px` : '0px'}}
                                        >
                                            <AccordionContent>
                                                {productDetail.sizes.map(row => {
                                                    const pickSizeQtyInfo = pickSizeQty.find(o => o.size === row.size);

                                                    return (
                                                        <OrderSize key={row.size} className="d-flex align-items-center">
                                                            <SizeText>{row.size}</SizeText>

                                                            <div className="w-100 d-flex justify-content-end align-items-center">
                                                                <HandOrderButton
                                                                    type="button"
                                                                    disabled={get(pickSizeQtyInfo, 'qty', 0) === 0}
                                                                    onClick={() => handleCountPickQty(row.size, 'minus')}
                                                                >
                                                                    <Icon code="minus-bold" size={10} color="#9d9a9a"/>
                                                                </HandOrderButton>

                                                                <HandOrderInput
                                                                    name={row.size}
                                                                    value={get(pickSizeQtyInfo, 'qty', 0)}
                                                                    defaultValue={0}
                                                                    onChange={(events: any) => {
                                                                        if (!isEmpty(events.target.value) && !/^\d+$/.test(events.target.value)) {
                                                                            alert('請輸入數字');
                                                                        } else {
                                                                            handleCountPickQty(row.size, 'enter', events.target.value);
                                                                        }
                                                                    }}
                                                                />

                                                                <HandOrderButton
                                                                    type="button"
                                                                    onClick={() => handleCountPickQty(row.size, 'add')}
                                                                >
                                                                    <Icon code="plus-bold" size={10} color="#9d9a9a"/>
                                                                </HandOrderButton>
                                                            </div>
                                                        </OrderSize>
                                                    );
                                                })}
                                            </AccordionContent>
                                        </SizeAccordion>
                                    </OrderDetail>
                                </DetailContainer>
                            </InStock>
                        )}

                        {((!productDetail.isOutStock || get(productDetail, 'inStock.length', 0) > 0) && isOrderNormalGoods) && (
                            <DetailContainer className="d-flex justify-content-center">
                                {/* 預先下特按鈕 */}
                                {get(productDetail, 'isOrderPreSale', false) && (
                                    <PreSaleButton
                                        themeType="danger"
                                        disabled={checkIsDisabled()}
                                        onClick={() => handleAddPreSale()}
                                    >
                                        預先下特
                                    </PreSaleButton>
                                )}

                                {/* 加入購物袋按鈕 */}
                                <SubmitButton
                                    isSingle={!get(productDetail, 'isOrderPreSale', false)}
                                    disabled={checkIsDisabled()}
                                    onClick={() => handleAddCart()}
                                >
                                    {countTotalPrice()}
                                </SubmitButton>

                                <FavoriteButton
                                    className="d-none d-md-flex"
                                    type="button"
                                    onClick={() => dispatch(TrackAction.putTrackProduct({productGoodsId: id, isTrack: !get(productDetail, 'isTrack', false)}))}
                                >
                                    <Icon code={productDetail.isTrack ? 'heart-solid' : 'heart'} color={productDetail.isTrack ? '#ff7d6a' : '#9d9a9a'} size={40}/>
                                </FavoriteButton>
                            </DetailContainer>
                        )}

                        <DetailContainer className="d-xl-none">
                            <DetailTabs
                                item={tabList}
                                activeCode={activeTabs}
                                onChangeIndex={(e: number) => setActiveTabs(e)}
                            />
                            {activeTabs === 0 && <Directions>{ReactHtmlParser(productDetail.desc.replaceAll("\n", "<br/>"))}</Directions>}
                            {activeTabs === 1 && <Directions>{ReactHtmlParser(productDesc.desc.replaceAll("\n", "<br/>"))}</Directions>}
                            {activeTabs === 2 && <Directions>{ReactHtmlParser(productDesc.notes.replaceAll("\n", "<br/>"))}</Directions>}
                        </DetailContainer>
                    </InfoSection>

                    {/* 手機版商品圖片 */}
                    <ImgContainer className="d-xl-none">
                        {productDetail.images.filter((row, index) => (index > 0 && !isEmpty(row.url))).map(row => (
                            <ProductImg
                                ref={(el: any) => imageRef.current[row.index] = el}
                                src={row.url}
                                onError={() => handleHiddenImage(row.index, imageRef)}
                            />
                        ))}
                    </ImgContainer>

                    {/* 電腦版商品圖片 */}
                    <ImgContainer className="d-none d-xl-block">
                        {/* 第一張圖片 */}
                        <ProductImg
                            src={isEmpty(get(productDetail, 'images.0.url')) ? asset('/images/goods/default.jpg') : get(productDetail, 'images.0.url')}
                            onError={(e: any) => e.target.src = asset('/images/goods/default.jpg')}
                        />
                    </ImgContainer>
                </div>

                <div className="d-none d-xl-block">
                    <DetailContainer>
                        <DetailTabs
                            item={tabList}
                            activeCode={activeTabs}
                            onChangeIndex={(e: number) => setActiveTabs(e)}
                        />
                        {activeTabs === 0 && <Directions>{ReactHtmlParser(productDetail.desc.replaceAll("\n", "<br/>"))}</Directions>}
                        {activeTabs === 1 && <Directions>{ReactHtmlParser(productDesc.desc.replaceAll("\n", "<br/>"))}</Directions>}
                        {activeTabs === 2 && <Directions>{ReactHtmlParser(productDesc.notes.replaceAll("\n", "<br/>"))}</Directions>}
                    </DetailContainer>

                    {/* 電腦版商品圖片 */}
                    <ImgContainer className="d-none d-xl-flex flex-column align-items-center">
                        {productDetail.images.filter((row, index) => index > 0 && !isEmpty(row.url)).map(row => (
                            <ProductImg
                                ref={(el: any) => imagePcRef.current[row.index] = el}
                                src={row.url}
                                onError={() => handleHiddenImage(row.index, imagePcRef)}
                            />
                        ))}
                    </ImgContainer>
                </div>
            </DetailRoot>

            {/* [挑碼] or [現貨] 下單光箱 */}
            <AnimatePresence>
                {isVisiblePick && (
                    <Overlay
                        initial={'initial'}
                        animate={'isOpen'}
                        exit={'exit'}
                        variants={{
                            initial: {opacity: 0, transition: {type:'spring'}},
                            isOpen: {opacity: 1},
                            exit: {opacity: 0}
                        }}
                    >
                        <PageScrollView className="d-flex align-items-end align-items-md-center justify-content-md-center">
                            <PickModal
                                isInStock={isInStock}
                                onClose={() => setVisiblePick(false)}
                                onBack={() => setVisiblePick(false)}
                                id={get(productDetail, 'id')}
                                brandName={get(productDetail, 'brandName')}
                                name={get(productDetail, 'name')}
                                model={get(productDetail, 'model')}
                                price={get(productDetail, 'price')}
                                prePrice={get(productDetail, 'prePrice')}
                                isSale={get(productDetail, 'isSale')}
                                inStock={get(productDetail, 'inStock')}
                                size={get(productDetail, 'sizes')}
                                imageUrl={isEmpty(get(productDetail, 'images.0.url', '')) ? asset('/images/goods/default.jpg') : get(productDetail, 'images.0.url', '')}
                                inStockSizeQty={inStockSizeQty}
                                setInStockSizeQty={(sizeArray: Array<{size: string, qty: number}>) => setInStockSizeQty(sizeArray)}
                                pickSizeQty={pickSizeQty}
                                setPickSizeQty={(sizeArray: Array<{size: string, qty: number}>) => setPickSizeQty(sizeArray)}
                            />
                        </PageScrollView>
                    </Overlay>
                )}
            </AnimatePresence>


            {/* 整手下單光箱 */}
            <AnimatePresence>
                {isVisibleBatch && (
                    <Overlay
                        initial={'initial'}
                        animate={'isOpen'}
                        exit={'exit'}
                        variants={{
                            initial: {opacity: 0, transition: {type:'spring'}},
                            isOpen: {opacity: 1},
                            exit: {opacity: 0}
                        }}
                    >
                        <PageScrollView className="d-flex align-items-end align-items-md-center justify-content-md-center">
                            <BatchModal
                                onClose={() => setVisibleBatch(false)}
                                onBack={() => setVisibleBatch(false)}
                                id={get(productDetail, 'id')}
                                brandName={get(productDetail, 'brandName')}
                                name={get(productDetail, 'name')}
                                model={get(productDetail, 'model')}
                                price={get(productDetail, 'price')}
                                prePrice={get(productDetail, 'prePrice')}
                                isSale={get(productDetail, 'isSale')}
                                size={get(productDetail, 'sizes')}
                                batchPrice={get(productDetail, 'batchPrice')}
                                batchUnitQty={get(productDetail, 'batchUnitQty')}
                                imageUrl={isEmpty(get(productDetail, 'images.0.url', '')) ? asset('/images/goods/default.jpg') : get(productDetail, 'images.0.url', '')}
                                batchQty={batchQty}
                                setBatchQty={(num: number) => setBatchQty(num)}
                            />
                        </PageScrollView>
                    </Overlay>
                )}
            </AnimatePresence>


            {/* 相關產品光箱 */}
            <AnimatePresence>
                {isVisibleRelated && (
                    <Overlay
                        initial={'initial'}
                        animate={'isOpen'}
                        exit={'exit'}
                        variants={{
                            initial: {opacity: 0, transition: {type:'spring'}},
                            isOpen: {opacity: 1},
                            exit: {opacity: 0}
                        }}
                    >
                        <PageScrollView className="d-flex align-items-end align-items-md-center justify-content-md-center">
                            <RelatedModal
                                onClose={() => setVisibleRelated(false)}
                                id={get(productDetail, 'id')}
                                brandName={get(productDetail, 'brandName')}
                                name={get(productDetail, 'name')}
                                model={get(productDetail, 'model')}
                                price={get(productDetail, 'price')}
                                prePrice={get(productDetail, 'prePrice')}
                                isSale={get(productDetail, 'isSale')}
                                relatedProducts={relatedProducts}
                                imageUrl={isEmpty(get(productDetail, 'images.0.url', '')) ? asset('/images/goods/default.jpg') : get(productDetail, 'images.0.url', '')}
                                isOrderNormalGoods={isOrderNormalGoods}
                            />
                        </PageScrollView>
                    </Overlay>
                )}
            </AnimatePresence>


            {/* 尺寸說明光箱 */}
            <AnimatePresence>
                {isVisibleSizeDesc && (
                    <Overlay
                        initial={'initial'}
                        animate={'isOpen'}
                        exit={'exit'}
                        variants={{
                            initial: {opacity: 0, transition: {type:'spring'}},
                            isOpen: {opacity: 1},
                            exit: {opacity: 0}
                        }}
                    >
                        <PageScrollView className="d-flex align-items-end align-items-md-center justify-content-md-center">
                            <SizeDescModal
                                onClose={() => setVisibleSizeDesc(false)}
                                sizesTable={get(productDesc, 'sizeTables', [])}
                            />
                        </PageScrollView>
                    </Overlay>
                )}
            </AnimatePresence>

            {/* 你可能會喜歡的商品 */}
            {/*<LikeTitle className="d-none d-lg-block">你可能會喜歡</LikeTitle>*/}
            {/*<LikeSubTitle className="d-none d-lg-block">You May Also Like</LikeSubTitle>*/}
            {/*<LikeProductCarousel data={likeProductList} className="d-none d-lg-block"/>*/}
        </>
    );
};

export default Detail;

// const LikeSubTitle = styled.div`
//     font-size: 16px;
//     text-align: center;
//     color: #9d9a9a;
//     margin-bottom: 30px;
//
//     ${media.xl`
//         font-size: 24px;
//         margin-bottom: 50px;
//     `}
// `;
//
// const LikeTitle = styled.div`
//     font-size: 30px;
//     font-weight: bold;
//     text-align: center;
//     color: #282731;
//     margin-bottom: 6px;
//     margin-top: 50px;
//
//     ${media.xl`
//         font-size: 40px;
//         margin-bottom: 10px;
//     `}
// `;
//
// const LikeProductCarousel = styled(ProductCarousel)`
//     margin-bottom: 50px;
// `;

const Overlay = styled(motion.div)`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
`;

const ProductImg = styled.img`
    padding: 0 20px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;

    ${media.md`
        padding: 0 40px;
        margin-bottom: 50px;
        width: auto;
        max-width: 100%;
    `}

    ${media.xl`
        padding: 0 20px 50px 30px;
        margin-bottom: 0;
    `}
`;

const ImgContainer = styled(Container)`
    ${media.xl`
        order: 1;
    `}
`;

const Directions = styled.div`
    font-size: 12px;
    color: #9d9a9a;
    padding: 0 10px 20px 10px;

    ${media.md`
        font-size: 16px;
        padding: 0 47px 0 0;
        margin-bottom: 50px;
    `}

    ${media.xl`
        padding: 0 100px;
    `}

    ${media.xxl`
        padding: 0 170px;
    `}
`;

const DetailTabs = styled(Tabs)`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    ul{
        li{
            padding: 0 20px;
        }
    }

    ${media.md`
        margin-bottom: 30px;

        ul{
            width: 100%;
            justify-content: space-between;

            li{
                padding: 0 35px;
            }
        }
    `}

    ${media.xl`
        margin-bottom: 40px;

        ul{
            justify-content: center;

            li{
                padding: 0 50px;

                div {
                    font-size: 24px;
                }
            }
        }
    `}
`;

const FavoriteButton = styled.button`
    min-width: 46px;
    min-height: 46px;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin: 0 0 46px 20px;
`;

const SubmitButton = styled(Button)<any>`
    width: 160px;
    height: 44px;
    min-height: auto;
    font-size: 14px;
    margin: 10px 0 46px 0;

    ${props => props.isSingle && css`
        width: 240px;
    `}

    ${media.md`
        width: 100%;
        height: 46px;
        margin: 0 0 40px 0;
    `}
`;

const PreSaleButton = styled(Button)`
    width: 110px;
    height: 44px;
    min-height: auto;
    font-size: 14px;
    margin: 10px 10px 46px 0;

    ${media.md`
        min-width: 240px;
        height: 46px;
        margin: 0 10px 40px 0;
    `}

    ${media.xl`
        min-width: 170px;
    `}

    ${media.xxl`
        min-width: 240px;
    `}
`;

const HandOrderInput = styled.input<any>`
    width: 80px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: ${site.theme.primaryColor};
    background-color: #f2f4f9;
    margin: 0 10px;
    padding: 0;
    text-align: center;
    border: none;
`;

const SizeText = styled.div`
    min-height: 40px;
    color: #9d9a9a;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
`;

const OrderSize = styled.div`
    margin-bottom: 10px;
`;

const AccordionContent = styled.div`
    padding: 6px 40px 3px 0;
`;

const SizeAccordion = styled.div<any>`
    overflow: hidden;
    padding: 0;
    transition: height .2s;
`;

const OrderTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;
    flex: 1 1 auto;

    ${media.md`
        font-size: 16px;
    `}
`;

const OrderInfo = styled.div`
    ${media.md`
        display: inline-flex;
        height: 56px;
        max-height: 56px;
        cursor: pointer;
    `}
`;

const OrderDetail = styled.div`
    height: 100%;

    ${media.md`
        border-bottom: solid 1px #e8e8e8;
    `}
`;

const InStockTitle = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;
    margin-right: 10px;

    ${media.md`
        width: 110px;
        height: 56px;
        font-size: 16px;
        margin-right: 0;
        display: flex;
        align-items: center;
    `}
`;

const InStock = styled.div`
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;

    ${media.md`
        min-height: 56px;
        margin-bottom: 20px;
        display: inline-table;
    `}

    ${media.xl`
        width: 500px;
    `}
`;

const ShowNum = styled.span<any>`
    font-size: 12px;
    color: #9d9a9a;

    ${media.md`
        font-size: 16px;
    `}

    ${props => props.isActive && css`
        color: ${site.theme.primaryColor};
    `}
`;

const HandOrderNum = styled.div`
    width: 60px;
    height: 26px;
    font-size: 14px;
    font-weight: bold;
    color: ${site.theme.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    ${media.md`
        width: 80px;
        height: 30px;
        font-size: 20px;
    `};
`;

const HandOrderButton = styled.button`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 1px #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: box-shadow .3s;

    i{
        transition: color .3s;
    }

    ${media.md`
        width: 30px;
        height: 30px;

        i{
            font-size: 15px;
        }
    `}

    ${props => props.disabled && css`
        i{
            opacity: 0.4;
        }
    `}

    ${media.lg`
        ${(props: any) => !props.disabled && css`
            &:hover{
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

                i{
                    color: #8ec5ff;
                }
            }
        `}
    `}
`;

const HandOrderTitle = styled.span`
    min-width: 70px;
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        min-width: 110px;
        height: 56px;
        display: flex;
        align-items: center;
    `}
`;

const HandOrder = styled.div`
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;

    ${media.md`
        height: auto;
        min-height: 56px;
        margin-bottom: 20px;
    `}

    ${media.xl`
        width: 500px;
    `}
`;

const CustomSize = styled(Size)`
    ${media.md`
        min-width: 40px;
        min-height: 40px;
        font-size: 22px;
    `}
`;

const Color = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: #6e6e6e;
    padding-left: 4px;

    ${media.md`
        font-size: 16px;
        font-weight: 600;
        color: #9d9a9a;
        padding-left: 0;
    `}
`;

const SizeTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;
    margin-right: 17px;
    min-width: 44px;

    ${media.md`
        font-size: 16px;
        font-weight: 600;
        min-width: 110px;
        margin-right: 0;
    `}

    ${media.xl`
        font-size: 20px;
        font-weight: bold;
    `}
`;

const SizeSection = styled.div`
    width: 100%;
    min-height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 15px 0;

    ${media.md`
        height: auto;
        margin-bottom: 20px;
    `}
`;

const RightButton = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    ${media.md`
        width: 30px;
        height: 30px;

        i{
            font-size: 15px;
        }
    `}
`;

const RelatedImg = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 10px;
    cursor: pointer;

    ${media.md`
        width: 40px;
        height: 40px;
        border-radius: 0;
        margin-right: 20px;
    `}
`;

const RelatedTitle = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #6e6e6e;

    ${media.md`
        font-size: 20px;
        min-width: 110px;
    `}
`;

const RelatedSection = styled.div`
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    margin-bottom: 10px;

    ${media.md`
        margin-bottom: 20px;
    `}
`;

const SizeDirections = styled.span`
    font-size: 10px;
    font-weight: 600;
    color: #9d9a9a;
    text-decoration: underline;
    white-space: nowrap;

    ${media.md`
        font-size: 16px;
    `}
`;

const SizeIcon = styled(Icon)`
    margin-right: 5px;

    ${media.md`
        font-size: 30px;
        margin-right: 12px;
    `}
`;

const SizeDesc = styled.div`
    cursor: pointer;
`;

const PriceDesc = styled.div<any>`
    font-size: 12px;
    color: #6e6e6e;
    padding-left: 20px;

    ${props => props.isExtraFare && css`
        color: #ff7d6a;
    `}

    ${media.md`
        font-size: 16px;
        padding-bottom: 5px;
    `}
`;

const Price = styled.span<any>`
    font-size: 20px;
    font-weight: bold;
    color: #6e6e6e;
    line-height: 1;

    ${media.md`
        font-size: 50px;
    `}

    ${media.xl`
        font-size: 60px;
    `}

    ${props => props.isSale && css`
        color: #ff7d6a;
    `}
`;

const PrePrice = styled.span`
    font-size: 12px;
    color: #6e6e6e;
    text-decoration: line-through;
    margin-right: 4px;

    ${media.md`
        font-size: 20px;
        margin-right: 14px;
        padding-bottom: 5px;
    `}

    ${media.xl`
        font-size: 30px;
    `}
`;

const PriceSection = styled.div`
    white-space:nowrap;
    text-align: right;
`;

const Model = styled.span`
    font-size: 12px;
    font-weight: 300;
    color: #6e6e6e;

    ${media.md`
        font-size: 16px;
        margin-bottom: 20px;
    `}
`;

const Name = styled.span`
    font-size: 12px;
    font-weight: bold;
    color: #282731;
    margin-bottom: 4px;

    ${media.md`
        font-size: 20px;
        margin-bottom: 10px;
    `}

    ${media.xl`
        font-size: 24px;
    `}
`;

const BrandName = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: #282731;
    cursor: pointer;

    ${media.md`
        font-size: 32px;
        display: inline-block;
        margin-top: 10px;
    `}

    ${media.xl`
        font-size: 40px;
    `}
`;

const BrandSection = styled.div`
    ${media.md`
        display: block;
        margin-bottom: 10px;
    `}

    ${media.xl`
        margin-bottom: 20px;
    `}
`;

const ProductInfo = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 10px 0;
    margin-bottom: 10px;

    ${media.md`
        padding: 0;
        margin-bottom: 20px;
    `}
`;

const CustomTag = styled(Tag)`
    width: 32px;
    height: 16px;
    margin: 0 4px 0 0;

    ${media.md`
        width: 44px;
        height: 22px;
        margin: 0 5px 0 0;
    `}
`;

const TagSection = styled.div`
    position: absolute;
    left: 10px;
    bottom: 8px;
    display: flex;

    ${media.md`
        display: none;
    `}
`;

const BagNum = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ff7d6a;
    position: absolute;
    top: -2px;
    right: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: bold;
    color: #ffffff;
`;

const RightSection = styled.div`
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
`;

const IconButton = styled.button`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
`;

const BackButton = styled(IconButton)`
    position: absolute;
    top: 15px;
    left: 10px;
`;

const BagButton = styled(IconButton)`
    margin-left: 10px;
    position: relative;
`;

const ProductBg = styled.img<any>`
    width: 100%;
    height: auto;
    position: relative;

    ${media.md`
        width: 54%;
        margin-bottom: 37px;
    `}
`;

const DetailContainer = styled(Container)<any>`
    ${media.md`
        padding: 0;
    `}
`;

const InfoSection = styled.div`
    ${media.xl`
        order: 2;
        padding: 0 40px 0 20px;
    `}

    ${media.xxl`
        min-width: 740px;
        max-width: 740px;
    `}
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 300;
    color: #9d9a9a;
    margin: 30px 0;
`;

const DetailRoot = styled.div`
    width: 100%;
    background-color: #fafafa;

    ${media.md`
        background-color: #ffffff;
    `}

    ${media.xl`
        max-width: 1140px;
        margin-right: auto;
        margin-left: auto;
    `}

    ${media.xxl`
        max-width: 1460px;
    `}
`;
