import React, {useState} from 'react';
import * as CSS from 'csstype';
import styled, {css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import Icon from 'components/atoms/Icon';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    title: string;
    content: React.ReactNode;
    children?: React.ReactNode;
}

/**
 * Collapsible
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Collapsible: React.FC<IProps> = ({
className,
    style,
    children,
    title,
    content,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <CollapsibleRoot className="d-flex align-items-center flex-column">
            <CollapsibleCard
                className={className}
                style={style}
                isVisible={isVisible}
                onClick={() => setIsVisible(!isVisible)}
            >
                <Title>{title}</Title>
                <Content>{content}</Content>
                <Triangle/>
                <TriggerIcon isVisible={isVisible} code={isVisible ? 'times-bold' : 'stream'} color="#ffffff" size={isVisible ? 11 : 15}/>
            </CollapsibleCard>

            <Children isVisible={isVisible}>
                {children}
            </Children>
        </CollapsibleRoot>
    );
};

export default Collapsible;

const CollapsibleRoot = styled.div`
    overflow: hidden;
    margin-bottom: 30px;
`;

const CollapsibleCard = styled.div<any>`
    width: 100%;
    max-width: 840px;
    min-height: 134px;
    border-radius: 4px;
    border: solid 1px rgba(156, 146, 255, 0.6);
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    padding: 10px;
    z-index: 1;
`;

const Triangle = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 40px 40px;
    border-color: transparent transparent rgba(156, 146, 255, 0.6) transparent;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const TriggerIcon = styled(Icon)<any>`
    position: absolute;
    bottom: ${props => (props.isVisible ? 6 : 4)}px;
    right: 6px;

    ${media.lg`
        font-size: ${(props: any) => (props.isVisible ? 13 : 17)}px;
    `};
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.theme.thirdColor};
    padding-bottom: 10px;
    display: block;

    ${media.lg`
        font-size: 20px;
    `};
`;

const Content = styled.span`
    font-size: 14px;
    color: #6e6e6e;
    display: block;

    ${media.lg`
        font-size: 20px;
    `};
`;

const Children = styled.div<any>`
    width: 100%;
    max-width: 840px;
    max-height: 0;
    padding: 0 10px;
    transition: transform .3s ease, opacity .1s ease, max-height .3s ease;
    opacity: 0;

    ${props => props.isVisible && css`
        opacity: 1;
        max-height: 100vh;
    `};
`;
