// Libraries & Utils
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

// Stores
import {Selector as AuthSelector, Actions as AuthAction} from 'store/auth';


interface IProps {
    component: React.FC | React.Component;
    exact?: boolean,
    path: string;
}

/**
 *
 * @param Component
 * @param path 路由路徑
 * @param exact 路由路徑是否需完整比對
 *              ex: sign => sign/login (false)
 *                  sign => sign       (true)
 * @constructor
 */
const PrivateRoute: React.FC<IProps> = ({
    component: Component,
    path,
    exact = false,
}) => {
    const dispatch = useDispatch();
    const isAuth = useSelector(AuthSelector.isAuth);

  return (
      <Route
          path={path}
          exact={exact}
          render={({location, ...props}) => {
              if (!isAuth) {
                  // 如果未登入，導回首頁並且開啟登入光箱
                  dispatch(AuthAction.openLoginModal());

                  return <Redirect to={{pathname: '/', state: {from: location}}}/>;
              }
              // @ts-ignore
              return <Component {...props}/>;
          }}
      />
  );
};

export default PrivateRoute;
