import {createActions, createReducer} from "reduxsauce";
import {IAsyncReducer, TActionCreators} from "./types";
import {upperLineToLowerCase} from "../../utils/format";
import {ImmutableObject} from "seamless-immutable";

/**
 * 轉換
 * {
 *      setInfo: (state, { payload }) => {…}
 * }
 *               ||
 * {
 *     setInfo: ['payload']
 * }
 * @param reducer
 */
function convertReducerAction(reducer: IAsyncReducer){
    return Object.keys(reducer).reduce((rows,key) => {
        return Object.assign(rows, {[key]: ['payload']});
    }, {});
}

/**
 * 轉換
 * auth  +  SET_INFO  +  {setInfo: (state, { payload }) => {…}}
 *                 ||
 * {
 *    auth/SET_INFO: (state, { payload }) => {…}
 * }
 *
 * @param storeKey
 * @param types
 * @param reducerFunc
 */
function convertTypeReducer<T>(storeKey: string, types: T, reducerFunc: IAsyncReducer){
    return Object.keys(types).reduce((rows,typeName) => {
        const mappingFunc = reducerFunc[upperLineToLowerCase(typeName)];
        if(typeof mappingFunc === 'undefined'){
            throw Error(`reducer mapping '${typeName}' function undefined, please check src/store/{serviceName}/reducer.ts`);
        }
        return Object.assign(rows, {[`${storeKey}${typeName}`]: mappingFunc});
    }, {});
}

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReduxSauce<T, A, S>(storeKey: string, InitialState: ImmutableObject<S>, reducers = {}) {
    const prefix = `${storeKey}/`;
    const reducerCase = convertReducerAction(reducers);
    const {Types, Creators} = createActions<T, TActionCreators<A>>(reducerCase, {prefix});

    const mapping = convertTypeReducer<T>(prefix, Types, reducers);
    const Reducer = createReducer<ImmutableObject<S>>(InitialState, mapping);
    return {
        ActionTypes: Types,
        Actions: Creators,
        Reducer
    }

}
