import React from 'react';
import CSS from 'csstype';
import cx from 'classnames';
import styled, {css, keyframes} from 'styled-components/macro';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    type?: 'fontClass' | 'svg';
    isInline?: boolean;
    onClick?: Function;
    size?: number;
    color?: string;
    code: string;
    isRotateAnimation?: boolean;
    rotate?: number;
}

/**
 * Icon
 * 依賴阿里巴巴Iconfont服務
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Icon: React.FC<IProps> = ({
    style,
    isInline = false,
    className,
    type = 'fontClass',
    onClick,
    size = 22,
    color = '#bdbdbd',
    code,
    isRotateAnimation = false,
    rotate,
}) => (
    <BasicIcon
        isInline={isInline}
        isRotateAnimation={isRotateAnimation}
        onClick={onClick}

        style={style}
        className={cx(className, `iconfont icon-${code}`)}
        type={type}
        size={size}
        rotate={rotate}
        color={color}
    >
        {type === 'svg' && <use xlinkHref={`#icon-${code}`}/>}
    </BasicIcon>
);

export default Icon;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const BasicIcon = styled.i.attrs((props: IProps) => ({
    as: props.type === 'svg' ? 'svg' : undefined,
}))<any>`
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        color: ${props =>  (props.color ? props.color : '#bdbdbd')};
        font-size: ${props =>  props.size}px;
        font-weight: 100;
        line-height: normal;

        width: ${props =>  props.size}px;
        height: ${props =>  props.size}px;

        vertical-align: middle;
        fill: currentColor;
        margin: 0;

        ${props =>  props.isRotateAnimation && css`
            animation: ${rotateAnimation} 1s linear infinite;
        `}

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;

            ${props =>  props.rotate && css`
                transform: rotate(${props.rotate}deg);
            `}
        };
`;
