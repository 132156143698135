// Libraries & Utils
import Immutable from 'seamless-immutable';
import {TReducers} from 'library/redux';
import createReduxSauce from 'library/redux/createReduxSauce';

// Types
import {IActionCreators, IActionTypes, IState, ISelector} from './types';


/** -----------------------------------------
 Store Key
 /** ---------------------------------------*/
export const storeKey = 'cart';


/** -----------------------------------------
 Initial State
 /** ---------------------------------------*/
export const InitialState = Immutable<IState>({
    isFetching: false,
    isSubmitting: false,
    isFetchingDetail: false,
    message: '',

    memberCart: [],
    memberCartTotal: {
        totalAmount: 0,
        totalItems: 0,
    },

    orderDetail: {
        id: 0,
        batchQty: 0,
        createdAt: '',
        pickType: {
            id: 0,
            name: '',
            code: '',
        },
        sizeQty: [],
        totalQty: 0,
        totalAmount: 0,
        productGoods: {
            id: 0,
            model: '',
            name: '',
            color: '',
            sizes: [],
            brandName: '',
            thumbUrl: '',
            price: 0,
            prePrice: 0,
            isSale: false,
            isOutStock: false,
            isCanChooseSize: false,
            isTrack: false,
            inStock: [],
            batchPrice: 0,
            batchUnitQty: 0,
        }
    }
});

/** -----------------------------------------
 Selectors
 /** --------------------------------------*/
export const Selector: ISelector = {
    orderDetail: (state) => state[storeKey].orderDetail,
};



/** -----------------------------------------
 Reducers
 /** ---------------------------------------*/
const Reducers: TReducers<IActionCreators ,IState> = {
    /**
     * 送出商品到購物車（整批）
     */
    submitCartBatch: (state) => {
        return state;
    },
    submitCartBatchBegin: (state) => {
        return state.merge({isFetching: true, message: '正在加入購物袋'});
    },
    submitCartBatchSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    submitCartBatchFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 送出商品到購物車（挑碼 or 現貨）
     */
    submitCartPick: (state) => {
        return state;
    },
    submitCartPickBegin: (state) => {
        return state.merge({isFetching: true, message: '正在加入購物袋'});
    },
    submitCartPickSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: ''});
    },
    submitCartPickFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 查詢購物車所有商品
     */
    fetchMemberCart: (state) => {
        return state;
    },
    fetchMemberCartBegin: (state) => {
        return state.merge({isFetching: true, message: '正在讀取購物袋商品列表'});
    },
    fetchMemberCartSuccess: (state, {payload}) => {
        return state.merge({isFetching: false, message: '', memberCart: payload.memberCart, memberCartTotal: payload.memberCartTotal});
    },
    fetchMemberCartFail: (state) => {
        return state.merge({isFetching: false, message: ''});
    },
    /**
     * 刪除購物車中的所有商品
     */
    cleanMemberCart: (state) => {
        return state;
    },
    cleanMemberCartBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在清除購物袋'});
    },
    cleanMemberCartSuccess: (state) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    cleanMemberCartFail: (state) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    /**
     * 刪除購物車的商品（多筆）
     */
    deleteMemberCart: (state) => {
        return state;
    },
    deleteMemberCartBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在刪除選取中的商品'});
    },
    deleteMemberCartSuccess: (state) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    deleteMemberCartFail: (state) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    /**
     * 查詢購物車商品下單明細
     */
    fetchCartOrderDetail: (state) => {
        return state;
    },
    fetchCartOrderDetailBegin: (state) => {
        return state.merge({isFetchingDetail: true, message: '正在讀取商品明細'});
    },
    fetchCartOrderDetailSuccess: (state, {payload}) => {
        return state.merge({isFetchingDetail: false, message: '', orderDetail: payload.orderDetail});
    },
    fetchCartOrderDetailFail: (state) => {
        return state.merge({isFetchingDetail: false, message: ''});
    },
    /**
     * 更改 商品到購物車(整批) 尺寸數量
     */
    putCartBatch: (state) => {
        return state;
    },
    putCartBatchBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在更改商品下單數量'});
    },
    putCartBatchSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    putCartBatchFail: (state) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    /**
     * 更改 商品到購物車(挑碼) 尺寸數量
     */
    putCartPick: (state) => {
        return state;
    },
    putCartPickBegin: (state) => {
        return state.merge({isSubmitting: true, message: '正在更改商品下單數量'});
    },
    putCartPickSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    putCartPickFail: (state) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    /**
     * 新增 購物車商品到訂單
     */
    submitCartOrder: (state) => {
        return state;
    },
    submitCartOrderBegin: (state) => {
        return state.merge({isSubmitting: true, message: '訂單成立中'});
    },
    submitCartOrderSuccess: (state, {payload}) => {
        return state.merge({isSubmitting: false, message: ''});
    },
    submitCartOrderFail: (state) => {
        return state.merge({isSubmitting: false, message: ''});
    },
};


export const {Reducer, Actions, ActionTypes} = createReduxSauce<IActionTypes, IActionCreators, IState>(storeKey, InitialState, Reducers);
