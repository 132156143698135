// Libraries
import api from 'library/apisauce';

// Types
import {TGetSetting, TGetBank, TShippingGuide, TSettingFaq, THotKeyword} from './types';


export default {
    /**
     * 取得系統設定
     */
    getSetting: (): Promise<TGetSetting> => {
        return api.get('/setting/site', {});
    },
    /**
     * 取得匯款銀行清單
     */
    getDepositBank: (): Promise<TGetBank> => {
        return api.get('/setting/deposit/bank', {}, {
            headers: {isAuth: true},
        });
    },
    /**
     * 查詢 購物說明設定
     */
    getShippingGuide: (): Promise<TShippingGuide> => {
        return api.get('/setting/shipping/guide', {});
    },
    /**
     * 查詢 常見問題
     */
    getSettingFaq: (): Promise<TSettingFaq> => {
        return api.get('/setting/faq', {});
    },
    /**
     * 查詢 商品查詢關鍵字
     */
    getHotKeyword: (): Promise<THotKeyword> => {
        return api.get('/ad/hotKeyword', {});
    },
};
