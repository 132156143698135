// Libraries & Utils
import React from 'react';
import styled,{css} from 'styled-components';
import {media} from 'library/styled-bs-grid';
import {HalfCircleSpinner} from 'react-epic-spinners';


interface IProps {
    isVisible: boolean;
    message?: string;
    transitionTiming?: number;
    position?: 'fixed'|'absolute';
}

const Block: React.FC<IProps> = ({
    isVisible = false,
    message,
    transitionTiming = 400,
    position = 'absolute',
}) => {

    return (
        <BlockContainer
            isVisible={isVisible}
            position={position}
            transitionTiming={transitionTiming}
        >
            {message} <HalfCircleSpinner style={{marginLeft: 5}} size={16}/>
        </BlockContainer>
    );
};

export default Block;

const BlockContainer = styled.div<{
    isVisible: boolean;
    transitionTiming: number;
    position: 'fixed'|'absolute';
}>`
    z-index: 997;
    color: #fff;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.6);
    position: ${props=>props.position};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
    transition: opacity ${props => props.transitionTiming}ms;
    opacity: 0;

    ${props => props.isVisible && css`
        opacity: 1;
        pointer-events: auto;
    `};


    ${media.lg`
        font-size: 16px;
    `}
`;
