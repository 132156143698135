import React, {useState, useCallback, useRef, useMemo} from 'react';
import * as CSS from 'csstype';
import {media, Container, Row, Col} from 'library/styled-bs-grid';
import {useHistory} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {get} from 'lodash';
import site from 'config/site';
import {checkIsMobile} from 'utils/browser';

// Component
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    categoryList: Array<{
        id: number;
        name: string;
        nickName?: string;
        isNew: boolean;
        categoryMains: Array<{
            id: number;
            name: string;
            categorySubs: Array<{
                id: number;
                name: string;
            }>;
        }>;
    }>;
    isOrderVipGoods?: boolean;
    isOrderPreSale?: boolean;
    adImageUrl: string;
    adSearchParam?: string;
}

/**
 * ProductCategory
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProductCategory: React.FC<IProps> = ({
    className,
    style,
    categoryList,
    isOrderVipGoods = false,
    isOrderPreSale= false,
    adImageUrl,
    adSearchParam = '',
}) => {
    const history = useHistory();
    const expandRef = useRef<Array<HTMLDivElement>>([]);
    const [isVisibleMore, setVisibleMore] = useState(false);
    const [isVisibleCategory, setVisibleCategory] = useState(false);
    const [categoryCode, setCategoryCode] = useState(undefined);

    const handleSelectedCategory = (code: any) => {
        // 分類群組：手機版點擊直接導頁
        if (checkIsMobile()) {
            history.push(`/product?categoryGroupId=${code}`)
        }
        setCategoryCode(code);
        setVisibleCategory(!isVisibleCategory);
    };

    const getHeight = useCallback((num: number) => {
        return expandRef?.current[num]?.scrollHeight;
    }, []);

    const memoCategoryGroup = useMemo(() => {
        return categoryList.map(row => (
            <CategoryCol
                key={`productCategory_${row.id}`}
                col="auto"
                forwardAs="button"
                type="button"
                onClick={() => handleSelectedCategory(row.id)}
            >
                <CategoryButton isActive={(categoryCode === row.id && isVisibleCategory)}>
                    {row.name}
                </CategoryButton>

                {row.isNew && (
                    <New className="d-none d-md-block">
                        NEW
                        <Arrow/>
                    </New>
                )}
            </CategoryCol>
        ))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryList, isVisibleCategory]);

    return (
        <Container className={className} style={style}>
            <ProductCategoryRow>
                {isVisibleCategory && <CloseArea className="d-none d-md-block" onClick={() => setVisibleCategory(false)}/>}

                <CategoryGroupCol col>
                    <CategoryRow isVisibleMore={isVisibleMore} className="d-md-flex justify-content-md-center">
                        <CategoryCol
                            col="auto"
                            forwardAs="button"
                            type="button"
                            onClick={() => {
                                setVisibleCategory(false);
                                history.push('/product');
                            }}
                        >
                            <CategoryButton>全部</CategoryButton>
                        </CategoryCol>

                        {memoCategoryGroup}

                        <CategoryCol
                            col="auto"
                            forwardAs="button"
                            type="button"
                            onClick={() => {
                                setVisibleCategory(false);
                                history.push('/product?popularId=1');
                            }}
                        >
                            <CategoryButton>現貨</CategoryButton>
                        </CategoryCol>
                        <CategoryCol
                            col="auto"
                            forwardAs="button"
                            type="button"
                            onClick={() => {
                                setVisibleCategory(false);
                                history.push('/product?popularId=2');
                            }}
                        >
                            <CategoryButton>新品</CategoryButton>
                        </CategoryCol>
                        <CategoryCol
                            col="auto"
                            forwardAs="button"
                            type="button"
                            onClick={() => {
                                setVisibleCategory(false);
                                history.push('/product?popularId=3');
                            }}
                        >
                            <CategoryButton>特價</CategoryButton>
                        </CategoryCol>

                        {isOrderPreSale && (
                            <CategoryCol
                                col="auto"
                                forwardAs="button"
                                type="button"
                                onClick={() => {
                                    setVisibleCategory(false);
                                    history.push('/product?popularId=5');
                                }}
                            >
                                <CategoryButton>預先下特</CategoryButton>
                            </CategoryCol>
                        )}

                        {/* 隱藏 Vip */}
                        {isOrderVipGoods && (
                            <CategoryCol
                                col="auto"
                                forwardAs="button"
                                type="button"
                                onClick={() => {
                                    setVisibleCategory(false);
                                    history.push('/product?popularId=4');
                                }}
                                style={{opacity: 0}}
                            >
                                <CategoryButton>
                                    <Icon code="star" color="#000000" size={17}/>
                                </CategoryButton>
                            </CategoryCol>
                        )}
                    </CategoryRow>
                </CategoryGroupCol>
                <Col col="auto" className="pl-0 d-md-none">
                    <MoreIcon onClick={() => setVisibleMore(!isVisibleMore)}>
                        <Icon code="chevron-fillet-down" color={site.theme.thirdColor} size={10} rotate={isVisibleMore ? 180 : 0}/>
                    </MoreIcon>
                </Col>

                {categoryList.map((group, i) => (
                    <Detail
                        ref={(el: any) => {
                            expandRef.current[i] = el;
                            return false;
                        }}
                        key={`productCategory_group_${group.id}`}
                        className="d-none d-lg-flex justify-content-center"
                        style={{height: (isVisibleCategory && group.id === categoryCode) ? `${getHeight(i)}px` : '0px'}}
                    >
                        <DetailContainer>
                            <Row className="m-0">
                                <Col col className="d-flex justify-content-center">
                                    <Row>
                                        {group.categoryMains.map((row: any) => (
                                            <CategoryMainsCol col="auto" key={row.id}>
                                                <CategoryMains
                                                    onClick={() => {
                                                        setVisibleCategory(false);
                                                        history.push(`/product?categoryGroupId=${categoryCode}&categoryMainId=${row.id}`);
                                                    }}
                                                >
                                                    {row.name}
                                                </CategoryMains>
                                                <CategorySubsSection isWrap={get(row, 'categorySubs.length') > 10}>
                                                    {row.categorySubs.map((item: any) => (
                                                        <CategorySubsName
                                                            key={item.id}
                                                            onClick={() => {
                                                                setVisibleCategory(false);
                                                                history.push(`/product?categoryGroupId=${categoryCode}&categoryMainId=${row.id}&categorySubId=${item.id}`);
                                                            }}
                                                        >
                                                            {item.name}
                                                        </CategorySubsName>
                                                    ))}
                                                </CategorySubsSection>
                                            </CategoryMainsCol>
                                        ))}
                                    </Row>
                                </Col>
                                <Col col="auto">
                                    <ProductImg imageUrl={adImageUrl}>
                                        <ShopButton
                                            themeType="whiteBorderBlur"
                                            size="middle"
                                            shape="raised"
                                            isBlurBg
                                            onClick={() => {
                                                setVisibleCategory(false);
                                                history.push(adSearchParam);
                                            }}
                                        >
                                            SHOP NOW
                                        </ShopButton>
                                    </ProductImg>
                                </Col>
                            </Row>
                        </DetailContainer>
                    </Detail>
                ))}
            </ProductCategoryRow>
        </Container>
    );
};

export default ProductCategory;

const CategorySubsName = styled.span<any>`
    font-size: 14px;
    color: #6e6e6e;
    display: block;
    position: relative;
    padding-left: 7px;
    margin-bottom: 10px;
    margin-right: 25px;
    cursor: pointer;
    writing-mode: horizontal-tb;

    ${props => props.isBold && css`
        font-weight: bold;
    `}

    &:before {
        content: '';
        width: 2px;
        height: 18px;
        background-color: #9c92ff;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: 0;
        transition: opacity .3s;
    }

    ${media.md`
        &:hover {
            &:before {
                opacity: 1;
            }
        }
    `}

    ${media.lg`
        margin-right: 36px;
    `}

    ${media.xl`
        font-size: 16px;
    `}
`;

const CategorySubsSection = styled.div<any>`
    display: flex;
    height: 320px;
    flex-wrap: wrap;
    writing-mode: vertical-lr;

    ${props => props.isWrap && css`
        min-width: 150px;
    `}
`;

const CategoryMains = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: #282731;
    padding-bottom: 10px;
    display: block;
    cursor: pointer;

    ${media.xl`
        font-size: 16px;
    `}
`;

const ShopButton = styled(Button)`
    width: 132px;
    height: 40px;
    font-size: 16px;
    min-height: auto;
    padding: 0;
    font-weight: bold;
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 36px;
    margin: auto;

    ${media.lg`
        width: 174px;
        height: 46px;
    `}
`;

const ProductImg = styled.div<any>`
    width: 319px;
    height: 350px;
    background-image: url("${props => props.imageUrl}");
    position: relative;
`;

const CategoryMainsCol = styled(Col)``;

const DetailContainer = styled(Container)`
    padding-top: 45px;
    padding-bottom: 45px;
`;

const Detail = styled.div<any>`
    width: 100vw;
    overflow: hidden;
    box-shadow: 0 25px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    transition: height .3s;
    z-index: 99;
`;

const MoreIcon = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    margin-top: 5px;
`;

const CategoryButton = styled.div<any>`
    font-size: 14px;
    font-weight: 600;
    color: #282731;
    height: 40px;
    padding-top: 5px;
    position: relative;

    ${media.md`
        margin: 0 10px;
        padding-top: 0;
        height: 100%;
        display: flex;
        align-items: center;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 3px;
            background-color: #9c92ff;
            opacity: 0;
            transition: opacity .3s;
            bottom: 0;
            border-radius: 2px;
        }
    `}

    ${media.xl`
        font-size: 16px;
        margin: 0 30px;

        i {
            font-size: 20px;
        }
    `}

    ${props => props.isActive && css`
        &:before {
            opacity: 1;
        }
    `};
`;

const Arrow = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${props => props.theme.primaryColor} transparent transparent transparent;
`;

const New = styled.div`
    width: 42px;
    height: 15px;
    background-color: #8ec5ff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: -5px;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.xl`
        top: 0;
    `}
`;

const CategoryCol = styled(Col)<any>`
    position: relative;

    ${media.md`
        height: 50px;

        &:hover {
            ${CategoryButton} {
                &:before {
                    opacity: 1;
                }
            }
        }
    `}

    ${media.xl`
        height: 60px;
    `}
`;

const CategoryRow = styled(Row)<any>`
    height: 40px;
    overflow: hidden;

    ${props => props.isVisibleMore && css`
        height: auto;
    `}

    ${media.md`
        height: auto;
        overflow: inherit;
    `}
`;

const CategoryGroupCol = styled(Col)`
    z-index: 4;
`;

const CloseArea = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 4;
`;

const ProductCategoryRow = styled(Row)`
    position: relative;
`;
